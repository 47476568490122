import React, { useEffect, useState } from 'react'
import { signOut } from 'firebase/auth'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, Route, useLocation } from 'react-router-dom'
import { auth } from '../apis/firebaseConfig'
import Navbar from '../components/Navbar'
import { signOut as reduxSignout } from '../redux/authSlice'

const PrivateRoutes = ({ children, ...rest }) => {
  const firebaseUser = useSelector((state) => state.auth.firebaseUser)
  const userData = useSelector((state) => state.auth.userData)
  const dispatch = useDispatch()
  const location = useLocation()

  if (!firebaseUser || !userData) {
    signOut(auth)
    dispatch(reduxSignout())
    return <Navigate to={`/login?redirect=${location.pathname}`} />
  }

  // console.log(moment(firebaseUser.lastLoggedIn).startOf('day').diff(moment(), 'days'))
  if (moment(firebaseUser.lastLoggedIn).startOf('day').diff(moment(), 'days') !== 0) {
    signOut(auth)
    dispatch(reduxSignout())
    return <Navigate to={`/login?redirect=${location.pathname}`} replace state={{ from: location.pathname }} />
  }

  return children
}

export default PrivateRoutes
