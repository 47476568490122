import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from 'firebase/auth'
import { createContext, useEffect, useState } from 'react'
import { auth } from '../apis/firebaseConfig'
import { useDispatch } from 'react-redux'
import { signOut as reduxSignout, saveFirebaseLoginData } from '../redux/authSlice'
import moment from 'moment'
import { useSelector } from 'react-redux'

const UserContext = createContext({})

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const savedUser = useSelector((state) => state.auth.userData)
  const dispatch = useDispatch()

  const signIn = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
  }
  const createUser = async (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password)
  }
  const logout = async () => {
    return signOut(auth).then((res) => dispatch(reduxSignout()))
  }
  const changePassword = async (password) => {
    return updatePassword(user, password)
  }

  const firebaseDataRedux = useSelector((state) => state.auth.firebaseUser)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      // console.log(firebaseDataRedux)
      if (currentUser) {
        setUser(currentUser)
        currentUser.role = savedUser.role
        dispatch(
          saveFirebaseLoginData({
            accessToken: currentUser.accessToken,
            refreshToken: currentUser.refreshToken,
            uid: currentUser.uid,
            email: currentUser.email,
            displayName: currentUser.displayName,
            lastLoggedIn: firebaseDataRedux.lastLoggedIn,
            lastRefreshToken: moment().format(),
            isRefreshing: false,
          })
        )
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <UserContext.Provider value={{ user, savedUser, createUser, logout, signIn, changePassword }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext
