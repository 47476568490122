import React, { useState } from 'react'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/components/ui/drawer'
import { Button } from '@/components/ui/button'
import { BarChart, Briefcase, Calendar, FolderOpen, LayoutList, LockOpen, LogOut, Menu } from 'lucide-react'
import CRMLogo from '../assets/icons/crm-logo.png'
import { Link, NavLink } from 'react-router-dom'
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import { useSelector } from 'react-redux'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from './ui/dropdown-menu'
import { signOut } from 'firebase/auth'
import { signOut as reduxSignOut } from '../redux/authSlice'
import { auth } from '../apis/firebaseConfig'
import { useDispatch } from 'react-redux'
import { cn } from '@/lib/utils'

export default function MobileNav() {
  const user = useSelector((state) => state.auth.userData)
  const dispatch = useDispatch()
  const [isDrawerOpen, setDrawerOpen] = useState(false)

  const logout = () =>
    signOut(auth).then((res) => {
      // setUser({})
      dispatch(reduxSignOut())
      console.log('hey')
    })
  return (
    <Drawer direction="left" open={isDrawerOpen} onOpenChange={setDrawerOpen}>
      <DrawerTrigger className="lg:hidden">
        <Button size="sm" asChild>
          <Menu size={24} />
        </Button>
      </DrawerTrigger>
      <DrawerContent className={'h-screen rounded-r-[10px] w-80 bg-main-500 border-main-500'}>
        <DrawerHeader className={'min-h-[56px] w-full justify-start mb-4'}>
          <Link
            className="font-bold leading-relaxed mr-4 py-2 whitespace-nowrap uppercase text-white flex items-center justify-center overflow-hidden"
            to="/"
          >
            <img src={CRMLogo} alt="crm-logo" className="w-[150px]" />
          </Link>
        </DrawerHeader>
        <div className="grid gap-2 px-2.5">
          <DrawerClose asChild>
            <NavLink to="/performance">
              {({ isActive }) => {
                return (
                  <Button
                    size={'lg'}
                    className={cn(
                      'gap-2.5 text-semibold w-full justify-start',
                      isActive ? 'text-primary-yellow bg-main-50/10' : 'text-white'
                    )}
                  >
                    <BarChart className="w-5 h-5" />
                    Performance
                  </Button>
                )
              }}
            </NavLink>
          </DrawerClose>
          <DrawerClose asChild>
            <NavLink to="/sales-plan">
              {({ isActive }) => {
                return (
                  <Button
                    size={'lg'}
                    className={cn(
                      'gap-2.5 text-semibold w-full justify-start',
                      isActive ? 'text-primary-yellow bg-main-50/10' : 'text-white'
                    )}
                  >
                    <Calendar className="w-5 h-5" />
                    Sales Plan
                  </Button>
                )
              }}
            </NavLink>
          </DrawerClose>
          <DrawerClose asChild>
            <NavLink to="/sales-deal">
              {({ isActive }) => {
                return (
                  <Button
                    size={'lg'}
                    className={cn(
                      'gap-2.5 text-semibold w-full justify-start',
                      isActive ? 'text-primary-yellow bg-main-50/10' : 'text-white'
                    )}
                  >
                    <Briefcase className="w-5 h-5" />
                    Sales Deal
                  </Button>
                )
              }}
            </NavLink>
          </DrawerClose>
          <DrawerClose asChild>
            <NavLink to="/projects">
              {({ isActive }) => {
                return (
                  <Button
                    size={'lg'}
                    className={cn(
                      'gap-2.5 text-semibold w-full justify-start',
                      isActive ? 'text-primary-yellow bg-main-50/10' : 'text-white'
                    )}
                  >
                    <LayoutList className="w-5 h-5" />
                    Projects
                  </Button>
                )
              }}
            </NavLink>
          </DrawerClose>
          <DrawerClose asChild>
            <NavLink to="/data">
              {({ isActive }) => {
                return (
                  <Button
                    size={'lg'}
                    className={cn(
                      'gap-2.5 text-semibold w-full justify-start',
                      isActive ? 'text-primary-yellow bg-main-50/10' : 'text-white'
                    )}
                  >
                    <FolderOpen className="w-5 h-5" />
                    Data
                  </Button>
                )
              }}
            </NavLink>
          </DrawerClose>
        </div>
        <DrawerFooter className={'px-2'}>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button className="justify-start h-12">
                <Avatar className="w-8 h-8">
                  <AvatarImage src={user.avatar} alt={user.name} />
                  <AvatarFallback>KD</AvatarFallback>
                </Avatar>
                <p className="text-white font-semibold">{user.name}</p>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-72">
              <DropdownMenuItem className="flex gap-2 py-2.5 cursor-pointer" onClick={() => setDrawerOpen(false)}>
                <Link to="/profile/change-password">
                  <span className="w-full flex gap-2 items-center">
                    <LockOpen className="w-8 h-8" />
                    Change Password
                  </span>
                </Link>
              </DropdownMenuItem>
              <DrawerClose asChild>
                <DropdownMenuItem className="flex gap-2 py-2.5 cursor-pointer" onClick={logout}>
                  <span className="w-full flex gap-2 items-center">
                    <LogOut className="w-8 h-8" />
                    Logout
                  </span>
                </DropdownMenuItem>
              </DrawerClose>
            </DropdownMenuContent>
          </DropdownMenu>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
