import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Label } from './ui/label'
import { Input } from './ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import Status from './Status'
import { ArrowRight, ChevronRight, Info } from 'lucide-react'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import Table from './table/Table'
import { Button } from './ui/button'
import Basic from './table/cell/Basic'
import StatusCell from './table/cell/StatusCell'
import SalesDealAPI from '@/apis/SalesDealAPI'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/App'
import { useParams } from 'react-router-dom'
import { Textarea } from '@/components/ui/textarea'

export default function UpdateStatusDialog({ salesDeal, documents, children }) {
  const { id } = useParams()
  const [isOpen, setOpen] = useState(false)
  const [statusState, setStatusState] = useState([
    {
      text: 'Completed',
      status: 'complete',
      state: 'success',
    },
    {
      text: 'Not Found',
      status: 'not found',
      state: 'warning',
    },
    {
      text: 'Not Valid',
      status: 'not valid',
      state: 'warning',
    },
    {
      text: 'Pending',
      status: 'pending',
      state: 'warning',
    },
    {
      text: 'In Review VP',
      status: 'in_review_vp',
      state: 'success',
    },
    {
      text: 'Need Revision',
      status: 'need_revision',
      state: 'success',
    },
    {
      text: 'Approved',
      status: 'approved',
      state: 'success',
    },
    {
      text: 'In Review SM',
      status: 'in_review_sm',
      state: 'warning',
    },
  ])
  const [selectedStatus, setSelectedStatus] = useState('')
  const [isShowAlert, setShowAlert] = useState(false)

  const [tableData, setTableData] = useState([])

  const [isLoadingUpdateStatus, setLoadingUpdateStatus] = useState(false)
  const [lossReason, setLossReason] = useState('')

  const salesDealStatusMutation = useMutation({
    mutationFn: async ({ sales_deal_id, status, lossReason }) => {
      if (status === 'loss') {
        return SalesDealAPI.updateSalesDealLoss(sales_deal_id, lossReason)
      }

      return SalesDealAPI.updateSalesDealStatus(sales_deal_id, status)
    },
    onSuccess: () => {
      toast.success('Update status sales-deal success!')
      queryClient.invalidateQueries(['salesDeal', id])
      setOpen(false)
      setSelectedStatus('')
      setLoadingUpdateStatus(false)
    },
    onError: (err) => {
      console.log(err)
      toast.error(err.response?.data?.message || err.statusText || 'Failed to update sales-deal status.')
      setLoadingUpdateStatus(false)
    },
  })

  useEffect(() => {
    setSelectedStatus('')
    setTableData([])
  }, [isOpen])

  useEffect(() => {
    // console.log(documents)
    // console.log(salesDeal)
  }, [documents])

  useEffect(() => {
    if (!selectedStatus) return

    const fetchData = async () => {
      try {
        var res = await SalesDealAPI.getUpdateStatusValidation(salesDeal.id, selectedStatus)
        // console.log(res.data)
        var tempData =
          res.data?.map((item) => ({
            requirement: item.requirement_text,
            // status: item.is_valid ? 'completed' : 'pending',
            status: item.current_status,
          })) || []

        // console.log(tempData)
        setTableData(tempData)
      } catch (err) {
        console.log(err)
        toast.error(err.response?.data?.message || 'Failed getting validation list for update sales deal.')
      }
    }

    fetchData()
  }, [selectedStatus])

  // useEffect(() => {
  //   if (!selectedStatus) {
  //     return
  //   }

  //   const statusRequirements = {
  //     hot_prospect: [{ value: 'quotation', label: 'Quotation document' }],
  //     on_hands: [
  //       { value: 'proposal', label: 'Proposal document' },
  //       { value: 'quotation', label: 'Quotation document' },
  //       { value: 'spk', label: 'Surat Perintah Kerja / PO document' },
  //       { value: 'cl', label: 'Confirmation letter document' },
  //     ],
  //     revenue: [
  //       { value: 'proposal', label: 'Proposal document' },
  //       { value: 'quotation', label: 'Quotation document' },
  //       { value: 'spk', label: 'Surat Perintah Kerja / PO document' },
  //       { value: 'cl', label: 'Confirmation letter document' },
  //       { value: 'bast', label: 'BAST document' },
  //     ],
  //   }

  //   let tempShowAlert = false

  //   var tempData = statusRequirements[selectedStatus]?.map((requirement) => {
  //     if (!documents[requirement.value]?.url) {
  //       tempShowAlert = true
  //     }
  //     var temp = {
  //       requirement: requirement.label,
  //       status: documents[requirement.value]?.url ? 'completed' : 'pending',
  //     }

  //     return temp
  //   })

  //   if (selectedStatus === 'on_hands' || selectedStatus === 'revenue') {
  //     tempData.push({
  //       requirement: 'Project feasibility status is approved',
  //       status: salesDeal.project.status === 'approved' ? 'complete' : 'pending',
  //     })
  //   }

  //   console.log(tempData)

  //   setShowAlert(tempShowAlert)
  //   setTableData(tempData || [])
  // }, [selectedStatus, documents, salesDeal])

  const handleUpdateStatus = () => {
    setLoadingUpdateStatus(true)

    if (selectedStatus === 'loss') {
      if (!lossReason) {
        toast.error('Please write in enough description')

        setLoadingUpdateStatus(false)
        return
      }
      salesDealStatusMutation.mutate({ sales_deal_id: parseInt(id), status: 'loss', lossReason: lossReason })
      return
    }

    salesDealStatusMutation.mutate({ sales_deal_id: parseInt(id), status: selectedStatus })
  }

  return (
    <Dialog open={isOpen} onOpenChange={setOpen} className="">
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="w-[600px] max-w-[600px]">
        <DialogHeader className={'mb-2.5'}>
          <DialogTitle className={'text-2xl font-semibold'}>Change Sales Deal Status</DialogTitle>
          {/* <DialogDescription>
            This action cannot be undone. This will permanently delete your account and remove your data from our
            servers.
          </DialogDescription> */}
        </DialogHeader>
        <div className="grid gap-4">
          <div className="flex gap-4 items-end w-full">
            <div className="flex-1">
              <Label>From Status</Label>
              <div className="px-3 h-10 flex items-center border border-primary-gray rounded-[5px]">
                <Status type={salesDeal.status} size={'xs'} />
              </div>
            </div>
            <span className="w-5 h-10 shrink-0 flex items-center justify-center">
              <ArrowRight className="w-5 h-5" />
            </span>
            <div className="flex-1">
              <Label>To Status</Label>
              <Select defaultValue={selectedStatus} onValueChange={(value) => setSelectedStatus(value)}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select a status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {/* <SelectItem value="visit">
                      <Status type={'visit'} size={'xs'} />
                    </SelectItem> */}
                    {salesDeal.status === 'visit' && (
                      <SelectItem value="leads">
                        <Status type={'leads'} size={'xs'} />
                      </SelectItem>
                    )}
                    {['visit', 'leads'].includes(salesDeal.status) && (
                      <SelectItem value="hot_prospect">
                        <Status type={'hot_prospect'} size={'xs'} />
                      </SelectItem>
                    )}
                    {['visit', 'leads', 'hot_prospect'].includes(salesDeal.status) && (
                      <SelectItem value="on_hands">
                        <Status type={'on_hands'} size={'xs'} />
                      </SelectItem>
                    )}
                    {['visit', 'leads', 'hot_prospect', 'on_hands'].includes(salesDeal.status) && (
                      <SelectItem value="revenue">
                        <Status type={'revenue'} size={'xs'} />
                      </SelectItem>
                    )}
                    <SelectItem value="loss">
                      <Status type={'loss'} size={'xs'} />
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <Button
              disabled={isShowAlert}
              isLoading={isLoadingUpdateStatus}
              onClick={handleUpdateStatus}
              className="w-28"
            >
              Change Status
            </Button>
          </div>
          {isShowAlert && (
            <Alert variant="warning" className="">
              <Info className="h-[18px] w-[18px]" />
              <AlertDescription className="mb-0 font-normal text-normal">
                Complete all requirements to change sales deal status to{' '}
                <span className="capitalize">{selectedStatus.replace('_', ' ')}</span>.
              </AlertDescription>
            </Alert>
          )}
          {tableData.length > 0 && (
            <>
              <Label className="font-medium">Status Requirements</Label>
              <div className="text-normal">
                <Table
                  tableType="full"
                  data={tableData}
                  cols={[
                    {
                      id: 0,
                      name: 'Requirements',
                      classes: 'w-[340px]',
                      selector: (row) => <Basic data={row.requirement} />,
                    },
                    {
                      id: 1,
                      name: 'Status',
                      classes: 'w-[280px]',
                      selector: (row) => <StatusCell config={statusState} data={row.status} />,
                    },
                  ]}
                />
              </div>
            </>
          )}

          {selectedStatus === 'loss' && (
            <div>
              <Label>Reason setting the sales deal to loss</Label>
              <Textarea
                placeholder="Please write in enough description"
                onChange={(e) => setLossReason(e.target.value)}
              />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}
