import { id } from 'date-fns/locale'
import { devAxios } from './axiosConfig'

export const ProductsAPI = {
  getProductOnly: async (status = undefined, sbu_id = undefined, page = 1, getAll = false) => {
    var res = await devAxios.get('products', { params: { status: status, sbu_id: sbu_id, page: page, all: getAll } })
    return res.data
  },

  createProduct: async (data) => {
    const res = await devAxios.post('/products', data)
    return res.data
  },

  updateProduct: async (id, data) => {
    const res = await devAxios.put(`/products/${id}`, data)
    return res.data
  },

  deleteProduct: async (id) => {
    const res = await devAxios.put(`/products/${id}/status`, { status: 'deleted' })
    return res.data
  },

  updateStatusProduct: async (id, status) => {
    const res = await devAxios.put(`/products/${id}/status`, { status: status })
    return res.data
  },

  getSubproduct: async (sbu_id = undefined, product_id = undefined, status = undefined, page = 1, getAll = false) => {
    var res = await devAxios.get('sub-products', {
      params: {
        sbu_id: sbu_id,
        product_id: product_id,
        status: status,
        page: 1,
      },
    })
    return res.data
  },

  createSubproduct: async (data) => {
    const res = await devAxios.post('/sub-products', data)
    return res.data
  },

  updateSubproduct: async (id, data) => {
    const res = await devAxios.put(`/sub-products/${id}`, data)
    return res.data
  },

  updateStatusSubproduct: async (id, status) => {
    const res = await devAxios.put(`/sub-products/${id}/status`, { status: status })
    return res.data
  },

  deleteSubproduct: async (id) => {
    const res = await devAxios.put(`/sub-products/${id}/status`, { status: 'deleted' })
    return res.data
  },

  getVariants: async (
    sbu_id = undefined,
    product_id = undefined,
    sub_product_id = undefined,
    status = undefined,
    page = 1,
    getAll = false
  ) => {
    var res = await devAxios.get('product-variants', {
      params: {
        sbu_id: sbu_id,
        product_id: product_id,
        sub_product_id: sub_product_id,
        status: status,
        page: page,
        all: getAll,
      },
    })
    return res.data
  },

  createVariant: async (data) => {
    var res = await devAxios.post('product-variants', data)
    return res.data
  },

  updateVariant: async (id, data) => {
    const res = await devAxios.put(`product-variants/${id}`, data)
    return res.data
  },

  changeStatusVariant: async (id, status) => {
    const res = await devAxios.put(`product-variants/${id}/status`, { status: status })
    return res.data
  },

  deleteVariant: async (id) => {
    const res = await devAxios.put(`product-variants/${id}/status`, { status: 'deleted' })
    return res.data
  },

  getProductVariantByProductId: async (id) => {
    var res = await devAxios.get('products/' + id)
    return res.data
  },

  getVariantByProductId: async (product_id, price_type = undefined) => {
    var res = await devAxios.get(`products/${product_id}`, { params: { price_type: price_type } })
    return res.data
  },
}
