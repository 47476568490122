import React, { useEffect, useRef, useState } from 'react'
import Item from './Item'
import { Droppable } from 'react-beautiful-dnd'
import Status from '../Status'
import { formatThousands } from '../../helper'
import Tooltip from '../Tooltip'
import { CircleDollarSign, Presentation } from 'lucide-react'
import { Button } from '../ui/button'
import { useWindowScroll } from '@uidotdev/usehooks'

export default function Column({ col, statistics, loadMoreItem }) {
  const [currentPage, setCurrentPage] = useState(1)
  const columnRef = useRef()
  const buttonRef = useRef()
  const [{ x, y }, scrollTo] = useWindowScroll()

  const handleLoadMore = (e) => {
    // console.log(columnRef.current.scrollHeight)
    // let scrollHeight = columnRef.current.scrollHeight
    loadMoreItem(col.id.toLowerCase().replace(' ', '_'), currentPage + 1)
    // if (currentPage + 1 >= totalPage) setShowLoadButton(false)
    setCurrentPage(currentPage + 1)
    // columnRef.current.scrollTop = scrollHeight
  }

  // useEffect(() => {
  //   console.log(statistics)
  // }, [statistics])

  return (
    <div className="h-full flex-1 flex flex-col min-w-[250px] bg-main-300/10 rounded-t-xl">
      <div className="p-4 mb-4">
        <Status type={col.id.replace(' ', '_').toLowerCase()} wrapperClassName={'mb-3'} size={'large'} />
        <div className="flex h-4 text-gray-text gap-4">
          <div className="flex gap-2 items-center pl-1">
            <Tooltip message={`${statistics.total_deal} projects`}>
              <span className="flex gap-1 items-center text-sm">
                <Presentation className="w-[18px] h-[18px]" />
                {statistics.total_deal}
              </span>
            </Tooltip>
            <span className="flex gap-1 items-center">
              <CircleDollarSign className="w-[18px] h-[18px]" />
              <p>Rp{formatThousands(statistics.total_revenue)}</p>
            </span>
          </div>
        </div>
      </div>
      <div className="px-4 flex-1 flex flex-col gap-4 overflow-y-auto scrollbar-thin pb-20 lg:pb-4" ref={columnRef}>
        <Droppable droppableId={col.id}>
          {(provided, snapshot) => {
            // console.log(snapshot)
            return (
              <div {...provided.droppableProps} ref={provided.innerRef} className="flex-1 flex flex-col gap-4">
                {col.list.map((salesDeal, index) => (
                  <Item className={''} key={index} salesDeal={salesDeal} index={index} />
                ))}
                {provided.placeholder}
              </div>
            )
          }}
        </Droppable>
        {Math.ceil(statistics.total_deal / 10) > 1 && (
          <div>
            <Button
              ref={buttonRef}
              onClick={handleLoadMore}
              className={'w-full'}
              disabled={currentPage >= Math.ceil(statistics.total_deal / 10)}
            >
              Load More
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
