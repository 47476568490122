import * as React from 'react'

import { cn } from '@/lib/utils'

const Textarea = React.forwardRef(({ className, isError, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        'flex min-h-[80px] w-full rounded-md border px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground placeholder:text-main-500/30 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-secondary-blue focus-visible:border-main-500 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm',
        className,
        isError ? 'border-red-500' : 'border-primary-gray'
      )}
      ref={ref}
      {...props}
    />
  )
})
Textarea.displayName = 'Textarea'

export { Textarea }
