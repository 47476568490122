import React, { useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { WarningAmber } from '@mui/icons-material'
import { useMutation, useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Textarea } from '@/components/ui/textarea'
import { queryClient } from '@/App'
import ClientAPI from '../../../apis/ClientAPI'
import StorageAPI from '../../../apis/storageAPI'
import Alert from '../../../components/Alert'
import BreadCrumbs from '../../../components/BreadCrumbs'
import DragAndDropFile from '../../../components/DragAndDropFile'
import InputDate from '../../../components/InputDate'
import Loading from '../../../components/Loading'
import usePermission from '../../../hooks/usePermission'
import { industry } from '../../../services/industry'

const clientScheme = z.object({
  company_name: z.string().min(1, { message: 'Company name is required' }),
  company_code: z
    .string()
    .min(1, { message: 'Company code is required' })
    .max(4, { message: 'Company code must be 4 characters' }),
  company_address: z.string().min(1, { message: 'Company address is required' }),
  industry: z.string().min(1, { message: 'Industry is required' }),
  company_anniversary_date: z.coerce.date().optional(),
  pic_name: z.string().optional(),
  pic_title: z.string().optional(),
  pic_email: z.string().optional(),
  pic_phone_number: z.string().optional(),
  pic_birth_date: z.coerce.date().optional(),
  logo: z.string().optional(),
})

export default function EditClient() {
  let { id } = useParams()
  // const [client, setClient] = useState({})
  const [title, setTitle] = useState('Client')
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(true)
  const [selectedIndustry, setSelectedIndustry] = useState()
  const [newLogo, setNewLogo] = useState(null)
  const [isLoadingSave, setLoadingSave] = useState(false)
  const [fieldsError, setFieldsError] = useState({
    company_name: false,
    company_code: false,
    company_address: false,
    industry: false,
    pic_name: false,
    pic_title: false,
    pic_email: false,
    pic_phone_number: false,
  })
  const [errorSave, setErrorSave] = useState()
  const { isAdmin } = usePermission()
  const [firstSave, setFirstSave] = useState(true)

  const form = useForm({
    resolver: zodResolver(clientScheme),
    defaultValues: {
      company_name: '',
      company_code: '',
      company_address: '',
      industry: '',
      pic_name: '',
      pic_title: '',
      pic_email: '',
      pic_phone_number: '',
      pic_birth_date: '',
      company_anniversary_date: '',
      logo: '',
    },
  })

  const client = useQuery({
    queryKey: ['clients', id, 'detail'],
    queryFn: async () => {
      const res = await ClientAPI.getClient(id)
      console.log(res.data)
      return res.data
    },
    enabled: !!id,
  })

  const clientMutation = useMutation({
    mutationFn: async (values) => {
      const temp = {
        ...values,
        company_anniversary_date: moment(values.company_anniversary_date).format('YYYY-MM-DD') || null,
        pic_birth_date: values.pic_birth_date ? moment(values.pic_birth_date).format('YYYY-MM-DD') : null,
      }
      if (newLogo != null) {
        try {
          var fileExtension = newLogo.file.name.split('.').pop()
          const logoUrl = await StorageAPI.uploadFile(`client-logo/${id + '.' + fileExtension}`, newLogo.file)

          temp.logo = logoUrl
        } catch (error) {
          console.log(error)
          toast.error('Failed to upload logo')
        }
      }

      if (id) {
        const res = await ClientAPI.update(id, temp)
        return res
      }

      const res = await ClientAPI.create(temp)
      return res
    },
    onSuccess: async (res) => {
      console.log(res)
      queryClient.invalidateQueries({ queryKey: ['clients', id, 'detail'] })
      toast.success('Client updated successfully')

      if (!id) {
        navigate(-1)
      }
    },
    onError: (err) => {
      console.log(err.response?.data?.message || err.statusText || 'Failed to update client')
      toast.error(err.response?.data?.message || err.statusText || 'Failed to update client')
    },
  })

  useEffect(() => {
    if (client.isLoading) {
      return
    }

    form.setValue('company_name', client.data.company_name)
    form.setValue('company_code', client.data.company_code)
    form.setValue('company_address', client.data.company_address)
    form.setValue('company_anniversary_date', moment(client.data.company_anniversary_date).format('YYYY-MM-DD'))
    form.setValue('industry', client.data.industry)
    form.setValue('pic_name', client.data.pic_name)
    form.setValue('pic_title', client.data.pic_title)
    form.setValue('pic_email', client.data.pic_email)
    form.setValue('pic_phone_number', client.data.pic_phone_number)
    form.setValue('pic_birth_date', moment(client.data.pic_birth_date).format('YYYY-MM-DD'))
    form.setValue('logo', client.data.logo)

    console.log(client.data.logo)
  }, [client.isLoading])

  function handleFileUpload(input) {
    const objectUrl = URL.createObjectURL(input)
    setNewLogo({ file: input, blob: objectUrl })
    form.setValue('logo', objectUrl)
  }

  return (
    <div className="py-4">
      {client.isLoading && id ? (
        <Loading />
      ) : (
        <div>
          <h1 className="text-big font-semibold mb-2">{title}</h1>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(clientMutation.mutate)} className=" p-4 border rounded-lg">
              <div className="grid mb-2 md:mb-2 md:grid-cols-2 md:gap-4 lg:gap-16 lg:mb-8">
                <div className="grid gap-4">
                  <FormField
                    control={form.control}
                    name="company_name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Company Name</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="company_code"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Company Code</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="company_address"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Company Address</FormLabel>
                        <FormControl>
                          <Textarea {...field} rows={4} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="industry"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Industry</FormLabel>
                        <Select value={field.value} onValueChange={field.onChange} defaultValue={field.value}>
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select an industry" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {industry.map((item) => (
                              <SelectItem key={item.id} value={item.id}>
                                {item.industry}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="company_anniversary_date"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Company Birthday</FormLabel>
                        <FormControl>
                          <Input {...field} type="date" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="pic_name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>PIC name</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="pic_title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>PIC Title</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="pic_email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>PIC Email</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="pic_phone_number"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>PIC Phone Number</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="pic_birth_date"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>PIC Birthday</FormLabel>
                        <FormControl>
                          <Input {...field} type="date" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="">
                  <DragAndDropFile
                    className="h-[100px]"
                    label="Logo"
                    onChange={(input) => {
                      handleFileUpload(input)
                    }}
                  />
                  {form.watch('logo') ? (
                    <div
                      className="w-48 h-48 border border-gray-border 
                                  flex items-center justify-center
                                  p-2 rounded-lg mt-4"
                    >
                      <img src={form.getValues('logo')} className="w-full" />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex gap-4">
                <Button type="submit" isLoading={clientMutation.isLoading} disabled={clientMutation.isPending}>
                  Save
                </Button>
                {clientMutation.isError && (
                  <Alert
                    type={'error'}
                    message={
                      clientMutation.error.response?.data?.message ||
                      clientMutation.error.message ||
                      'Failed to save client'
                    }
                  />
                )}
              </div>
            </form>
          </Form>
        </div>
      )}
    </div>
  )
}
