import React, { forwardRef, useEffect, useState } from 'react'
import Column from './Column'
import { DragDropContext } from 'react-beautiful-dnd'
import SalesDealAPI from '../../apis/SalesDealAPI'
import { useNavigate } from 'react-router-dom'
import usePermission from '../../hooks/usePermission'
import toast from 'react-hot-toast'

export default function DnD({ initialColumns, statistics, loadMoreItem, toastUpdateSalesDeal }) {
  const [columns, setColumns] = useState(initialColumns)
  const [statisticsData, setStatisticsData] = useState(statistics)
  const navigate = useNavigate()
  const { user, isAE, isSM, isAdmin, isVP } = usePermission()

  useEffect(() => {
    setColumns(initialColumns)
    setStatisticsData(statistics)
    console.log(isAdmin || isVP)
    // console.log('here')
  }, [initialColumns, statistics])

  const onDragEnd = ({ source, destination }) => {
    // Make sure we have a valid destination
    console.log(source)
    if (destination === undefined || destination === null) return null

    // Make sure we're actually moving the item
    if (source.droppableId === destination.droppableId && destination.index === source.index) return null

    // Set start and end variables
    const start = columns[source.droppableId.replace(' ', '_').toLowerCase()]
    const end = columns[destination.droppableId.replace(' ', '_').toLowerCase()]
    // If start is the same as end, we're in the same column
    if (!['ae', 'sm', 'admin'].includes(user.role)) {
      toastUpdateSalesDeal('error', 'Your Account don`t have permission to do update sales deal status.')
      return
    }

    if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newList = start.list.filter((item, index) => index !== source.index)

      // Then insert the item at the right location
      newList.splice(destination.index, 0, start.list[source.index])

      // Then create a new copy of the column object
      const newCol = {
        id: start.id,
        list: newList,
      }

      // Update the state
      setColumns((state) => ({ ...state, [newCol.id.replace(' ', '_').toLowerCase()]: newCol }))
      return
    } else {
      var droppedSalesDeal = start.list[source.index]
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before

      SalesDealAPI.updateSalesDealStatus(droppedSalesDeal.id, destination.droppableId.replace(' ', '_').toLowerCase())
        .then((res) => {
          if (res.success) {
            const newStartList = start.list.filter((item, index) => index !== source.index)

            // Create a new start column
            const newStartCol = {
              id: start.id,
              list: newStartList,
            }

            // Make a new end list array
            const newEndList = end.list

            // Insert the item into the end list
            newEndList.splice(destination.index, 0, start.list[source.index])

            // Create a new end column
            const newEndCol = {
              id: end.id,
              list: newEndList,
            }

            // Update the state
            setColumns((state) => ({
              ...state,
              [newStartCol.id.replace(' ', '_').toLowerCase()]: newStartCol,
              [newEndCol.id.replace(' ', '_').toLowerCase()]: newEndCol,
            }))
            toastUpdateSalesDeal('success')
            return
          }
          console.log(res)
        })
        .catch((err) => {
          console.log(err)

          if (err.response?.data?.message) {
            toast.error(err.response?.data?.message)
            return
          }

          if (err.response.status === 403) {
            toastUpdateSalesDeal('error')
          }
        })
    }
  }
  useEffect(() => {
    // console.log(statistics)
  }, [])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex mx-auto w-full h-full gap-4 overflow-y-hidden overflow-x-auto scrollbar-thin scrollbar-main-500">
        {Object.values(columns).map((col) => {
          return (
            <Column
              col={col}
              key={col.id}
              statistics={statisticsData[col.id.replace(' ', '_').toLowerCase()]}
              loadMoreItem={loadMoreItem}
            />
          )
        })}
      </div>
    </DragDropContext>
  )
}
