import React, { forwardRef, useEffect, useState } from 'react'
import Column from './Column'
import { DragDropContext } from 'react-beautiful-dnd'
import moment from 'moment'
import SalesPlanAPI from '@/apis/SalesPlanAPI'
import toast from 'react-hot-toast'

export default function Dnd({ initialColumns, setRefreshDOM }) {
  const [columns, setColumns] = useState(initialColumns)

  useEffect(() => {
    console.log(initialColumns)
  }, [])

  const onDragEnd = ({ source, destination }) => {
    // Make sure we have a valid destination

    console.log(source)
    console.log(destination)

    console.log(moment(destination.droppableId).format('DD-MM-YYYY'))
    if (destination === undefined || destination === null) return null

    // Make sure we're actually moving the item
    if (source.droppableId === destination.droppableId && destination.index === source.index) return null

    // Set start and end variables
    console.log(moment(source.droppableId).format('dddd').toLowerCase())
    const start = columns[moment(source.droppableId).format('dddd').toLowerCase()]
    const end = columns[moment(destination.droppableId).format('dddd').toLowerCase()]

    // If start is the same as end, we're in the same column
    if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newList = start.list.filter((item, index) => index !== source.index)

      // Then insert the item at the right location
      newList.splice(destination.index, 0, start.list[source.index])

      // Then create a new copy of the column object
      const newCol = {
        id: start.id,
        list: newList,
      }

      // Update the state
      setColumns((state) => ({ ...state, [moment(newCol.id).format('dddd').toLowerCase()]: newCol }))
      return null
    } else {
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      const selectedSalesPlan = start.list[source.index]
      console.log(selectedSalesPlan)

      SalesPlanAPI.updateSalesPlan(selectedSalesPlan.id, moment(destination.droppableId).format('YYYY-MM-DD'))
        .then((res) => {
          console.log(res)

          if (!res.success) {
            throw res
          }

          toast.success('Update sales plan date success!')

          const newStartList = start.list.filter((item, index) => index !== source.index)

          // Create a new start column
          const newStartCol = {
            id: start.id,
            list: newStartList,
          }

          // Make a new end list array
          const newEndList = end.list

          // Insert the item into the end list
          newEndList.splice(destination.index, 0, start.list[source.index])

          // Create a new end column
          const newEndCol = {
            id: end.id,
            list: newEndList,
          }

          // Update the state
          setColumns((state) => ({
            ...state,
            [moment(newStartCol.id).format('dddd').toLowerCase()]: newStartCol,
            [moment(newEndCol.id).format('dddd').toLowerCase()]: newEndCol,
          }))
        })
        .catch((err) => {
          console.log(err)

          if (err.response?.data?.message) {
            toast.error(err.response?.data?.message)
            return
          }

          toast.error(err.statusText || err.status || 'Something went wront when updating sales deal date')
        })
      return null
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex mx-auto w-full h-full overflow-hidden gap-4 overflow-x-auto">
        {Object.values(columns).map((col) => (
          <Column col={col} key={col.id} setRefreshDOM={setRefreshDOM} />
        ))}
      </div>
    </DragDropContext>
  )
}
