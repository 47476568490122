import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva } from 'class-variance-authority'

import { cn } from '@/lib/utils'
import Loading from '../Loading'

const buttonVariants = cva(
  'inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-lg font-semibold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:shrink-0',
  {
    variants: {
      variant: {
        default: 'bg-main-500 text-white hover:bg-main-400',
        outline: 'border border-slate-300 text-main-500 hover:bg-main-100/20',
        secondary: 'bg-main-50 text-main-500 hover:bg-main-100/80',
        destructive: 'bg-destructive text-white hover:bg-destructive/90',
        ghost: 'hover:bg-main-50 text-main-500',
        link: 'text-main-500 underline-offset-4 hover:underline',
      },
      size: {
        default: 'text-xs h-10 px-4',
        xs: 'h-7 text-[10px] px-1.5',
        sm: 'h-9 text-[10px] px-2',
        lg: 'h-11 px-4',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

const loadingIconVariant = cva('w-5 h-5 animate-spin', {
  variants: {
    variant: {
      default: '!text-white',
      outline: 'text-main-500',
      secondary: 'text-main-500',
      destructive: 'text-destructive',
      ghost: 'text-main-500',
    },
    size: {
      default: 'w-5 h-5',
      xs: 'w-3 h-3',
      sm: 'w-4 h-4',
      lg: 'w-6 h-6',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
})

const Button = React.forwardRef(({ className, variant, size, isLoading, asChild = false, children, ...props }, ref) => {
  const Comp = asChild ? Slot : 'button'
  // if (isLoading) {
  //   console.log(variant, size)
  // }
  return (
    <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props}>
      {isLoading ? <Loading className={cn(loadingIconVariant({ variant, size }))} /> : children}
    </Comp>
  )
})
Button.displayName = 'Button'

export { Button, buttonVariants }
