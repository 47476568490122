import * as React from 'react'
import { addDays, format } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import moment from 'moment'

export function DateRangePicker({ className, label, defaultDate, onSelect, labelWidth }) {
  const [date, setDate] = React.useState(
    defaultDate || { from: new Date(moment().toISOString()), to: new Date(moment().add(7, 'day').toISOString()) }
  )
  const [skipFirst, setSkipFirst] = React.useState(true)

  React.useEffect(() => {
    if (skipFirst) setSkipFirst(false)
    if (!skipFirst) onSelect(date)
  }, [date])

  const handlePreset = (preset) => {
    if (preset === 'week') {
      setDate({
        from: new Date(moment().startOf('week').toISOString()),
        to: new Date(moment().endOf('week').toISOString()),
      })
    } else if (preset === 'month') {
      setDate({
        from: new Date(moment().startOf('month').toISOString()),
        to: new Date(moment().endOf('month').toISOString()),
      })
    } else if (preset === 'year') {
      setDate({
        from: new Date(moment().startOf('year').toISOString()),
        to: new Date(moment().endOf('year').toISOString()),
      })
    }
  }

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button id="date" variant={'outline'} className={cn('h-10 px-3 items-center justify-between', className)}>
            <div className="flex-1 flex gap-3 items-center">
              <span
                className={cn(
                  'flex gap-2 items-center text-gray-900  [&_svg]:w-5 [&_svg]:h-5',
                  labelWidth && `w-[${labelWidth}px]`
                )}
              >
                <CalendarIcon />
                <span className="font-medium">{label}</span>
              </span>
              <div className="border-r min-h-4 !min-w-[1px] border-gray-500"></div>
              {date?.from ? (
                date.to ? (
                  <>
                    {moment(date.from).format('D MMM YYYY')} - {moment(date.to).format('D MMM YYYY')}
                  </>
                ) : (
                  moment(date.from).format('D MMM YYYY')
                )
              ) : (
                <span>Pick a date</span>
              )}
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          {/* <Select onValueChange={(value) => handlePreset(value)}>
            <SelectTrigger>
              <SelectValue placeholder="Select" />
            </SelectTrigger>
            <SelectContent position="popper">
              <SelectItem value="week">This Week</SelectItem>
              <SelectItem value="month">This Month</SelectItem>
              <SelectItem value="year">This Year</SelectItem>
            </SelectContent>
          </Select> */}
          <div className="flex">
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={date?.from}
              selected={date}
              onSelect={setDate}
              numberOfMonths={1}
              className={'border-r pr-2.5 !mr-0'}
            />
            <div className="presets w-40 flex flex-col py-8 px-2.5">
              <p className="text-normal mb-2.5">Select Preset</p>
              <Button className="justify-start w-full" variant="ghost" size="sm" onClick={() => handlePreset('week')}>
                This Week
              </Button>
              <Button className="justify-start w-full" variant="ghost" size="sm" onClick={() => handlePreset('month')}>
                This Month
              </Button>
              <Button className="justify-start w-full" variant="ghost" size="sm" onClick={() => handlePreset('year')}>
                This Year
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}
