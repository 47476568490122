import React, { useEffect, useState } from 'react'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Alert from '../../components/Alert'
import useAuth from '../../hooks/useAuth'

export default function ChangePassword() {
  const [password, setPassword] = useState({ lastPassword: '', newPassword: '', retypePassword: '' })
  const { changePassword } = useAuth()
  const [err, setErr] = useState()
  const [isLoadingSavePassword, setIsLoadingSavePassword] = useState(false)
  const [success, setSuccess] = useState()
  const [isLoading, setLoading] = useState()

  useEffect(() => {
    if (success) {
      setErr()
    } else if (err) {
      setSuccess()
    }
  }, [err, success])

  const handleChange = (input) => {
    setPassword({ ...password, [input.name]: input.value })
  }

  const handleSavePassword = () => {
    setIsLoadingSavePassword(true)
    if (!password.newPassword || !password.retypePassword) {
      setErr('Please filled every fields')
    } else if (password.newPassword === password.retypePassword) {
      changePassword(password.newPassword)
        .then((res) => {
          setSuccess('Change password success!')
          setIsLoadingSavePassword(false)
        })
        .catch((err) => {
          setErr(err.message)
          setIsLoadingSavePassword(false)
        })
    } else {
      setErr('Password are different. Please check your input!')
      setIsLoadingSavePassword(false)
    }
  }

  const testLoading = () => {
    setLoading(true)
  }
  return (
    <div className="w-[600px]">
      <h2 className="text-giant mb-8 font-semibold">Change Password</h2>
      {/* <Input
            label={'Last Password'}
            name="lastPassword"
            onChange={(e) => handleChange(e.target)}
            className={'mb-4'}
          /> */}
      <Input
        label={'New Password'}
        name="newPassword"
        type={'password'}
        onChange={(e) => handleChange(e.target)}
        className={'mb-4'}
      />
      <Input
        label={'Retype New Password'}
        name="retypePassword"
        type={'password'}
        onChange={(e) => handleChange(e.target)}
        className={'mb-4'}
      />
      <Button className={'mb-4'} onClick={handleSavePassword} isLoading={isLoadingSavePassword}>
        Save Password
      </Button>
      {err && <Alert type={'error'} message={err} />}
      {success && <Alert type={'success'} message={success} />}

      {/* <Button
            className={'mb-4'}
            onClick={() => {
              SalesDealAPI.generateQuotation(24).then((res) => {
                console.log(res)
              })
            }}
          >
            Generate
          </Button> */}
      {/* <Button onClick={testLoading} className={'mb-4'} isLoading={isLoading}>
            Save
          </Button> */}
    </div>
  )
}
