import React, { useEffect, useState } from 'react'
import Loading from '../../components/Loading'
import DropdownUser from '../../components/DropdownUser'
import SBUAPI from '../../apis/SBUAPI'
import AccountAPI from '../../apis/AccountAPI'
import Table from '../../components/table/Table'
import Basic from '../../components/table/cell/Basic'
import StatusCell from '../../components/table/cell/StatusCell'
import ProjectAPI from '../../apis/ProjectAPI'
import { formatThousands } from '../../helper'
import LogoCell from '../../components/table/cell/LogoCell'
import Modal from '../../components/Modal'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import usePermission from '../../hooks/usePermission'
import Alert from '../../components/Alert'
import Pagination from '../../components/Pagination'
import { useCustomSearchParams } from '../../hooks/useCustomSearchParams'
import { Copy, ListTodo } from 'lucide-react'
import { Combobox } from '@/components/Combobox'
import { Button } from '@/components/ui/button'
import toast from 'react-hot-toast'
import { useCopyToClipboard } from '@uidotdev/usehooks'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { Link } from 'react-router-dom'

export default function ProjectFlagship() {
  const [searchParams, setSearchParams] = useCustomSearchParams()

  const [copiedText, copyToClipboard] = useCopyToClipboard()
  const [isLoading, setLoading] = useState(true)
  const [status, setStatus] = useState([
    { id: 1, text: 'Active', value: 'active' },
    { id: 2, text: 'Inactive', value: 'inactive' },
  ])
  const [selectedStatus, setSelectedStatus] = useState()
  const [listClient, setListClient] = useState()
  const [selectedClient, setSelectedClient] = useState()
  const [stateCellConfig, setCellConfig] = useState([
    {
      text: 'Inactive',
      status: 'inactive',
      state: 'danger',
    },
    {
      text: 'Active',
      status: 'active',
      state: 'success',
    },
  ])
  const [projects, setProjects] = useState([])
  const [pagination, setPagination] = useState({ total_page: 1 })
  const [projectTableCols, setProjectTableCols] = useState([
    {
      id: 1,
      name: 'Project Name',
      classes: 'min-w-[200px]',
      selector: (row) => <Basic data={row.name} />,
    },
    {
      id: 2,
      name: 'Code',
      classes: 'min-w-[100px]',
      selector: (row) => <Basic data={row.flagship_code} />,
    },
    {
      id: 3,
      name: 'Project Id',
      classes: 'min-w-[200px]',
      selector: (row) => (
        <Basic
          data={
            row.cc_project_id ? (
              <span className="flex items-center gap-2">
                {row.cc_project_id}
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        size={'xs'}
                        variant={'outline'}
                        onClick={(e) => {
                          e.stopPropagation()
                          toast.success('Copied to clipboard')
                          copyToClipboard(row.cc_project_id)
                        }}
                      >
                        <Copy className="w-4 h-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Click to copy project id</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </span>
            ) : (
              '-'
            )
          }
        />
      ),
    },
    {
      id: 4,
      name: 'Total Clients',
      classes: 'min-w-[100px] text-right',
      selector: (row) => <Basic data={row.total_client} alignment={'right'} />,
    },
    {
      id: 5,
      name: 'Total Revenue',
      classes: 'text-right min-w-[150px]',
      selector: (row) => <Basic alignment="right" data={`Rp${formatThousands(row.total_revenue)}`} />,
    },
    {
      id: 6,
      name: 'Total Cogs',
      classes: 'text-right  min-w-[150px]',
      selector: (row) => <Basic alignment="right" data={`Rp${formatThousands(row.total_cogs)}`} />,
    },
    {
      id: 7,
      name: 'Margin',
      classes: 'text-right  min-w-[150px]',
      selector: (row) => <Basic alignment="right" data={`${Math.round(row.margin * 100)}%`} />,
    },
    {
      id: 8,
      name: 'COGS',
      classes: 'text-right  min-w-[150px]',
      selector: (row) => <Basic alignment="right" data={`${Math.round(row.cogs * 100)}%`} />,
    },
    {
      id: 9,
      name: 'Status',
      classes: 'min-w-[150px]',
      selector: (row) => <StatusCell config={stateCellConfig} data={row.status} className={'font-medium'} />,
    },
  ])
  const { isAdmin } = usePermission()
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedDelete, setSelectedDelete] = useState()
  const [isLoadingDelete, setLoadingDelete] = useState(false)
  const [refreshDOM, setRefreshDOM] = useState(0)
  const [errorDelete, setErrorDelete] = useState()

  useEffect(() => {
    setLoading(true)
    ProjectAPI.getAllFlagshipProject(searchParams.status || undefined).then((res) => {
      setProjects(res.data)
      // console.log(res.data)
      setLoading(false)
    })
  }, [searchParams.status, refreshDOM])

  useEffect(() => {
    console.log(selectedDelete)
  }, [selectedDelete])

  const handleCloseModal = () => {
    setModalOpen(!isModalOpen)
    setSelectedDelete()
  }

  const handleDelete = () => {
    setLoadingDelete(true)
    ProjectAPI.deleteFlagshipProject(selectedDelete)
      .then((res) => {
        setRefreshDOM(Math.random() * 1000000)
        setModalOpen(false)
        setSelectedDelete()
        setLoadingDelete(false)
      })
      .catch((err) => {
        if (err.response) {
          setErrorDelete(err.response.data.message)
        } else {
          setErrorDelete(err.statusText)
        }
      })
  }

  return (
    <div>
      {isModalOpen && (
        <Modal
          setIsOpen={() => {
            handleCloseModal()
          }}
        >
          <Modal.Header
            setIsOpen={() => {
              handleCloseModal()
            }}
          >
            Delete Flagship Project
          </Modal.Header>
          <Modal.Body
            setIsOpen={() => {
              handleCloseModal()
            }}
          >
            <div className="px-2 py-1.5 rounded">Are you sure to delete this flagship project?</div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-end items-center gap-4">
              <div className="flex-1">{errorDelete && <Alert type={'error'} message={errorDelete} />}</div>
              <Button>Cancel</Button>
              <Button
                isLoading={isLoadingDelete}
                onClick={handleDelete}
                className="!bg-red-100 !text-red-500 ring-red-100 hover:!bg-red-500 hover:ring-red-500 hover:!text-white"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      <div className="flex flex-col md:flex-row gap-4 mb-4">
        <div className="md:flex-1">
          <Combobox
            label={'Status'}
            icon={<ListTodo />}
            placeholder={'All'}
            className={'w-[250px] shrink-0'}
            options={status.map((stat) => ({ value: stat.value, label: stat.text }))}
            defaultValue={status.find((stat) => stat.value === searchParams.status)?.id}
            onSelect={(selected) => setSearchParams({ ...searchParams, status: selected })}
          />
          {/* <DropdownUser
            data={status}
            value={status.find((stat) => stat.value === searchParams.status)}
            noPic
            type="Status"
            handleSelectedOption={(selected) =>
              setSearchParams({ ...searchParams, page: 1, status: selected.value || '' })
            }
            attributeShown="text"
          /> */}
        </div>
        {isAdmin && (
          <Link to={'/projects/create-flagship'}>
            <Button className={'w-full md:w-fit'}>Create Project Flagship</Button>
          </Link>
        )}
      </div>
      <div>
        {isLoading ? (
          <Loading />
        ) : (
          <Table
            data={projects}
            cols={projectTableCols}
            withActions={isAdmin}
            viewLink={(row) => `/projects/view/f/${row.id}`}
            editLink={(row) => (isAdmin ? `/projects/edit/f/${row.id}/` : '/403')}
            handleDelete={(row) => {
              setModalOpen(true)
              setSelectedDelete(row)
            }}
            // tableType="inline"
          />
        )}
        <Pagination
          totalPage={pagination.total_page || 1}
          currentPage={parseInt(searchParams.page) || 1}
          onChange={(page) => setSearchParams({ ...searchParams, page: page })}
        />
      </div>
    </div>
  )
}
