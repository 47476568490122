import axios from 'axios'
import { auth } from './firebaseConfig'
import { getIdToken, onAuthStateChanged, signOut } from 'firebase/auth'
import { store } from '../redux/store'
import { signOut as reduxSignout, saveFirebaseLoginData } from '../redux/authSlice'
import moment from 'moment/moment'

const getUserIdToken = async () => {
  return new Promise((resolve) => {
    const unsub = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // console.log(user)
        const token = await getIdToken(user)
        resolve(token)
      } else {
        console.log('User not logged in')
        resolve(null)
      }
      unsub()
    })
  })
}

const apiConfig = {
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    //  Authorization: `<Your Auth Token>`,
    'Content-Type': 'application/json',
    timeout: 1000,
  },
}

const errorHandler = (error) => {
  const statusCode = error.response?.status

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error)
  }

  return Promise.reject(error)
}

const devAxios = axios.create(apiConfig)

devAxios.interceptors.request.use(async (request) => {
  var token = await getUserIdToken()
  request.headers.Authorization = `Bearer ${token}`
  return request
})

devAxios.interceptors.response.use(undefined, (response) => {
  // console.log(response.response)
  if (response.response?.status === 401 && response.response.data.error_code === 'auth/argument-error') {
    signOut(auth)
    window.location = '/login'
  }
  return errorHandler(response)
})

export { devAxios }
