import _ from 'lodash'

const ROLES = {
  ae: {
    performance: {
      filter: {
        sbu: false,
        sm: false,
        ae: false,
        year: true,
      },
    },
    salesPlan: {
      filter: {
        date: true,
        ae: false,
        tags: true,
      },
      addPlan: true,
      addMom: true,
      editMom: true,
      deletePlan: true,
      export: true,
    },
    salesDeal: {
      filter: {
        ae: false,
        client: true,
        industry: false,
        sbu: false,
        flagship: true,
        value: true,
        topic: false,
      },
      dragAndDrop: false,
      detail: (user, salesDealAEId) => user.id === salesDealAEId,
      fillProjectFeasibility: (user, projectStatus) => projectStatus === 'pending',
      changeStatus: true,
      documents: {
        edit: false,
      },
      project: {
        view: true,
        edit: (user, projectStatus) => projectStatus === 'pending',
        review: false,
        sendRequestReview: (user, data = { salesDealStatus, projectStatus }) => {
          // console.log(data)
          return data.salesDealStatus === 'hot_prospect' && data.projectStatus === 'pending'
        },
        sync: true,
      },
      invoice: {
        change: (user, data = { salesDealStatus, projectStatus }) =>
          ['hot_prospect', 'on_hands', 'revenue'].includes(data.salesDealStatus) &&
          ['approved'].includes(data.projectStatus),
        sync: true,
      },
      meetingHistory: {
        addMom: true,
      },
    },
    project: {
      feasibility: {
        filter: {
          sbu: false,
          sm: false,
          ae: false,
          status: true,
        },
        view: true,
        edit: (user, projectStatus) => ['pending'].includes(projectStatus),
        review: false,
      },
    },
    data: {
      product: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
      subproduct: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
      variant: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
      client: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
    },
  },
  sm: {
    performance: {
      filter: {
        sbu: false,
        sm: false,
        ae: true,
        year: true,
      },
    },
    salesPlan: {
      filter: {
        date: true,
        ae: true,
        tags: true,
      },
      addPlan: false,
      addMom: false,
      editMom: false,
      deletePlan: false,
      export: true,
    },
    salesDeal: {
      filter: {
        ae: true,
        client: true,
        industry: false,
        sbu: false,
        flagship: true,
        value: true,
        topic: false,
      },
      dragAndDrop: true,
      detail: true,
      fillProjectFeasibility: false,
      changeStatus: true,
      documents: {
        edit: false,
      },
      project: {
        view: true,
        edit: false,
        sendRequestReview: false,
        review: (user, data = { salesDealStatus, projectStatus }) =>
          ['hot_prospect', 'on_hands', 'revenue'].includes(data.salesDealStatus) &&
          ['in_review_sm', 'need_revision', 'approved'].includes(data.projectStatus),
        sync: true,
      },
      invoice: {
        change: false,
        sync: true,
      },
      meetingHistory: {
        addMom: false,
      },
    },
    project: {
      feasibility: {
        filter: {
          sbu: false,
          sm: false,
          ae: true,
          status: true,
        },
        view: true,
        edit: false,
        review: (user, data = { salesManagerId: '', projectStatus: '' }) =>
          user.id === data.salesManagerId && ['in_review_sm', 'need_revision', 'approved'].includes(data.projectStatus),
      },
    },
    data: {
      product: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
      subproduct: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
      variant: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
      client: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
    },
  },
  finance: {
    performance: {
      filter: {
        sbu: true,
        sm: true,
        ae: true,
        year: true,
      },
    },
    salesPlan: {
      filter: {
        date: true,
        ae: true,
        tags: true,
      },
      addPlan: false,
      addMom: false,
      editMom: false,
      deletePlan: false,
      export: false,
    },
    salesDeal: {
      filter: {
        ae: true,
        client: true,
        industry: false,
        sbu: false,
        flagship: true,
        value: true,
        topic: false,
      },
      dragAndDrop: false,
      detail: true,
      fillProjectFeasibility: false,
      changeStatus: false,
      documents: {
        edit: false,
      },
      project: {
        view: true,
        edit: false,
        sendRequestReview: false,
        review: false,
        sync: true,
      },
      invoice: {
        change: false,
        sync: true,
      },
      meetingHistory: {
        addMom: false,
      },
    },
    project: {
      feasibility: {
        filter: {
          sbu: false,
          sm: false,
          ae: true,
          status: true,
        },
        view: true,
        edit: false,
        review: false,
      },
    },
    data: {
      product: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
      subproduct: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
      variant: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
      client: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
    },
  },
  vp: {
    performance: {
      filter: {
        sbu: true,
        sm: true,
        ae: true,
        year: true,
      },
    },
    salesPlan: {
      filter: {
        date: true,
        ae: true,
        tags: true,
      },
      addPlan: false,
      addMom: false,
      editMom: false,
      deletePlan: false,
      export: true,
    },
    salesDeal: {
      filter: {
        ae: true,
        client: true,
        industry: true,
        sbu: true,
        flagship: true,
        value: true,
        topic: true,
      },
      dragAndDrop: false,
      detail: true,
      fillProjectFeasibility: false,
      changeStatus: false,
      documents: {
        edit: false,
      },
      project: {
        view: true,
        edit: false,
        sendRequestReview: false,
        review: (user, { salesDealStatus, projectStatus }) =>
          ['hot_prospect', 'on_hands', 'revenue'].includes(salesDealStatus) &&
          ['in_review_vp', 'approved'].includes(projectStatus),
        sync: true,
      },
      invoice: {
        change: false,
        sync: true,
      },
      meetingHistory: {
        addMom: false,
      },
    },
    project: {
      feasibility: {
        filter: {
          sbu: true,
          sm: true,
          ae: true,
          status: true,
        },
        view: true,
        edit: false,
        review: (user, data = { projectStatus: '' }) => ['in_review_vp', 'approved'].includes(data.projectStatus),
      },
    },
    data: {
      product: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
      subproduct: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
      variant: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
      client: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
    },
  },
  head: {
    performance: {
      filter: {
        sbu: true,
        sm: true,
        ae: true,
        year: true,
      },
    },
    salesPlan: {
      filter: {
        date: true,
        ae: true,
        tags: true,
      },
      addPlan: false,
      addMom: false,
      editMom: false,
      deletePlan: false,
      export: true,
    },
    salesDeal: {
      filter: {
        ae: true,
        client: true,
        industry: true,
        sbu: true,
        flagship: true,
        value: true,
        topic: true,
      },
      dragAndDrop: false,
      detail: true,
      fillProjectFeasibility: false,
      changeStatus: false,
      documents: {
        edit: false,
      },
      project: {
        view: true,
        edit: false,
        sendRequestReview: false,
        review: false,
        sync: false,
      },
      invoice: {
        change: false,
        sync: false,
      },
      meetingHistory: {
        addMom: false,
      },
    },
    project: {
      feasibility: {
        filter: {
          sbu: true,
          sm: true,
          ae: true,
          status: true,
        },
        view: true,
        edit: false,
        review: false,
      },
    },
    data: {
      product: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
      subproduct: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
      variant: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
      client: {
        create: false,
        edit: false,
        delete: false,
        changeStatus: false,
      },
    },
  },
  admin: {
    performance: {
      filter: {
        sbu: true,
        sm: true,
        ae: true,
        year: true,
      },
    },
    salesPlan: {
      filter: {
        date: true,
        ae: true,
        tags: true,
      },
      addPlan: false,
      addMom: false,
      editMom: true,
      deletePlan: true,
      export: true,
    },
    salesDeal: {
      filter: {
        ae: true,
        client: true,
        industry: true,
        sbu: true,
        flagship: true,
        value: true,
        topic: true,
      },
      dragAndDrop: false,
      detail: true,
      fillProjectFeasibility: true,
      changeStatus: true,
      documents: {
        edit: false,
      },
      project: {
        view: true,
        edit: true,
        sendRequestReview: (user, data = { salesDealStatus, projectStatus }) => {
          return data.salesDealStatus === 'hot_prospect' && data.projectStatus === 'pending'
        },
        review: true,
        sync: true,
      },
      invoice: {
        change: true,
        sync: true,
      },
      meetingHistory: {
        addMom: true,
      },
    },
    project: {
      feasibility: {
        filter: {
          sbu: true,
          sm: true,
          ae: true,
          status: true,
        },
        view: true,
        edit: false,
        review: false,
      },
    },
    data: {
      product: {
        create: true,
        edit: true,
        delete: true,
        changeStatus: true,
      },
      subproduct: {
        create: true,
        edit: true,
        delete: true,
        changeStatus: true,
      },
      variant: {
        create: true,
        edit: true,
        delete: true,
        changeStatus: true,
      },
      client: {
        create: true,
        edit: true,
        delete: true,
        changeStatus: false,
      },
    },
  },
}

export function hasPermission(user, resource, data) {
  const authorized = _.get(ROLES[user.role], resource, false)

  // console.log(user)
  // console.log(authorized)
  console.log(resource)
  console.log(data)
  if (authorized == null) return false

  if (typeof authorized === 'boolean') return authorized

  return authorized(user, data)
}
