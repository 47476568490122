import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SalesDealAPI from '../../apis/SalesDealAPI'
import Loading from '../../components/Loading'
import BreadCrumbs from '../../components/BreadCrumbs'
import { formatThousands } from '../../helper'
import moment from 'moment'
import Status from '../../components/Status'
import Button from '../../components/Button'
import Basic from '../../components/table/cell/Basic'
import Table from '../../components/table/Table'
import Input from '../../components/Input'
import { AttachMoney, Delete, Money, Percent } from '@mui/icons-material'
import Alert from '../../components/Alert'
import InputDate from '../../components/InputDate'
import StatusCell from '../../components/table/cell/StatusCell'
import GroupButtons from '../../components/GroupButtons'
import { useQuery } from '@tanstack/react-query'
import Checkbox from '@/components/Checkbox'

export default function RequestInvoice() {
  const { id } = useParams()
  const [project, setProject] = useState()
  const [isLoading, setLoading] = useState(true)
  const [paymentTerms, setPaymentTerms] = useState([
    {
      id: Math.random() * 10000,
      amount_type: 'percentage',
      amount_percentage: 0,
      amount_absolute: null,
      amount: 0,
      invoice_date: '',
      due_date: '',
      status: 'pending',
      term: 1,
    },
  ])
  const [copyPaymentTerms, setCopyPaymentTerms] = useState([])
  const [addedTerms, setAddedTerms] = useState([])
  const invoiceItemsCols = [
    {
      id: 0,
      name: 'Descriptions',
      selector: (row) => <Basic data={row.product_variant_name} />,
      classes: 'min-w-[300px] w-[400px]',
    },
    {
      id: 1,
      name: 'Price',
      selector: (row) => <Basic data={`Rp${formatThousands(row.price)}`} alignment={'right'} />,
      classes: 'min-w-[100px] text-right',
    },

    {
      id: 3,
      name: 'Quantity',
      selector: (row) => <Basic data={row.quantity} alignment={'right'} />,
      classes: 'min-w-[100px] text-right',
    },
    {
      id: 2,
      name: 'Discount',
      classes: 'text-right min-w-[200px]',
      selector: (row) => (
        <Basic
          className={'text-right'}
          data={
            row.discount_type === 'absolute'
              ? `Rp${formatThousands(row.discount_absolute)}`
              : `${parseFloat((parseFloat(row.discount_percentage) * 100).toFixed(3))}%`
          }
        />
      ),
    },

    {
      id: 4,
      name: 'Total',
      selector: (row) => (
        <Basic
          data={
            row.discount_type === 'absolute'
              ? `Rp${formatThousands(row.price * row.quantity - row.discount_absolute)}`
              : `Rp${formatThousands(row.price * row.quantity - row.price * row.quantity * row.discount_percentage)}`
          }
          alignment={'right'}
        />
      ),
      classes: 'min-w-[200px] text-right',
    },
  ]
  const [saveInvoiceError, setSaveInvoiceError] = useState()
  const navigate = useNavigate()
  const [loadingSaveInvoice, setLoadingSaveInvoice] = useState(false)
  const [stateCellConfig, setCellConfig] = useState([
    {
      text: 'In Review',
      status: 'in_review',
      state: 'warning',
    },
    {
      text: 'Pending',
      status: 'pending',
      // state: 'success',
    },
    {
      text: 'Processed',
      status: 'processed',
      state: 'warning-urgent',
    },
    {
      text: 'Invoiced',
      status: 'invoiced',
      state: 'success',
    },
    {
      text: 'Rejected',
      status: 'rejected',
      state: 'danger',
    },
    {
      text: 'Returned',
      status: 'returned',
      state: 'danger',
    },
    {
      text: 'Void',
      status: 'void',
      state: 'neutral',
    },
  ])
  const [paymentTermsType, setPaymentTermsType] = useState('percentage')
  const [haveDefaultPaymentTermsType, sethaveDefaultPaymentTermsType] = useState(false)

  // const project = useQuery({
  //   queryKey: ['sales-deal', id, 'project'],
  //   queryFn: async () => {
  //     const res = await SalesDealAPI.getSalesDealProject(id)
  //     console.log(res.data)

  //     return res.data
  //   },
  // })

  // const invoices = useQuery({
  //   queryKey: ['sales-deal', id, 'invoice'],
  //   queryFn: async () => {
  //     const res = await SalesDealAPI.getSalesDealInvoice(id)
  //     console.log(res)

  //     return res.data
  //   },
  // })

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        SalesDealAPI.getSalesDealProject(id)
          .then((res) => {
            setProject(res.data)
            console.log(res.data)
            setLoading(false)
          })
          .then((res) => {
            resolve()
          })
      }),
      new Promise((resolve, reject) => {
        SalesDealAPI.getSalesDealInvoice(id).then((res) => {
          if (res.success) {
            console.log(res.data)
            if (res.data.length > 0) {
              let haveInvoiceTerm = false
              res.data.forEach((term) => {
                if (term.invoice_date) haveInvoiceTerm = true
              })

              if (haveInvoiceTerm) sethaveDefaultPaymentTermsType(true)
            }
            setPaymentTermsType(res.data[0]?.amount_type || 'percentage')
            setPaymentTerms(
              res.data.map((item) => ({ ...item, status: item.status || 'pending', id: Math.random() * 10000 }))
            )
            setCopyPaymentTerms(
              res.data.map((item) => ({ ...item, status: item.status || 'pending', id: Math.random() * 10000 }))
            )
          }
          resolve()
        })
      }),
    ]
    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    // console.log(paymentTerms)
  }, [paymentTerms])

  const handleAddPaymentTerm = () => {
    var temp = [
      ...paymentTerms,
      {
        id: Math.random() * 10000,
        amount_type: 'percentage',
        amount_percentage: 0,
        amount_absolute: null,
        amount: 0,
        invoice_date: '',
        due_date: '',
        status: 'pending',
        term: null,
        newTerm: true,
      },
    ]
    setPaymentTerms(temp)
  }

  const handlePaymentTermTypeChange = (type) => {
    setPaymentTermsType(type)
    var temp
    if (paymentTermsType !== type) {
      if (type === 'absolute') {
        temp = paymentTerms.map((term) => ({
          ...term,
          amount_type: type,
          amount_absolute: term.amount,
          amount_percentage: null,
        }))
      } else {
        temp = paymentTerms.map((term) => ({
          ...term,
          amount_type: type,
          amount_absolute: null,
          amount_percentage: (term.amount / project.total_revenue).toFixed(2),
        }))
      }
      console.log(temp)
      setPaymentTerms(temp)
    }
  }

  const handlePaymentTermChange = (input, id) => {
    var temp = paymentTerms.findIndex((item) => item.id === id)
    if (temp !== -1) {
      if (input.name === 'amount_percentage') {
        var tempTerm = {
          ...paymentTerms[temp],
          [input.name]: (parseInt(input.value) / 100).toFixed(2),
          amount: project.total_revenue * (parseInt(input.value) / 100).toFixed(2),
        }
        var paymentTermsTemp = [...paymentTerms]
        paymentTermsTemp.splice(temp, 1, tempTerm)
        setPaymentTerms(paymentTermsTemp)
      } else if (input.name === 'amount_absolute') {
        var tempTerm = { ...paymentTerms[temp], [input.name]: parseInt(input.value), amount: input.value }
        var paymentTermsTemp = [...paymentTerms]
        paymentTermsTemp.splice(temp, 1, tempTerm)
        setPaymentTerms(paymentTermsTemp)
      } else {
        var tempTerm = { ...paymentTerms[temp], [input.name]: input.value }
        var paymentTermsTemp = [...paymentTerms]
        paymentTermsTemp.splice(temp, 1, tempTerm)
        // console.log(paymentTermsTemp)
        setPaymentTerms(paymentTermsTemp)
      }
    } else {
    }
  }

  const handleDateChange = (value, name, itemTerm) => {
    var itemIndex = paymentTerms.findIndex((existItem) => existItem.id === itemTerm.id)
    if (itemIndex !== -1) {
      var tempTerm = { ...paymentTerms[itemIndex], [name]: value }
      var paymentTermsTemp = [...paymentTerms]
      paymentTermsTemp.splice(itemIndex, 1, tempTerm)
      setPaymentTerms(paymentTermsTemp)
    }
  }

  const handleDeletePaymentTerm = (id) => {
    var temp = paymentTerms.filter((item) => item.id !== id)
    setPaymentTerms(temp)
  }

  const handleAddDateLater = (itemTerm) => {
    var itemIndex = paymentTerms.findIndex((existItem) => existItem.id === itemTerm.id)
    if (itemIndex !== -1) {
      var tempTerm = { ...paymentTerms[itemIndex], invoice_date: null, due_date: null }
      var paymentTermsTemp = [...paymentTerms]
      paymentTermsTemp.splice(itemIndex, 1, tempTerm)
      setPaymentTerms(paymentTermsTemp)
    }
  }

  const handleSavePaymentTerm = () => {
    setLoadingSaveInvoice(true)
    var terms_complete = false

    var hasInvoiceLock = false

    copyPaymentTerms.map((term) => {
      if (['in_review', 'processed', 'invoiced', 'rejected', 'returned', 'void'].includes(term.status)) {
        hasInvoiceLock = true
      }
    })

    if (paymentTermsType === 'percentage') {
      var totalPercentage = 0
      paymentTerms.map((term) => {
        if (['pending', 'in_review', 'processed', 'invoiced'].includes(term.status)) {
          totalPercentage += parseFloat(term.amount_percentage)
        }
      })
      if (totalPercentage.toFixed(3) == 1) terms_complete = true
    } else {
      var total_amount = 0
      paymentTerms.map((term) => {
        if (['pending', 'in_review', 'processed', 'invoiced'].includes(term.status)) {
          total_amount += parseFloat(term.amount_absolute)
        }
      })
      if (total_amount === project.total_revenue) terms_complete = true
    }

    if (hasInvoiceLock && paymentTerms.length === 0) {
      console.log(copyPaymentTerms)
      setSaveInvoiceError('A previously reviewed invoice cannot be deleted!')
      setLoadingSaveInvoice(false)
      return
    }

    if (!terms_complete && paymentTerms.length > 0) {
      setSaveInvoiceError('Make sure total of all active invoices is correct!')
      setLoadingSaveInvoice(false)
      return
    }

    var temp = {
      sales_deal_id: id,
      payment_terms: paymentTerms
        .filter((term) => term.status === 'pending')
        .map((term) => ({
          amount_type: paymentTermsType,
          term: parseInt(term.term),
          amount_absolute: term.amount_absolute,
          amount_percentage: parseFloat(term.amount_percentage),
          amount: term.amount,
          invoice_date: term.invoice_date ? moment(term.invoice_date).format('YYYY-MM-DD') : null,
          due_date: term.due_date ? moment(term.due_date).format('YYYY-MM-DD') : null,
        })),
    }
    // console.log(temp)
    SalesDealAPI.saveInvoiceData(temp)
      .then((res) => {
        if (res.success) {
          navigate(-1)
        }
      })
      .catch((err) => {
        if (err.response) {
          setSaveInvoiceError(err.response.statusText)
          setLoadingSaveInvoice(false)
        } else {
          setSaveInvoiceError(err.statusText)
          setLoadingSaveInvoice(false)
        }
      })
  }

  return (
    <div className="py-4">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="">
          <div className="mb-4">
            <BreadCrumbs>
              <BreadCrumbs.Item href={'/sales-deal'}>Sales Deal</BreadCrumbs.Item>
              <BreadCrumbs.Item href={`/sales-deal/${project.sales_deal_id}`}>{project.name}</BreadCrumbs.Item>
              <BreadCrumbs.Item current>Request Invoice</BreadCrumbs.Item>
            </BreadCrumbs>
          </div>
          <div className="mb-8">
            <h1 className="font-semibold text-giant mb-4">Invoice Items</h1>
            <Table
              data={project.revenue_items}
              cols={invoiceItemsCols}
              tableType="full"
              footer={
                <>
                  <Table.Tr>
                    <Table.Td colSpan={4}></Table.Td>
                    <Table.Td className={'text-right font-semibold'}>Total Revenue</Table.Td>
                    <Table.Td className={'text-right font-semibold'}>{`Rp${formatThousands(
                      project.total_revenue
                    )}`}</Table.Td>
                  </Table.Tr>
                </>
              }
            />
            {/* <table className={'table-auto text-left'}>
                  <thead className="rounded-[5px] py-4 text-left font-bold text-white bg-main-500 overflow-hidden">
                    <tr>
                      <th className="px-4 py-4 text-center grow-0 shrink-0 rounded-l-[5px]">#</th>
                      <th className="px-4 py-4 grow-0 shrink-0">Description</th>
                      <th className="px-4 py-4 grow-0 shrink-0 min-w-[500px]">Price</th>
                      <th className="px-4 py-4 text-center grow-0 shrink-0 rounded-r-[5px]">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-4"></td>
                    </tr>
                  </tbody>
                </table> */}
          </div>
          <div className="mb-8 w-fit">
            <h1 className="font-semibold text-giant mb-4">Payment Terms</h1>
            {/* <Table data={salesDeal.project.revenue_items} cols={invoiceItemsCols} tableType="inline" /> */}
            <div className="mb-4 flex gap-2 justify-between items-end">
              <Button variant={'outlined'} onClick={handleAddPaymentTerm}>
                Add Payment Terms
              </Button>
              {!haveDefaultPaymentTermsType && (
                <GroupButtons
                  icons={true}
                  size="small"
                  defaultValue={paymentTermsType}
                  data={[
                    {
                      id: 'absolute',
                      text: <AttachMoney sx={{ fontSize: 18 }} />,
                      onClick: () => {
                        handlePaymentTermTypeChange('absolute')
                      },
                    },
                    {
                      id: 'percentage',
                      text: <Percent sx={{ fontSize: 18 }} />,
                      onClick: () => {
                        handlePaymentTermTypeChange('percentage')
                      },
                    },
                  ]}
                />
              )}
            </div>
            <table className={'table-auto text-left w-full'}>
              <thead className="rounded-[5px] py-4 text-left font-bold text-white bg-main-500 overflow-hidden">
                <tr>
                  <th className="px-4 py-4 text-center grow-0 shrink-0 rounded-l-[5px]">Term</th>
                  <th className="px-4 py-4 grow-0 shrink-0 w-[250px] text-right">Amount</th>
                  <th className="px-4 py-4 grow-0 shrink-0 min-w-[180px] text-right">Total</th>
                  <th className="px-4 py-4 grow-0 shrink-0 w-[225px] ">Invoice Date</th>
                  <th className="px-4 py-4 grow-0 shrink-0 w-[225px] ">Invoice Due Date</th>
                  <th className="px-4 py-4 grow-0 shrink-0 w-[225px] "></th>
                  <th className="px-4 py-4 grow-0 shrink-0 w-[225px] text-center">Status</th>
                  <th className="px-4 py-4 grow-0 shrink-0 w-[100px] rounded-r-[5px]">Action</th>
                </tr>
              </thead>
              <tbody>
                {paymentTerms.length === 0 ? (
                  <tr key={0}>
                    <td className="p-4 text-center italic text-gray-400" colSpan={6}>
                      No payment terms added
                    </td>
                  </tr>
                ) : (
                  paymentTerms.map((term, index) => {
                    return (
                      <>
                        {term.status !== 'pending' ? (
                          <tr key={index} className="border-b">
                            <td className="py-4 text-center">{term.term}</td>
                            <td className="py-4 px-4 text-right">
                              {paymentTermsType === 'percentage'
                                ? `${term.amount_percentage ? term.amount_percentage * 100 : 0}%`
                                : `Rp${formatThousands(term.amount_absolute || 0)}`}
                            </td>
                            <td className="py-4 px-4 text-right">Rp{formatThousands(term.amount)}</td>
                            <td className="py-4 px-4 ">{moment(term.invoice_date).format('D MMMM YYYY')}</td>
                            <td className="py-4 px-4 ">{moment(term.due_date).format('D MMMM YYYY')}</td>
                            <td className="text-center">
                              <StatusCell data={term.status} config={stateCellConfig} className={'font-medium'} />
                            </td>
                          </tr>
                        ) : (
                          <tr key={index} className="border-b">
                            <td className="py-2 text-center">
                              <Input
                                placeholder="term"
                                type={'number'}
                                name="term"
                                style={{ minWidth: 'auto' }}
                                containerClasses={'w-16'}
                                onChange={(e) => handlePaymentTermChange(e.target, term.id)}
                                value={term.term}
                              />
                            </td>
                            <td className="py-2 px-4 w-max">
                              {paymentTermsType === 'percentage' ? (
                                <div
                                  className={`flex rounded-[5px] border ${'border-primary-gray'} w-full transition-all focus-within:ring-4 focus-within:ring-secondary-blue focus-within:outline-none focus:border-main-500`}
                                >
                                  <div className="bg-main-500/10 rounded-l-[5px] flex items-center p-3">
                                    <Percent sx={{ fontSize: 16 }} />
                                  </div>
                                  <input
                                    placeholder="%"
                                    type={'number'}
                                    name="amount_percentage"
                                    className={`p-3 outline-none rounded-[5px] flex-1`}
                                    min="0"
                                    max="100"
                                    onChange={(e) => handlePaymentTermChange(e.target, term.id)}
                                    value={term.amount_percentage ? term.amount_percentage * 100 : 0}
                                  />
                                </div>
                              ) : (
                                <div
                                  className={`flex rounded-[5px] border ${'border-primary-gray'} w-full transition-all focus-within:ring-4 focus-within:ring-secondary-blue focus-within:outline-none focus:border-main-500`}
                                >
                                  <div className="bg-main-500/10 rounded-l-[5px] flex items-center p-3">
                                    <AttachMoney sx={{ fontSize: 16 }} />
                                  </div>
                                  <input
                                    placeholder="$"
                                    type={'number'}
                                    name="amount_absolute"
                                    className={`p-3 outline-none rounded-[5px] flex-1`}
                                    onChange={(e) => handlePaymentTermChange(e.target, term.id)}
                                    value={term.amount_absolute ? term.amount_absolute : 0}
                                  />
                                </div>
                              )}
                            </td>
                            <td className="py-2 px-4 text-right">Rp{formatThousands(term.amount)}</td>
                            <td className="p-2 px-4">
                              {/* <Input
                            type={'date'}
                            name={'invoice_date'}
                            placeholder="Invoice Date"
                            onChange={(e) => handlePaymentTermChange(e.target, term.id)}
                            value={term.invoice_date}
                          /> */}

                              <InputDate
                                toYear={new Date().getFullYear() + 1}
                                value={term.invoice_date}
                                handleSelectedOption={(selected) => handleDateChange(selected, 'invoice_date', term)}
                                name="invoice_date"
                              />
                            </td>
                            <td className="p-2 px-4">
                              {/* <Input
                            type={'date'}
                            name={'due_date'}
                            placeholder="Due Date"
                            onChange={(e) => handlePaymentTermChange(e.target, term.id)}
                            value={term.due_date}
                          /> */}

                              <InputDate
                                toYear={new Date().getFullYear() + 1}
                                value={term.due_date}
                                handleSelectedOption={(selected) => handleDateChange(selected, 'due_date', term)}
                                name="due_date"
                              />
                            </td>
                            <td className="text-center">
                              <Checkbox
                                label={'Add invoice date later'}
                                onChange={() => handleAddDateLater(term)}
                                checked={!term.invoice_date && !term.due_date}
                              />
                            </td>
                            <td className="text-center">
                              <StatusCell data={term.status} config={stateCellConfig} className={'font-medium'} />
                            </td>
                            <td className="p-2 px-4">
                              <button
                                className="w-8 h-8 rounded text-gray-400 hover:text-red-500 hover:bg-red-100"
                                onClick={() => {
                                  handleDeletePaymentTerm(term.id)
                                }}
                              >
                                <Delete sx={{ fontSize: 20 }} />
                              </button>
                            </td>
                          </tr>
                        )}
                      </>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
          <div className="w-[800px]">
            {saveInvoiceError && <Alert className={'mb-4 w-fit'} type={'error'} message={saveInvoiceError} />}
            {/* <Alert
              className="mb-4"
              message={
                'Your invoice request will be sent into Cost Control system and finance team will contact you to give your invoice documents.'
              }
            /> */}
            <Button isLoading={loadingSaveInvoice} onClick={handleSavePaymentTerm}>
              Save Invoice Request
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
