import React, { useEffect, useState } from 'react'
import { COGSitemAPI } from '../../apis/COGSitemsAPI'
import Loading from '../../components/Loading'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Table from '../../components/table/Table'
import Basic from '../../components/table/cell/Basic'
import BreadCrumbs from '../../components/BreadCrumbs'
import { formatThousands } from '../../helper'
import { useNavigate } from 'react-router-dom'
import { Close, ChevronRight, ChevronLeft } from '@mui/icons-material'
import GroupButtons from '../../components/GroupButtons'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import usePermission from '../../hooks/usePermission'
import Pagination from '../../components/Pagination'
import { useCustomSearchParams } from '../../hooks/useCustomSearchParams'
import toast from 'react-hot-toast'

export default function COGSItems() {
  const [cogs, setCogs] = useState([])
  const [searchParams, setSearchParams] = useCustomSearchParams()
  const [cols, setCols] = useState([
    {
      id: 0,
      name: 'Item Name',
      classes: 'min-w-[200px]',
      selector: (row) => row.name,
    },
    {
      id: 1,
      name: 'Description',
      classes: 'min-w-[300px]',
      selector: (row) => row.description,
    },
    {
      id: 2,
      name: 'Cost',
      classes: 'min-w-[200px]',
      selector: (row) => <Basic data={'Rp' + formatThousands(row.cost)} />,
    },
  ])
  const [isLoading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState({ total_page: 1 })
  const [isOpenModal, setOpenModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const { isAdmin } = usePermission()

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      COGSitemAPI.getCOGSitems(searchParams.q, searchParams.page)
        .then((res) => {
          setCogs(res.data)
          setPagination(res.meta)
          setLoading(false)
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message)
          } else toast.error(err.statusText)
          setLoading(false)
        })
    }, 250)

    return () => clearTimeout(timeOutId)
  }, [searchParams.q, searchParams.page])

  function handleDelete(id) {
    setDeleteId(id)
    setOpenModal(true)
  }

  return (
    <div className="py-4">
      <div className="flex flex-col md:flex-row md:justify-between md:items-end gap-2.5 mb-2">
        <Input
          label={'Search COGS Items'}
          placeholder="COGS item name"
          className="w-full md:w-[270px]"
          onChange={(e) => setSearchParams({ ...searchParams, page: 1, q: e.target.value })}
          value={searchParams.q}
        ></Input>
        {isAdmin && (
          <Button href="add" className={'w-full md:w-fit'}>
            Add New Items
          </Button>
        )}
      </div>
      <div className="max-w-fit">
        {isLoading ? (
          <Loading />
        ) : isAdmin ? (
          <Table
            cols={cols}
            data={cogs}
            className="block"
            withActions
            editLink={(row) => {
              return `${row.id}/edit`
            }}
            handleDelete={handleDelete}
            tableType="inline"
            pageIndex={searchParams.page || 1}
          />
        ) : (
          <Table cols={cols} data={cogs} className="block" tableType="inline" pageIndex={searchParams.page || 1} />
        )}

        <div className="w-full flex justify-end">
          <Pagination
            totalPage={pagination.total_page || 1}
            currentPage={parseInt(searchParams.page) || 1}
            onChange={(page) => setSearchParams({ ...searchParams, page: page })}
          />
        </div>
      </div>

      {isOpenModal && <CreateModal setOpenModal={setOpenModal} id={deleteId} />}
    </div>
  )
}

const CreateModal = ({ setOpenModal, id }) => {
  const navigate = useNavigate()

  function handleDelete() {
    console.log(id)

    COGSitemAPI.delete(id)
      .then((data) => {
        setOpenModal(false)
        navigate(0)
      })
      .catch((err) => {
        setOpenModal(false)
        console.log(err)
      })
  }

  return (
    <>
      <div
        className="bg-black bg-opacity-20 z-10 w-screen h-screen top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 fixed"
        onClick={() => setOpenModal(false)}
      />
      <div className="fixed z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="w-[600px] rounded bg-white shadow-lg z-20">
          <div className="p-4 overflow-hidden rounded-t border-b flex justify-between items-center mb-2.5">
            <h5 className="text-big"> Are you sure to delete this COGS Item?</h5>
            <button
              className="w-6 h-6 flex items-center justify-center rounded text-gray-400 hover:bg-red-200 hover:text-red-500"
              onClick={() => setOpenModal(false)}
            >
              <Close sx={{ fontSize: 16 }} />
            </button>
          </div>
          <div className="flex justify-end gap-2.5 p-4 pt-0">
            <Button variant="outlined" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
            <Button onClick={handleDelete}>Delete</Button>
          </div>
        </div>
      </div>
    </>
  )
}
