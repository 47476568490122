import { useEffect, useState } from 'react'
import { DollarSign, Percent } from 'lucide-react'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

export default function DiscountTypeMenu({ discountType, onChangeType }) {
  const [listMenu, setListMenu] = useState({
    percentage: {
      type: '%',
      value: 'percentage',
      helperText: 'Discount is a percentage of the original price.',
    },
    absolute: {
      type: '$',
      value: 'absolute',
      helperText: 'Discount is a fixed amount off the original price.',
    },
  })
  const [selectedType, setSelectedType] = useState(discountType)

  useEffect(() => {
    onChangeType(selectedType)
  }, [selectedType])

  return (
    <div className="relative h-full">
      <Select value={selectedType} onValueChange={(value) => setSelectedType(value)}>
        <SelectTrigger className="w-14 px-2 border-none bg-slate-100 rounded-l-none">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <SelectValue />
              </TooltipTrigger>
              <TooltipContent>
                <p className="text-center max-w-[200px]">{listMenu[selectedType].helperText}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>Discount Type</SelectLabel>
            <SelectItem value="percentage">
              <Percent className="w-4 h-4" />
            </SelectItem>
            <SelectItem value="absolute">
              <DollarSign className="w-4 h-4" />
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
      {/* {open && (
        <div className="absolute z-[120] mt-2 w-full max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-track-sky-50 scrollbar-thumb-main-500 scrollbar-thumb-rounded origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1" role="none">
            {listMenu.map((menu) => {
              return (
                <Tooltip key={menu.id} title={menu.value} placement="right">
                  <div
                    key={menu.id}
                    className="text-gray-700 w-full px-4 py-1 text-sm flex items-center cursor-pointer gap-2 hover:bg-main-50"
                    onClick={() => {
                      handleSelect(menu)
                      setOpen((open) => !open)
                    }}
                  >
                    {menu.type}
                  </div>
                </Tooltip>
              )
            })}
          </div>
        </div>
      )} */}
    </div>
  )
}
