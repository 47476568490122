import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BreadCrumbs from '../../components/BreadCrumbs'
import Loading from '../../components/Loading'
// import Input from '../../components/Input'
// import Textarea from '../../components/Textarea'
import DropdownInput from '../../components/DropdownInput'
// import Button from '../../components/Button'
import Alert from '../../components/Alert'
import Table from '../../components/table/Table'
import ProjectAPI from '../../apis/ProjectAPI'
import { ProductsAPI } from '../../apis/ProductsAPI'
import Basic from '../../components/table/cell/Basic'
import { formatThousands } from '../../helper'
import { COGSitemAPI } from '../../apis/COGSitemsAPI'
import usePermission from '../../hooks/usePermission'
import RevenueItemsTable from '../../components/RevenueItemsTable'
import COGSItemsTable from '../../components/COGSItemsTable'
import { useQuery } from '@tanstack/react-query'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { ArrowRight } from 'lucide-react'
import { DateInput } from '@/components/DateInput'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import toast from 'react-hot-toast'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import moment from 'moment'
import { queryClient } from '@/App'

export default function FlagshipDetail() {
  const { id } = useParams()

  const [project, setProject] = useState()
  const [updatedProject, setUpdatedProject] = useState()
  const [isLoading, setLoading] = useState(true)
  const [isLoadingSaveProject, setLoadingSaveProject] = useState(false)
  const [errorUpdateProject, setErrorUpdateProject] = useState()
  const navigate = useNavigate()
  const { isAdmin } = usePermission()
  const [marginCols, setMarginCols] = useState([
    { id: 0, name: 'Component', selector: (row) => <Basic data={row.row} />, classes: 'w-[180px] font-semibold' },
    {
      id: 1,
      name: 'Total Amount',
      selector: (row) => <Basic data={`Rp${formatThousands(row.totalAmount)}`} alignment={'right'} />,
      classes: 'w-[180px]  text-right',
    },
    {
      id: 2,
      name: 'Percentage',
      selector: (row) => <Basic data={`${(row.percentage * 100).toFixed(2)}%`} alignment={'right'} />,
      classes: 'w-[180px] text-right',
    },
  ])

  const flagshipProject = useQuery({
    queryKey: ['projects', 'f', id],
    queryFn: async () => {
      const res = await ProjectAPI.getFlagshipProjectById(id)
      console.log(res.data)
      return res.data
    },
  })

  const flagshipForm = useForm({
    resolver: zodResolver(
      z.object({
        name: z.string().min(1, { message: 'Required Field' }),
        flagship_code: z.string().min(1, { message: 'Required Field' }),
        description: z.string().min(1, { message: 'Required Field' }),
        status: z.string().min(1, { message: 'Required Field' }),
        start_date: z.coerce.date(),
        end_date: z.coerce.date(),
      })
    ),
    defaultValues: {
      name: '',
      flagship_code: '',
      description: '',
      status: '',
      start_date: '',
      end_date: '',
    },
  })

  const watchFlagshipName = flagshipForm.watch('name')

  useEffect(() => {
    if (!flagshipProject.isLoading) {
      console.log(flagshipProject.data)
      flagshipForm.setValue('name', flagshipProject.data.name)
      flagshipForm.setValue('flagship_code', flagshipProject.data.flagship_code)
      flagshipForm.setValue('description', flagshipProject.data.description)
      flagshipForm.setValue('status', flagshipProject.data.status)
      flagshipForm.setValue('start_date', flagshipProject.data.start_date)
      flagshipForm.setValue('end_date', flagshipProject.data.end_date)
    }
  }, [flagshipProject.data])

  useEffect(() => {
    console.log(flagshipForm.formState.errors)
  }, [flagshipForm])

  useEffect(() => {
    // console.log(watchFlagshipName)
    const timeoutId = setTimeout(async () => {
      // console.log(watchFlagshipName)
      var res = await ProjectAPI.getFlagshipProjectCode(watchFlagshipName)
      // console.log(res.data)
      flagshipForm.setValue('flagship_code', res.data.flagship_code)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [watchFlagshipName])

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        ProjectAPI.getFlagshipProjectById(id).then((res) => {
          setProject({ ...res.data, type: 'flagship' })
          setUpdatedProject({ ...res.data, type: 'flagship' })
          resolve()
        })
      }),
    ]
    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [id])

  const handleStatusChange = (selected) => {
    var temp = { ...updatedProject, status: selected.value }
    setUpdatedProject(temp)
  }

  const handleChange = (input) => {
    setUpdatedProject({ ...updatedProject, [input.name]: input.value })
  }

  const saveUpdatedProject = () => {
    setLoadingSaveProject(true)
    console.log(updatedProject.revenue_items.length > 0)
    if (updatedProject.revenue_items.length > 0) {
      var tempObject = {
        name: updatedProject.name,
        description: updatedProject.description,
        status: updatedProject.status,
        revenue_items: updatedProject.revenue_items.map((item) => {
          return {
            id: item.id,
            product_id: item.product_id,
            sub_product_id: item.sub_product_id,
            product_variant_id: item.product_variant_id,
            price: item.price,
            quantity: item.quantity,
            discount_type: item.discount_type,
            discount_percentage: item.discount_percentage,
            discount_absolute: item.discount_absolute,
          }
        }),
        cogs_items: updatedProject.cogs_items.map((item) => {
          // console.log(item.revenue_items_id)
          return {
            revenue_items_id: item.project_revenue_item_id,
            cogs_id: item.cogs_id,
            cost: item.cost,
            quantity: item.quantity,
            custom_name: '',
          }
        }),
      }
      // console.log(tempObject)
      ProjectAPI.updateFlagshipProject(id, tempObject)
        .then((res) => {
          if (res.success) {
            // console.log(res)
            navigate('/projects/flagship')
          }
        })
        .catch((err) => {
          if (err.response) {
            setErrorUpdateProject(err.response.data.message)
          } else {
            setErrorUpdateProject(err.message)
          }
        })
    } else {
      setErrorUpdateProject("Can't save flagship project with no revenue item.")
      setLoadingSaveProject(false)
    }
  }

  const saveUpdateFlagshipProject = async (values) => {
    setLoadingSaveProject(true)
    console.log(values)
    try {
      var res = await ProjectAPI.updateFlagshipProject(id, {
        ...values,
        start_date: moment(values.start_date).format('YYYY-MM-DD'),
        end_date: moment(values.end_date).format('YYYY-MM-DD'),
      })

      console.log(res.data)
      toast.success('Create flagship project success!')
      queryClient.invalidateQueries(['projects', 'f', id])
      setLoadingSaveProject(false)
      navigate('/projects/flagship')
    } catch (err) {
      console.log(err)
      toast.error(err.response?.data?.message || 'Failed to save new flagship project')
    }
  }

  return (
    <div className="py-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="mb-12">
            <h1 className="font-semibold text-giant mb-4">Create Flagship Project</h1>
            <div className="border border-gray-border rounded-[5px] p-4 inline-block">
              <Form {...flagshipForm}>
                <form
                  className="max-w-[500px] w-full md:w-[500px] grid gap-4"
                  onSubmit={flagshipForm.handleSubmit(saveUpdateFlagshipProject)}
                >
                  <FormField
                    control={flagshipForm.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-normal">Project Name</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormDescription className="flex items-center gap-2 text-xs">
                          Flagship code <ArrowRight className="w-3 h-3" /> {flagshipForm.watch('flagship_code')}
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={flagshipForm.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-normal">Description</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <div className="flex gap-4 items-center">
                    <FormField
                      control={flagshipForm.control}
                      name="start_date"
                      render={({ field }) => {
                        console.log(field.value)
                        return (
                          <FormItem className="flex-1">
                            <FormLabel className="text-normal block">Start Date</FormLabel>
                            <FormControl>
                              <DateInput
                                onSelect={(selectedDate) => {
                                  console.log(selectedDate)
                                  field.onChange(moment(selectedDate).format('YYYY-MM-DD'))
                                }}
                                defaultValue={field.value}
                                className={'w-full'}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )
                      }}
                    />
                    <FormField
                      control={flagshipForm.control}
                      name="end_date"
                      render={({ field }) => (
                        <FormItem className="flex-1">
                          <FormLabel className="text-normal">End Date</FormLabel>
                          <FormControl>
                            <DateInput
                              onSelect={(selectedDate) => {
                                field.onChange(moment(selectedDate).format('YYYY-MM-DD'))
                              }}
                              defaultValue={field.value}
                              className={'w-full'}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <FormField
                    control={flagshipForm.control}
                    name="status"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-normal">Status</FormLabel>
                        <FormControl>
                          <Select onValueChange={field.onChange} defaultValue={field.value}>
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Active" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              <SelectItem value="active">Active</SelectItem>
                              <SelectItem value="inactive">Inactive</SelectItem>
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {/* <p className="mb-1 font-medium">Status</p>
                <DropdownInput
                  data={[
                    { id: 'active', text: 'Active', value: 'active' },
                    { id: 'inactive', text: 'Inctive', value: 'inactive' },
                  ]}
                  attributeShown="text"
                  defaultValue={project.status}
                  handleSelectedOption={(selected) => {
                    handleStatusChange(selected)
                  }}
                  placeholder={'Choose status'}
                /> */}
                  <Button type="submit" className="w-fit">
                    {isLoadingSaveProject ? <Loading className={'text-white'} /> : 'Create Project'}
                  </Button>
                </form>
              </Form>
            </div>
          </div>
          {/* <div className="mb-4">
            <BreadCrumbs>
              <BreadCrumbs.Item href={'/projects'}>Project</BreadCrumbs.Item>
              <BreadCrumbs.Item current>{project.name}</BreadCrumbs.Item>
            </BreadCrumbs>
          </div>
          <div className="mb-12">
            <h1 className="font-semibold text-giant mb-4">Project Information</h1>
            <div className="border border-gray-border rounded-[5px] p-4">
              <div className="max-w-[500px]">
                <Input
                  label={'Project Name'}
                  onChange={(e) => handleChange(e.target)}
                  name="name"
                  className={'mb-4'}
                  placeholder="Project Name"
                  value={updatedProject.name}
                />
                <Textarea
                  label={'Description'}
                  onChange={(e) => handleChange(e.target)}
                  name="description"
                  className={'mb-4'}
                  rows={5}
                  value={updatedProject.description}
                />

                <p className="mb-1 font-medium">Status</p>
                <DropdownInput
                  data={[
                    { id: 'active', text: 'Active', value: 'active' },
                    { id: 'inactive', text: 'Inactive', value: 'inactive' },
                  ]}
                  attributeShown="text"
                  defaultValue={updatedProject.status}
                  handleSelectedOption={(selected) => {
                    handleStatusChange(selected)
                  }}
                  placeholder={'Choose status'}
                />
              </div>
            </div>
          </div>
          <RevenueItemsTable
            project={updatedProject}
            setProject={setUpdatedProject}
            isFlagshipPages={isAdmin}
            projectTypeTemp={'custom'}
          />
          <COGSItemsTable
            project={updatedProject}
            setProject={setUpdatedProject}
            isEditable={isAdmin}
            projectTypeTemp={'custom'}
          />
          <div className="mb-12">
            <h1 className="text-giant mb-4 font-semibold">Margin</h1>
            <div className="border border-gray-border rounded-[5px] p-4">
              <Table
                tableType="inline"
                noIndex={true}
                cols={marginCols}
                data={[
                  {
                    id: 0,
                    row: 'Revenue',
                    totalAmount: updatedProject.total_revenue,
                    percentage:
                      updatedProject.total_revenue !== 0
                        ? 1 - updatedProject.total_cogs / updatedProject.total_revenue
                        : 0,
                  },
                  {
                    id: 1,
                    row: 'COGS',
                    totalAmount: updatedProject.total_cogs,
                    percentage:
                      updatedProject.total_revenue !== 0 ? updatedProject.total_cogs / updatedProject.total_revenue : 1,
                  },
                ]}
              />
            </div>
          </div>
          <div className="flex justify-end gap-4 mb-12">
            {errorUpdateProject && <Alert type={'error'} message={errorUpdateProject} />}
            <Button onClick={saveUpdatedProject} isLoading={isLoadingSaveProject}>
              Save Project
            </Button>
          </div> */}
        </>
      )}
    </div>
  )
}
