import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import BreadCrumbs from '../../components/BreadCrumbs'
import Loading from '../../components/Loading'
import Table from '../../components/table/Table'
import ProjectAPI from '../../apis/ProjectAPI'
import Basic from '../../components/table/cell/Basic'
import { formatThousands } from '../../helper'
import usePermission from '../../hooks/usePermission'
import RevenueItemsTable from '../../components/RevenueItemsTable'
import COGSItemsTable from '../../components/COGSItemsTable'
import StatusCell from '../../components/table/cell/StatusCell'
import moment from 'moment'
import { Button } from '@/components/ui/button'
import { Copy, SquarePen } from 'lucide-react'
import LogoCell from '@/components/table/cell/LogoCell'
import { useQuery } from '@tanstack/react-query'
import Status from '@/components/Status'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { useCopyToClipboard } from '@uidotdev/usehooks'
import toast from 'react-hot-toast'

export default function FlagshipDetail() {
  const { id } = useParams()
  const [project, setProject] = useState()
  const [isLoading, setLoading] = useState(true)
  const { isAdmin } = usePermission()
  const [marginCols, setMarginCols] = useState([
    { id: 0, name: 'Component', selector: (row) => <Basic data={row.row} />, classes: 'w-[180px] font-semibold' },
    {
      id: 1,
      name: 'Total Amount',
      selector: (row) => <Basic data={`Rp${formatThousands(row.totalAmount)}`} alignment={'right'} />,
      classes: 'w-[180px]  text-right',
    },
    {
      id: 2,
      name: 'Percentage',
      selector: (row) => <Basic data={`${(row.percentage * 100).toFixed(2)}%`} alignment={'right'} />,
      classes: 'w-[180px] text-right',
    },
  ])
  const [copiedText, copyToClipboard] = useCopyToClipboard()

  const flagshipProjects = useQuery({
    queryKey: ['projects', 'f', id],
    queryFn: async () => {
      const res = await ProjectAPI.getFlagshipProjectById(id)
      console.log(res.data)
      return res.data
    },
  })

  const [clientCols, setClientCols] = useState([
    {
      id: 0,
      name: 'Client',
      selector: (row) => <LogoCell img={row.client.logo} text={row.client.name} />,
      classes: 'w-[180px] font-semibold',
    },
    {
      id: 1,
      name: 'Project Name',
      selector: (row) => <Basic data={row.name} />,
      classes: 'w-[180px] font-semibold',
    },
    {
      id: 2,
      name: 'Project ID',
      selector: (row) => (
        <Basic
          data={
            <Basic
              data={
                row.cc_project_id ? (
                  <span className="flex items-center gap-2">
                    {row.cc_project_id}
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            size={'xs'}
                            variant={'outline'}
                            onClick={(e) => {
                              e.stopPropagation()
                              toast.success('Copied to clipboard')
                              copyToClipboard(row.cc_project_id)
                            }}
                          >
                            <Copy className="w-4 h-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Click to copy project id</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </span>
                ) : (
                  '-'
                )
              }
            />
          }
        />
      ),
      classes: 'w-[216px] font-semibold',
    },
    {
      id: 3,
      name: 'Total Revenue',
      selector: (row) => <Basic alignment={'right'} data={`Rp${formatThousands(row.total_revenue || 0)}`} />,
      classes: 'w-[180px] font-semibold text-right',
    },
    {
      id: 4,
      name: 'Total COGS',
      selector: (row) => <Basic alignment={'right'} data={`Rp${formatThousands(row.total_cogs || 0)}`} />,
      classes: 'w-[180px] font-semibold text-right',
    },
    {
      id: 5,
      name: 'Margin',
      selector: (row) => <Basic alignment={'right'} data={`${Math.round(row.margin * 100)}%`} />,
      classes: 'w-[180px] font-semibold text-right',
    },
    {
      id: 6,
      name: 'COGS',
      selector: (row) => <Basic alignment={'right'} data={`${Math.round(row.cogs * 100)}%`} />,
      classes: 'w-[180px] font-semibold text-right',
    },
    {
      id: 7,
      name: 'Project Status',
      selector: (row) => (
        <StatusCell
          data={row.status}
          config={[
            {
              text: 'Approved',
              status: 'approved',
              state: 'success',
            },
            {
              text: 'Pending',
              status: 'pending',
              state: 'default',
            },
            {
              text: 'Rejected',
              status: 'rejected',
              state: 'danger',
            },
            {
              text: 'Need Revision',
              status: 'need_revision',
              state: 'warning-urgent',
            },
            {
              text: 'In Review - VP',
              state: 'warning',
              status: 'in_review_vp',
            },
            {
              text: 'In Review - SM',
              state: 'warning',
              status: 'in_review_sm',
            },
          ]}
          className={'font-medium'}
        />
      ),
      classes: 'w-[180px] font-semibold',
    },
    {
      id: 8,
      name: 'Sales Deal Status',
      selector: (row) => <Basic data={<Status type={row.sales_deal_status} size={'small'} />} />,
      classes: 'w-[180px] font-semibold',
    },
  ])

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        ProjectAPI.getFlagshipProjectById(id).then((res) => {
          setProject({ ...res.data, type: 'flagship' })
          resolve()
        })
      }),
    ]
    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [id])

  return (
    <div className="py-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="mb-4">
            <BreadCrumbs>
              <BreadCrumbs.Item href={'/projects'}>Project</BreadCrumbs.Item>
              <BreadCrumbs.Item href={'/projects/flagship'}>Flagship</BreadCrumbs.Item>
              <BreadCrumbs.Item current>{project.name}</BreadCrumbs.Item>
            </BreadCrumbs>
          </div>
          <div className="mb-8">
            <div className="flex items-center mb-4">
              <h1 className="font-semibold text-giant mr-4">Flagship {project.name}</h1>

              {isAdmin && (
                <Link to={`/projects/edit/f/${id}`}>
                  <Button
                    variant="outline"
                    size="icon"
                    // href={`/projects/edit/${project.type === 'standard' || project.type === 'custom' ? 's' : 'f'}/${
                  >
                    <SquarePen className="w-5 h-5" />
                  </Button>
                </Link>
              )}
            </div>
            <div className="border border-gray-border rounded-[5px] p-4 w-full max-w-[500px] lg:w-[500px] grid gap-4">
              <div className="">
                <p className="font-semibold mb-1">Project Name</p>
                <p className="">{project.name}</p>
              </div>
              <div className="">
                <p className="font-semibold mb-1">Flagship Code</p>
                <p className="">{project.flagship_code}</p>
              </div>
              <div className="">
                <p className="font-semibold mb-1">Description</p>
                <p className="">{project.description ? project.description : '-'}</p>
              </div>
              <div className="">
                <p className="font-semibold mb-1">Date</p>
                <p className="">
                  {moment(project.start_date).format('D MMMM YYYY')} - {moment(project.end_date).format('D MMMM YYYY')}
                </p>
              </div>
              <div className="">
                <p className="font-semibold mb-1">Project ID</p>
                <p className="">
                  {project.cc_project_id ? (
                    <span className="flex items-center gap-2">
                      {project.cc_project_id}

                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              size={'xs'}
                              variant={'outline'}
                              onClick={(e) => {
                                e.stopPropagation()
                                toast.success('Copied to clipboard')
                                copyToClipboard(row.cc_project_id)
                              }}
                            >
                              <Copy className="w-4 h-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Click to copy project id</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </span>
                  ) : (
                    '-'
                  )}
                </p>
              </div>
              <div className="">
                <p className="font-semibold mb-2">Status</p>
                <StatusCell
                  data={project.status}
                  config={[
                    {
                      text: 'Active',
                      status: 'active',
                      state: 'success',
                    },
                    {
                      text: 'Inactive',
                      status: 'inactive',
                      state: 'danger',
                    },
                  ]}
                  className={'font-medium'}
                />
              </div>
            </div>
          </div>
          <div>
            <h1 className="font-semibold text-giant mb-4">Clients</h1>
            <Table
              tableType="full"
              cols={clientCols}
              data={flagshipProjects.data.projects}
              viewLink={(row) => `/projects/view/${row.id}`}
              footer={
                <Table.Tr className={'font-bold'}>
                  <Table.Td colSpan={3}></Table.Td>
                  <Table.Td className={'font-bold'}>Total</Table.Td>
                  <Table.Td className={'text-right font-bold'}>{`Rp${formatThousands(
                    flagshipProjects.data.total_revenue || 0
                  )}`}</Table.Td>
                  <Table.Td className={'text-right font-bold'}>{`Rp${formatThousands(
                    flagshipProjects.data.total_cogs || 0
                  )}`}</Table.Td>
                  <Table.Td className={'text-right font-bold'}>{`${Math.round(
                    flagshipProjects.data.margin * 100
                  )}%`}</Table.Td>
                  <Table.Td className={'text-right font-bold'}>{`${Math.round(
                    flagshipProjects.data.cogs * 100
                  )}%`}</Table.Td>
                </Table.Tr>
              }
            ></Table>
          </div>
          {/* <RevenueItemsTable
            project={project}
            setProject={setProject}
            // isFlagshipPages={isAdmin}
            isEditable={false}
            projectTypeTemp={'custom'}
          />
          <COGSItemsTable project={project} setProject={setProject} isEditable={false} projectTypeTemp={'custom'} /> */}
          {/* <div className="mb-12">
            <h1 className="text-giant mb-4 font-semibold">Margin</h1>
            <div className="border border-gray-border rounded-[5px] p-4">
              <Table
                tableType="inline"
                noIndex={true}
                cols={marginCols}
                data={[
                  {
                    id: 0,
                    row: 'Revenue',
                    totalAmount: project.total_revenue,
                    percentage: project.total_revenue !== 0 ? 1 - project.total_cogs / project.total_revenue : 0,
                  },
                  {
                    id: 1,
                    row: 'COGS',
                    totalAmount: project.total_cogs,
                    percentage:
                      project.total_revenue !== 0
                        ? project.total_cogs / project.total_revenue
                        : project.total_cogs === 0
                        ? 0
                        : 1,
                  },
                ]}
              />
            </div>
          </div> */}
        </>
      )}
    </div>
  )
}
