import { useSelector } from 'react-redux'

const usePermission = () => {
  const user = useSelector((state) => state.auth.userData)
  var role = user?.role

  let isAE = false
  let isSM = false
  let isFinance = false
  let isVP = false
  let isAdmin = false

  if (user) {
    isAE = role === 'ae'
    isSM = role === 'sm'
    isVP = role === 'vp'
    isFinance = role === 'finance'
    isAdmin = role === 'admin'

    return { user, role, isAE, isSM, isVP, isAdmin, isFinance }
  }

  return { user: {}, role: '', isAE, isSM, isVP, isAdmin, isFinance }
}

export default usePermission
