'use client'

import * as React from 'react'
import { Check, ChevronDown, ChevronsUpDown, X } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Badge } from '@/components/ui/badge'

export function MultiSelectCombobox({
  icon,
  label,
  options,
  placeholder = 'Select items...',
  emptyMessage = 'No items found.',
  className,
  disableSearch = false,
  labelWidth,
  onSelect,
}) {
  const [open, setOpen] = React.useState(false)
  const [selected, setSelected] = React.useState([])
  const [skipFirst, setSkipFirst] = React.useState(true)

  React.useEffect(() => {
    if (skipFirst) setSkipFirst(false)
    if (!skipFirst) onSelect(selected)
  }, [selected])

  const handleSelect = (option) => {
    setSelected((prev) => {
      if (prev.some((item) => item.value === option.value)) {
        return prev.filter((item) => item.value !== option.value)
      } else {
        return [...prev, option]
      }
    })
  }

  const handleRemove = (option) => {
    setSelected((prev) => prev.filter((item) => item.value !== option.value))
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn('h-10 px-3 items-center justify-between', className)}
        >
          <div className="flex-1 flex gap-3 items-center">
            <span
              className={cn(
                'flex gap-2 items-center text-gray-900 [&_svg]:w-5 [&_svg]:h-5',
                labelWidth && `w-[${labelWidth}px]`
              )}
            >
              {icon}
              <span className="font-medium">{label}</span>
            </span>
            <div className="border-r min-h-4 !min-w-[1px] border-gray-500/30"></div>
            {selected.length > 0 ? (
              <div className="flex flex-wrap gap-1">
                {selected.map((item) => (
                  <Badge key={item.value} variant="secondary" className="mr-1">
                    {item.label}
                    <button
                      className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleRemove(item)
                        }
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                      onClick={() => handleRemove(item)}
                    >
                      <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                    </button>
                  </Badge>
                ))}
              </div>
            ) : (
              <span className="text-gray-500/30">{placeholder}</span>
            )}
          </div>

          <ChevronDown className={cn('ml-2 h-4 w-4 shrink-0 opacity-50 transition-all', open ? 'rotate-180' : '')} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command>
          {!disableSearch && <CommandInput placeholder={placeholder} />}
          <CommandList>
            <CommandEmpty className="text-main-500/30">{emptyMessage}</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem key={option.value} onSelect={() => handleSelect(option)}>
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      selected.some((item) => item.value === option.value) ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
