import React, { useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Delete } from '@mui/icons-material'
import { useQuery } from '@tanstack/react-query'
import { Plus } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { ProductsAPI } from '../apis/ProductsAPI'
import { formatThousands } from '../helper'
import usePermission from '../hooks/usePermission'
import CustomTextDropdownInput from './CustomTextDropdownInput'
import DiscountInput from './DiscountInput'
// import Button from './Button'
import Basic from './table/cell/Basic'
import { Button } from './ui/button'
import { Input } from './ui/input'
import { Label } from './ui/label'

const reCountTotal = (revenue_items) => {
  var total = 0
  revenue_items.map((item) => {
    if (item.discount_type === 'absolute') {
      total += parseInt(item.price) * parseInt(item.quantity) - item.discount_absolute
    } else
      total += parseInt(item.price) * parseInt(item.quantity) * (1 - parseFloat(item.discount_percentage).toFixed(3))
  })
  return total
}

const reCountTotalRevenueRealization = (revenue_items) => {
  var total = 0
  revenue_items.forEach((item) => {
    total += item.realization_amount
  })
}

export default function RevenueItemsTable({ project, setProject, projectTypeTemp, isEditable }) {
  const { isAE, isSM, isVP } = usePermission()
  console.log(project)
  // const [listProducts, setListProduct] = useState([])
  const [selectedProduct, setSelectedProduct] = useState({})
  // const [listVariantProduct, setListVariantProduct] = useState([])
  const [selectedVariant, setSelectedVariant] = useState({})
  const [variantPrice, setVariantPrice] = useState(0)
  const [isLoadingVariant, setLoadingVariant] = useState(false)
  const [totalRev, setTotalRev] = useState(reCountTotal(project.revenue_items))
  const [totalRevenueRealization, setTotalRevenueRealization] = useState(
    reCountTotalRevenueRealization(project.revenue_items)
  )
  const [revenueCols, setRevenueCols] = useState([
    {
      id: 0,
      name: 'Product',
      classes: 'min-w-[200px]',
    },
    {
      id: 1,
      name: 'Variant',
      classes: 'min-w-[320px]',
    },
    {
      id: 2,
      name: 'Price',
      classes: 'text-right min-w-[200px]',
    },
    {
      id: 3,
      name: 'Quantity',
      classes: 'text-right w-[100px]',
    },
    {
      id: 4,
      name: 'Discount',
      classes: 'text-right w-[250px]',
    },
    {
      id: 5,
      name: 'Total Revenue',
      classes: 'text-right min-w-[200px]',
    },
  ])

  const addItemForm = useForm({
    resolver: zodResolver(z.object({})),
  })

  const listProducts = useQuery({
    queryKey: ['products'],
    queryFn: async () => {
      const res = await ProductsAPI.getProductOnly('active', undefined, undefined, true)
      console.log(res)
      return res.data
    },
  })

  const listVariantProduct = useQuery({
    queryKey: ['products', selectedProduct.id, 'variants'],
    queryFn: async () => {
      const res = await ProductsAPI.getVariants(undefined, selectedProduct.id, undefined, 'active', undefined, true)
      console.log(res)
      return res.data
    },
    enabled: !!selectedProduct?.id,
  })

  useEffect(() => {
    if (isEditable) {
      setRevenueCols([
        ...revenueCols,
        {
          id: 6,
          name: 'Action',
          classes: 'text-center w-20',
        },
      ])
    }

    // console.log(project)
  }, []) /
    // useEffect(() => {
    //   ProductsAPI.getProductOnly(undefined, undefined, undefined, true).then((res) => {
    //     setListProduct(res.data)
    //   })
    // }, [projectTypeTemp])

    // useEffect(() => {
    //   setLoadingVariant(true)
    //   if (selectedProduct.id) {
    //     ProductsAPI.getVariants(undefined, selectedProduct.id, undefined, undefined, undefined, true)
    //       .then((res) => {
    //         console.log(res.data)
    //         setListVariantProduct(res.data)
    //       })
    //       .catch((err) => {
    //         if (err.response?.data.length === 0) {
    //         }
    //       })
    //   }
    // }, [selectedProduct])

    useEffect(() => {
      console.log(selectedVariant)
      if (selectedVariant.product_variant_price) {
        setVariantPrice(selectedVariant.product_variant_price)
      }
    }, [selectedVariant])

  const handleAddRevenueItems = (values) => {
    var objectTemp = {
      id: 'new-' + crypto.randomUUID(),
      product_id: selectedVariant.product_id,
      sub_product_id: selectedVariant.sub_product_id,
      product_variant_id: selectedVariant.product_variant_id,
      price: parseInt(variantPrice),
      quantity: 1,
      discount_type: 'percentage',
      discount_absolute: 0,
      discount_percentage: 0,
      product_name: selectedProduct.name,
      sub_product_name: selectedVariant.sub_product_name,
      sub_product_type: selectedVariant.sub_product_type,
      price_type: selectedVariant.price_type,
      cogs: selectedVariant.cogs,
      product_variant_name: selectedVariant.product_variant_name,
      allow_edit: true,
      cogs_items: [],
    }
    var temp = project.revenue_items
    temp.push(objectTemp)

    setSelectedVariant({})
    setSelectedProduct({})
    const tempTotal = reCountTotal(temp)
    setProject({
      ...project,
      revenue_items: temp,
      total_revenue: tempTotal,
    })
    setTotalRev(tempTotal)
  }

  const handleRevenueItemPriceChange = (price, item) => {
    var temp = project.revenue_items
    const index = temp.findIndex((rev_item) => rev_item.id === item.id)
    var objectTemp = { ...temp[index], price: price }
    temp.splice(index, 1, objectTemp)

    const tempTotal = reCountTotal(temp)
    setProject({
      ...project,
      revenue_items: temp,
      total_revenue: tempTotal,
    })
    setTotalRev(tempTotal)
  }

  const handleRevenueItemQtyChange = (qty, item) => {
    var temp = project.revenue_items
    const index = temp.findIndex((rev_item) => rev_item.id === item.id)
    // console.log(index)
    var objectTemp = { ...temp[index], quantity: qty }
    temp.splice(index, 1, objectTemp)

    const tempTotal = reCountTotal(temp)
    setProject({
      ...project,
      revenue_items: temp,
      total_revenue: tempTotal,
    })
    setTotalRev(tempTotal)
  }

  const handleRevenueDiscountTypeChange = (type, item) => {
    var temp = [...project.revenue_items]
    const index = temp.findIndex((rev_item) => rev_item.id === item.id)
    var objectTemp = { ...temp[index], discount_type: type }
    temp.splice(index, 1, objectTemp)
    var tempTotal = reCountTotal(temp)
    setProject({ ...project, revenue_items: temp, total_revenue: tempTotal })
    setTotalRev(tempTotal)
  }

  const handleRevenueItemDiscountChange = (dsc, item) => {
    var temp = project.revenue_items
    const index = temp.findIndex((rev_item) => rev_item.id === item.id)
    console.log(dsc)
    var objectTemp =
      item.discount_type === 'absolute'
        ? {
            ...temp[index],
            discount_absolute: dsc === '' ? 0 : dsc < item.price * item.quantity ? dsc | 0 : item.price * item.quantity,
          }
        : {
            ...temp[index],
            discount_percentage: dsc === '' ? 0 : parseFloat(dsc / 100) > 1.0 ? 1.0 : parseFloat(dsc / 100),
          }
    temp.splice(index, 1, objectTemp)
    // console.log(temp)
    const tempTotal = reCountTotal(temp)
    setProject({
      ...project,
      revenue_items: temp,
      total_revenue: tempTotal,
    })
    setTotalRev(tempTotal)
  }

  const handleDeleteRevenueItem = (item) => {
    var temp = project.revenue_items
    const index = temp.findIndex((rev_item) => rev_item.id === item.id)
    temp.splice(index, 1)
    const tempTotal = reCountTotal(temp)
    setProject({
      ...project,
      revenue_items: temp,
      total_revenue: tempTotal,
    })
    setTotalRev(tempTotal)
  }

  return (
    <div className="mb-12">
      <h1 className="font-semibold text-giant mb-4">Revenue</h1>
      {isEditable && (
        <div className="flex flex-col gap-4 md:flex-row md:items-end mb-4">
          <div className="md:w-[270px]">
            <Label>Product</Label>
            <Select
              key={selectedProduct?.id}
              value={selectedProduct?.id}
              onValueChange={(value) => {
                console.log(listProducts.data?.find((product) => product.id === value))
                setSelectedProduct(listProducts.data?.find((product) => product.id === value))
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select Product" />
              </SelectTrigger>
              <SelectContent>
                {/* <SelectItem value="active">Active</SelectItem>
                  <SelectItem value="inactive">Inactive</SelectItem> */}
                {listProducts.data?.map((product) => (
                  <SelectItem key={product.id} value={product.id}>
                    {product.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="md:w-[500px]">
            <Label>Variant</Label>
            <Select
              key={selectedProduct?.id}
              onValueChange={(value) => {
                const tempValue = value.split('-')
                setSelectedVariant(
                  listVariantProduct.data?.find(
                    (variant) =>
                      variant.product_id === parseInt(tempValue[0]) &&
                      variant.sub_product_id === parseInt(tempValue[1]) &&
                      variant.product_variant_id === parseInt(tempValue[2])
                  )
                )
              }}
            >
              <SelectTrigger>
                <SelectValue
                  placeholder="Select Variant"
                  defaultValue={`${selectedVariant?.product_id}-${selectedVariant?.sub_product_id}-${selectedVariant?.product_variant_id}`}
                />
              </SelectTrigger>
              <SelectContent>
                {/* <SelectItem value="active">Active</SelectItem>
                  <SelectItem value="inactive">Inactive</SelectItem> */}
                {listVariantProduct.data?.map((variant) => (
                  <SelectItem
                    key={`${variant.product_id}-${variant.sub_product_id}-${variant.product_variant_id}`}
                    value={`${variant.product_id}-${variant.sub_product_id}-${variant.product_variant_id}`}
                  >
                    {variant.sub_product_name} - {variant.product_variant_name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="w-full lg:max-w-[250px] lg:w-[250px]">
            <Label>Price</Label>
            <Input
              type={'number'}
              key={selectedVariant.product_variant_id}
              defaultValue={selectedVariant?.product_variant_price}
              onChange={(e) => setVariantPrice(parseInt(e.target.value))}
            />
          </div>
          <Button
            disabled={!selectedProduct?.id || !selectedVariant.product_variant_id || !parseInt(variantPrice)}
            onClick={() => {
              handleAddRevenueItems()
            }}
          >
            <Plus className="w-5 h-5" />
            Add Item
          </Button>
        </div>
      )}
      <div className="pb-2 relative overflow-x-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-gray-300 scrollbar-track-sky-50">
        <table className={'table-auto w-full'}>
          <thead className="rounded-[5px] h-[56px] text-left font-bold text-black bg-[#F1F5F9] overflow-hidden">
            <tr>
              <th className="px-4 text-center grow-0 shrink-0 rounded-l-[5px]">#</th>
              {revenueCols.map((col, index) => {
                // console.log(col)
                if (index === revenueCols.length - 1) {
                  return (
                    <th key={index} className={`px-4 py-4 font-semibold rounded-r-[5px] ${col.classes && col.classes}`}>
                      {col.name}
                    </th>
                  )
                }
                return (
                  <th key={index} className={`px-4 py-4 font-semibold ${col.classes && col.classes}`}>
                    {col.name}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {project.revenue_items.length === 0 ? (
              <tr className="text-center italic">
                <td colSpan={revenueCols.length} className="text-gray-text font-semibold py-4">
                  No data found
                </td>
              </tr>
            ) : (
              project.revenue_items.map((row, index) => {
                return (
                  <tr key={index} className="border-b border-gray-border">
                    <td className="px-4 py-4 w-8">{index + 1}.</td>
                    <td className={`px-4 py-4`}>{row.product_name}</td>
                    <td className={`px-4 py-4`}>{`${row.sub_product_name} - ${row.product_variant_name}`}</td>
                    <td className={`px-4 py-4 text-right`}>
                      {isEditable && row.allow_edit && row.price_type === 'custom' ? (
                        <Input
                          onChange={(e) => {
                            var temp
                            if (e.target.value < 0 || e.target.value === '') {
                              temp = 0
                            } else {
                              temp = e.target.value
                            }
                            handleRevenueItemPriceChange(parseInt(temp), row)
                          }}
                          value={row.price}
                        />
                      ) : (
                        `Rp${formatThousands(row.price)}`
                      )}
                    </td>
                    <td className={`px-4 py-4 text-right`}>
                      {isEditable && row.allow_edit ? (
                        <Input
                          onChange={(e) => {
                            var temp
                            if (e.target.value < 0 || e.target.value === '') {
                              temp = 0
                            } else {
                              temp = e.target.value
                            }
                            handleRevenueItemQtyChange(parseInt(temp), row)
                          }}
                          value={row.quantity}
                        />
                      ) : (
                        <Basic data={row.quantity} alignment={'right'} />
                      )}
                    </td>
                    <td className={`px-4 py-4 text-right`}>
                      {isEditable && row.allow_edit ? (
                        <DiscountInput
                          discountType={row.discount_type}
                          onChangeType={(type) => handleRevenueDiscountTypeChange(type, row)}
                          onChange={(e) => {
                            handleRevenueItemDiscountChange(e.target.value, row)
                          }}
                          min={'0'}
                          max={'100'}
                          type={'number'}
                          value={
                            row.discount_type === 'absolute'
                              ? row.discount_absolute
                              : parseFloat((parseFloat(row.discount_percentage) * 100).toFixed(3))
                          }
                        />
                      ) : (
                        <>
                          {row.discount_type === 'absolute' ? (
                            <Basic
                              data={row.discount_absolute > 0 ? `Rp${formatThousands(row.discount_absolute)}` : `0`}
                              alignment={'right'}
                            />
                          ) : (
                            <Basic
                              data={
                                row.discount_percentage > 0
                                  ? `${parseFloat((parseFloat(row.discount_percentage) * 100).toFixed(3))}%`
                                  : `0`
                              }
                              alignment={'right'}
                            />
                          )}
                        </>
                      )}
                    </td>
                    <td className={`px-4 py-4 text-right`}>
                      Rp
                      {row.discount_type === 'absolute'
                        ? formatThousands(row.price * row.quantity - row.discount_absolute)
                        : formatThousands(
                            row.price * row.quantity - row.price * row.quantity * row.discount_percentage
                          )}
                    </td>

                    {isEditable && row.allow_edit ? (
                      <td className={`px-4 py-4`}>
                        <div className="flex justify-center">
                          <button
                            className="w-8 h-8 rounded flex items-center justify-center text-gray-300 hover:bg-red-100 hover:text-red-500"
                            onClick={() => handleDeleteRevenueItem(row)}
                          >
                            <Delete sx={{ fontSize: 20 }} />
                          </button>
                        </div>
                      </td>
                    ) : null}
                  </tr>
                )
              })
            )}
            {!isEditable && (
              <tr className="border-b border-gray-border">
                <td className="px-4 py-4 w-8" colSpan={5}></td>
                <td className={`px-4 py-4 font-bold text-right`}>Total</td>
                <td className={`px-4 py-4 font-bold text-right`}>Rp{formatThousands(project.total_revenue || 0)}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
