import SalesDealAPI from '@/apis/SalesDealAPI'
import StorageAPI from '@/apis/storageAPI'
import { queryClient } from '@/App'
import Alert from '@/components/Alert'
import DragAndDropFile from '@/components/DragAndDropFile'
import Loading from '@/components/Loading'
import { Button } from '@/components/ui/button'
import { dotsLimiter } from '@/helper'
import usePermission from '@/hooks/usePermission'
import { hasPermission } from '@/lib/hasPermission'
import { useMutation, useQuery } from '@tanstack/react-query'
import { CircleX, File, Paperclip } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Link, useParams } from 'react-router-dom'

const DocumentView = ({ type, docs, label, handleUpdateDocs, handleAddDocs, project, className, salesDeal }) => {
  const [tempDocs, setTempDocs] = useState()
  const [showInput, setShowInput] = useState(!!docs)
  const { isAE } = usePermission()

  useEffect(() => {
    // console.log(tempDocs?.type.split('/').pop())
    console.log(type)
    console.log(docs)

    if (docs?.type === 'bast') console.log(docs)
  }, [tempDocs])

  useEffect(() => {
    // console.log(docs)
    if (tempDocs) {
      if (!docs) {
        StorageAPI.uploadFile(
          `sales-deal-documents/${type}/${type}-${project.name.replaceAll(' ', '_')}.${tempDocs?.type
            .split('/')
            .pop()}`,
          tempDocs,
          {
            name: `${type}-${project.name.replaceAll(' ', '_')}.${tempDocs?.type.split('/').pop()}`,
          }
        ).then((res) => {
          // console.log(res)
          handleAddDocs(type, res)
        })
      } else {
        StorageAPI.uploadFile(
          `sales-deal-documents/${type}/${type}-${project.name.replaceAll(' ', '_')}.${tempDocs?.type
            .split('/')
            .pop()}`,
          tempDocs,
          {
            name: `${type}-${project.name.replaceAll(' ', '_')}.${tempDocs?.type.split('/').pop()}`,
          }
        ).then((res) => {
          // console.log(res)
          handleUpdateDocs(type, docs.id, res)
        })
      }
    }
  }, [tempDocs])

  if (!isAE) {
    return (
      <div className={`${className}`}>
        <p className="mb-2 font-semibold">{label}</p>
        {docs ? (
          <a
            href={docs.url}
            target="_blank"
            className="flex-1 flex gap-2 items-center py-2 px-4 border-2 border-main-400 rounded-[5px] text-main-500 font-medium h-fit"
          >
            <span className="w-3 h-3 flex items-center justify-center">
              <File sx={{ fontSize: 20 }} />
            </span>
            {docs.metadata.name}
          </a>
        ) : (
          <div className="py-2.5 px-4 mb-2 flex items-center gap-2 text-gray-text border border-gray-border rounded-[5px] h-fit">
            {/* <i className="w-6 h-6 flex items-center justify-center"> */}
            <Paperclip className="w-5 h-5" />
            {/* </i> */}
            <p>No File Yet</p>
          </div>
        )}
      </div>
    )
  } else if (salesDeal?.status === 'loss' && !showInput)
    return (
      <div className={`${className}`}>
        <p className="mb-2 font-semibold">{label}</p>
        <div className="py-2.5 px-4 mb-2 flex items-center gap-2 text-gray-text border border-gray-border rounded-[5px] h-fit">
          {/* <i className="w-6 h-6 flex items-center justify-center"> */}
          <Paperclip className="w-5 h-5" />
          {/* </i> */}
          <p>No File Yet</p>
        </div>
      </div>
    )

  if (showInput) {
    return (
      <div className={`${className}`}>
        <p className="mb-2 font-semibold">{label}</p>
        <div className="flex gap-2 items-center">
          <a
            href={docs?.url}
            target="_blank"
            className="flex-1 flex gap-2 items-center py-2 px-4 border-2 border-main-400 rounded-[5px] text-main-500 font-medium h-fit"
          >
            <File className="w-4 h-4" />
            {docs?.metadata?.name}
          </a>
          <button
            onClick={() => setShowInput(!showInput)}
            className="flex items-center justify-center w-6 h-6 rounded-full text-gray-600 hover:bg-red-200 hover:text-red-600 transition-all"
          >
            <CircleX className="w-4 h-4" />
          </button>
        </div>
      </div>
    )
  }
  return (
    <DragAndDropFile
      label={label}
      className={`py-8 flex-1 ${className}`}
      value={tempDocs}
      onChange={(file) => {
        console.log(file)
        setTempDocs(file)
      }}
    />
  )
}

export default function SalesDealDocuments({ salesDeal }) {
  const { id, tab } = useParams()

  const { isAE, user } = usePermission()

  const [generateQuotationError, setGenerateQuotationError] = useState()
  const [isLoadingGenerateQuotation, setLoadingGenerateQuotation] = useState(false)
  const [isLoadingRequestReview, setLoadingRequestReview] = useState(false)

  const documents = useQuery({
    queryKey: ['sales-deal', id, 'documents'],
    queryFn: async () => {
      const res = await SalesDealAPI.getSalesDealDocuments(id)
      const temp = {
        proposal: null,
        quotation: null,
        spk: null,
        confirmation_letter: null,
        bast: null,
      }

      const promises = res.data.map(async (doc) => {
        var tempDoc = { ...doc }

        if (doc.url) {
          const meta = await StorageAPI.getMetadataFromURL(doc.url)
          console.log(meta)
          tempDoc['metadata'] = { name: meta.name, size: meta.size }
        }

        console.log(tempDoc)
        temp[doc.type] = tempDoc
      })

      await Promise.all(promises)

      console.log(temp)
      return temp
    },
  })

  const documentMutation = useMutation({
    mutationFn: (data) => SalesDealAPI.saveSalesDealDocument(data),
    onSuccess: () => {
      toast.success('Document uploaded successfully')
      queryClient.invalidateQueries(['sales-deal', id, 'documents'])
    },
    onError: (error) => {
      toast.error('Failed to upload your file')
    },
  })

  const quotationMutation = useMutation({
    mutationFn: async (id) => {
      var res = await SalesDealAPI.generateQuotation(id)
      return res.data
    },
    onSuccess: () => {
      toast.success('Document uploaded successfully')
      queryClient.invalidateQueries(['sales-deal', id, 'documents'])
      setLoadingGenerateQuotation(false)
    },
    onError: (err) => {
      console.log(err)
      setGenerateQuotationError(err.message)
      toast.error(err.message)
      setLoadingGenerateQuotation(false)
    },
  })

  useEffect(() => {
    console.log(documents)
  }, [documents])

  const handleAddDocs = (type, url) => {
    // console.log(type, url)
    documentMutation.mutate({
      sales_deal_id: salesDeal.id,
      project_id: salesDeal?.project?.id,
      type: type,
      document_url: url,
    })
  }

  const handleUpdateDocs = (type, id, url) => {
    SalesDealAPI.updateSalesDealDocument(id, url).then((updateRes) => {
      console.log(updateRes)
      if (!updateRes.error) {
        setRefreshDOM(Math.random())
      } else {
        toast.error('Failed to upload your file')
      }
    })
  }

  const handleGenerateQuotation = () => {
    setLoadingGenerateQuotation(true)
    quotationMutation.mutate(id)
  }

  const saveToBeReviewed = () => {
    setLoadingRequestReview(true)

    var tempObject = {
      id: salesDeal?.project?.i,
      name: salesDeal?.project?.nam,
      description: salesDeal?.project?.descriptio,
      type: salesDeal?.project?.typ,
      flagship_project_id: salesDeal?.project?.flagship_project_i,
      status: 'in_review_sm',
      topic: salesDeal?.project?.topi,
      revenue_items: salesDeal?.project?.revenue_items.map((item) => {
        return {
          product_id: item.product_id,
          sub_product_id: item.sub_product_id,
          product_variant_id: item.product_variant_id,
          price: item.price,
          quantity: item.quantity,
          discount_type: item.discount_type,
          discount_percentage: item.discount_percentage,
          discount_absolute: item.discount_absolute,
        }
      }),
      // cogs_items: salesDeal?.project?.cogs_items.map((item) =>{
      //   return { cogs_id: item.cogs_id, cost: item.cost, quantity: item.quantity, custom_name: '' }
      // }),
    }
    // console.log(tempObject)
    ProjectAPI.updateProject(tempObject.id, tempObject)
      .then((res) => {
        // console.log(res)
        setRefreshDOM(Math.random())
        setLoadingRequestReview(false)
      })
      .catch((err) => {
        if (err.response) {
          setErrorRequestReview(err.response.data.message)
        } else {
          setErrorRequestReview(err.statusText)
        }
        setLoadingRequestReview(false)
      })
  }

  // console.log(documents.data)
  // console.log(salesDeal?.project)

  return (
    <>
      {documents.isLoading ? (
        <Loading />
      ) : (
        <div className="grid grid-cols-2 gap-16">
          {hasPermission(user, 'salesDeal.documents.edit') ? (
            <>
              {['visit', 'leads', 'hot_prospect', 'on_hands', 'revenue'].includes(salesDeal.status) && (
                <>
                  <DocumentView
                    className={''}
                    label="Proposal"
                    docs={documents.data.proposal}
                    salesDeal={salesDeal}
                    project={salesDeal?.project || {}}
                    type="proposal"
                    handleAddDocs={(type, url) => handleAddDocs(type, url)}
                    handleUpdateDocs={(type, id, url) => handleUpdateDocs(type, id, url)}
                  />
                  <div className="">
                    <p className="mb-2 font-semibold">Quotation</p>
                    {documents.data.quotation ? (
                      <div>
                        <a href={documents.data.quotation.url} target="_blank">
                          <div className="mb-2 py-2.5 px-4 border-2 border-main-400 rounded-[5px] text-main-500 font-medium h-fit">
                            {dotsLimiter(documents.data.quotation?.metadata?.name, 40)}
                          </div>
                        </a>
                        {salesDeal.status !== 'loss' && isAE && (
                          <Button
                            className="w-full text-white"
                            onClick={handleGenerateQuotation}
                            isLoading={isLoadingGenerateQuotation}
                          >
                            Generate Quotation
                          </Button>
                        )}
                      </div>
                    ) : (
                      <>
                        <div className="py-2.5 px-4 mb-2 flex items-center gap-2 text-gray-text border border-gray-border rounded-[5px]">
                          {/* <i className="w-6 h-6 flex items-center justify-center"> */}
                          <Paperclip className="w-6 h-6" />
                          {/* </i> */}
                          <p>No File Yet</p>
                        </div>
                        {salesDeal?.status !== 'loss' && isAE && (
                          <Button
                            className="w-full mb-2"
                            onClick={handleGenerateQuotation}
                            isLoading={isLoadingGenerateQuotation}
                          >
                            Generate Quotation
                          </Button>
                        )}
                        {generateQuotationError && <Alert type={'error'} message={generateQuotationError} />}
                      </>
                    )}
                  </div>
                </>
              )}
              {['hot_prospect', 'on_hands', 'revenue'].includes(salesDeal.status) && (
                <>
                  <DocumentView
                    label="Surat Perintah Kerja/PO"
                    className={''}
                    docs={documents.data.spk}
                    salesDeal={salesDeal}
                    project={salesDeal?.project}
                    type="spk"
                    handleAddDocs={(type, url) => handleAddDocs(type, url)}
                    handleUpdateDocs={(type, id, url) => handleUpdateDocs(type, id, url)}
                  />
                  <div className="">
                    <p className="mb-2 font-semibold">Confirmation Letter</p>

                    {documents.data.confirmation_letter ? (
                      <div>
                        <a href={documents.data.confirmation_letter.url} target="_blank">
                          <div className="mb-2 py-2.5 px-4 border-2 border-main-400 rounded-[5px] text-main-500 font-medium h-fit">
                            {dotsLimiter(documents.data.confirmation_letter.metadata.name, 40)}
                          </div>
                        </a>
                        {salesDeal.status !== 'loss' && isAE && (
                          <Link to={`/sales-deal/${id}/generate-cl`}>
                            <Button className="w-full">Generate Confirmation Letter</Button>
                          </Link>
                        )}
                      </div>
                    ) : (
                      <>
                        <div className="py-2.5 px-4 mb-2 flex items-center gap-2 text-gray-text border border-gray-border rounded-[5px]">
                          {/* <i className="w-6 h-6 flex items-center justify-center"> */}
                          <Paperclip className="w-5 h-5" />
                          {/* </i> */}
                          <p>No File Yet</p>
                        </div>
                        {salesDeal?.status !== 'loss' && isAE && (
                          <Link to={`/sales-deal/${id}/generate-cl`}>
                            <Button className="w-full">Generate Confirmation Letter</Button>
                          </Link>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}

              {['on_hands', 'revenue'].includes(salesDeal.status) && (
                <DocumentView
                  label="BAST"
                  docs={documents.data.bast}
                  salesDeal={salesDeal}
                  project={salesDeal?.project}
                  type="bast"
                  handleAddDocs={(type, url) => handleAddDocs(type, url)}
                  handleUpdateDocs={(type, id, url) => handleUpdateDocs(type, id, url)}
                />
              )}
            </>
          ) : (
            <>
              <DocumentView
                className={''}
                label="Proposal"
                docs={documents.data.proposal}
                salesDeal={salesDeal}
                project={salesDeal?.project || {}}
                type="proposal"
                handleAddDocs={(type, url) => handleAddDocs(type, url)}
                handleUpdateDocs={(type, id, url) => handleUpdateDocs(type, id, url)}
              />
              <div className="">
                <p className="mb-2 font-semibold">Quotation</p>
                {documents.data.quotation ? (
                  <div>
                    <a href={documents.data.quotation.url} target="_blank">
                      <div className="mb-2 py-2.5 px-4 border-2 border-main-400 rounded-[5px] text-main-500 font-medium h-fit">
                        {dotsLimiter(documents.data.quotation?.metadata?.name, 40)}
                      </div>
                    </a>
                    {salesDeal.status !== 'loss' && isAE && (
                      <Button
                        className="w-full text-white"
                        onClick={handleGenerateQuotation}
                        isLoading={isLoadingGenerateQuotation}
                      >
                        Generate Quotation
                      </Button>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="py-2.5 px-4 mb-2 flex items-center gap-2 text-gray-text border border-gray-border rounded-[5px]">
                      {/* <i className="w-6 h-6 flex items-center justify-center"> */}
                      <Paperclip className="w-5 h-5" />
                      {/* </i> */}
                      <p>No File Yet</p>
                    </div>
                    {salesDeal?.status !== 'loss' && isAE && (
                      <Button
                        className="w-full mb-2"
                        onClick={handleGenerateQuotation}
                        isLoading={isLoadingGenerateQuotation}
                      >
                        Generate Quotation
                      </Button>
                    )}
                    {generateQuotationError && <Alert type={'error'} message={generateQuotationError} />}
                  </>
                )}
              </div>
              <DocumentView
                label="Surat Perintah Kerja/PO"
                className={''}
                docs={documents.data.spk}
                salesDeal={salesDeal}
                project={salesDeal?.project}
                type="spk"
                handleAddDocs={(type, url) => handleAddDocs(type, url)}
                handleUpdateDocs={(type, id, url) => handleUpdateDocs(type, id, url)}
              />
              <div className="">
                <p className="mb-2 font-semibold">Confirmation Letter</p>

                {documents.data.confirmation_letter ? (
                  <div>
                    <a href={documents.data.confirmation_letter.url} target="_blank">
                      <div className="mb-2 py-2.5 px-4 border-2 border-main-400 rounded-[5px] text-main-500 font-medium h-fit">
                        {dotsLimiter(documents.data.confirmation_letter.metadata.name, 40)}
                      </div>
                    </a>
                    {salesDeal.status !== 'loss' && isAE && (
                      <Link to="generate-cl">
                        <Button className="w-full">Generate Confirmation Letter</Button>
                      </Link>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="py-2.5 px-4 mb-2 flex items-center gap-2 text-gray-text border border-gray-border rounded-[5px]">
                      {/* <i className="w-6 h-6 flex items-center justify-center"> */}
                      <Paperclip className="w-5 h-5" />
                      {/* </i> */}
                      <p>No File Yet</p>
                    </div>
                    {salesDeal?.status !== 'loss' && isAE && (
                      <Button className="w-full" href={`generate-cl`}>
                        Generate Confirmation Letter
                      </Button>
                    )}
                  </>
                )}
              </div>
              <DocumentView
                label="BAST"
                docs={documents.data.bast}
                salesDeal={salesDeal}
                project={salesDeal?.project}
                type="bast"
                handleAddDocs={(type, url) => handleAddDocs(type, url)}
                handleUpdateDocs={(type, id, url) => handleUpdateDocs(type, id, url)}
              />
            </>
          )}
        </div>
      )}
    </>
  )
}
