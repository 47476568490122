import * as React from 'react'
import { format } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import moment from 'moment'

export function DateInput({ label, labelWidth, icon, className, defaultValue, onSelect }) {
  const [date, setDate] = React.useState(new Date(moment(defaultValue)))
  const [skipFirst, setSkipFirst] = React.useState(true)

  React.useEffect(() => {
    if (skipFirst) setSkipFirst(false)
    if (!skipFirst) onSelect(moment(date).format('MM-DD-YYYY'))
  }, [date])

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          id="date"
          variant={'outline'}
          className={cn('h-10 px-3 items-center justify-between border-slate-400', className)}
        >
          <div className="flex-1 flex gap-3 items-center font-normal text-black">
            {date ? <p>{moment(date).format('DD MMMM YYYY')}</p> : <span>Pick a date</span>}
          </div>

          <span className={cn('flex gap-2 items-center text-gray-900 [&_svg]:w-4 [&_svg]:h-4')}>
            <CalendarIcon />
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar mode="single" selected={date} onSelect={setDate} initialFocus />
      </PopoverContent>
    </Popover>
  )
}
