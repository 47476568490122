import React from 'react'
import { Navigate, Route, Router, Routes } from 'react-router-dom'
import { SidebarProvider, SidebarTrigger } from '@/components/ui/sidebar'
import { DataSidebar } from '@/components/DataSidebar'
import ErrorPage from '../ErrorPage'
import usePermission from '../hooks/usePermission'
import AccountRoles from './Data/AccountRoles'
import Agency from './Data/Agency'
import ClientRouter from './Data/Clients/ClientRouter'
import Clients from './Data/Clients/Clients'
import EditClient from './Data/Clients/EditClient'
import COGSItems from './Data/COGSItems'
import CreateAccount from './Data/CreateAccount'
import EditAE from './Data/EditAE'
import EditAgency from './Data/EditAgency'
import EditCOGSItems from './Data/EditCOGSItems'
import EditOtherSBU from './Data/EditOtherSBU'
import EditSBU from './Data/EditSBU'
import EditSM from './Data/EditSM'
import EditTarget from './Data/EditTarget'
import InvoiceDetail from './Data/InvoiceDetail'
import InvoiceRequest from './Data/InvoiceRequest'
import ProductRouter from './Data/Products/ProductRouter'
import Reimbursement from './Data/Reimbursement'
import ReimbursementDetails from './Data/ReimbursementDetails'
import ReimbursementReview from './Data/ReimbursementReview'
import SBU from './Data/SBU'
import Target from './Data/Target'

export default function DataRoutes() {
  const { isAdmin } = usePermission()
  return (
    <div className="flex h-fit min-h-[calc(100vh-56px)]">
      <SidebarProvider className="h-full min-h-full">
        <DataSidebar />
        <div className="flex-1 lg:px-8 h-fit max-w-[calc(100vw-256px)]">
          <Routes>
            <Route path="/products/*" element={<ProductRouter />} />
            {/* <Route path="/clients/add" element={isAdmin ? <EditClient /> : <ErrorPage type={403} />} /> */}
            {/* <Route path="/clients/:id/edit" element={<EditClient />} /> */}
            <Route path="/clients/add" exact element={<EditClient />} />
            <Route path="/clients/:id/*" element={<ClientRouter />} />
            <Route path="/clients" exact element={<Clients />} />
            <Route path="/cogs" element={<COGSItems />} />
            <Route path="/cogs/:id/edit" element={isAdmin ? <EditCOGSItems /> : <ErrorPage type={403} />} />
            <Route path="/cogs/add" element={isAdmin ? <EditCOGSItems /> : <ErrorPage type={403} />} />
            <Route path="/account-role/sbu/:id" element={isAdmin ? <EditSBU /> : <ErrorPage type={403} />} />
            <Route path="/account-role/sm/:id" element={isAdmin ? <EditSM /> : <ErrorPage type={403} />} />
            <Route path="/account-role/ae/:id" element={isAdmin ? <EditAE /> : <ErrorPage type={403} />} />
            <Route
              path="/account-role/create-account"
              element={isAdmin ? <CreateAccount /> : <ErrorPage type={403} />}
            />
            <Route path="/account-role/" element={<AccountRoles />} />
            <Route path="/target/edit/:year" element={isAdmin ? <EditTarget /> : <ErrorPage type={403} />} />
            <Route path="/target/add" element={isAdmin ? <EditTarget /> : <ErrorPage type={403} />} />
            <Route path="/target" element={<Target />} />
            <Route path="/reimbursement/" element={<Reimbursement />} />
            <Route path="/reimbursement/:id/review" element={<ReimbursementReview />} />
            <Route path="/reimbursement/:id/" element={<ReimbursementDetails />} />
            <Route path="/invoice-requests/:mode/:id" element={<InvoiceDetail />} />
            <Route path="/invoice-requests/" element={<InvoiceRequest />} />
            <Route path="/sbu/:id" element={isAdmin ? <EditOtherSBU /> : <ErrorPage type={403} />} />
            <Route path="/sbu" element={<SBU />} />
            <Route path="/agency/:id/edit" element={isAdmin ? <EditAgency /> : <ErrorPage type={403} />} />
            <Route path="/agency/add" element={isAdmin ? <EditAgency /> : <ErrorPage type={403} />} />
            <Route path="/agency" element={<Agency />} />
            <Route exact path="/" element={<Navigate to={'products'} replace />} />
            {/* <Route path="*" element={<ErrorPage type={404} />} /> */}
          </Routes>
        </div>
      </SidebarProvider>
    </div>
  )
}
