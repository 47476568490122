import { cn } from '@/lib/utils'
import { cva } from 'class-variance-authority'
import React from 'react'

export default function Status({ type, size, className, wrapperClassName }) {
  const statusClassVariant = cva('inline-block rounded-full font-semibold text-normal capitalize', {
    variants: {
      type: {
        visit: 'bg-primary-blue border-secondary-blue',
        leads: 'bg-primary-yellow border-secondary-yellow',
        hot_prospect: 'bg-primary-orange border-secondary-orange',
        on_hands: 'bg-primary-green border-secondary-green',
        revenue: 'bg-primary-pink border-secondary-pink',
        loss: 'bg-primary-gray border-secondary-gray',
      },
      size: {
        xs: 'w-[20px] h-[20px] border-[2px]',
        small: 'w-[20px] h-[20px] border-[3px]',
        medium: 'w-[22px] h-[22px] border-[3px]',
        large: 'w-[28px] h-[28px] border-[4px]',
      },
    },
    defaultVariants: {
      type: 'loss',
      size: 'large',
    },
  })

  const textClassVariant = cva('capitalize font-semibold text-black', {
    variants: {
      size: {
        xs: 'text-xs',
        small: 'text-normal',
        medium: 'text-lg',
        large: 'text-giant',
      },
    },
  })

  return (
    <span className={cn('flex gap-2 items-center', wrapperClassName)}>
      <span className={cn(statusClassVariant({ type, size }, className))}></span>
      <p className={cn(textClassVariant({ size }))}>{type.replaceAll('_', ' ')}</p>
    </span>
  )
}
