import React from 'react'

export default function LogoCell({ img, imgShape, text }) {
  return (
    <div className="flex items-center">
      <span
        className={`w-8 h-8 mr-2 overflow-hidden flex item-center justify-center shrink-0 ${
          imgShape === 'circle' ? 'rounded-full' : ''
        }`}
      >
        <img
          className="w-full object-contain"
          src={
            img
              ? img
              : text
              ? 'https://ui-avatars.com/api/?name=' + text.split(' ').join('+') + '&background=e5ecf1&color=034A73'
              : 'https://ui-avatars.com/api/?name=Kata+Data&background=e5ecf1&color=034A73'
          }
          alt="logo_img"
        />
      </span>
      {text && <p>{text}</p>}
    </div>
  )
}
