import * as React from 'react'
import { Check, ChevronDown, ChevronsUpDown } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import LoadingSpinner from './LoadingSpinner'
import Loading from './Loading'

// const frameworks = [
//   {
//     value: 'next.js',
//     label: 'Next.js',
//   },
//   {
//     value: 'sveltekit',
//     label: 'SvelteKit',
//   },
//   {
//     value: 'nuxt.js',
//     label: 'Nuxt.js',
//   },
//   {
//     value: 'remix',
//     label: 'Remix',
//   },
//   {
//     value: 'astro',
//     label: 'Astro',
//   },
// ]

export function Combobox({
  variant,
  icon,
  options,
  label,
  placeholder,
  className,
  isLoading,
  disableSearch = false,
  labelWidth,
  onSelect,
  defaultValue,
}) {
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState(defaultValue || '')
  const [skipFirst, setSkipFirst] = React.useState(true)

  React.useEffect(() => {
    console.log(defaultValue)
    if (skipFirst) setSkipFirst(false)
    if (!skipFirst) onSelect(value)
  }, [value])

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn('h-10 px-3 items-center justify-between', className)}
        >
          {variant === 'select' ? (
            <>
              {value ? (
                <span className="flex-1 max-w-full text-left truncate">
                  {options.find((option) => option.value === value)?.label}
                </span>
              ) : (
                <span className="text-gray-500/30">{placeholder}</span>
              )}
            </>
          ) : (
            <div className="flex-1 flex max-w-[calc(100%-12px-20px)] gap-3 items-center">
              <span
                className={cn(
                  'flex gap-2 items-center text-gray-900 [&_svg]:w-5 [&_svg]:h-5',
                  labelWidth && `w-[${labelWidth}px] shrink-0`
                )}
              >
                {icon}
                <span className="font-medium">{label}</span>
              </span>
              <div className="border-r min-h-4 !min-w-[1px] border-gray-500/30"></div>
              {value ? (
                <span className="text-left truncate">{options.find((option) => option.value === value)?.label}</span>
              ) : (
                <span className="text-gray-500/30">{placeholder}</span>
              )}
            </div>
          )}
          <ChevronDown className={cn('ml-2 h-4 w-4 shrink-0 opacity-50 transition-all', open ? 'rotate-180' : '')} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-[300px]">
        <Command>
          {!disableSearch && <CommandInput placeholder="Find something..." />}

          <CommandList>
            <CommandGroup>
              {isLoading ? (
                <Loading className={'mx-auto my-4'} />
              ) : (
                <>
                  <CommandEmpty>No option found.</CommandEmpty>
                  {options.map((option) => (
                    <CommandItem
                      key={option.value}
                      value={option.value}
                      onSelect={() => {
                        setValue(option.value === value ? '' : option.value)
                        setOpen(false)
                      }}
                      className={cn('p-2', value === option.value ? 'bg-gray-500/10' : '')}
                    >
                      {option.label}
                    </CommandItem>
                  ))}
                </>
              )}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
