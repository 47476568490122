import React, { useEffect, useState } from 'react'
import Basic from '../../components/table/cell/Basic'
import { formatThousands } from '../../helper'
import BreadCrumbs from '../../components/BreadCrumbs'
import Button from '../../components/Button'
import Input from '../../components/Input'
import DropdownInput from '../../components/DropdownInput'
import { useCustomSearchParams } from '../../hooks/useCustomSearchParams'
import moment from 'moment'
import { current } from '@reduxjs/toolkit'
import TargetAPI from '../../apis/TargetAPI'
import toast, { Toaster } from 'react-hot-toast'
import Table from '../../components/table/Table'
import Loading from '../../components/Loading'
import DismissableToast from '../../components/Toast'
import { useNavigate, useParams } from 'react-router-dom'
import Alert from '../../components/Alert'

const listMonth = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const monthNumber = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
}

const formatTarget = (target) => {
  var temp = {
    account_executive_level: [],
    sbu: [],
    company: [],
  }

  let formatRow = (row) => {
    var tempFormatRow = {
      title: row[0].entity,
    }

    listMonth.forEach((month, index) => {
      tempFormatRow[month] = {
        client: {
          title: 'Client',
          amount: row.find((element) => element.month == index + 1 && element.type == 'client').amount,
        },
        revenue: {
          title: 'Revenue',
          amount: row.find((element) => element.month == index + 1 && element.type == 'revenue').amount,
        },
      }
    })
    // console.log(tempFormatRow)
    return tempFormatRow
  }

  var keys = Object.keys(target)
  keys.forEach((key) => {
    var temp_row = []
    var tableKeys = Object.keys(target[key])
    tableKeys.forEach((tableKey) => {
      temp_row.push(formatRow(target[key][tableKey]))
    })
    temp[key] = temp_row
  })
  console.log(temp)
  return temp
}

const getYears = (currentYear) => {
  var temp = []
  var startYear = 2023
  var index = 0
  while (startYear < currentYear + 5) {
    temp.push({ id: index, year: startYear })
    startYear += 1
    index += 1
  }
  return temp
}

const defaultValueTarget = {
  account_executive_level: [
    {
      title: 'junior',
      January: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      February: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      March: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      April: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      May: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      June: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      July: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      August: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      September: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      October: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      November: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      December: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
    },
    {
      title: 'mid',
      January: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      February: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      March: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      April: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      May: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      June: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      July: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      August: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      September: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      October: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      November: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      December: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
    },
    {
      title: 'senior',
      January: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      February: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      March: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      April: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      May: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      June: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      July: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      August: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      September: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      October: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      November: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      December: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
    },
  ],
  sbu: [
    {
      title: 'kmn',
      January: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      February: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      March: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      April: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      May: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      June: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      July: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      August: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      September: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      October: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      November: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      December: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
    },
    {
      title: 'kic',
      January: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      February: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      March: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      April: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      May: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      June: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      July: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      August: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      September: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      October: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      November: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      December: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
    },
    {
      title: 'spi',
      January: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      February: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      March: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      April: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      May: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      June: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      July: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      August: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      September: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      October: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      November: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      December: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
    },
  ],
  company: [
    {
      title: 'katadata',
      January: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      February: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      March: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      April: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      May: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      June: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      July: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      August: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      September: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      October: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      November: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
      December: {
        client: {
          title: 'Client',
          amount: 0,
        },
        revenue: {
          title: 'Revenue',
          amount: 0,
        },
      },
    },
  ],
}

export default function EditTarget() {
  const { year } = useParams()
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [target, setTarget] = useState()
  const [updatedTarget, setUpdatedTarget] = useState([])
  const [targetFormated, setTargetFormated] = useState({
    account_executive_level: [
      {
        title: 'junior',
        January: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        February: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        March: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        April: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        May: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        June: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        July: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        August: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        September: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        October: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        November: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        December: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
      },
      {
        title: 'mid',
        January: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        February: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        March: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        April: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        May: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        June: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        July: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        August: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        September: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        October: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        November: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        December: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
      },
      {
        title: 'senior',
        January: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        February: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        March: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        April: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        May: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        June: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        July: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        August: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        September: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        October: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        November: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        December: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
      },
    ],
    sbu: [
      {
        title: 'kmn',
        January: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        February: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        March: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        April: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        May: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        June: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        July: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        August: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        September: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        October: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        November: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        December: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
      },
      {
        title: 'kic',
        January: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        February: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        March: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        April: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        May: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        June: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        July: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        August: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        September: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        October: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        November: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        December: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
      },
      {
        title: 'spi',
        January: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        February: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        March: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        April: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        May: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        June: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        July: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        August: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        September: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        October: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        November: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        December: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
      },
    ],
    company: [
      {
        title: 'katadata',
        January: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        February: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        March: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        April: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        May: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        June: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        July: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        August: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        September: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        October: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        November: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
        December: {
          client: {
            title: 'Client',
            amount: 0,
          },
          revenue: {
            title: 'Revenue',
            amount: 0,
          },
        },
      },
    ],
  })
  const [targetAE, setTargetAE] = useState([
    {
      id: 0,
      name: 'AE Level',
      classes: 'min-w-[150px]',
    },
    {
      id: 1,
      name: 'Target',
      classes: 'min-w-[150px]',
    },
    ...listMonth.map((month, index) => ({
      id: index + 2,
      name: month,
      classes: 'min-w-[200px]',
    })),
  ])
  const [targetSBU, setTargetSBU] = useState([
    {
      id: 0,
      name: 'SBU',
      classes: 'min-w-[150px]',
    },

    {
      id: 1,
      name: 'Target',
      classes: 'min-w-[150px]',
    },
    ...listMonth.map((month, index) => ({
      id: index + 2,
      name: month,
      classes: 'min-w-[200px]',
    })),
  ])
  const [targetCompany, setTargetCompany] = useState([
    {
      id: 0,
      name: 'Company',
      classes: 'min-w-[150px]',
    },

    {
      id: 1,
      name: 'Target',
      classes: 'min-w-[150px]',
    },
    ...listMonth.map((month, index) => ({
      id: index + 2,
      name: month,
      classes: 'min-w-[200px]',
    })),
  ])
  const [searchParams, setSearchParams] = useCustomSearchParams()
  const [selectedYear, setSelectedYear] = useState({})
  const [years, setYears] = useState(
    getYears(moment().year()).filter((year) => year.year > parseInt(searchParams.max || 0))
  )
  const [isLoadingSave, setLoadingSave] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (year) {
      var promises = [
        new Promise((resolve, reject) => {
          TargetAPI.getTargetByYear(year)
            .then((res) => {
              if (Object.keys(res.data).length > 0) {
                setTarget(res.data)
                var temp = formatTarget(res.data)
                // console.log(temp)
                setTargetFormated(temp)
                resolve()
              } else {
                setTargetFormated(defaultValueTarget)
                resolve()
              }
            })
            .catch((err) => {
              if (err.response) {
                setError(err.response.data.message)
                toast.error(err.response.data.message)
              } else {
                setError(err.statusText)
                toast.error(err.statusText)
              }
              reject(err)
            })
        }),
      ]

      Promise.all(promises)
        .then((res) => {
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [searchParams.year])

  useEffect(() => {
    if (target?.account_executive_level) {
      // setUpdatedTarget([...target.account_executive_level, ...target.company, ...target.sbu])
    }
  }, [target])

  useEffect(() => {
    console.log(updatedTarget)
  }, [updatedTarget])

  useEffect(() => {
    console.log(targetFormated)
  }, [targetFormated])

  const handleTargetChange = (table_name, title, type, month, e) => {
    // console.log(table_name, title, type, month, e.target.value)
    // console.log(targetFormated)
    var tempTable = targetFormated[table_name]
    var tempRowIndex = tempTable.findIndex((row) => row.title === title)
    var tempRow = tempTable[tempRowIndex]
    var tempCol = tempRow[month]

    tempCol[type] = { ...tempCol[type], amount: parseInt(e.target.value) }
    tempRow[month] = tempCol
    tempTable[tempRowIndex] = tempRow

    setTargetFormated((targetFormated) => ({ ...targetFormated, [table_name]: tempTable }))
  }

  const saveTarget = () => {
    // toast.success('HEHEHHEHEHEHEHEHEHE')
    var tempKeys = Object.keys(targetFormated)
    var tempTarget = []

    var shouldStop = false

    tempKeys.forEach((key) => {
      targetFormated[key].forEach((row) => {
        listMonth.forEach((month) => {
          if (shouldStop) {
            return
          }
          if (!row[month].client.amount || !row[month].revenue.amount) {
            shouldStop = true
            return
          }
          tempTarget.push({
            year: searchParams.year || moment().year().toString(),
            month: monthNumber[month].toString(),
            entity: row.title,
            type: 'client',
            amount: row[month].client.amount.toString(),
          })
          tempTarget.push({
            year: searchParams.year || moment().year().toString(),
            month: monthNumber[month].toString(),
            entity: row.title,
            type: 'revenue',
            amount: row[month].revenue.amount.toString(),
          })
        })
      })
    })
    if (!year && !selectedYear.year) {
      toast.error('Please select year to be added!')
      return
    }

    if (shouldStop) {
      toast.error('Please complete all target field!')
      return
    }

    if (!year && !selectedYear.year) {
      toast.error('Please select year to be added!')
      return
    }
    if (tempTarget.length !== 168) {
      toast.error('Please complete all target field!')
      return
    }
    setLoadingSave(true)

    if (year) {
      TargetAPI.updateTarget(year, tempTarget)
        .then((res) => {
          if (res.success) {
            toast.success('Target Saved!')
            setLoadingSave(false)
            navigate('/data/target')
          }
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message)
          } else {
            toast.error(err.statusText)
          }
          setLoadingSave(false)
        })
    } else {
      TargetAPI.createTarget(tempTarget)
        .then((res) => {
          if (res.success) {
            toast.success('Target Saved!')
            setLoadingSave(false)
            navigate('/data/target')
          }
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message)
          } else {
            toast.error(err.statusText)
          }
          setLoadingSave(false)
        })
    }
  }

  return (
    <div className="py-4">
      <DismissableToast />
      <div className="flex items-center mb-4 gap-2.5">
        <h1 className="font-semibold text-giant flex-1">Edit Target</h1>

        <Button onClick={saveTarget} isLoading={isLoadingSave}>
          Save Target
        </Button>
      </div>
      <div className="p-4 border rounded-[5px]">
        <div className="max-w-[200px] mb-4">
          <p className="font-semibold mb-1">Year</p>
          {year ? (
            <h1 className="text-3xl mb-6 font-bold">{year}</h1>
          ) : (
            <DropdownInput
              data={years}
              placeholder="Select Year"
              attributeShown={'year'}
              value={years.find((selected) => selected.year == selectedYear.year)}
              handleSelectedOption={(selected) => setSelectedYear(selected)}
            />
          )}
        </div>

        {isLoading ? (
          <Loading />
        ) : error ? (
          <Alert type={'error'} message={error} />
        ) : (
          <>
            {/* START OF AE TARGET CODE */}
            <div className="pb-2 relative overflow-x-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-main-500 scrollbar-track-sky-50">
              <table className={'table-auto'}>
                <thead className="rounded-[5px] py-4 text-left font-bold text-white bg-main-500 overflow-hidden">
                  <tr>
                    <th className="px-4 text-center grow-0 shrink-0 rounded-l-[5px]">#</th>
                    {targetAE.map((col, index) => {
                      if (index === targetAE.length - 1) {
                        return (
                          <th key={index} className={`px-4 py-4 rounded-r-[5px] ${col.classes}`}>
                            {col.name}
                          </th>
                        )
                      }
                      return (
                        <th key={index} className={`px-4 py-4 ${col.classes}`}>
                          {col.name}
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody className="font-semibold">
                  {targetFormated.account_executive_level.length === 0 ? (
                    <tr className="text-center italic">
                      <td colSpan={targetAE.length} className="text-gray-text font-semibold py-4">
                        No data found
                      </td>
                    </tr>
                  ) : (
                    targetFormated.account_executive_level.map((row, index) => {
                      return (
                        <tr key={index} className="border-b border-gray-border">
                          <td className="px-4 py-4 w-8">{index + 1}.</td>
                          <td className="p-4">
                            <Basic data={row.title} className={'capitalize'} />
                          </td>
                          <td className="p-0">
                            <div className="h-full">
                              <p className="p-4 py-5 border-b">{row['January'].client.title}</p>
                              <p className="p-4 py-5">{row['January'].revenue.title}</p>
                            </div>
                          </td>
                          {listMonth.map((month, index) => {
                            return (
                              <td className="p-0" key={index}>
                                <div className="grid">
                                  <div className="py-2 px-4 border-b">
                                    <Input
                                      type={'number'}
                                      value={row[month].client.amount}
                                      error={!row[month].client.amount}
                                      onChange={(e) => {
                                        handleTargetChange('account_executive_level', row.title, 'client', month, e)
                                      }}
                                      className={''}
                                      placeholder={`Target client ${month}`}
                                    />
                                  </div>
                                  <div className="py-2 px-4">
                                    <Input
                                      type={'number'}
                                      value={row[month].revenue.amount}
                                      error={!row[month].revenue.amount}
                                      onChange={(e) => {
                                        handleTargetChange('account_executive_level', row.title, 'revenue', month, e)
                                      }}
                                      className={''}
                                      placeholder={`Target revenue ${month}`}
                                    />
                                  </div>
                                </div>
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })
                  )}
                </tbody>
              </table>
            </div>

            {/* START OF SBU TARGET CODE */}

            <div className="pb-2 relative overflow-x-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-main-500 scrollbar-track-sky-50">
              <table className={'table-auto'}>
                <thead className="rounded-[5px] py-4 text-left font-bold text-white bg-main-500 overflow-hidden">
                  <tr>
                    <th className="px-4 text-center grow-0 shrink-0 rounded-l-[5px]">#</th>
                    {targetSBU.map((col, index) => {
                      if (index === targetSBU.length - 1) {
                        return (
                          <th key={index} className={`px-4 py-4 rounded-r-[5px] ${col.classes}`}>
                            {col.name}
                          </th>
                        )
                      }
                      return (
                        <th key={index} className={`px-4 py-4 ${col.classes}`}>
                          {col.name}
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody className="font-semibold">
                  {targetFormated.sbu.length === 0 ? (
                    <tr className="text-center italic">
                      <td colSpan={targetSBU.length} className="text-gray-text font-semibold py-4">
                        No data found
                      </td>
                    </tr>
                  ) : (
                    targetFormated.sbu.map((row, index) => {
                      return (
                        <tr key={index} className="border-b border-gray-border">
                          <td className="px-4 py-4 w-8">{index + 1}.</td>
                          <td className="p-4">
                            <Basic data={row.title} className={'uppercase'} />
                          </td>
                          <td className="p-0">
                            <div className="h-full">
                              <p className="p-4 py-5 border-b">{row['January'].client.title}</p>
                              <p className="p-4 py-5">{row['January'].revenue.title}</p>
                            </div>
                          </td>
                          {listMonth.map((month, index) => {
                            return (
                              <td className="p-0" key={index}>
                                <div className="grid">
                                  <div className="py-2 px-4 border-b">
                                    <Input
                                      type={'number'}
                                      value={row[month].client.amount}
                                      error={!row[month].client.amount}
                                      onChange={(e) => {
                                        handleTargetChange('sbu', row.title, 'client', month, e)
                                      }}
                                      className={''}
                                      placeholder={`Target client ${month}`}
                                    />
                                  </div>
                                  <div className="py-2 px-4">
                                    <Input
                                      type={'number'}
                                      value={row[month].revenue.amount}
                                      error={!row[month].revenue.amount}
                                      onChange={(e) => {
                                        handleTargetChange('sbu', row.title, 'revenue', month, e)
                                      }}
                                      className={''}
                                      placeholder={`Target revenue ${month}`}
                                    />
                                  </div>
                                </div>
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })
                  )}
                </tbody>
              </table>
            </div>
            {/* START OF COMPANY TARGET CODE */}

            <div className="pb-2 relative overflow-x-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-main-500 scrollbar-track-sky-50">
              <table className={'table-auto'}>
                <thead className="rounded-[5px] py-4 text-left font-bold text-white bg-main-500 overflow-hidden">
                  <tr>
                    <th className="px-4 text-center grow-0 shrink-0 rounded-l-[5px]">#</th>
                    {targetCompany.map((col, index) => {
                      if (index === targetCompany.length - 1) {
                        return (
                          <th key={index} className={`px-4 py-4 rounded-r-[5px] ${col.classes}`}>
                            {col.name}
                          </th>
                        )
                      }
                      return (
                        <th key={index} className={`px-4 py-4 ${col.classes}`}>
                          {col.name}
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody className="font-semibold">
                  {targetFormated.company.length === 0 ? (
                    <tr className="text-center italic">
                      <td colSpan={targetCompany.length} className="text-gray-text font-semibold py-4">
                        No data found
                      </td>
                    </tr>
                  ) : (
                    targetFormated.company.map((row, index) => {
                      return (
                        <tr key={index} className="border-b border-gray-border">
                          <td className="px-4 py-4 w-8">{index + 1}.</td>
                          <td className="p-4">
                            <Basic data={row.title} className={'capitalize'} />
                          </td>
                          <td className="p-0">
                            <div className="h-full">
                              <p className="p-4 py-5 border-b">{row['January'].client.title}</p>
                              <p className="p-4 py-5">{row['January'].revenue.title}</p>
                            </div>
                          </td>
                          {listMonth.map((month, index) => {
                            return (
                              <td className="p-0" key={index}>
                                <div className="grid">
                                  <div className="py-2 px-4 border-b">
                                    <Input
                                      type={'number'}
                                      value={row[month].client.amount}
                                      error={!row[month].client.amount}
                                      onChange={(e) => {
                                        handleTargetChange('company', row.title, 'client', month, e)
                                      }}
                                      className={''}
                                      placeholder={`Target client ${month}`}
                                    />
                                  </div>
                                  <div className="py-2 px-4">
                                    <Input
                                      type={'number'}
                                      value={row[month].revenue.amount}
                                      error={!row[month].revenue.amount}
                                      onChange={(e) => {
                                        handleTargetChange('company', row.title, 'revenue', month, e)
                                      }}
                                      className={''}
                                      placeholder={`Target revenue ${month}`}
                                    />
                                  </div>
                                </div>
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })
                  )}
                </tbody>
              </table>
            </div>
            {/* <Table data={targetFormated.account_executive_level} cols={targetAE} tableType="inline" /> */}
            {/* <Table data={targetFormated.sbu} cols={targetSBU} tableType="inline" /> */}
            {/* <Table data={targetFormated.company} cols={targetCompany} tableType="inline" /> */}
          </>
        )}
      </div>
    </div>
  )
}
