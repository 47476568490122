import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../components/BreadCrumbs'
import Table from '../../components/table/Table'
import AccountAPI from '../../apis/AccountAPI'
import LoadingSpinner from '../../components/LoadingSpinner'
import LogoCell from '../../components/table/cell/LogoCell'
import Basic from '../../components/table/cell/Basic'
import Button from '../../components/Button'
import usePermission from '../../hooks/usePermission'
import SBUAPI from '../../apis/SBUAPI'
import Pagination from '../../components/Pagination'

export default function AccountRoles() {
  const { isAdmin } = usePermission()

  const [listSbu, setListSBU] = useState()
  const [listSM, setListSM] = useState([])
  const [listAE, setListAE] = useState([])
  const [listVP, setListVP] = useState([])
  const [sbuCols, setSbuCols] = useState([
    {
      id: 0,
      name: 'SBU',
      classes: 'w-[200px]',
      selector: (row) => <Basic data={row.name} />,
    },
    {
      id: 1,
      name: 'Vice President',
      classes: 'w-[300px]',
      selector: (row) => <LogoCell img={row.vp_avatar} imgShape={'circle'} text={row.vp_name} />,
    },
    {
      id: 4,
      name: 'Email',
      classes: 'w-[200px]',
      selector: (row) => <Basic data={row.vp_email} />,
    },
  ])
  const [smCols, setSMCols] = useState([
    {
      id: 0,
      name: 'Sales Manager',
      classes: 'w-[300px]',
      selector: (row) => <LogoCell img={row.avatar} imgShape={'circle'} text={row.name} />,
    },
    {
      id: 4,
      name: 'Email',
      classes: 'w-[200px]',
      selector: (row) => <Basic data={row.email} />,
    },
    {
      id: 1,
      name: 'SBU',
      classes: 'w-[250px]',
      selector: (row) => (
        <Basic
          img={row.sbu_image ? row.sbu_image : `https://ui-avatars.com/api/?name=${row.sbu_name.replaceAll(' ', '+')}`}
          imgShape={'circle'}
          data={row.sbu_name}
        />
      ),
    },
    {
      id: 2,
      name: 'Status',
      classes: 'w-[100px]',
      selector: (row) => <Basic data={row.status} />,
    },
  ])
  const [aeCols, setAECols] = useState([
    {
      id: 0,
      name: 'Account Executive',
      classes: 'w-[300px]',
      selector: (row) => <LogoCell img={row.avatar} imgShape={'circle'} text={row.name} />,
    },
    {
      id: 4,
      name: 'Email',
      classes: 'w-[200px]',
      selector: (row) => <Basic data={row.email} />,
    },
    {
      id: 1,
      name: 'SBU',
      classes: 'w-[300px]',
      selector: (row) => <Basic data={row.sbu_name} />,
    },
    {
      id: 2,
      name: 'Level',
      classes: 'w-[150px]',
      selector: (row) => <Basic data={row.ae_seniority_level} />,
    },
    // {
    //   id: 3,
    //   name: 'Status',
    //   classes: 'w-[100px]',
    //   selector: (row) => <Basic data={row.status} />,
    // },
  ])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        AccountAPI.getList('vp')
          .then((res) => {
            if (res.success) {
              resolve(res.data)
            }
          })
          .catch((err) => {
            console.log(err)
            reject()
          })
      }),
      new Promise((resolve, reject) => {
        SBUAPI.getAllSBU().then((res) => {
          if (res.success) resolve(res.data)
        })
      }),
      new Promise((resolve, reject) => {
        AccountAPI.getList('sm')
          .then((res) => {
            if (res.success) {
              setListSM(res.data)
              resolve()
            }
          })
          .catch((err) => {
            console.log(err)
            reject()
          })
      }),
      new Promise((resolve, reject) => {
        AccountAPI.getList('ae')
          .then((res) => {
            if (res.success) setListAE(res.data)
            resolve()
          })
          .catch((err) => {
            console.log(err)
            reject()
          })
      }),
    ]

    Promise.all(promises).then((res) => {
      // setListSBU(res)
      let tempSBU = []
      res[1].map((sbu) => {
        res[0].map((vp) => {
          if (vp.id === sbu.vp_id) {
            tempSBU.push({
              sbu_id: sbu.sbu_id,
              vp_name: vp.name,
              vp_avatar: sbu.vp_avatar,
              vp_email: vp.email,
              name: sbu.sbu_name,
              avatar: sbu.avatar,
            })
          }
        })
      })
      setListSBU(tempSBU)
      setLoading(false)
    })
  }, [])

  return (
    <div className="py-4">
      {isAdmin && (
        <div className="mb-8">
          <Button href={'create-account'} className={'w-full md:w-fit'}>
            Create New Account
          </Button>
        </div>
      )}
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="sbu mb-8">
            <h1 className="font-semibold text-giant mb-4">Sales Business Unit</h1>
            <Table
              data={listSbu}
              cols={sbuCols}
              tableType="inline"
              withActions={isAdmin}
              editLink={(row) => `sbu/${row.sbu_id}`}
            />
          </div>
          <div className="sbu mb-8">
            <h1 className="font-semibold text-giant mb-4">Sales Manager</h1>
            <Table
              data={listSM}
              cols={smCols}
              tableType="inline"
              withActions={isAdmin}
              editLink={(row) => `sm/${row.id}`}
            />
          </div>
          <div className="sbu mb-8 w-fit max-w-full">
            <h1 className="font-semibold text-giant mb-4">Account Executive</h1>
            <Table
              data={listAE}
              cols={aeCols}
              tableType="inline"
              withActions={isAdmin}
              editLink={(row) => `ae/${row.id}`}
              withPaging
            />
            {/* <Pagination totalPage={ae_pagination.totalPage || 1} currentPage={s} */}
          </div>
        </>
      )}
    </div>
  )
}
