import React, { useEffect, useRef, useState } from 'react'
import { Delete, Edit, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { SquarePen } from 'lucide-react'
import { Link, useNavigate } from 'react-router-dom'
import { cn } from '@/lib/utils'
// import Button from '../Button'
import Pagination from '../Pagination'
import { Button } from '../ui/button'
import Basic from './cell/Basic'

const Td = ({ className, children, ...props }) => {
  return (
    <td className={`px-4 py-4 ${className && className}`} {...props}>
      {children}
    </td>
  )
}

const Tr = ({ className, children, ...props }) => {
  return (
    <tr className="" {...props}>
      {children}
    </tr>
  )
}

function Table({
  cols,
  data,
  handleDelete,
  withActions,
  customAction,
  editLink,
  viewLink,
  tableType = 'full',
  footer,
  noIndex,
  withPaging,
  pageIndex,
  withoutDelete,
}) {
  const navigate = useNavigate()

  const [totalPage, setTotalPage] = useState(Math.ceil(data.length / 10))
  const [currentPage, setCurrentPage] = useState(1)
  const [displayedData, setDisplayedData] = useState([])
  const wrapperRef = useRef()
  const [isEndOFScroll, setEndOfScroll] = useState()

  useEffect(() => {
    // console.log(Math.ceil(data.length / 10))
    if (withPaging) {
      setDisplayedData(data.slice((currentPage - 1) * 10, currentPage * 10))
    } else {
      setDisplayedData(data)
    }
  }, [currentPage, data])

  useEffect(() => {
    // console.log((pageIndex - 1) * 10 + 0 + 1)
    console.log(isEndOFScroll)
  }, [isEndOFScroll])

  useEffect(() => {
    if (wrapperRef.current.scrollWidth === wrapperRef.current.clientWidth) {
      setEndOfScroll(true)
    }
  }, [wrapperRef])

  useEffect(() => {
    const handleScroll = () => {
      // Update class based on scroll position
      if (wrapperRef.current?.scrollLeft === wrapperRef.current?.scrollWidth - wrapperRef.current?.clientWidth) {
        setEndOfScroll(true)
      } else {
        setEndOfScroll(false)
      }
    }

    const div = wrapperRef.current
    div.addEventListener('scroll', handleScroll)

    // Cleanup listener on unmount
    return () => div.removeEventListener('scroll', handleScroll)
  }, [])

  const handlePageChange = (page) => {
    setCurrentPage(page)
    // console.log(page)
  }

  return (
    <>
      <div
        className="pb-2 relative overflow-x-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-gray-300 scrollbar-track-sky-50"
        ref={wrapperRef}
      >
        <table className={cn('', tableType == 'inline' ? 'table-auto' : 'table-auto min-w-full')}>
          <thead className="rounded-[5px] h-[56px] text-left font-bold text-black bg-[#F1F5F9] overflow-hidden">
            <tr>
              {!noIndex && <th className="px-4 text-center grow-0 shrink-0 rounded-l-[5px]">#</th>}
              {withActions ? (
                <>
                  {cols.map((col, index) => {
                    return (
                      <th key={index} className={`px-2.5 ${col.classes}`}>
                        {col.name}
                      </th>
                    )
                  })}
                  <th
                    className={cn(
                      'action-column top-auto right-0 px-2.5 z-[1] bg-[#F1F5F9] text-left shadow-[rgba(203,213,225,0.5)_0_2px_10px]',
                      isEndOFScroll ? '' : 'sticky'
                    )}
                  >
                    Action
                  </th>
                </>
              ) : (
                <>
                  {customAction ? (
                    <>
                      {cols.map((col, index) => {
                        if (index === cols.length - 1) {
                          return (
                            <th key={index} className={`px-2.5 ${col.classes}`}>
                              {col.name}
                            </th>
                          )
                        } else if (noIndex && index === 0) {
                          return (
                            <th key={index} className={`px-2.5 rounded-l-[5px] ${col.classes}`}>
                              {col.name}
                            </th>
                          )
                        }
                        return (
                          <th key={index} className={`px-2.5 ${col.classes}`}>
                            {col.name}
                          </th>
                        )
                      })}
                      <th
                        className={cn(
                          'action-column w-[175px] top-auto right-0 px-2.5 z-[1] bg-[#F1F5F9] text-left shadow-[rgba(203,213,225,0.5)_0_2px_10px]',
                          isEndOFScroll ? '' : 'sticky'
                        )}
                      >
                        Action
                      </th>
                    </>
                  ) : (
                    cols.map((col, index) => {
                      if (index === cols.length - 1) {
                        return (
                          <th key={index} className={`px-2.5 rounded-r-[5px] ${col.classes}`}>
                            {col.name}
                          </th>
                        )
                      } else if (noIndex && index === 0) {
                        return (
                          <th key={index} className={`px-2.5 rounded-l-[5px] ${col.classes}`}>
                            {col.name}
                          </th>
                        )
                      }
                      return (
                        <th key={index} className={`px-2.5 ${col.classes}`}>
                          {col.name}
                        </th>
                      )
                    })
                  )}
                </>
              )}
            </tr>
          </thead>
          <tbody className="">
            {data.length === 0 ? (
              <tr className="text-center italic">
                <td colSpan={cols.length} className="text-gray-text font-semibold py-4">
                  No data found
                </td>
              </tr>
            ) : (
              displayedData.map((row, index) => {
                if (viewLink) {
                  return (
                    <tr key={index} className="border-b border-gray-border !box-content !static">
                      {!noIndex && (
                        <td className="px-2.5 py-4 w-8  hover:cursor-pointer" onClick={() => navigate(viewLink(row))}>
                          {withPaging
                            ? (currentPage - 1) * 10 + index + 1
                            : pageIndex
                              ? (pageIndex - 1) * 10 + index + 1
                              : index + 1}
                          .
                        </td>
                      )}
                      {cols.map((col) => {
                        return (
                          <td
                            className={`px-2.5 py-4  hover:cursor-pointer`}
                            key={col.id}
                            onClick={() => navigate(viewLink(row))}
                          >
                            {col.customCell ? col.customCell(row) : col.selector(row)}
                          </td>
                        )
                      })}
                      {withActions && customAction === undefined && (
                        <td
                          className={cn(
                            'action-column top-auto right-0 px-2.5 py-4 w-8  bg-[rgba(256,256,256,1)] shadow-[rgba(203,213,225,0.5)_0_2px_10px]',
                            isEndOFScroll ? '' : 'sticky'
                          )}
                        >
                          <div className="h-full flex gap-1 items-center">
                            <Link to={editLink(row)} className="">
                              <Button variant="outline" size="icon" className="shrink-0">
                                <SquarePen className="w-5 h-5" />
                              </Button>
                            </Link>
                            {!withoutDelete && (
                              <Button
                                variant="outline"
                                size="icon"
                                className="shrink-0"
                                onClick={() => handleDelete(row.id)}
                              >
                                <Delete sx={{ fontSize: 20 }} />
                              </Button>
                            )}
                          </div>
                        </td>
                      )}
                      {customAction !== undefined && (
                        <td
                          className={cn(
                            'action-column top-auto right-0 px-2.5 py-4 w-8  bg-[rgba(256,256,256,1)] shadow-[rgba(203,213,225,0.5)_0_2px_10px]',
                            isEndOFScroll ? '' : 'sticky'
                          )}
                        >
                          <div className="h-full flex gap-1 items-center">{customAction(row)}</div>
                        </td>
                      )}
                    </tr>
                  )
                }
                return (
                  <tr key={index} className="border-b border-gray-border !box-content !static">
                    {!noIndex && (
                      <td className="px-2.5 py-4 w-8 ">
                        {withPaging
                          ? (currentPage - 1) * 10 + index + 1
                          : pageIndex
                            ? (pageIndex - 1) * 10 + index + 1
                            : index + 1}
                        .
                      </td>
                    )}
                    {cols.map((col) => {
                      return (
                        <td className={cn('px-2.5 py-4 ', col.tdClassName)} key={col.id}>
                          {col.customCell ? col.customCell(row) : col.selector(row)}
                        </td>
                      )
                    })}
                    {withActions && customAction === undefined && (
                      <td
                        className={cn(
                          'action-column top-auto right-0 px-2.5 py-4 w-8 border-b border-gray-border h-[calc(100%-1px)] bg-[rgba(256,256,256,1)] shadow-[rgba(203,213,225,0.5)_0_2px_10px]',
                          isEndOFScroll ? '' : 'sticky'
                        )}
                      >
                        <div className="h-full flex gap-1 items-center">
                          <Link
                            to={editLink(row)}
                            className="inline-flex w-8 h-8 items-center justify-center rounded text-main-100 hover:bg-sky-200 hover:text-main-500 transition-all"
                          >
                            <Edit sx={{ fontSize: 20 }} />
                          </Link>

                          {!withoutDelete && (
                            <button
                              onClick={() => handleDelete(row.id)}
                              className="inline-flex w-8 h-8 items-center justify-center rounded text-main-100 hover:bg-red-200 hover:text-red-500 transition-all"
                            >
                              <Delete sx={{ fontSize: 20 }} />
                            </button>
                          )}
                        </div>
                      </td>
                    )}
                    {customAction !== undefined && (
                      <td
                        className={cn(
                          'action-column top-auto right-0 px-2.5 py-4 w-8 border-b border-gray-border h-[calc(100%-1px)] bg-[rgba(256,256,256,1)] shadow-[rgba(203,213,225,0.5)_0_2px_10px]',
                          isEndOFScroll ? '' : 'sticky'
                        )}
                      >
                        <div className="h-full flex gap-1 items-center ">{customAction(row)}</div>
                      </td>
                    )}
                  </tr>
                )
              })
            )}
          </tbody>
          {/* {withPaging && (
        <tfoot>
          <tr>
            <td colSpan={cols.length + 1}>
            </td>
          </tr>
        </tfoot>
      )} */}
          {footer && <tfoot>{footer}</tfoot>}
        </table>
        {withPaging && (
          <div className="mt-4 flex justify-end">
            <Pagination totalPage={totalPage} currentPage={currentPage} onChange={handlePageChange} />
          </div>
        )}
      </div>
    </>
  )
}

Table.Tr = Tr
Table.Td = Td

export default Table
