import React, { useEffect, useState } from 'react'
import usePermission from '../hooks/usePermission'
import Input from './Input'
import Basic from './table/cell/Basic'
import { formatThousands } from '../helper'
// import Button from './Button'
import CustomTextDropdownInput from './CustomTextDropdownInput'
import Checkbox from './Checkbox'
import { COGSitemAPI } from '../apis/COGSitemsAPI'
import { Delete } from '@mui/icons-material'
import DropdownInput from './DropdownInput'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select'
import { Button } from './ui/button'

const reCountTotal = (revenue_items) => {
  var total = 0
  revenue_items.forEach((product) => {
    product.cogs_items.forEach((item) => {
      total += item.cost * item.quantity
    })
  })
  return total
}

const reCountTotalCOGSRealization = (revenue_items) => {
  var total = 0
  revenue_items.forEach((product) => {
    product.cogs_items.forEach((item) => {
      total += item.realization_amount
    })
  })
  return total
}

export default function COGSItemsTable({ project, setProject, projectTypeTemp }) {
  const { isSM } = usePermission()
  const [isCustomCOGS, setIsCustomCOGS] = useState(false)
  const [customCOGS, setCustomCOGS] = useState({ name: '', cost: '' })
  const [listCOGS, setListCOGS] = useState()
  const [selectedCOGS, setSelectedCOGS] = useState()
  const [selectedProduct, setSelectedProduct] = useState()
  const [isMissingCogsData, setMissingCustomCOGSData] = useState(false)
  const [totalCOGSRealization, setTotalCOGSRealization] = useState(reCountTotalCOGSRealization(project.revenue_items))
  // const [totalCOGS, setTotalCOGS] = useState(reCountTotal(project.cogs_items))
  const [cogsCols, setCOGSCols] = useState([
    {
      id: 0,
      name: 'Product/Variant',
      classes: 'w-[300px]',
      selector: (row) => <Basic data={row.cogs_item_name} />,
    },
    {
      id: 1,
      name: 'COGS',
      classes: 'w-[150px]',
      selector: (row) => <Basic data={row.cogs_item_name} />,
    },
    {
      id: 2,
      name: 'Cost',
      classes: 'text-right w-[200px]',
      selector: (row) => <Basic data={`Rp${formatThousands(row.cost)}`} alignment={'right'} />,
    },
    {
      id: 3,
      name: 'Quantity',
      classes: 'text-right w-[100px]',
      selector: (row) => <Basic alignment={'right'} data={row.quantity} />,
    },
    {
      id: 4,
      name: 'Total COGS',
      classes: 'text-right w-[200px]',
      selector: (row) => <Basic data={row.current_cost} alignment={'right'} />,
    },
    {
      id: 8,
      name: 'COGS Realization',
      classes: 'text-right w-[200px]',
      selector: (row) => <Basic data={row.realization_amount} alignment={'right'} />,
    },
    {
      id: 9,
      name: 'Remaining Amount',
      classes: 'text-right w-[200px]',
      selector: (row) => <Basic data={row.current_cost} alignment={'right'} />,
    },
    {
      id: 5,
      name: 'COGS per Product',
      classes: 'text-right w-[150px]',
      selector: (row) => <Basic data={`??????`} alignment={'right'} />,
    },
    {
      id: 6,
      name: 'COGS%',
      classes: 'text-right w-[150px]',
      selector: (row) => <Basic data={`??????`} alignment={'right'} />,
    },
  ])

  console.log(totalCOGSRealization)
  console.log(project)

  // useEffect(() => {
  //   var tempRevenueItems = project.revenue_items
  //   var tempCOGSItems = project.cogs_items
  //   console.log(tempCOGSItems)
  //   var temp = [...displayedCOGS]
  //   tempRevenueItems.forEach((rev_item) => {
  //     var tempCogs = []
  //     tempCOGSItems.forEach((cogs_item) => {
  //       if (cogs_item.project_revenue_item_id === rev_item.id) tempCogs.push(cogs_item)
  //     })
  //     temp.push({ ...rev_item, list_cogs: tempCogs })
  //   })
  //   setDisplayedCOGS(temp)
  // }, [project])

  useEffect(() => {
    new Promise((resolve, reject) => {
      COGSitemAPI.getCOGSItemsSimple().then((res) => {
        setListCOGS(res.data)
        resolve()
      })
    })
  }, [])

  useEffect(() => {
    console.log(selectedProduct)
  }, [selectedProduct])

  useEffect(() => {
    setSelectedCOGS()
    setSelectedProduct()
    setCustomCOGS({ name: '', cost: 0 })
  }, [isCustomCOGS])

  const handleAddCOGSItem = () => {
    var tempObject = {
      id: Math.round(Math.random() * 10000000000),
      cogs_id: selectedCOGS.id,
      cost: selectedCOGS.cost,
      quantity: 1,
      cogs_item_name: selectedCOGS.name,
      cogs_item_description: selectedCOGS.description,
      current_cost: selectedCOGS.cost,
      project_revenue_item_id: selectedProduct.id,
      unique_code:
        (project.id ? project.id : Math.random() * 1000) * selectedProduct.id * selectedProduct.product_id +
        selectedProduct.product_variant_id,
    }
    // var tempCogsItems = []
    // var existFlag = false

    var temp_revenue_items = [...project.revenue_items]
    const index_product = temp_revenue_items.findIndex(
      (product) =>
        product.product_id === selectedProduct.product_id &&
        product.sub_product_id === selectedProduct.sub_product_id &&
        product.product_variant_id === selectedProduct.product_variant_id
    )
    // console.log(index_product)

    // console.log(selectedCOGS)
    var temp_cogs_items = [...selectedProduct.cogs_items]
    // console.log(temp_cogs_items)

    var indexCogsItemSelected = temp_cogs_items.findIndex((item) => item.cogs_id === selectedCOGS.id)

    // console.log(indexCogsItemSelected)
    // if not exist
    if (indexCogsItemSelected === -1) {
      temp_cogs_items.push(tempObject)
      temp_revenue_items.splice(index_product, 1, { ...selectedProduct, cogs_items: temp_cogs_items })
      setProject({
        ...project,
        revenue_items: temp_revenue_items,
      })
    } else {
      // console.log(temp_cogs_items[indexCogsItemSelected])

      var temp_existing_cogs_item = {
        ...temp_cogs_items[indexCogsItemSelected],
        quantity: temp_cogs_items[indexCogsItemSelected].quantity + 1,
      }

      temp_cogs_items.splice(indexCogsItemSelected, 1, temp_existing_cogs_item)

      temp_revenue_items.splice(index_product, 1, { ...selectedProduct, cogs_items: temp_cogs_items })

      setProject({
        ...project,
        revenue_items: temp_revenue_items,
      })
    }
    setSelectedCOGS()
    setSelectedProduct()
  }

  const handleCOGSItemQtyChange = (qty, selected_product, cogs_item) => {
    var temp_revenue_items = [...project.revenue_items]
    const index_product = temp_revenue_items.findIndex(
      (product) =>
        product.product_id === selected_product.product_id &&
        product.sub_product_id === selected_product.sub_product_id &&
        product.product_variant_id === selected_product.product_variant_id
    )
    // console.log(index_product)

    // console.log(selectedCOGS)
    var temp_cogs_items = [...selected_product.cogs_items]
    // console.log(temp_cogs_items)

    // console.log(cogs_item)
    var indexCogsItemSelected = temp_cogs_items.findIndex((item) => item.id === cogs_item.id)

    // console.log(indexCogsItemSelected)

    const temp_edited_item = { ...temp_cogs_items[indexCogsItemSelected], quantity: parseInt(qty) }
    // console.log(temp_edited_item)

    temp_cogs_items.splice(indexCogsItemSelected, 1, temp_edited_item)

    // console.log(temp_cogs_items)

    temp_revenue_items.splice(index_product, 1, { ...temp_revenue_items[index_product], cogs_items: temp_cogs_items })
    // console.log(temp_revenue_items)

    setProject({ ...project, revenue_items: temp_revenue_items })
    // const index = temp.findIndex((cogs_item) => {
    //   return item.unique_code === cogs_item.unique_code && cogs_item.cogs_id === item.cogs_id
    // })
    // console.log(index)
    // var objectTemp = { ...temp[index], quantity: qty }
    // var itemCOGSBefore = item.cost * item.quantity
    // var itemCOGSAfter = item.cost * qty
    // temp.splice(index, 1, objectTemp)
    // // console.log(temp)
    // const tempTotal = reCountTotal(temp)
    // setProject({
    //   ...project,
    //   cogs_items: temp,
    //   total_cogs: tempTotal,
    // })
    // setTotalCOGS(tempTotal)
  }

  const handleDeleteCOGSItem = (selected_product, cogs_item) => {
    console.log(selected_product)

    var temp_revenue_items = project.revenue_items

    var index_selected_product = project.revenue_items.findIndex(
      (product) =>
        product.product_id === selected_product.product_id &&
        product.sub_product_id === selected_product.sub_product_id &&
        product.product_variant_id === selected_product.product_variant_id
    )

    var temp_cogs_items = selected_product.cogs_items

    const index = temp_cogs_items.findIndex((cogs) => {
      return cogs.id === cogs_item.id
    })

    // var itemCOGSBefore = item.cost * item.quantity
    temp.splice(index, 1)
    const tempTotal = reCountTotal(temp)
    setProject({ ...project, cogs_items: temp, total_cogs: tempTotal })
    setTotalCOGS(tempTotal)
  }

  const handleCustomCOGSChange = (input) => {
    setCustomCOGS({ ...customCOGS, [input.name]: input.value })
  }

  const handleAddCustomCOGS = () => {
    if (customCOGS.name && customCOGS.cost) {
      var tempObject = {
        id: Math.round(Math.random() * 10000000000),
        cogs_id: null,
        cost: parseInt(customCOGS.cost),
        quantity: 1,
        cogs_item_name: customCOGS.name,
        custom_name: customCOGS.name,
        cogs_item_description: '',
        current_cost: parseInt(customCOGS.cost),
        project_revenue_item_id: selectedProduct.id,
        unique_code: project.id * selectedProduct.id * selectedProduct.product_id + selectedProduct.product_variant_id,
      }
      var tempCogsItems = [...project.cogs_items, tempObject]

      const tempTotal = reCountTotal(tempCogsItems)
      setProject({
        ...project,
        cogs_items: tempCogsItems,
        total_cogs: tempTotal,
      })
      setTotalCOGS(tempTotal)
      setCustomCOGS({ name: '', cost: 0 })
      setMissingCustomCOGSData(false)
    } else {
      setMissingCustomCOGSData(true)
    }
  }
  return (
    <div className="mb-12">
      <h1 className="font-semibold text-giant mb-4">COGS</h1>
      <div className="mb-2">
        {isMissingCogsData && <p className="text-small font-semibold text-red-500">Please complete all field</p>}
      </div>
      {/* <Table data={project.cogs_items} cols={cogsCols} /> */}
      <div className="pb-2 relative overflow-x-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-gray-300 scrollbar-track-sky-50">
        <table className={'table-fixed w-full'}>
          <thead className="rounded-[5px] h-[56px] text-left font-bold text-black bg-[#F1F5F9] overflow-hidden">
            <tr>
              <th className="px-4 w-8 text-center grow-0 shrink-0 rounded-l-[5px]">#</th>
              {cogsCols.map((col, index) => {
                if (index === cogsCols.length - 1) {
                  return (
                    <th key={index} className={`px-4 py-4 font-semibold rounded-r-[5px] ${col.classes}`}>
                      {col.name}
                    </th>
                  )
                }
                return (
                  <th key={index} className={`px-4 py-4 font-semibold ${col.classes}`}>
                    {col.name}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {project.revenue_items.length === 0 ? (
              <tr className="text-center italic">
                <td colSpan={cogsCols.length} className="text-gray-text font-semibold py-4">
                  No data found
                </td>
              </tr>
            ) : (
              project.revenue_items.map((row, rev_index) => {
                var tempItemTotalCOGS = 0
                return (
                  <tr key={rev_index} className="border-b border-gray-border">
                    <td className="px-4 py-4 w-8">{rev_index + 1}.</td>
                    <td className="px-4 py-4 w-8">
                      <Basic data={`${row.product_name} - ${row.product_variant_name}`} />
                    </td>
                    <td colSpan={6}>
                      {row.cogs_items.map((cogs_row, cogs_index) => {
                        tempItemTotalCOGS += cogs_row.cost * cogs_row.quantity
                        return (
                          <tr key={cogs_index} className={`border-b last:border-none border-gray-border w-[700px]`}>
                            <td className={`px-4 py-4 min-w-[150px]`}>
                              {cogs_row.cogs_id === null
                                ? cogs_row.custom_name
                                : `${cogs_row.cogs_item_name}${
                                    cogs_row.cogs_item_description !== '' ? ' - ' + cogs_row.cogs_item_description : ''
                                  }`}
                            </td>
                            <td className={`px-4 py-4 text-right w-[200px]`}>Rp{formatThousands(cogs_row.cost)}</td>
                            <td className={`px-4 py-4 text-right w-[100px]`}>
                              <Basic data={cogs_row.quantity} alignment={'right'} />
                            </td>
                            <td className={`px-4 py-4 text-right min-w-[200px]`}>
                              Rp{formatThousands(cogs_row.cost * cogs_row.quantity)}
                            </td>
                            <td className={`px-4 py-4 text-right w-[200px]`}>
                              Rp{formatThousands(cogs_row.realization_amount || 0)}
                            </td>
                            <td className={`px-4 py-4 text-right w-[200px]`}>
                              Rp{formatThousands(cogs_row.cost * cogs_row.quantity - cogs_row.realization_amount)}
                            </td>
                          </tr>
                        )
                      })}
                    </td>
                    <td className="px-4 py-4 w-8">
                      <Basic data={`Rp${formatThousands(tempItemTotalCOGS)}`} alignment={'right'} />
                    </td>
                    <td className="px-4 py-4 w-8">
                      <Basic
                        data={
                          row.discount_type === 'absolute'
                            ? `${parseFloat(
                                (tempItemTotalCOGS / (row.quantity * row.price - row.discount_absolute)) * 100
                              ).toFixed(0)}% / ${(parseFloat(row.cogs) * 100).toFixed(0)}%`
                            : `${parseFloat(
                                (tempItemTotalCOGS /
                                  (row.quantity * row.price * (1 - parseFloat(row.discount_percentage)))) *
                                  100
                              ).toFixed(0)}% / ${(parseFloat(row.cogs) * 100).toFixed(0)}%`
                        }
                        alignment={'right'}
                        state={
                          row.discount_type === 'absolute'
                            ? `${
                                (tempItemTotalCOGS / (row.quantity * row.price - row.discount_absolute)) * 100 <=
                                parseFloat(row.cogs) * 100
                                  ? 'positive'
                                  : 'negative'
                              }`
                            : `${
                                (tempItemTotalCOGS /
                                  (row.quantity * row.price * (1 - parseFloat(row.discount_percentage)))) *
                                  100 <=
                                parseFloat(row.cogs) * 100
                                  ? 'positive'
                                  : 'negative'
                              }`
                        }
                        withIcon
                      />
                    </td>
                  </tr>
                )
              })
            )}
            <tr className="border-b border-gray-border">
              <td colSpan={4}></td>
              <td className={`px-4 py-4 font-bold text-right w-32 `}>Total</td>
              <td className={`px-4 py-4 font-bold text-right`}>Rp{formatThousands(project.total_cogs || 0)}</td>
              <td className="px-4 py-4 font-bold text-right">Rp{formatThousands(totalCOGSRealization || 0)}</td>
              <td className="px-4 py-4 font-bold text-right">
                Rp{formatThousands(project.total_cogs - totalCOGSRealization)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
