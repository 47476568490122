import React, { useEffect, useState } from 'react'
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'

import { Button } from '../ui/button'
import { AlertCircle, Calendar1, CirclePlus, Terminal } from 'lucide-react'
import ProjectAPI from '@/apis/ProjectAPI'
import usePermission from '@/hooks/usePermission'
import { useNavigate } from 'react-router-dom'
import ClientAPI from '@/apis/ClientAPI'
import SalesPlanAPI from '@/apis/SalesPlanAPI'
import moment from 'moment'
import GroupButton from '../ui/group-button'
import { Label } from '../ui/label'
import { Combobox } from '../Combobox'
import { Input } from '../ui/input'
import { Alert, AlertDescription, AlertTitle } from '../ui/alert'

export default function CreateSalesPlan({ setIsOpen, meeting_date, setRefreshDOM }) {
  const [clients, setClients] = useState([])
  const [flagshipProjects, setFlagshipProject] = useState([])
  const [listAgency, setListAgency] = useState([])
  const [dealType, setDealType] = useState('new')
  const [selectedClient, setSelectedClient] = useState()
  const [projectType, setProjectType] = useState('single_client')
  const [projectId, setProjectId] = useState()
  const [selectedFlagship, setSelectedFlagship] = useState()
  const [projectName, setProjectName] = useState('')
  const [projectByClient, setProjectByClient] = useState([])
  const [fieldsError, setFieldsError] = useState({
    projectName: false,
    selectedClient: false,
  })
  const [isLoading, setLoading] = useState(true)
  const [isLoadingSavePlan, setLoadingSavePlan] = useState(false)
  const [errorSave, setErrorSave] = useState()
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedAgency, setSelectedAgency] = useState()
  const [existingProjectAgency, setExistingProjectAgency] = useState('')
  const { isAe, isAdmin } = usePermission()
  const navigate = useNavigate()

  useEffect(() => {
    if (dealType === 'existing') {
      if (selectedClient) {
        setProjectByClient([])
        setProjectId(null)
        ProjectAPI.getProjectSimple(selectedClient).then((res) => {
          setProjectByClient(res.data)
        })
      }
    }
  }, [selectedClient, dealType])

  useEffect(() => {
    const promises = [
      new Promise((resolve, reject) => {
        ClientAPI.getClientSimple()
          .then((res) => {
            setClients(res.data)
            resolve()
          })
          .catch((err) => console.log(err))
      }),
      new Promise((resolve, reject) => {
        ProjectAPI.getAllFlagshipProject('active')
          .then((res) => {
            setFlagshipProject(res.data)
            resolve()
          })
          .catch((err) => console.log(err))
      }),
      new Promise((resolve, reject) => {
        ClientAPI.getListAgency().then((res) => {
          setListAgency(res.data)
          resolve()
        })
      }),
    ]

    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [])

  const handleSavePlan = () => {
    setLoadingSavePlan(true)
    setErrorSave(false)

    var allowSave = true
    if (dealType === 'new' && !projectName) {
      allowSave = false
      setFieldsError((fieldsError) => ({ ...fieldsError, projectName: true }))
    }

    if (!selectedClient) {
      allowSave = false
      setFieldsError((fieldsError) => ({ ...fieldsError, selectedClient: true }))
    }

    if (allowSave) {
      var temp = {
        type: dealType,
        client_id: selectedClient,
        agency_id: selectedAgency || null,
        project_type: projectType,
        project_name: projectName,
        project_id: projectId || null,
        flagship_project_id: selectedFlagship || null,
        meeting_date: moment(meeting_date).format('YYYY-MM-DD'),
        // tags: selectedTags,
      }
      // console.log(temp)
      SalesPlanAPI.createNewSalesPlan(temp)
        .then((res) => {
          // console.log(res.message)
          if (res.success) {
            setIsOpen(false)
            setLoadingSavePlan(false)
            setRefreshDOM(Math.random())
          }
        })
        .catch((err) => {
          // console.log(err)
          if (err.response) setErrorSave(err.response.data.message)
          else setErrorSave(err.statusText)
          setLoadingSavePlan(false)
        })
    } else {
      setLoadingSavePlan(false)
      setErrorSave('Please fill the required fields')
    }
  }
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button className="bg-white shadow" variant="outline" size="sm">
          <CirclePlus className="w-4 h-4" />
          <p>Add New Plan</p>
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle className="text-xl">Add New Plan</SheetTitle>
          {/* <SheetDescription>
            This action cannot be undone. This will permanently delete your account and remove your data from our
            servers.
          </SheetDescription> */}
        </SheetHeader>

        <div className="py-4 flex flex-col gap-4">
          <div className="flex items-center gap-2 mb-2.5">
            <Calendar1 className="w-5 h-5" />
            <p className="text-lg font-semibold">{moment(meeting_date).format('dddd, D MMMM YYYY')}</p>
          </div>

          <div className="">
            <Label>Deal Type</Label>
            <GroupButton
              items={[
                { value: 'new', label: 'New', ariaLabel: 'New Deal' },
                { value: 'existing', label: 'Existing', ariaLabel: 'Existing Deal' },
              ]}
              onValueChange={(value) => setDealType(value)}
            />
          </div>

          {dealType === 'new' ? (
            <>
              <div className="">
                <Label className="">Project Type</Label>
                <GroupButton
                  className=""
                  items={[
                    { value: 'single_client', label: 'Single Client', ariaLabel: 'Single Client' },
                    { value: 'multiple_clients', label: 'Multiple Clients', ariaLabel: 'Multiple Clients' },
                  ]}
                  onValueChange={(value) => setProjectType(value)}
                />
              </div>
              <div className="">
                <Label>Client</Label>
                <Combobox
                  options={clients.map((client) => ({ value: client.id, label: client.company_name }))}
                  label={'Client'}
                  className={'w-full h-10'}
                  variant={'select'}
                  placeholder={'Select Client'}
                  onSelect={(selected) => setSelectedClient(selected)}
                />
              </div>
              <div className="">
                <Label>Agency</Label>
                <Combobox
                  options={listAgency.map((agency) => ({ value: agency.id, label: agency.name }))}
                  label={'Agency'}
                  className={'w-full h-10'}
                  variant={'select'}
                  placeholder="Select Agency(optional)"
                  onSelect={(selected) => setSelectedAgency(selected)}
                />
              </div>
              {projectType === 'multiple_clients' && (
                <>
                  <div className="">
                    <Label className="">Project Flagship</Label>
                    <Combobox
                      options={flagshipProjects.map((project) => ({ value: project.id, label: project.name }))}
                      label={'Flagship Project'}
                      className={'w-full h-10'}
                      variant={'select'}
                      placeholder="Search Project Flagship"
                      onSelect={(selected) => setSelectedFlagship(selected)}
                    />
                  </div>
                </>
              )}

              <div className="">
                <Label>Project Name</Label>
                <Input
                  onChange={(e) => setProjectName(e.target.value)}
                  placeholder="Project name"
                  error={fieldsError.projectName}
                  value={projectName}
                  className="border-slate-300 text-main-500"
                />
              </div>
            </>
          ) : (
            <>
              <div className="">
                <Label>Client</Label>
                <Combobox
                  options={clients.map((client) => ({ value: client.id, label: client.company_name }))}
                  label={'Client'}
                  className={'w-full h-10'}
                  variant={'select'}
                  placeholder={'Select Client'}
                  onSelect={(selected) => setSelectedClient(selected)}
                />
              </div>
              {existingProjectAgency && (
                <div className="mb-4 w-[300px]">
                  <p className="mb-1">Agency</p>
                  <Input value={existingProjectAgency} disabled />
                </div>
              )}
              <div className="" key={selectedClient}>
                <Label className="">Project</Label>
                <div className="">
                  <Combobox
                    options={projectByClient.map((project) => ({ value: project.id, label: project.project_name }))}
                    label={'Project'}
                    defaultValue={projectId}
                    className={'w-full h-10'}
                    variant={'select'}
                    placeholder={'Search Project'}
                    onSelect={(selected) => {
                      setProjectId(selected)
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {errorSave && (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Something went wrong!</AlertTitle>
              <AlertDescription>{errorSave}</AlertDescription>
            </Alert>
          )}
          <div className="flex gap-4 w-full">
            <Button
              variant="outline"
              className="flex-1"
              onClick={() => {
                setIsOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSavePlan()}
              disabled={isLoadingSavePlan}
              isLoading={isLoadingSavePlan}
              className="flex-1"
            >
              Save
            </Button>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  )
}
