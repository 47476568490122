import { Info } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { cva } from 'class-variance-authority'
import React, { useState } from 'react'

export default function StatusCell({ data, config, withInfo, info, className }) {
  var type = config.find((item) => item.status === data) || { status: 'neutral', text: 'Unknown', state: 'neutral' }

  const statusCVA = cva('py-1 px-3 rounded relative bg-[#CBD5E1] text-[#334155]', {
    variants: {
      type: {
        default: 'bg-secondary-blue text-primary-blue',
        success: 'bg-secondary-green text-primary-green',
        warning: 'bg-secondary-yellow text-primary-yellow',
        'warning-urgent': 'bg-secondary-orange text-primary-orange',
        danger: 'bg-secondary-red text-primary-red',
        neutral: 'bg-[#CBD5E1] text-[#334155]',
      },
    },
    defaultVariants: {
      type: 'default',
    },
  })

  useState(() => {}, [data])

  const setStatus = () => {
    if (!type) {
      return (
        <span className="py-1 px-3 rounded bg-secondary-gray text-primary-gray ">
          <span>{'missing'}</span>
          {withInfo ? (
            <Tooltip title={info} placement="top-start">
              <span className="relative -top-[1px]">
                <Info sx={{ fontSize: 14 }} />{' '}
              </span>
            </Tooltip>
          ) : null}
        </span>
      )
    } else if (type.state === 'success') {
      return (
        <span className="py-1 px-3 rounded bg-secondary-green text-primary-green relative">
          <span>{type.text}</span>
          {withInfo ? (
            <Tooltip title={info} placement="top-start">
              <span className="relative -top-[1px]">
                <Info sx={{ fontSize: 14 }} />{' '}
              </span>
            </Tooltip>
          ) : null}
        </span>
      )
    } else if (type.state === 'warning') {
      return (
        <span className="py-1 px-3 rounded bg-secondary-yellow text-primary-yellow relative">
          <span>{type.text}</span>
          {withInfo ? (
            <Tooltip title={info} placement="top-start">
              <span className="relative -top-[1px]">
                <Info sx={{ fontSize: 14 }} />{' '}
              </span>
            </Tooltip>
          ) : null}
        </span>
      )
    } else if (type.state === 'warning-urgent') {
      return (
        <span className="py-1 px-3 rounded bg-secondary-orange text-primary-orange relative">
          <span>{type.text}</span>
          {withInfo ? (
            <Tooltip title={info} placement="top-start">
              <span className="relative -top-[1px]">
                <Info sx={{ fontSize: 14 }} />
              </span>
            </Tooltip>
          ) : null}
        </span>
      )
    } else if (type.state === 'danger') {
      return (
        <span className="py-1 px-3 rounded bg-secondary-red text-primary-red relative">
          <span>{type.text}</span>
          {withInfo ? (
            <Tooltip title={info} placement="top-start">
              <span className="relative -top-[1px]">
                <Info sx={{ fontSize: 14 }} />{' '}
              </span>
            </Tooltip>
          ) : null}
        </span>
      )
    }
    return (
      <span className="py-1 px-3 rounded bg-secondary-blue text-primary-blue relative">
        <span>{type.text}</span>
        {withInfo ? (
          <Tooltip title={info} placement="top-start">
            <span className="relative -top-[1px]">
              <Info sx={{ fontSize: 14 }} />{' '}
            </span>
          </Tooltip>
        ) : null}
      </span>
    )
  }

  // console.log(type)

  return (
    <div className="w-full">
      <span className={statusCVA({ type: type.state, className })}>
        <span>{type.text}</span>
        {withInfo ? (
          <Tooltip title={info} placement="top-start">
            <span className="relative -top-[1px]">
              <Info sx={{ fontSize: 14 }} />{' '}
            </span>
          </Tooltip>
        ) : null}
      </span>
    </div>
  )
}
