import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../components/BreadCrumbs'
import Input from '../../components/Input'
import DropdownInput from '../../components/DropdownInput'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../../components/Button'
import Alert from '../../components/Alert'
import SBUAPI from '../../apis/SBUAPI'
import AccountAPI from '../../apis/AccountAPI'
import LoadingSpinner from '../../components/LoadingSpinner'
import { toast } from 'react-hot-toast'
import DismissableToast from '../../components/Toast'
import { sleep } from '../../helper'
import { setSelectedSBU } from '../../redux/performanceSlice'

export default function EditSM() {
  const { id } = useParams()
  const [sm, setSM] = useState()
  const [updatedSM, setUpdatedSM] = useState({
    sm_id: parseInt(id),
    sm_name: '',
    sbu_id: '',
    status: '',
  })
  const [listSBU, setListSBU] = useState([])
  const [listStatus, setListStatus] = useState([
    {
      id: 0,
      text: 'Active',
      value: 'active',
    },
    {
      id: 1,
      text: 'Inactive',
      value: 'inactive',
    },
  ])
  const [selectedSBU, setSelectedSBU] = useState()
  const [selectedStatus, setSelectedStatus] = useState()
  const [errorSaveSM, setErrorSaveSM] = useState()
  const [isLoadingSaveSM, setLoadingSaveSM] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        AccountAPI.getSingleAccount(id)
          .then((res) => {
            setSM(res.data[0])
            // setSelectedSBU(listStatus.find((item) => item.value === res.data[0].status))
            resolve(res.data[0])
          })
          .catch((err) => {
            console.log(err)
            reject()
          })
      }),
      new Promise((resolve, reject) => {
        SBUAPI.getAllSBU()
          .then((res) => {
            setListSBU(res.data)
            setLoading(false)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
            reject()
          })
      }),
    ]
    Promise.all(promises).then((res) => {
      // console.log(sm)
      let tempStatus = listStatus.find((status) => status.value === res[0].status)
      // console.log(tempStatus)
      setSelectedStatus(tempStatus)
      console.log(selectedStatus)
      let temp = res[1].find((item) => item.sbu_id === res[0].sbu_id)
      // console.log(temp)
      setSelectedSBU(temp)
      setUpdatedSM({ ...updatedSM, sbu_id: temp.sbu_id, status: tempStatus.value })
      setLoading(false)
    })
  }, [id])

  useEffect(() => {
    console.log(updatedSM)
  }, [updatedSM])

  const handleSaveSM = () => {
    setLoadingSaveSM(true)
    if (updatedSM.sbu_id && updatedSM.sm_id && updatedSM.status) {
      AccountAPI.updateSMAccount(updatedSM)
        .then((res) => {
          if (res.success) {
            toast.success('SM Updated')
            sleep(3000).then((res) => navigate(-1))
          }
        })
        .catch((err) => {
          if (err.response) setErrorSaveSM(err.response.data.message)
          else setErrorSaveSM(err.statusText)
          setLoadingSaveSM(false)
        })
      setLoadingSaveSM(false)
    } else {
      setErrorSaveSM('Please filled all fields')
      setLoadingSaveSM(false)
    }
  }

  return (
    <div className="py-4">
      <h1 className="font-semibold text-giant mb-4">Edit SM</h1>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="p-4 border rounded-[5px] mb-4">
            <p className="font-semibold mb-1">Sales Manager</p>
            <p className="mb-4">{sm ? sm.name : '-'}</p>
            <div className="w-[400px] mb-4">
              <p className="font-semibold mb-1">SBU</p>
              <DropdownInput
                data={listSBU}
                attributeShown="sbu_name"
                value={selectedSBU}
                handleSelectedOption={(selected) => {
                  setUpdatedSM({ ...updatedSM, sbu_id: selected.sbu_id })
                  setSelectedSBU(selected)
                }}
                placeholder="Pilih SBU"
              />
            </div>
            <div className="w-[400px]">
              <p className="font-semibold mb-1">Status</p>
              <DropdownInput
                data={listStatus}
                value={selectedStatus}
                attributeShown="text"
                handleSelectedOption={(selected) => setUpdatedSM({ ...updatedSM, status: selected.value })}
                placeholder="Pilih Status"
              />
            </div>
          </div>
          <div className="flex gap-4">
            <Button onClick={handleSaveSM} isLoading={isLoadingSaveSM}>
              Save
            </Button>
            {errorSaveSM && <Alert type={'error'} message={errorSaveSM} />}
          </div>
        </>
      )}
    </div>
  )
}
