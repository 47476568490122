import React, { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { signOut } from 'firebase/auth'
import { BarChart, Briefcase, Calendar, FolderOpen, LayoutList, LockOpen, LogOut, TimerReset } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { cn } from '@/lib/utils'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { auth } from '../apis/firebaseConfig'
import CRMLogo from '../assets/icons/crm-logo.png'
import { signOut as reduxSignOut } from '../redux/authSlice'
import MobileNav from './MobileNav'
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import { Button } from './ui/button'

export default function Navbar({}) {
  const [navbarOpen, setNavbarOpen] = React.useState(false)
  const user = useSelector((state) => state.auth.userData)
  const [isShowDropdown, setShowDropdown] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const logout = () => {
    // navigate('/login')
    signOut(auth).then((res) => {
      dispatch(reduxSignOut())
    })
  }
  return (
    <>
      <nav
        className={`absolute lg:sticky top-0 z-30 flex flex-wrap items-center justify-between w-full min-h-[56px] bg-main-500`}
      >
        <div className="container 2xl:max-w-[1440px] 3xl:max-w-[1660px] mx-auto flex px-2 md:px-4 lg:px-6">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:flex lg:justify-start">
            <Link
              className="font-bold leading-relaxed mr-4 py-2 whitespace-nowrap uppercase text-white flex items-center justify-center overflow-hidden"
              to="/"
            >
              <img src={CRMLogo} alt="crm-logo" className="w-[150px]" />
            </Link>
            <MobileNav />
          </div>
          <div
            className={'lg:flex items-center' + (navbarOpen ? ' flex' : ' hidden') + ' w-full lg:w-auto lg:flex-1'}
            id="example-navbar-danger"
          >
            <ul className="flex flex-col items-start w-full lg:flex-row lg:items-center">
              <div className="flex-1 flex flex-col list-none w-full lg:w-fit lg:ml-auto lg:flex-row lg:items-center">
                <li className="nav-item shrink-0">
                  <NavLink to="/performance">
                    {({ isActive }) => {
                      return (
                        <Button
                          onClick={() => setNavbarOpen(false)}
                          size={'lg'}
                          className={cn('gap-2.5 text-semibold', isActive ? 'text-primary-yellow' : 'text-white')}
                        >
                          <BarChart className="w-5 h-5" />
                          Performance
                        </Button>
                      )
                    }}
                  </NavLink>
                </li>
                <li className="nav-item shrink-0">
                  <NavLink to="/sales-plan" onClick={() => setNavbarOpen(false)}>
                    {({ isActive }) => {
                      return (
                        <Button
                          onClick={() => setNavbarOpen(false)}
                          size={'lg'}
                          className={cn('gap-2.5 text-semibold', isActive ? 'text-primary-yellow' : 'text-white')}
                        >
                          <Calendar className="w-5 h-5" />
                          Sales Plan
                        </Button>
                      )
                    }}
                  </NavLink>
                </li>
                <li className="nav-item shrink-0">
                  <NavLink to="/sales-deal" onClick={() => setNavbarOpen(false)}>
                    {({ isActive }) => {
                      return (
                        <Button
                          onClick={() => setNavbarOpen(false)}
                          size={'lg'}
                          className={cn('gap-2.5 text-semibold', isActive ? 'text-primary-yellow' : 'text-white')}
                        >
                          <Briefcase className="w-5 h-5" />
                          Sales Deal
                        </Button>
                      )
                    }}
                  </NavLink>
                </li>
                <li className="nav-item shrink-0">
                  <NavLink to="/projects" onClick={() => setNavbarOpen(false)}>
                    {({ isActive }) => {
                      return (
                        <Button
                          onClick={() => setNavbarOpen(false)}
                          size={'lg'}
                          className={cn('gap-2.5 text-semibold', isActive ? 'text-primary-yellow' : 'text-white')}
                        >
                          <LayoutList className="w-5 h-5" />
                          Projects
                        </Button>
                      )
                    }}
                  </NavLink>
                </li>
                <li className="nav-item shrink-0">
                  <NavLink to="/data" onClick={() => setNavbarOpen(false)}>
                    {({ isActive }) => {
                      return (
                        <Button
                          onClick={() => setNavbarOpen(false)}
                          size={'lg'}
                          className={cn('gap-2.5 text-semibold', isActive ? 'text-primary-yellow' : 'text-white')}
                        >
                          <FolderOpen className="w-5 h-5" />
                          Data
                        </Button>
                      )
                    }}
                  </NavLink>
                </li>
              </div>

              <li>
                {user ? (
                  <div className=" gap-4 lg:flex items-center ">
                    {/* <Button onClick={() => logout()}>Log out</Button> */}
                    {/* <Button href={'/login'}>Login</Button> */}

                    <div>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button>
                            <p className="text-white font-semibold">{user.name}</p>
                            <Avatar className="w-8 h-8">
                              <AvatarImage src={user.avatar} alt={user.name} />
                              <AvatarFallback>KD</AvatarFallback>
                            </Avatar>
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-48">
                          <DropdownMenuItem className="flex gap-2 cursor-pointer">
                            <Link to="/profile/change-password">
                              <span className="w-full flex gap-2 items-center">
                                <LockOpen className="w-8 h-8" />
                                Change Password
                              </span>
                            </Link>
                          </DropdownMenuItem>
                          <DropdownMenuItem className="flex gap-2 cursor-pointer" onClick={logout}>
                            <span className="w-full flex gap-2 items-center">
                              <LogOut className="w-8 h-8" />
                              Logout
                            </span>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>

                      {/* <DropdownNavbar logout={logout} className="mb-4 lg:mb-0">
                        
                      </DropdownNavbar> */}
                    </div>
                  </div>
                ) : (
                  <div className=" gap-4 lg:flex items-center ">
                    <Button onClick={() => logout()}>Log out</Button>
                    <Button href={'/login'}>Login</Button>
                    <button className="text-white w-8 h-8 flex items-center justify-center rounded hover:bg-main-400 hover:opacity-75 transition-all">
                      <MoreVertIcon sx={{ fontsize: 24 }} />
                    </button>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}
