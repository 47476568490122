import SalesDealAPI from '@/apis/SalesDealAPI'
import Loading from '@/components/Loading'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import usePermission from '@/hooks/usePermission'
import { hasPermission } from '@/lib/hasPermission'
import { formatThousands } from '@/services/helper'
import { useQuery } from '@tanstack/react-query'
import { ArrowRight, Calendar1, Check, Paperclip, SlidersHorizontal } from 'lucide-react'
import moment from 'moment'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

const sortByDateUpdated = (arr) => {
  var temp = []
  let sortedArray = arr.sort(
    (a, b) => moment(b.meeting_date).format('YYYYMMDDHHmmss') - moment(a.meeting_date).format('YYYYMMDDHHmmss')
  )
  return sortedArray
}

export default function SalesDealMeetingHistory({ salesDeal }) {
  const { id } = useParams()
  const { user } = usePermission()

  const meetingHistory = useQuery({
    queryKey: ['sales-deal', id, 'meeting-history'],
    queryFn: async () => {
      var res = await SalesDealAPI.getSalesDealMeetingHistory(id)
      return res.data
    },
  })

  // console.log(meetingHistory.data)
  return (
    <>
      {meetingHistory.isLoading ? (
        <Loading />
      ) : meetingHistory.data.length === 0 ? (
        <p className="text-center">No meeting history found</p>
      ) : (
        <div className="grid gap-4">
          {meetingHistory.data.map((meeting) => {
            if (meeting.type === 'drag_and_drop') {
              return (
                <div key={meeting.id} className="meeting-detail pb-4 border-b border-gray-border last:border-none">
                  <div className="flex items-center gap-4 mb-8">
                    <span className="p-2 rounded border">
                      <SlidersHorizontal className="w-5 h-5" />
                    </span>
                    <div className="flex-1">
                      <p>Meeting Date</p>
                      <p>{moment(meeting.updated_at).format('D MMMM YYYY')}</p>
                    </div>
                  </div>
                  <div className="flex gap-10 mb-4">
                    <div>
                      <p className="font-semibold mb-1">Status</p>
                      <div className="flex gap-2 items-center capitalize">
                        <span>{meeting.sales_deal_status_before.replace('_', ' ')}</span>
                        <ArrowRight className="w-5 h-5" />
                        <span>{meeting.sales_deal_status_after.replace('_', ' ')}</span>
                      </div>
                    </div>
                  </div>
                  <div className="notes">
                    <p className="font-semibold mb-1">Notes</p>
                    <article>{meeting.meeting_notes}</article>
                  </div>
                </div>
              )
            } else if (meeting.mom_status === 'empty') {
              return (
                <div key={meeting.id} className="meeting-detail pb-4 border-b border-gray-border last:border-none">
                  <div className="flex items-center gap-4 mb-8">
                    <span className="p-2 rounded border">
                      <Calendar1 className="w-5 h-5" />
                    </span>
                    <div className="flex-1">
                      <p>Meeting Date</p>
                      <p>{moment(meeting.meeting_date).format('D MMMM YYYY')}</p>
                    </div>
                    {hasPermission(user, 'salesDeal.meetingHistory.addMom') && (
                      <Link to={`/sales-plan/${meeting.id}/add-mom`}>
                        <Button size={'sm'} variant="outline">
                          <Paperclip className="w-5 h-5" />
                          Add MoM
                        </Button>
                      </Link>
                    )}
                  </div>
                  <div className="flex gap-10 mb-4">
                    <div>
                      <p className="font-semibold mb-1">Response</p>
                      <p className="capitalize">-</p>
                    </div>
                    <div>
                      <p className="font-semibold mb-1">Status</p>
                      <div className="flex gap-2 items-center capitalize">-</div>
                    </div>
                    <div>
                      <p className="font-semibold mb-1">Meeting Expenses</p>
                      <p>-</p>
                    </div>
                  </div>
                  <div className="notes mb-4">
                    <p className="font-semibold mb-1">Notes</p>
                    <article>-</article>
                  </div>
                  {/* <div className="tags">
                <p className="font-semibold mb-1">Tags</p>
                <article>{meeting.tags.length > 0 ? meeting.tags.toString().replace(',', ', ') : '-'}</article>
              </div> */}
                </div>
              )
            }
            return (
              <div key={meeting.id} className="meeting-detail pb-4 border-b border-gray-border last:border-none">
                <div className="flex flex-col gap-4 mb-8 md:flex-row md:items-center">
                  <div className="flex-1 flex gap-16">
                    <div className="">
                      <div className="flex gap-2 mb-2 items-center text-big font-semibold">
                        <Calendar1 className="w-5 h-5" />
                        <p>Meeting Date</p>
                      </div>
                      <p>{moment(meeting.meeting_date).format('D MMMM YYYY')}</p>
                    </div>
                    <div className="">
                      <div className="flex gap-2 mb-2 items-center text-big font-semibold">
                        <Calendar1 className="w-5 h-5" />
                        <p>MoM Saved Date</p>
                      </div>
                      <p>{meeting.mom_saved_at ? moment(meeting.mom_saved_at).format('D MMMM YYYY') : '-'}</p>
                    </div>
                  </div>
                  <Badge
                    className={
                      'bg-primary-green h-8 text-white gap-2 rounded-lg hover:bg-primary-green hover:text-white'
                    }
                  >
                    <Check className="w-4 h-4" />
                    MoM Added
                  </Badge>
                </div>
                <div className="flex flex-col gap-4 mb-4 md:flex-row md:gap-10">
                  <div>
                    <p className="font-semibold mb-1">Response</p>
                    <p className="capitalize">
                      {meeting.client_response ? meeting.client_response.replaceAll('_', ' ') : '-'}
                    </p>
                  </div>
                  <div>
                    <p className="font-semibold mb-1">Status</p>
                    <div className="flex gap-2 items-center capitalize">
                      <span>{meeting.sales_deal_status_before.replaceAll('_', ' ')}</span>
                      <ArrowRight className="w-5 h-5" />
                      <span>{meeting.sales_deal_status_after.replaceAll('_', ' ')}</span>
                    </div>
                  </div>
                  <div>
                    <p className="font-semibold mb-1">Meeting Expenses</p>
                    <p>Rp{meeting.total_meeting_expenses ? formatThousands(meeting.total_meeting_expenses) : 0}</p>
                  </div>
                  <div>
                    <p className="font-semibold mb-1">PIC Client</p>
                    <p className="capitalize">{meeting.pic_name}</p>
                  </div>

                  <div>
                    <p className="font-semibold mb-1">PIC Title</p>
                    <p className="capitalize">{meeting.pic_title}</p>
                  </div>
                  <div>
                    <p className="font-semibold mb-1">PIC Phone Number</p>
                    <p className="capitalize">{meeting.pic_phone_number}</p>
                  </div>
                </div>
                <div className="notes mb-4">
                  <p className="font-semibold mb-1">Notes</p>
                  <article>{meeting.meeting_notes}</article>
                </div>
                {/* <div className="tags">
              <p className="font-semibold mb-1">Tags</p>
              <article>{meeting.tags.toString().replace(',', ', ')}</article>
            </div> */}
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}
