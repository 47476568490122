const formatThousands = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const formatSimplerNumber = (number) => {
  if (number > 999 && number < 1000000) {
    return (number / 1000).toFixed() + "K"; // convert to K for number from > 1000 < 1 million
  } else if (number > 1000000) {
    return (number / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
  } else if (number < 900) {
    return number; // if value < 1000, nothing to do
  }
};

const dotsLimiter = (text, maxLength) => {
  if (maxLength > text.length) return text;
  else return text.slice(0, maxLength) + "...";
};

export { formatThousands, formatSimplerNumber, dotsLimiter };
