import SalesDealAPI from '@/apis/SalesDealAPI'
import COGSItemsTable from '@/components/COGSItemsTable'
import Loading from '@/components/Loading'
import RevenueItemsTable from '@/components/RevenueItemsTable'
import Basic from '@/components/table/cell/Basic'
import Table from '@/components/table/Table'
import { formatThousands } from '@/helper'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { FolderOpen, ListTodo, RefreshCw, SquarePen } from 'lucide-react'
import toast from 'react-hot-toast'
import usePermission from '@/hooks/usePermission'
import StatusCell from '@/components/table/cell/StatusCell'
import ProjectAPI from '@/apis/ProjectAPI'
import { queryClient } from '@/App'
import { hasPermission } from '@/lib/hasPermission'

export default function SalesDealProject({ salesDeal }) {
  const { id } = useParams()
  const { isAE, isSM, isVP, user } = usePermission()
  const [project, setProject] = useState()
  const [stateCellConfig, setCellConfig] = useState([
    {
      text: 'Approved',
      status: 'approved',
      state: 'success',
    },
    {
      text: 'Pending',
      status: 'pending',
      state: 'default',
    },
    {
      text: 'Rejected',
      status: 'rejected',
      state: 'danger',
    },
    {
      text: 'Need Revision',
      status: 'need_revision',
      state: 'warning-urgent',
    },
    {
      text: 'In Review - VP',
      state: 'warning',
      status: 'in_review_vp',
    },
    {
      text: 'In Review - SM',
      state: 'warning',
      status: 'in_review_sm',
    },
  ])
  const [marginCols, setMarginCols] = useState([
    { id: 0, name: 'Component', selector: (row) => <Basic data={row.row} />, classes: 'w-[180px] font-semibold' },
    {
      id: 1,
      name: 'Total Amount',
      selector: (row) => <Basic data={`Rp${formatThousands(row.totalAmount)}`} alignment={'right'} />,
      classes: 'w-[180px]  text-right',
    },
    {
      id: 2,
      name: 'Percentage',
      selector: (row) => <Basic data={`${(row.percentage * 100).toFixed(2)}%`} alignment={'right'} />,
      classes: 'w-[180px] text-right',
    },
  ])
  const [isLoadingSaveProject, setLoadingSaveProject] = useState(false)

  const projectData = useQuery({
    queryKey: ['sales-deal', id, 'project'],
    queryFn: async () => {
      const res = await SalesDealAPI.getSalesDealProject(id)
      // console.log(res.data)

      return res.data
    },
  })

  console.log(projectData.data)

  useEffect(() => {
    if (!projectData.isLoading) {
      // console.log(projectData.data)
      setProject(projectData.data)
    }
  }, [projectData])

  const sendRequestReviewBySM = () => {
    setLoadingSaveProject(true)

    if (!projectData.data.name) {
      toast.error("Can't send review request with no project name.")
      setMissingProjectName(true)
      setLoadingSaveProject(false)
      return
    }

    if (projectData.data.revenue_items.length === 0) {
      toast.error("Can't send review request with no revenue item.")
      setLoadingSaveProject(false)
      return
    }

    var tempObject = {
      // id: updatedProject.id,
      name: projectData.data.name,
      description: projectData.data.description,
      type: projectData.data.type,
      flagship_project_id: projectData.data.flagship_project_id,
      status: 'in_review_sm',
      topic: projectData.data.topic,
      revenue_items: projectData.data.revenue_items.map((item) => {
        return {
          product_id: item.product_id,
          sub_product_id: item.sub_product_id,
          product_variant_id: item.product_variant_id,
          price: item.price,
          quantity: item.quantity,
          discount_type: item.discount_type,
          discount_percentage: item.discount_percentage,
          discount_absolute: item.discount_absolute,
        }
      }),
    }
    ProjectAPI.updateProjectByAE(projectData.data.id, tempObject)
      .then((res) => {
        if (res.success) {
          setLoadingSaveProject(false)
          queryClient.invalidateQueries(['sales-deal', id, 'project'])
        } else {
          setLoadingSaveProject(false)
        }

        toast.success('Send review request success!')
      })
      .catch((err) => {
        console.log(err)
        if (err.response) {
          toast.error(err.response.data.message)
        } else {
          toast.error(err.message)
        }
        setLoadingSaveProject(false)
      })
  }

  return (
    <>
      {projectData.isLoading ? (
        <Loading />
      ) : (
        <div className="w-full max-w-full grow-0">
          <div className="mb-8 flex gap-16">
            <div className="">
              <p className="text-semibold mb-2">Status</p>
              <p className="capitalize">
                <StatusCell config={stateCellConfig} data={projectData.data.status} className="font-medium" />
              </p>
            </div>
            <div className="max-w-[300px]">
              <p className="text-semibold mb-2">Description</p>
              <p className="capitalize">{projectData.data.description || '-'}</p>
            </div>
          </div>
          <div className="mb-8 flex gap-4">
            {hasPermission(user, 'salesDeal.project.view') && (
              <Link to={`/projects/view/${projectData.data.id}`}>
                <Button variant="outline">
                  <FolderOpen className="w-5 h-5" /> View Project
                </Button>
              </Link>
            )}
            {hasPermission(user, 'salesDeal.project.edit', {
              salesDealStatus: salesDeal.status,
              projectStatus: projectData.data.status,
            }) && (
              <Link to={`/projects/edit/s/${projectData.data.id}`}>
                <Button variant="outline">
                  <SquarePen className="w-5 h-5" /> Edit Project
                </Button>
              </Link>
            )}

            {hasPermission(user, 'salesDeal.project.review', {
              salesDealStatus: salesDeal.status,
              projectStatus: projectData.data.status,
            }) && (
              <Link to={`/projects/review/${projectData.data.id}`}>
                <Button variant="outline">
                  <SquarePen className="w-5 h-5" /> Review Project
                </Button>
              </Link>
            )}

            {hasPermission(user, 'salesDeal.project.sendRequestReview', {
              salesDealStatus: salesDeal.status,
              projectStatus: projectData.data.status,
            }) && (
              <Button
                variant="outline"
                onClick={() => {
                  sendRequestReviewBySM()
                }}
              >
                <ListTodo className="w-5 h-5" />
                Send Review Request to SM
              </Button>
            )}
            {hasPermission(user, 'salesDeal.project.sync', {
              salesDealStatus: salesDeal.status,
              projectStatus: projectData.data.status,
            }) && (
              <Button variant="outline" onClick={() => toast.error('This feature is under development.')}>
                <RefreshCw className="w-5 h-5" />
                Sync Project with CC
              </Button>
            )}
          </div>
          <RevenueItemsTable project={projectData.data} setProject={setProject} isEditable={false} />
          <COGSItemsTable project={projectData.data} setProject={setProject} isEditable={false} />
          <div className="mb-8">
            <h1 className="text-giant mb-4 font-semibold">Margin</h1>
            <Table
              tableType="inline"
              noIndex={true}
              cols={marginCols}
              data={[
                {
                  id: 0,
                  row: 'Revenue',
                  totalAmount: projectData.data.total_revenue,
                  percentage:
                    projectData.data.total_revenue !== 0
                      ? 1 - projectData.data.total_cogs / projectData.data.total_revenue
                      : 0,
                },
                {
                  id: 1,
                  row: 'COGS',
                  totalAmount: projectData.data.total_cogs,
                  percentage:
                    projectData.data.total_revenue !== 0
                      ? projectData.data.total_cogs / projectData.data.total_revenue
                      : 1,
                },
              ]}
            />
          </div>
        </div>
      )}
    </>
  )
}
