import usePermission from '@/hooks/usePermission'
import { formatThousands } from '@/services/helper'
import React, { useEffect, useState } from 'react'
import Basic from './table/cell/Basic'
import { COGSitemAPI } from '@/apis/COGSitemsAPI'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select'
import CustomTextDropdownInput from './CustomTextDropdownInput'
import { Button } from './ui/button'
import { Input } from './ui/input'
import { Lock, Trash, Trash2 } from 'lucide-react'
import { SearchableSelect } from './SearchableSelect'
import { Label } from './ui/label'
import { useQuery } from '@tanstack/react-query'
import Loading from './Loading'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/tooltip'

export default function EditableCOGSItemsTable({ project, setProject }) {
  const { isSM } = usePermission()
  const [isCustomCOGS, setIsCustomCOGS] = useState(false)
  const [customCOGS, setCustomCOGS] = useState({ name: '', cost: 0, type: 'existing' })
  const [selectedCOGS, setSelectedCOGS] = useState({})
  const [selectedProduct, setSelectedProduct] = useState({})
  const [isMissingCogsData, setMissingCustomCOGSData] = useState(false)
  const [cogsCols, setCOGSCols] = useState([
    {
      id: 0,
      name: 'Product/Variant',
      classes: 'w-[300px]',
      selector: (row) => <Basic data={row.cogs_item_name} />,
    },
    {
      id: 1,
      name: 'COGS',
      classes: 'w-[150px]',
      selector: (row) => <Basic data={row.cogs_item_name} />,
    },
    {
      id: 2,
      name: 'Cost',
      classes: 'text-right w-[200px]',
      selector: (row) => <Basic data={`Rp${formatThousands(row.cost)}`} alignment={'right'} />,
    },
    {
      id: 3,
      name: 'Quantity',
      classes: 'text-right w-[100px]',
      selector: (row) => <Basic alignment={'right'} data={row.quantity} />,
    },
    {
      id: 4,
      name: 'Total COGS',
      classes: 'text-right w-[200px]',
      selector: (row) => <Basic data={row.current_cost} alignment={'right'} />,
    },
    {
      id: 7,
      name: 'Action',
      classes: 'text-center w-20',
      selector: (row) => <Basic data={`??????`} alignment={'right'} />,
    },
    {
      id: 5,
      name: 'COGS per Product',
      classes: 'text-right w-[150px]',
      selector: (row) => <Basic data={`??????`} alignment={'right'} />,
    },
    {
      id: 6,
      name: 'COGS%',
      classes: 'text-right w-[150px]',
      selector: (row) => <Basic data={`??????`} alignment={'right'} />,
    },
  ])
  const [resetForm, setResetForm] = useState(0)

  const [displayedCOGS, setDisplayedCOGS] = useState([])

  const listCOGS = useQuery({
    queryKey: ['simple-cogs'],
    queryFn: async () => {
      const res = await COGSitemAPI.getCOGSItemsSimple()
      console.log(res)
      return res.data
    },
  })

  const reCountTotal = (revenue_items) => {
    let tempTotalCogs = 0
    revenue_items.forEach((product) => {
      product.cogs_items.forEach((cogs_item) => {
        tempTotalCogs += cogs_item.cost * cogs_item.quantity
      })
    })
    return tempTotalCogs
  }

  const reCountTotalCOGSRealization = (revenue_items) => {
    var total = 0
    revenue_items.forEach((product) => {
      product.cogs_items.forEach((item) => {
        total += item.realization_amount
      })
    })
    return total
  }

  const handleAddCOGSItem = () => {
    let tempObject = {}
    if (isCustomCOGS) {
      tempObject = {
        id: 'new-' + crypto.randomUUID(),
        cogs_id: 'custom-' + crypto.randomUUID(),
        cost: customCOGS.cost,
        quantity: 1,
        cogs_item_name: customCOGS.name,
        cogs_item_description: '',
        project_revenue_item_id: selectedProduct.id,
        allow_edit: true,
      }
    } else {
      tempObject = {
        id: 'new-' + crypto.randomUUID(),
        cogs_id: selectedCOGS.id,
        cost: selectedCOGS.cost,
        quantity: 1,
        cogs_item_name: selectedCOGS.name,
        cogs_item_description: selectedCOGS.description,
        current_cost: selectedCOGS.cost,
        project_revenue_item_id: selectedProduct.id,
        allow_edit: true,
      }
    }

    var temp_revenue_items = [...project.revenue_items]
    const index_product = temp_revenue_items.findIndex((product) => product.id === selectedProduct.id)
    // console.log(index_product)

    // console.log(selectedCOGS)
    var temp_cogs_items = [...selectedProduct.cogs_items]
    // console.log(temp_cogs_items)

    var indexCogsItemSelected = temp_cogs_items.findIndex((item) => item.cogs_id === tempObject.cogs_id)

    // console.log(indexCogsItemSelected)

    temp_cogs_items.push(tempObject)
    temp_revenue_items.splice(index_product, 1, { ...selectedProduct, cogs_items: temp_cogs_items })
    const newTotalCOGS = reCountTotal(temp_revenue_items)
    console.log(newTotalCOGS)
    setProject({
      ...project,
      revenue_items: temp_revenue_items,
      total_cogs: newTotalCOGS,
    })

    setSelectedCOGS({})
    setSelectedProduct({})
    setCustomCOGS({ name: '', cost: 0, type: 'existing' })
    setIsCustomCOGS(false)
    setResetForm((resetForm) => resetForm + 1)
  }

  const handleCOGSItemQtyChange = (qty, selected_product, cogs_item) => {
    var temp_revenue_items = [...project.revenue_items]
    const index_product = temp_revenue_items.findIndex((product) => product.id === selected_product.id)
    var temp_cogs_items = [...selected_product.cogs_items]
    var indexCogsItemSelected = temp_cogs_items.findIndex((item) => item.id === cogs_item.id)

    const temp_edited_item = { ...temp_cogs_items[indexCogsItemSelected], quantity: parseInt(qty) }
    temp_cogs_items.splice(indexCogsItemSelected, 1, temp_edited_item)
    temp_revenue_items.splice(index_product, 1, { ...temp_revenue_items[index_product], cogs_items: temp_cogs_items })

    const newTotalCOGS = reCountTotal(temp_revenue_items)
    setProject({ ...project, revenue_items: temp_revenue_items, total_cogs: newTotalCOGS })
  }

  const handleCOGSItemCostChange = (cost, selected_product, cogs_item) => {
    var temp_revenue_items = [...project.revenue_items]
    const index_product = temp_revenue_items.findIndex((product) => product.id === selected_product.id)
    // console.log(index_product)
    var temp_cogs_items = [...selected_product.cogs_items]
    // console.log(temp_cogs_items)
    var indexCogsItemSelected = temp_cogs_items.findIndex((item) => item.id === cogs_item.id)
    // console.log(indexCogsItemSelected)

    const temp_edited_item = { ...temp_cogs_items[indexCogsItemSelected], cost: parseInt(cost) }
    temp_cogs_items.splice(indexCogsItemSelected, 1, temp_edited_item)
    temp_revenue_items.splice(index_product, 1, { ...temp_revenue_items[index_product], cogs_items: temp_cogs_items })
    // console.log(temp_revenue_items)

    const newTotalCOGS = reCountTotal(temp_revenue_items)
    setProject({ ...project, revenue_items: temp_revenue_items, total_cogs: newTotalCOGS })
  }

  const handleDeleteCOGSItem = (selected_product, cogs_item) => {
    var temp_revenue_items = [...project.revenue_items]
    const index_product = temp_revenue_items.findIndex((product) => product.id === selected_product.id)
    var temp_cogs_items = selected_product.cogs_items

    const index = temp_cogs_items.findIndex((cogs) => {
      return cogs.id === cogs_item.id
    })

    temp_cogs_items.splice(index, 1)

    temp_revenue_items.splice(index_product, 1, {
      ...temp_revenue_items[index_product],
      cogs_items: temp_cogs_items,
    })

    const newTotalCOGS = reCountTotal(temp_revenue_items)
    setProject({ ...project, revenue_items: temp_revenue_items, total_cogs: newTotalCOGS })
  }

  return (
    <div className="mb-12">
      <h1 className="font-semibold text-giant mb-4">COGS</h1>
      <div className="mb-2 flex flex-col gap-4 md:mb-0 md:flex-row md:items-end" key={resetForm}>
        <div className="md:w-[300px]">
          <Label className="">Select Product</Label>
          <Select
            onValueChange={(value) => {
              setSelectedProduct(project.revenue_items[value])
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select Product" />
            </SelectTrigger>
            <SelectContent>
              {/* <SelectItem value="active">Active</SelectItem>
                  <SelectItem value="inactive">Inactive</SelectItem> */}
              {project.revenue_items.map((product, product_index) => (
                <SelectItem key={product_index} value={product_index}>
                  {`${product.product_name} - ${product.product_variant_name}`}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="md:w-[120px]">
          <Label className="">COGS Type</Label>
          <Select
            defaultValue={isCustomCOGS ? 'custom' : 'existing'}
            onValueChange={(value) => {
              if (value === 'existing') {
                setIsCustomCOGS(false)
              } else if (value === 'custom') {
                setIsCustomCOGS(true)
              }
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select Product" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="existing">Existing</SelectItem>
              <SelectItem value="custom">Custom</SelectItem>
            </SelectContent>
          </Select>
        </div>
        {isCustomCOGS ? (
          <>
            <div className="md:w-[300px] ">
              <Label className="">COGS Name</Label>
              <Input
                type="text"
                defaultValue={customCOGS.name || ''}
                onChange={(e) => setCustomCOGS({ ...customCOGS, name: e.target.value })}
              />
            </div>
            <div className="md:w-[300px] ">
              <Label className="">Price</Label>
              <Input
                type="number"
                defaultValue={customCOGS.cost || 0}
                onChange={(e) => setCustomCOGS({ ...customCOGS, cost: parseInt(e.target.value) })}
              />
            </div>
          </>
        ) : (
          <>
            <div className="md:w-[300px]">
              <Label className="">Item</Label>
              {!listCOGS.isLoading && (
                <SearchableSelect
                  onSelect={(selected) => setSelectedCOGS(listCOGS.data.find((item) => item.id === selected))}
                  options={listCOGS.data.map((item) => ({
                    value: item.id,
                    label: `${item.name}${item.description ? ' - ' + item.description : ''} - Rp${formatThousands(
                      item.cost
                    )}`,
                  }))}
                />
              )}
            </div>
            <div className="md:w-[300px] ">
              <Label className="">Price</Label>
              <Input
                key={selectedCOGS?.id}
                type="number"
                defaultValue={selectedCOGS?.cost || 0}
                onChange={(e) => setSelectedCOGS({ ...selectedCOGS, cost: parseInt(e.target.value) })}
              />
            </div>
          </>
        )}
        {isCustomCOGS ? (
          <Button
            onClick={() => {
              handleAddCOGSItem()
            }}
            disabled={!selectedProduct?.id && !!customCOGS.name && !!customCOGS.cost === 0}
          >
            Add
          </Button>
        ) : (
          <Button
            onClick={() => {
              handleAddCOGSItem()
            }}
            disabled={!selectedProduct?.id && !selectedCOGS?.id}
          >
            Add
          </Button>
        )}
      </div>
      <div className="mb-2">
        {isMissingCogsData && <p className="text-small font-semibold text-red-500">Please complete all field</p>}
      </div>
      <div className="pb-2 relative overflow-x-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-gray-300 scrollbar-track-sky-50">
        <table className={'table-fixed w-full'}>
          <thead className="rounded-[5px] h-[56px] text-left font-bold text-black bg-[#F1F5F9] overflow-hidden">
            <tr>
              <th className="px-4 w-8 text-center grow-0 shrink-0 rounded-l-[5px]">#</th>
              {cogsCols.map((col, index) => {
                if (index === cogsCols.length - 1) {
                  return (
                    <th key={index} className={`px-4 py-4 font-semibold rounded-r-[5px] ${col.classes}`}>
                      {col.name}
                    </th>
                  )
                }
                return (
                  <th key={index} className={`px-4 py-4 font-semibold ${col.classes}`}>
                    {col.name}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {project.revenue_items.length === 0 ? (
              <tr className="text-center italic">
                <td colSpan={cogsCols.length} className="text-gray-text font-semibold py-4">
                  No data found
                </td>
              </tr>
            ) : (
              project.revenue_items.map((row, rev_index) => {
                var tempItemTotalCOGS = 0
                return (
                  <tr key={rev_index} className="border-b border-gray-border">
                    <td className="px-4 py-4 w-8">{rev_index + 1}.</td>
                    <td className="px-4 py-4">
                      <Basic data={`${row.product_name} - ${row.product_variant_name}`} />
                    </td>
                    <td colSpan={5}>
                      <table className="w-full">
                        {row.cogs_items.map((cogs_row, cogs_index) => {
                          tempItemTotalCOGS += cogs_row.cost * cogs_row.quantity
                          return (
                            <tr key={cogs_index} className={`border-b last:border-none border-gray-border w-[700px]`}>
                              <td className={`px-4 py-4 w-[150px]`}>
                                {cogs_row.cogs_id === null
                                  ? cogs_row.custom_name
                                  : `${cogs_row.cogs_item_name}${
                                      cogs_row.cogs_item_description !== ''
                                        ? ' - ' + cogs_row.cogs_item_description
                                        : ''
                                    }`}
                              </td>
                              <td className={`px-4 py-4 text-right w-[200px]`}>
                                {!cogs_row.allow_edit ? (
                                  `Rp${formatThousands(cogs_row.cost)}`
                                ) : (
                                  <Input
                                    onChange={(e) => {
                                      var temp
                                      if (e.target.value < 0 || e.target.value === '') {
                                        temp = 0
                                      } else {
                                        temp = e.target.value
                                      }
                                      handleCOGSItemCostChange(parseInt(temp), row, cogs_row)
                                    }}
                                    value={cogs_row.cost}
                                  />
                                )}
                              </td>
                              <td className={`px-4 py-4 text-right w-[100px]`}>
                                {!cogs_row.allow_edit ? (
                                  cogs_row.quantity
                                ) : (
                                  <Input
                                    onChange={(e) => {
                                      var temp
                                      if (e.target.value < 0 || e.target.value === '') {
                                        temp = 0
                                      } else {
                                        temp = e.target.value
                                      }
                                      handleCOGSItemQtyChange(parseInt(temp), row, cogs_row)
                                    }}
                                    value={cogs_row.quantity}
                                  />
                                )}
                              </td>
                              <td className={`px-4 py-4 text-right w-[200px]`}>
                                Rp{formatThousands(cogs_row.cost * cogs_row.quantity)}
                              </td>
                              <td className={`px-4 py-4 !w-20`}>
                                <div className="flex justify-center">
                                  {!cogs_row?.allow_edit ? (
                                    <TooltipProvider>
                                      <Tooltip>
                                        <TooltipTrigger asChild>
                                          <Lock className="w-5 h-5" />
                                        </TooltipTrigger>
                                        <TooltipContent>
                                          <p>COGS Locked</p>
                                        </TooltipContent>
                                      </Tooltip>
                                    </TooltipProvider>
                                  ) : (
                                    <Button
                                      size="icon"
                                      variant="outline"
                                      onClick={() => handleDeleteCOGSItem(row, cogs_row)}
                                    >
                                      <Trash2 className="w-5 h-5" />
                                    </Button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </table>
                    </td>
                    <td className="px-4 py-4 w-8">
                      <Basic data={`Rp${formatThousands(tempItemTotalCOGS)}`} alignment={'right'} />
                    </td>
                    <td className="px-4 py-4 w-8">
                      <Basic
                        data={
                          row.discount_type === 'absolute'
                            ? `${parseFloat(
                                (tempItemTotalCOGS / (row.quantity * row.price - row.discount_absolute)) * 100
                              ).toFixed(0)}% / ${(parseFloat(row.cogs) * 100).toFixed(0)}%`
                            : `${parseFloat(
                                (tempItemTotalCOGS /
                                  (row.quantity * row.price * (1 - parseFloat(row.discount_percentage)))) *
                                  100
                              ).toFixed(0)}% / ${(parseFloat(row.cogs) * 100).toFixed(0)}%`
                        }
                        alignment={'right'}
                        state={
                          row.discount_type === 'absolute'
                            ? `${
                                (tempItemTotalCOGS / (row.quantity * row.price - row.discount_absolute)) * 100 <=
                                parseFloat(row.cogs) * 100
                                  ? 'positive'
                                  : 'negative'
                              }`
                            : `${
                                (tempItemTotalCOGS /
                                  (row.quantity * row.price * (1 - parseFloat(row.discount_percentage)))) *
                                  100 <=
                                parseFloat(row.cogs) * 100
                                  ? 'positive'
                                  : 'negative'
                              }`
                        }
                        withIcon
                      />
                    </td>
                  </tr>
                )
              })
            )}
            <tr className="border-b border-gray-border">
              <td colSpan={4}></td>
              <td className={`px-4 py-4 font-bold text-right w-32 `}>Total</td>
              <td className={`px-4 py-4 font-bold text-right`}>Rp{formatThousands(project.total_cogs || 0)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
