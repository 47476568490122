import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../components/BreadCrumbs'
import { Link, Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom'
import SalesDealAPI from '../../apis/SalesDealAPI'
import Loading from '../../components/Loading'
import DismissableToast from '../../components/Toast'
import ErrorPage from '../../ErrorPage'
import Status from '@/components/Status'
import { useQuery } from '@tanstack/react-query'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import {
  BriefcaseBusiness,
  Calculator,
  CalendarArrowDown,
  CalendarArrowUp,
  CircleDollarSign,
  Copy,
  IdCard,
  ListTodo,
  Presentation,
  SlidersHorizontal,
  Speech,
} from 'lucide-react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import SalesDealDocuments from './SalesDealDocuments'
import { formatThousands } from '@/helper'
import SalesDealProject from './SalesDealProject'
import SalesDealInvoice from './SalesDealInvoice'
import SalesDealMeetingHistory from './SalesDealMeetingHistory'
import usePermission from '@/hooks/usePermission'
import moment from 'moment'
import UpdateStatusDialog from '@/components/UpdateStatusDialog'
import StorageAPI from '@/apis/storageAPI'
import { hasPermission } from '@/lib/hasPermission'
import { useCopyToClipboard } from '@uidotdev/usehooks'
import toast from 'react-hot-toast'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

export default function SalesDealDetail() {
  const { id } = useParams()
  const { user, isAE } = usePermission()
  const location = useLocation()
  const { hash, pathname, search } = location
  const [copiedText, copyToClipboard] = useCopyToClipboard()
  // const [tab, setTab] = useState(pathname.split('/').pop())

  const salesDeal = useQuery({ queryKey: ['salesDeal', id], queryFn: () => SalesDealAPI.getSalesDealById(id) })
  // console.log(salesDeal.error?.response?.data)

  const documents = useQuery({
    queryKey: ['sales-deal', id, 'documents'],
    queryFn: async () => {
      const res = await SalesDealAPI.getSalesDealDocuments(id)
      const temp = {
        proposal: null,
        quotation: null,
        spk: null,
        confirmation_letter: null,
        bast: null,
      }

      const promises = res.data.map(async (doc) => {
        var tempDoc = { ...doc }

        if (doc.url) {
          const meta = await StorageAPI.getMetadataFromURL(doc.url)
          // console.log(meta)
          tempDoc['metadata'] = { name: meta.name, size: meta.size }
        }

        // console.log(tempDoc)
        temp[doc.type] = tempDoc
      })

      await Promise.all(promises)

      // console.log(temp)
      return temp
    },
  })

  useEffect(() => {
    // console.log(pathname.split('/').pop())
  }, [pathname])

  return (
    <div className="py-4">
      <DismissableToast />
      {salesDeal.isLoading ? (
        <Loading />
      ) : salesDeal.error?.response?.data?.error_code === 'forbidden_row_access' ? (
        <ErrorPage type={403} />
      ) : !hasPermission(user, 'salesDeal.detail', salesDeal.data.data.account.id) ? (
        <ErrorPage type={403} />
      ) : (
        <>
          <div className="mb-4">
            <BreadCrumbs>
              <BreadCrumbs.Item href={'/sales-deal'}>Sales Deal</BreadCrumbs.Item>
              <BreadCrumbs.Item current>{salesDeal.data.data.project.name}</BreadCrumbs.Item>
            </BreadCrumbs>
          </div>

          <div className="flex gap-8 mb-4">
            <div className="w-full max-w-[240px] aspect-square border rounded-lg flex items-center justify-center overflow-hidden">
              <img
                src={
                  salesDeal.data.data.client.logo
                    ? salesDeal.data.data.client.logo
                    : salesDeal.data.data?.client?.company_name
                    ? 'https://ui-avatars.com/api/?name=' +
                      salesDeal.data.data?.client?.company_name.split(' ').join('+') +
                      '&background=e5ecf1&color=034A73'
                    : 'https://ui-avatars.com/api/?name=Kata+Data&background=e5ecf1&color=034A73'
                }
                alt=""
                className="w-full object-cover"
              />
            </div>
            <div className="flex-1">
              <h1 className="text-3xl font-bold mb-4">{salesDeal.data.data?.client?.company_name || ''}</h1>
              <div className="flex gap-8 items-center mb-4">
                <Status type={salesDeal.data.data.status} size={'large'} />
                <div className="flex gap-2 items-center">
                  <Avatar className="w-8 h-8">
                    <AvatarImage src={salesDeal.data.data.account?.avatar} />
                    <AvatarFallback>KD</AvatarFallback>
                  </Avatar>
                  <p className="text-xl font-medium">{salesDeal.data.data?.account?.name}</p>
                </div>
              </div>
              <div className="flex gap-2">
                {hasPermission(user, 'salesDeal.fillProjectFeasibility', salesDeal.data.data.project.status) && (
                  <Link to={`/projects/edit/s/${salesDeal.data.data.project.id}`}>
                    <Button variant={salesDeal.data.data.project.total_revenue ? 'outline' : 'default'}>
                      <Calculator className="w-5 h-5" /> Fill Project Feasibility
                    </Button>
                  </Link>
                )}
                {hasPermission(user, 'salesDeal.changeStatus') && (
                  <UpdateStatusDialog salesDeal={salesDeal.data.data} documents={documents.data}>
                    <Button variant="outline">
                      <SlidersHorizontal className="w-5 h-5" /> Change Status
                    </Button>
                  </UpdateStatusDialog>
                )}
              </div>
            </div>
          </div>
          <div className="flex gap-8">
            <div className="border rounded-lg p-4 w-[240px] shrink-0 h-fit grid gap-4">
              <div className="flex gap-4 items-center">
                <span className="p-2 rounded border aspect-square">
                  <BriefcaseBusiness className="w-5 h-5" />
                </span>
                <div className="flex-1">
                  <p className="font-bold text-xs mb-0.5">Project Name</p>
                  <p className="text-main-500/70 text-xs">{salesDeal.data.data.project.name}</p>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <span className="p-2 rounded border aspect-square">
                  <IdCard className="w-5 h-5" />
                </span>
                <div className="flex-1">
                  <p className="font-bold text-xs mb-0.5">Project ID</p>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <button
                          className="w-full text-left "
                          onClick={() => {
                            toast.success('Copied to clipboard')
                            copyToClipboard(salesDeal.data.data.project.cc_project_id)
                          }}
                        >
                          <p className="text-main-500/70 text-xs flex items-center gap-1.5">
                            {salesDeal.data.data.project.cc_project_id || '-'}
                            {salesDeal.data.data.project.cc_project_id && <Copy className="w-3 h-3" />}
                          </p>
                        </button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Click to copy project id</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  {/* <div className="flex-1">
                  </div>
                  {salesDeal.data.data.project.cc_project_id && (
                    <Button
                      variant="outline"
                      size="xs"
                      onClick={() => {
                        toast.success('Copied to clipboard')
                        copyToClipboard(salesDeal.data.data.project.cc_project_id)
                      }}
                    >
                      <Copy className="w-4 h-4" />
                    </Button>
                  )} */}
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <span className="p-2 rounded border aspect-square">
                  <ListTodo className="w-5 h-5" />
                </span>
                <div className="flex-1">
                  <p className="font-bold text-xs mb-0.5">Project Type</p>
                  <p className="text-main-500/70 text-xs capitalize">
                    {salesDeal.data.data.project.type.replaceAll('_', ' ')}
                  </p>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <span className="p-2 rounded border aspect-square">
                  <CircleDollarSign className="w-5 h-5" />
                </span>
                <div className="flex-1">
                  <p className="font-bold text-xs mb-0.5">Project Value</p>
                  <p className="text-main-500/70 text-xs">
                    {`Rp${formatThousands(salesDeal.data.data.project.total_revenue)}` || '-'}
                  </p>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <span className="p-2 rounded border aspect-square">
                  <CalendarArrowDown className="w-5 h-5" />
                </span>
                <div className="flex-1">
                  <p className="font-bold text-xs mb-0.5">Start Date</p>
                  <p className="text-main-500/70 text-xs">
                    {moment(salesDeal.data.data.created_at).format('DD MMMM YYYY')}
                  </p>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <span className="p-2 rounded border aspect-square">
                  <CalendarArrowUp className="w-5 h-5" />
                </span>
                <div className="flex-1">
                  <p className="font-bold text-xs mb-0.5">Expired Date</p>
                  <p className="text-main-500/70 text-xs">
                    {salesDeal.data.data.expired_date
                      ? moment(salesDeal.data.data.expired_date).format('DD MMMM YYYY')
                      : '-'}
                  </p>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <span className="p-2 rounded border aspect-square">
                  <CircleDollarSign className="w-5 h-5" />
                </span>
                <div className="flex-1">
                  <p className="font-bold text-xs mb-0.5">Total Meeting Expenses</p>
                  <p className="text-main-500/70 text-xs">{`Rp${formatThousands(
                    salesDeal.data.data.total_meeting_expenses || 0
                  )}`}</p>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <span className="p-2 rounded border aspect-square">
                  <Presentation className="w-5 h-5" />
                </span>
                <div className="flex-1">
                  <p className="font-bold text-xs mb-0.5">Number of Meetings</p>
                  <p className="text-main-500/70 text-xs">{salesDeal.data.data.number_of_meetings}</p>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <span className="p-2 rounded border aspect-square">
                  <Speech className="w-5 h-5" />
                </span>
                <div className="flex-1">
                  <p className="font-bold text-xs mb-0.5">Agency</p>
                  <p className="text-main-500/70 text-xs">{salesDeal.data.data.agency?.name || '-'}</p>
                </div>
              </div>
            </div>
            <div className="w-full lg:max-w-[calc(100%-240px)]">
              <Tabs defaultValue={pathname.split('/').pop()} key={pathname.split('/').pop()}>
                <TabsList className="w-full justify-start mb-4">
                  <Link to={`/sales-deal/${id}/documents`}>
                    <TabsTrigger value="documents">Documents</TabsTrigger>
                  </Link>
                  <Link to={`/sales-deal/${id}/project`}>
                    <TabsTrigger value="project">Project</TabsTrigger>
                  </Link>
                  <Link to={`/sales-deal/${id}/invoice`}>
                    <TabsTrigger value="invoice">Invoice</TabsTrigger>
                  </Link>
                  <Link to={`/sales-deal/${id}/meeting-history`}>
                    <TabsTrigger value="meeting-history">Meeting History</TabsTrigger>
                  </Link>
                  <Link to={`/sales-deal/${id}/logs`}>
                    <TabsTrigger value="logs">Project Log</TabsTrigger>
                  </Link>
                </TabsList>
              </Tabs>

              <div className="p-4 border rounded-xl">
                <Routes>
                  <Route path="documents" element={<SalesDealDocuments salesDeal={salesDeal.data.data} />} />
                  <Route path="project" element={<SalesDealProject salesDeal={salesDeal.data.data} />}></Route>
                  <Route path="invoice" element={<SalesDealInvoice salesDeal={salesDeal.data.data} />}></Route>

                  <Route
                    path="meeting-history"
                    element={<SalesDealMeetingHistory salesDeal={salesDeal.data.data} />}
                  ></Route>
                </Routes>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
