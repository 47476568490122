import React, { useEffect, useState } from 'react'
import moment from 'moment/moment'
import { useDispatch, useSelector } from 'react-redux'
// import AuthAPI from '../apis/authAPI'
import { Navigate, useNavigate } from 'react-router-dom'
import { useCustomSearchParams } from '@/hooks/useCustomSearchParams'
import AccountAPI from '../apis/AccountAPI'
import Alert from '../components/Alert'
import Button from '../components/Button'
import Input from '../components/Input'
import useAuth from '../hooks/useAuth'
import { saveDetailUserData, saveFirebaseLoginData } from '../redux/authSlice'

export default function Login() {
  const firebaseData = useSelector((state) => state.auth.firebaseUser)
  const userData = useSelector((state) => state.auth.userData)
  const [inputUser, setInputUser] = useState()
  const { signIn } = useAuth()
  const [error, setError] = useState('')
  const [isLoadingLogin, setLoadingLogin] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isShowPassword, setShowPassword] = useState(false)
  const [searchParams, setSearchParams] = useCustomSearchParams()

  useEffect(() => {
    // console.log(error)
  }, [error])

  const handleChange = (input) => {
    setInputUser({ ...inputUser, [input.name]: input.value })
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    setLoadingLogin(true)
    var signInResponse = undefined

    try {
      signInResponse = await signIn(inputUser.email, inputUser.password)
      dispatch(
        saveFirebaseLoginData({
          accessToken: signInResponse.user.accessToken,
          refreshToken: signInResponse.user.refreshToken,
          uid: signInResponse.user.uid,
          email: signInResponse.user.email,
          displayName: signInResponse.user.displayName,
          lastLoggedIn: moment().startOf('days').format(),
          lastRefreshToken: moment().format(),
          isRefreshing: false,
        })
      )
    } catch (err) {
      console.log(error)
      if (err.code === 'auth/user-not-found') {
        setError('Email was not found in database')
        return
      }
      if (err.code === 'auth/wrong-password') {
        setError('Wrong password.')
        return
      }

      setError(err.code)
      setLoadingLogin(false)
      return
    }

    try {
      const userDataResponse = await AccountAPI.getSingleAccountByUID(signInResponse.user.uid)
      dispatch(
        saveDetailUserData({
          id: userDataResponse.data.id,
          name: userDataResponse.data.name,
          role: userDataResponse.data.role,
          avatar: userDataResponse.data.avatar,
          email: userDataResponse.data.email,
          sbu_id: userDataResponse.data.sbu_id,
        })
      )

      if (!!searchParams.redirect) {
        return navigate(searchParams.redirect)
      }
      return navigate('/sales-plan')
    } catch (err) {
      console.log(err)
      setError(err.message)
      setLoadingLogin(false)
    }
  }

  if (userData && firebaseData) return <Navigate to={searchParams.redirect ? searchParams.redirect : '/sales-plan'} />

  return (
    <>
      <div className="w-full h-screen flex flex-col items-center justify-center bg-main-100">
        <div className="box w-[300px] rounded shadow-lg overflow-hidden mb-4">
          <div className="header h-[60px] bg-main-500 flex justify-center items-center text-lg font-bold leading-relaxed text-white">
            Katadata CRM
          </div>
          <form onSubmit={(e) => handleLogin(e)} className="body bg-white px-4 py-6 flex flex-col gap-4">
            <Input
              label={'Email'}
              onChange={(e) => handleChange(e.target)}
              name="email"
              type="email"
              placeholder="email@katadata.co.id"
            />
            <div className="relative">
              <Input
                label={'Password'}
                onChange={(e) => handleChange(e.target)}
                name="password"
                type="password"
                placeholder=""
              />
            </div>
            <Button className={'block'} type="submit" onClick={(e) => handleLogin(e)} isLoading={isLoadingLogin}>
              Login
            </Button>
          </form>
        </div>
        <div className="h-12 w-[300px]">{error && <Alert type="error" message={error} />}</div>
      </div>
    </>
  )
}
