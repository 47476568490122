import * as React from 'react'
import { Check, ChevronDown, ChevronsDown, ChevronsUpDown } from 'lucide-react'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'

const frameworks = [
  {
    value: 'next.js',
    label: 'Next.js',
  },
  {
    value: 'sveltekit',
    label: 'SvelteKit',
  },
  {
    value: 'nuxt.js',
    label: 'Nuxt.js',
  },
  {
    value: 'remix',
    label: 'Remix',
  },
  {
    value: 'astro',
    label: 'Astro',
  },
]

export function SearchableSelect({ options, placeholder, defaultValue, value, onSelect }) {
  const [open, setOpen] = React.useState(false)
  const [selected, setSelected] = React.useState(value || defaultValue || '')
  const [skipFirst, setSkipFirst] = React.useState(true)

  React.useEffect(() => {
    if (skipFirst) {
      setSkipFirst(false)
      return
    }
    onSelect(selected)
  }, [selected])

  React.useEffect(() => {
    setSelected(value)
  }, [value])

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-start border-primary-gray text-sm text-black text-left font-normal rounded-[5px]"
        >
          <p className="w-full line-clamp-1 flex-1">
            {selected ? options.find((option) => option.value === selected)?.label : placeholder || 'Select'}
          </p>
          <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="max-w-full p-0">
        <Command>
          <CommandInput placeholder="Search something..." />
          <CommandList>
            <CommandEmpty>No option found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      setSelected(option.value)
                      setOpen(false)
                    }}
                  >
                    <Check className={cn('mr-2 h-4 w-4', selected === option.value ? 'opacity-100' : 'opacity-0')} />
                    {option.label}
                  </CommandItem>
                )
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
