import React, { useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Component, Info, ListTodo, PenSquare, ShoppingBasket, Trash2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { z } from 'zod'
import { hasPermission } from '@/lib/hasPermission'
import useAuth from '@/hooks/useAuth'
import { useCustomSearchParams } from '@/hooks/useCustomSearchParams'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Sheet, SheetClose, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'
import { Switch } from '@/components/ui/switch'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { Combobox } from '@/components/Combobox'
import Loading from '@/components/Loading'
import Pagination from '@/components/Pagination'
import { SearchableSelect } from '@/components/SearchableSelect'
import Basic from '@/components/table/cell/Basic'
import StatusCell from '@/components/table/cell/StatusCell'
import Table from '@/components/table/Table'
import { ProductsAPI } from '@/apis/ProductsAPI'
import SBUAPI from '@/apis/SBUAPI'
import { queryClient } from '@/App'
import { formatThousands } from '@/helper'

export default function Variants() {
  const { user } = useAuth()
  const [searchParams, setSearchParams] = useCustomSearchParams()
  const [selectedVariant, setSelectedVariant] = useState({})
  const [saveError, setSaveError] = useState('')
  const [meta, setMeta] = useState({ total_page: 1 })
  const [colsSubproduct, setColsSubproduct] = useState([
    {
      id: 0,
      name: 'SBU',
      selector: (row) => <Basic data={row.sbu_name} />,
      classes: 'min-w-[150px]',
    },
    {
      id: 1,
      name: 'Product',
      selector: (row) => <Basic data={row.product_name} />,
      classes: 'min-w-[150px]',
    },
    {
      id: 2,
      name: 'Sub Product',
      selector: (row) => <Basic data={row.sub_product_name} />,
      classes: 'min-w-[150px]',
    },
    {
      id: 3,
      name: 'Variant',
      selector: (row) => <Basic data={row.product_variant_name} />,
      classes: 'min-w-[200px]',
    },
    {
      id: 4,
      name: 'Price',
      selector: (row) => <Basic data={`Rp${formatThousands(row.product_variant_price || 0)}`} alignment={'right'} />,
      classes: 'min-w-[150px] text-right',
    },
    {
      id: 5,
      name: 'COGS GUideline',
      selector: (row) => <Basic data={`${(row.product_variant_cogs * 100).toFixed(2)}%`} alignment={'right'} />,
      classes: 'min-w-[150px] text-right',
    },
    {
      id: 6,
      name: 'Status',
      selector: (row) => (
        <StatusCell
          data={row.product_variant_status}
          config={[
            { state: 'danger', text: 'Inactive', status: 'inactive' },
            { state: 'success', text: 'Active', status: 'active' },
            { state: 'neutral', text: 'Deleted', status: 'deleted' },
          ]}
        />
      ),
      classes: 'min-w-[150px]',
    },
  ])
  const form = useForm({
    resolver: zodResolver(
      z.object({
        product_id: z.string({ required_error: 'This field is required!' }),
        sub_product_id: z.string({ required_error: 'This field is required!' }),
        name: z.string().min(1, { message: 'This field is required!' }),
        price: z.coerce.number({ required_error: 'This field is required!' }).min(0),
        cogs: z.coerce.number({ required_error: 'This field is required!' }).min(0).max(100),
      })
    ),
    defaultValues: {
      product_id: '',
      sub_product_id: '',
      name: '',
      price: 0,
      cogs: 0,
    },
  })
  const { setValue, getValues, handleSubmit } = form
  const sbu = useQuery({
    queryKey: ['sbu'],
    queryFn: async () => {
      var res = await SBUAPI.getAllSBU()
      return res.data
    },
  })

  const products = useQuery({
    queryKey: ['products'],
    queryFn: async () => {
      var res = await ProductsAPI.getProductOnly(undefined, undefined, undefined, true)
      return res.data
    },
  })
  const subproduct = useQuery({
    queryKey: ['products', 'subproduct'],
    queryFn: async () => {
      var res = await ProductsAPI.getSubproduct(undefined, undefined, undefined, undefined, true)
      return res.data
    },
  })
  const variants = useQuery({
    queryKey: [
      'products',
      'variants',
      {
        sbu: searchParams.sbu,
        product: searchParams.product,
        subprodut: searchParams.subproduct,
        status: searchParams.status,
        page: searchParams.page,
      },
    ],
    queryFn: async () => {
      var res = await ProductsAPI.getVariants(
        searchParams.sbu || undefined,
        searchParams.product || undefined,
        searchParams.subproduct || undefined,
        searchParams.status || undefined,
        searchParams.page
      )
      setMeta(res.meta)
      return res.data
    },
  })

  const mutateVariant = useMutation({
    mutationFn: async (data) => {
      const temp = { ...data, cogs: data.cogs / 100 }
      let res = undefined
      if (searchParams.edit) {
        res = await ProductsAPI.updateVariant(searchParams.edit, temp)
      } else {
        res = await ProductsAPI.createVariant(data)
      }
      return res
    },
    onSuccess: (res) => {
      if (!res.success) {
        throw res
      }
      toast.success(res.message)
      queryClient.invalidateQueries(['products', 'variants'])
      setSearchParams(delete searchParams.edit)
      setSearchParams(delete searchParams.create)
      setSaveError('')
      form.reset()
    },
    onError: (err) => {
      setSaveError(
        err.data?.message || err?.response.data.message || err.statusText || 'Something went wrong while updating data'
      )
    },
  })

  const changeStatusVariant = useMutation({
    mutationFn: async ({ variantId, status }) => {
      console.log(variantId)
      const res = await ProductsAPI.changeStatusVariant(variantId, status)
      return res
    },
    onSuccess: (res) => {
      if (!res.success) throw res

      queryClient.invalidateQueries(['products', 'variants'])
      toast.success(res.message)
      setSearchParams(delete searchParams.changeTo)
      setSearchParams(delete searchParams.changeStatus)
    },
    onError: (err) => {
      console.log(err)
      toast.error(
        err.data?.message || err?.response?.data.message || err.statusText || 'Something went wrong while updating data'
      )
    },
  })

  const deleteVariant = useMutation({
    mutationFn: async (variantId) => {
      const res = await ProductsAPI.deleteVariant(variantId)
      return res
    },
    onSuccess: (res) => {
      if (!res.success) {
        throw res
      }
      queryClient.invalidateQueries(['products', 'variants'])
      toast.success('Delete variant success!')
      form.reset()
      setSearchParams(delete searchParams.delete)
    },
    onError: (err) => {
      console.log(err)
      toast.error(err?.response.data.message || err.statusText || 'Something went wrong while deleting product!')
    },
  })

  useEffect(() => {
    setSaveError('')
    if (variants.isLoading) {
      return
    }
    if (searchParams.create) {
      form.reset()
      return
    }
    if (searchParams.edit) {
      form.reset()
      const temp = variants?.data?.find((item) => item.product_variant_id === parseInt(searchParams.edit))
      console.log(temp)
      setSelectedVariant(temp)
      form.setValue('product_id', temp.product_id.toString())
      form.setValue('sub_product_id', temp.sub_product_id.toString())
      form.setValue('name', temp.product_variant_name)
      form.setValue('price', parseInt(temp.product_variant_price))
      form.setValue('cogs', parseFloat(temp.product_variant_cogs * 100))
      return
    }
    if (searchParams.delete) {
      const temp = variants?.data?.find((item) => item.product_variant_id === parseInt(searchParams.delete))
      setSelectedVariant(temp)
      return
    }
    if (searchParams.changeStatus) {
      const temp = variants?.data?.find((item) => item.product_variant_id === parseInt(searchParams.changeStatus))
      setSelectedVariant(temp)
      return
    }
  }, [searchParams.edit, searchParams.create, searchParams.delete, searchParams.changeStatus, variants.isLoading])

  return (
    <div>
      <div className="flex gap-4 items-center mb-4">
        <div className="flex-1 flex gap-2">
          <Combobox
            className={'w-72'}
            options={sbu.isLoading ? [] : sbu.data.map((item) => ({ label: item.sbu_name, value: item.sbu_id }))}
            label={'SBU'}
            placeholder={'All'}
            icon={<Component />}
            isLoading={sbu.isLoading}
            defaultValue={parseInt(searchParams.sbu) || ''}
            onSelect={(value) => setSearchParams({ ...searchParams, sbu: value })}
          />
          <Combobox
            className={'w-72'}
            options={products.isLoading ? [] : products.data.map((item) => ({ label: item.name, value: item.id }))}
            label={'Product'}
            placeholder={'All'}
            icon={<ShoppingBasket />}
            isLoading={products.isLoading}
            defaultValue={parseInt(searchParams.product) || ''}
            onSelect={(value) => setSearchParams({ ...searchParams, product: value })}
          />
          <Combobox
            className={'w-72'}
            options={
              subproduct.isLoading
                ? []
                : subproduct.data.map((item) => ({ label: item.sub_product_name, value: item.sub_product_id }))
            }
            label={'Sub Product'}
            placeholder={'All'}
            icon={<ShoppingBasket />}
            isLoading={subproduct.isLoading}
            defaultValue={parseInt(searchParams.subproduct) || ''}
            onSelect={(value) => setSearchParams({ ...searchParams, subproduct: value })}
          />
          <Combobox
            className={'w-64'}
            options={[
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' },
            ]}
            label={'Status'}
            placeholder={'All'}
            icon={<ListTodo />}
            defaultValue={searchParams.status || 'active'}
            onSelect={(value) => setSearchParams({ ...searchParams, status: value })}
          />
        </div>
        {hasPermission(user, 'data.variant.create') && (
          <Button onClick={() => setSearchParams({ ...searchParams, create: true })}>Create New Product Variant</Button>
        )}
      </div>
      {subproduct.isLoading ? (
        <Loading />
      ) : (
        <div className="overflow-x-auto">
          <Table
            data={variants.data || []}
            cols={colsSubproduct}
            customAction={
              !hasPermission(user, 'data.variant.edit')
                ? undefined
                : (row) => (
                    <>
                      {row.status === 'deleted' ? null : (
                        <>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <div>
                                  <Switch
                                    id="status"
                                    checked={row.product_variant_status === 'active'}
                                    onCheckedChange={(value) => {
                                      console.log(value)
                                      setSearchParams({
                                        ...searchParams,
                                        changeTo: value ? 'active' : 'inactive',
                                        changeStatus: row.product_variant_id,
                                      })
                                    }}
                                  />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>Change Status</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                          {/* <Label htmlFor="airplane-mode">Status</Label> */}
                          <Button
                            variant={'outline'}
                            size={'icon'}
                            onClick={() => {
                              setSearchParams({ ...searchParams, edit: row.product_variant_id })
                            }}
                          >
                            <PenSquare className="w-5 h-5" />
                          </Button>

                          <Button
                            variant={'outline'}
                            size={'icon'}
                            onClick={() => {
                              setSearchParams({ ...searchParams, delete: row.product_variant_id })
                            }}
                          >
                            <Trash2 className="w-5 h-5" />
                          </Button>
                        </>
                      )}
                    </>
                  )
            }
            pageIndex={searchParams.page || 1}
          />
          <Pagination
            currentPage={parseInt(searchParams.page) || 1}
            totalPage={meta.total_page || 1}
            onChange={(page) => setSearchParams({ ...searchParams, page: page })}
          />
        </div>
      )}
      <Dialog open={!!searchParams.delete} onOpenChange={() => setSearchParams(delete searchParams.delete)}>
        <DialogContent className="w-full max-w-[500px]">
          <DialogHeader className={'mb-2.5'}>
            <DialogTitle className={'text-xl font-semibold'}>Are you sure to delete the variant?</DialogTitle>
            {/* <DialogDescription>
          This action will result in the deletion of all sub-products and variants of the product, resulting
          it inaccessible to all users.
        </DialogDescription> */}
          </DialogHeader>
          <div>
            <p className="font-semibold">Variant</p>
            <p className="text-main-500/70">{selectedVariant?.product_variant_name}</p>
          </div>
          <Alert variant="warning" size="sm">
            <AlertDescription className="flex items-start gap-2">
              <Info className="w-6 h-6" />
              <p>This action will result in the deletion this variant, making it inaccessible to all users.</p>
            </AlertDescription>
          </Alert>
          <div className="flex gap-2">
            <Button variant="outline" className="flex-1">
              Cancel
            </Button>
            <Button
              variant="destructive"
              className="flex-1"
              onClick={() => deleteVariant.mutate(selectedVariant?.product_variant_id)}
              isLoading={deleteVariant.isLoading}
              disabled={deleteVariant.isLoading}
            >
              Yes, proceed
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={!!searchParams.changeStatus} onOpenChange={() => setSearchParams(delete searchParams.changeStatus)}>
        <DialogContent className="w-full max-w-[500px]">
          <DialogHeader className={'mb-2.5'}>
            <DialogTitle className={'text-xl font-semibold'}>Change status to {searchParams.changeTo}?</DialogTitle>
            {/* <DialogDescription>
          This action will result in the deletion of all sub-products and variants of the product, resulting
          it inaccessible to all users.
        </DialogDescription> */}
          </DialogHeader>
          <div>
            <p className="font-semibold">Variant</p>
            <p className="text-main-500/70">{selectedVariant?.product_variant_name}</p>
          </div>
          <Alert variant="warning" size="sm">
            <AlertDescription className="flex items-start gap-2">
              <Info className="w-6 h-6" />
              <p>
                This action will result in the&nbsp;
                {searchParams.changeTo === 'inactive'
                  ? 'deactivation'
                  : searchParams.changeTo === 'active'
                    ? 'activation'
                    : ''}
                &nbsp;of product variant, making it&nbsp;
                {searchParams.changeTo === 'inactive'
                  ? 'inaccessible'
                  : searchParams.changeTo === 'active'
                    ? 'accessible'
                    : ''}
                &nbsp;to all users.
              </p>
            </AlertDescription>
          </Alert>
          <div className="flex gap-2">
            <Button variant="outline" className="flex-1">
              Cancel
            </Button>
            <Button
              variant={searchParams.changeTo === 'inactive' ? 'destructive' : ''}
              className="flex-1"
              onClick={() =>
                changeStatusVariant.mutate({
                  variantId: selectedVariant?.product_variant_id,
                  status: searchParams.changeTo,
                })
              }
              isLoading={changeStatusVariant.isLoading}
              disabled={changeStatusVariant.isLoading}
            >
              Yes, proceed
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Sheet
        open={!!searchParams.edit || !!searchParams.create}
        onOpenChange={() => setSearchParams(delete searchParams.edit)}
      >
        <SheetContent>
          <SheetHeader className={'mb-4'}>
            <SheetTitle className="text-xl">Edit Product</SheetTitle>
          </SheetHeader>
          {products.isLoading ? (
            <Loading />
          ) : (
            <Form {...form}>
              <form onSubmit={handleSubmit(mutateVariant.mutate)} className="grid gap-2.5">
                <FormField
                  control={form.control}
                  name="product_id"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Product</FormLabel>
                        {products.isLoading ? (
                          <Loading />
                        ) : (
                          <SearchableSelect
                            options={products?.data.map((product) => ({
                              label: product.name,
                              value: product.id.toString(),
                            }))}
                            defaultValue={field.value.toString()}
                            value={field.value.toString()}
                            onSelect={field.onChange}
                            // onValueChange={(value) => field.onChange(value)} defaultValue={field.value.toString()}
                          />
                        )}

                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />
                <FormField
                  control={form.control}
                  name="sub_product_id"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Sub Product</FormLabel>
                        {subproduct.isLoading ? (
                          <Loading />
                        ) : (
                          <SearchableSelect
                            defaultValue={field.value.toString()}
                            value={field.value.toString()}
                            options={subproduct?.data.map((subproduct) => ({
                              label: subproduct.sub_product_name,
                              value: subproduct.sub_product_id.toString(),
                            }))}
                            onSelect={field.onChange}
                            // onValueChange={(value) => field.onChange(value)} defaultValue={field.value.toString()}
                          />
                        )}

                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => {
                    console.log(field)
                    return (
                      <FormItem>
                        <FormLabel>Variant Name</FormLabel>
                        <FormControl>
                          <Input placeholder="" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />
                <FormField
                  control={form.control}
                  name="price"
                  render={({ field }) => {
                    console.log(field)
                    return (
                      <FormItem>
                        <FormLabel>Variant Price</FormLabel>
                        <FormControl>
                          <div className="relative">
                            <span className="absolute left-2 top-1/2 -translate-y-1/2 text-sm">Rp</span>
                            <Input placeholder="" className="pl-8" {...field} type="number" />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />
                <FormField
                  control={form.control}
                  name="cogs"
                  render={({ field }) => {
                    console.log(field)
                    return (
                      <FormItem>
                        <FormLabel className="m-0">COGS Percentage Max</FormLabel>
                        <FormDescription className="!m-0 mb-2">Maximum percentage allowed as COGS</FormDescription>
                        <FormControl>
                          <div className="relative overflow-hidden">
                            <Input placeholder="" className="pr-8" {...field} type="number" max="100" min="0" />
                            <span className="absolute right-2 top-1/2 -translate-y-1/2 text-sm">%</span>
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />
                {saveError && (
                  <Alert variant="destructive">
                    <AlertDescription>{saveError}</AlertDescription>
                  </Alert>
                )}
                <div className="flex gap-2.5">
                  <SheetClose asChild>
                    <Button className="w-full" variant="outline">
                      Close
                    </Button>
                  </SheetClose>
                  <Button className="w-full" isLoading={mutateVariant.isLoading} disabled={mutateVariant.isLoading}>
                    Save
                  </Button>
                </div>
              </form>
            </Form>
          )}
        </SheetContent>
      </Sheet>
    </div>
  )
}
