import React, { useEffect, useState } from 'react'
import Button from '../../components/Button'
import { Link } from 'react-router-dom'
import ClientAPI from '../../apis/ClientAPI'
import Table from '../../components/table/Table'
import Basic from '../../components/table/cell/Basic'
import Tabs from '../../components/Tabs'
import BreadCrumbs from '../../components/BreadCrumbs'
import usePermission from '../../hooks/usePermission'
import Modal from '../../components/Modal'

export default function Agency() {
  const [isLoading, setLoading] = useState(true)
  const [isOpenModal, setOpenModal] = useState(false)
  const [isLoadingDelete, setLoadingDelete] = useState(false)
  const [selectedToDelete, setSelectedToDelete] = useState()
  const [errorDelete, setErrorDelete] = useState()
  const [refreshDom, setRefreshDOM] = useState(0)
  const [listAgency, setListAgency] = useState([])
  const [agencyTableCols, setAgencyTableCols] = useState([
    {
      id: 0,
      name: 'Agency Name',
      classes: 'min-w-[250px]',
      selector: (row) => <Basic data={row.name} />,
    },
    {
      id: 1,
      name: 'Number of Clients',
      classes: 'min-w-[120px]',
      selector: (row) => <Basic data={row.number_of_clients} />,
    },
  ])
  const { isAdmin } = usePermission()

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        ClientAPI.getListAgency().then((res) => {
          setListAgency(res.data)
          resolve()
        })
      }),
    ]

    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [refreshDom])

  useEffect(() => {
    if (!isOpenModal) {
      setSelectedToDelete()
    }
  }, [isOpenModal])

  const handleDelete = () => {
    setLoadingDelete(true)

    if (selectedToDelete) {
      ClientAPI.deleteAgency(selectedToDelete.id)
        .then((res) => {
          if (res.success) {
            setLoadingDelete(false)
            setOpenModal(false)
            setRefreshDOM((refreshDom) => refreshDom + 1)
          }
        })
        .catch((err) => {
          console.log(err)
          if (err.response) setErrorDelete(err.response.data.message)
          else setErrorDelete(err.statusText)
          setLoadingDelete(false)
        })
    }
  }

  return (
    <div className="py-4">
      {isOpenModal && (
        <Modal setIsOpen={setOpenModal}>
          <Modal.Header setIsOpen={setOpenModal}>
            <p className="font-semibold text-big">Change Status To Loss</p>
          </Modal.Header>
          <Modal.Body setIsOpen={setOpenModal}>
            <p>
              You are going to delete {selectedToDelete.name} from the system. Do you really want to delete this agency?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-end gap-4">
              <Button onClick={() => setOpenModal(false)} variant={'outlined'}>
                Cancel
              </Button>
              <Button isLoading={isLoadingDelete} onClick={handleDelete}>
                Delete
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {isAdmin && (
        <Link to="agency/add">
          <Button className="mb-4">Add New Agency</Button>
        </Link>
      )}

      <div className="max-w-[600px]">
        <Table
          cols={agencyTableCols}
          data={listAgency}
          tableType="inline"
          editLink={(row) => {
            if (isAdmin) return `agency/${row.id}/edit`
          }}
          withActions={isAdmin}
          handleDelete={(id) => {
            var temp = listAgency.find((agency) => agency.id === id)
            setSelectedToDelete(temp)
            setOpenModal(true)
          }}
        />
      </div>
    </div>
  )
}
