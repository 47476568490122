import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import BreadCrumbs from '../../components/BreadCrumbs'
import Loading from '../../components/Loading'
// import Input from '../../components/Input'
// import Textarea from '../../components/Textarea'
import GroupButtons from '../../components/GroupButtons'
import DropdownInput from '../../components/DropdownInput'
// import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import Table from '../../components/table/Table'
import ProjectAPI from '../../apis/ProjectAPI'
import { ProductsAPI } from '../../apis/ProductsAPI'
import LogoCell from '../../components/table/cell/LogoCell'
import Basic from '../../components/table/cell/Basic'
import ActionCell from '../../components/table/cell/ActionCell'
import { formatThousands } from '../../helper'
import { COGSitemAPI } from '../../apis/COGSitemsAPI'
import { Delete } from '@mui/icons-material'
import CustomTextDropdownInput from '../../components/CustomTextDropdownInput'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import usePermission from '../../hooks/usePermission'
import ErrorPage from '../../ErrorPage'
import RevenueItemsTable from '../../components/RevenueItemsTable'
import COGSItemsTable from '../../components/COGSItemsTable'
import Alert from '../../components/Alert'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import moment from 'moment'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { ArrowRight } from 'lucide-react'
import { DateInput } from '@/components/DateInput'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import toast from 'react-hot-toast'

export default function CreateFlagship() {
  const [user, setUser] = useLocalStorage('user', {})
  const [project, setProject] = useState({
    name: '',
    status: '',
    description: '',
    review_note: null,
    total_revenue: 0,
    total_cogs: 0,
    type: 'flagship',
    // revenue_items: [],
    // cogs_items: [],
  })
  // const [selectedProduct, setSelectedProduct] = useState()
  // const [marginCols, setMarginCols] = useState([
  //   { id: 0, name: 'Component', selector: (row) => <Basic data={row.row} />, classes: 'w-[180px] font-semibold' },
  //   {
  //     id: 1,
  //     name: 'Total Amount',
  //     selector: (row) => <Basic data={`Rp${formatThousands(row.totalAmount)}`} alignment={'right'} />,
  //     classes: 'w-[180px]  text-right',
  //   },
  //   {
  //     id: 2,
  //     name: 'Percentage',
  //     selector: (row) => <Basic data={`${(row.percentage * 100).toFixed(2)}%`} alignment={'right'} />,
  //     classes: 'w-[180px] text-right',
  //   },
  // ])
  const [isLoadingSaveProject, setLoadingSaveProject] = useState(false)
  const [errorSaveProject, setErrorSaveProject] = useState()
  const navigate = useNavigate()
  const { isAdmin } = usePermission()

  const flagshipForm = useForm({
    resolver: zodResolver(
      z.object({
        name: z.string().min(1, { message: 'Required Field' }),
        flagship_code: z.string().min(1, { message: 'Required Field' }),
        description: z.string().min(1, { message: 'Required Field' }),
        status: z.string().min(1, { message: 'Required Field' }),
        start_date: z.coerce.date(),
        end_date: z.coerce.date(),
      })
    ),
    defaultValues: {
      name: '',
      flagship_code: '',
      description: '',
      status: 'active', // active, inactive
      start_date: '',
      end_date: '',
    },
  })

  const watchFlagshipName = flagshipForm.watch('name')

  useEffect(() => {
    // console.log(watchFlagshipName)
    const timeoutId = setTimeout(async () => {
      // console.log(watchFlagshipName)
      var res = await ProjectAPI.getFlagshipProjectCode(watchFlagshipName)
      // console.log(res.data)
      flagshipForm.setValue('flagship_code', res.data.flagship_code)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [watchFlagshipName])

  useEffect(() => {
    console.log(project)
  }, [project])

  useEffect(() => {
    console.log(flagshipForm.formState.errors)
  }, [flagshipForm])

  // const handleStatusChange = (selected) => {
  //   var temp = { ...project, status: selected.value }
  //   setProject(temp)
  // }

  // const handleChange = (input) => {
  //   setProject({ ...project, [input.name]: input.value })
  // }

  // const saveproject = () => {
  //   setLoadingSaveProject(true)
  //   if (project.revenue_items.length > 0) {
  //     var tempObject = {
  //       name: project.name,
  //       description: project.description,
  //       status: project.status,
  //       revenue_items: project.revenue_items.map((item) => {
  //         return {
  //           id: item.id,
  //           product_id: item.product_id,
  //           sub_product_id: item.sub_product_id,
  //           product_variant_id: item.product_variant_id,
  //           price: item.price,
  //           quantity: item.quantity,
  //           discount_type: item.discount_type,
  //           discount_percentage: item.discount_percentage,
  //           discount_absolute: item.discount_absolute,
  //         }
  //       }),
  //       cogs_items: project.cogs_items.map((item) => {
  //         return {
  //           revenue_items_id: item.project_revenue_item_id,
  //           cogs_id: item.cogs_id,
  //           cost: item.cost,
  //           quantity: item.quantity,
  //           custom_name: '',
  //         }
  //       }),
  //     }
  //     // console.log(tempObject)
  //     ProjectAPI.createNewFlagshipProject(tempObject)
  //       .then((res) => {
  //         console.log(res)
  //         if (res.success) navigate('/projects/flagship')
  //       })
  //       .catch((err) => {
  //         if (err.response) {
  //           setErrorSaveProject(err.response.data.message)
  //           setLoadingSaveProject(false)
  //         } else {
  //           setErrorSaveProject(err.message)

  //           setLoadingSaveProject(false)
  //         }
  //       })
  //   } else {
  //     setErrorSaveProject("Can't create flaghip project with no revenue item.")
  //     setLoadingSaveProject(false)
  //   }
  // }

  const saveFlagshipProject = async (values) => {
    setLoadingSaveProject(true)
    console.log(values)
    try {
      var res = await ProjectAPI.createNewFlagshipProject({
        ...values,
        start_date: moment(values.start_date).format('YYYY-MM-DD'),
        end_date: moment(values.end_date).format('YYYY-MM-DD'),
      })

      console.log(res.data)
      toast.success('Create flagship project success!')
      setLoadingSaveProject(false)
      navigate('/projects/flagship')
    } catch (err) {
      console.log(err)
      toast.error(err.response?.data?.message || 'Failed to save new flagship project')
    }
  }

  if (!isAdmin) {
    return <ErrorPage type={403} />
  }

  return (
    <div className="py-4">
      <>
        <div className="mb-4">
          <BreadCrumbs>
            <BreadCrumbs.Item href={'/projects'}>Project</BreadCrumbs.Item>
            <BreadCrumbs.Item current>Create Flagship Project</BreadCrumbs.Item>
          </BreadCrumbs>
        </div>
        <div className="mb-12">
          <h1 className="font-semibold text-giant mb-4">Create Flagship Project</h1>
          <div className="border border-gray-border rounded-[5px] p-4 inline-block">
            <Form {...flagshipForm}>
              <form
                className="max-w-[500px] w-full lg:w-[500px] grid gap-4"
                onSubmit={flagshipForm.handleSubmit(saveFlagshipProject)}
              >
                <FormField
                  control={flagshipForm.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-normal">Project Name</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormDescription className="flex items-center gap-2 text-xs">
                        Flagship code <ArrowRight className="w-3 h-3" /> {flagshipForm.watch('flagship_code')}
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={flagshipForm.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-normal">Description</FormLabel>
                      <FormControl>
                        <Textarea {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="flex gap-4 items-center">
                  <FormField
                    control={flagshipForm.control}
                    name="start_date"
                    render={({ field }) => (
                      <FormItem className="flex-1">
                        <FormLabel className="text-normal block">Start Date</FormLabel>
                        <FormControl>
                          <DateInput
                            onSelect={(selectedDate) => {
                              console.log(selectedDate)
                              field.onChange(moment(selectedDate).format('YYYY-MM-DD'))
                            }}
                            defaultValue={field.value}
                            className={'w-full'}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={flagshipForm.control}
                    name="end_date"
                    render={({ field }) => (
                      <FormItem className="flex-1">
                        <FormLabel className="text-normal">End Date</FormLabel>
                        <FormControl>
                          <DateInput
                            onSelect={(selectedDate) => {
                              field.onChange(moment(selectedDate).format('YYYY-MM-DD'))
                            }}
                            defaultValue={field.value}
                            className={'w-full'}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <FormField
                  control={flagshipForm.control}
                  name="status"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-normal">Status</FormLabel>
                      <FormControl>
                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Active" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value="active">Active</SelectItem>
                            <SelectItem value="inactive">Inactive</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {/* <p className="mb-1 font-medium">Status</p>
                <DropdownInput
                  data={[
                    { id: 'active', text: 'Active', value: 'active' },
                    { id: 'inactive', text: 'Inctive', value: 'inactive' },
                  ]}
                  attributeShown="text"
                  defaultValue={project.status}
                  handleSelectedOption={(selected) => {
                    handleStatusChange(selected)
                  }}
                  placeholder={'Choose status'}
                /> */}
                <Button type="submit" className="w-fit">
                  {isLoadingSaveProject ? <Loading className={'text-white'} /> : 'Create Project'}
                </Button>
              </form>
            </Form>
          </div>
        </div>
        {/* <RevenueItemsTable
          project={project}
          setProject={setProject}
          projectTypeTemp={'custom'}
          isEditable={isAdmin}
          isFlagshipPages={isAdmin}
        />
        <COGSItemsTable project={project} setProject={setProject} projectTypeTemp={'custom'} isEditable={isAdmin} /> */}
        {/* <div className="mb-12">
          <h1 className="text-giant mb-4 font-semibold">Margin</h1>
          <div className="border border-gray-border rounded-[5px] p-4">
            <Table
              tableType="inline"
              noIndex={true}
              cols={marginCols}
              data={[
                {
                  id: 0,
                  row: 'Revenue',
                  totalAmount: project.total_revenue,
                  percentage: project.total_revenue !== 0 ? 1 - project.total_cogs / project.total_revenue : 0,
                },
                {
                  id: 1,
                  row: 'COGS',
                  totalAmount: project.total_cogs,
                  percentage:
                    project.total_revenue !== 0
                      ? project.total_cogs / project.total_revenue
                      : project.total_cogs
                      ? 1
                      : 0,
                },
              ]}
            />
          </div>
        </div> */}
        {/* <div className="flex justify-end gap-4 mb-12">
          {errorSaveProject && <Alert type={'error'} message={errorSaveProject} />}
          <Button onClick={saveproject} isLoading={isLoadingSaveProject}>
            Save Project
          </Button>
        </div> */}
      </>
    </div>
  )
}
