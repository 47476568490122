import React, { useEffect, useState } from 'react'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { FileUploader } from 'react-drag-drop-files'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { Description, DescriptionRounded, DocumentScanner } from '@mui/icons-material'
import { cn } from '@/lib/utils'

export default function DragAndDropFile({
  onChange,
  name,
  value,
  children,
  className,
  label,
  wrapperClassName,
  ...props
}) {
  const [file, setFile] = useState()
  const [error, setError] = useState()
  const fileTypes = ['PDF', 'JPG', 'JPEG', 'SVG', 'PNG']

  useEffect(() => {
    if (file) {
      onChange(file)
    }
  }, [file])

  const handleChange = (inputFile) => {
    setFile(inputFile)
  }

  const handleError = (inputFile) => {
    console.log('error')
    setError(inputFile)
  }

  useEffect(() => {
    setFile(value)
  }, [value])

  if (file) {
    return (
      <FileUploader
        multiple={false}
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        classes=""
        maxSize={10}
        onSizeError={(file) => handleError(file)}
        children={
          <div className="relative">
            <p className="mb-2 font-semibold">{label}</p>
            <div
              className={`border-2 rounded text-main-400 flex items-center px-4 py-1 w-full ${
                error ? 'border-red-500' : 'border-main-400'
              } `}
            >
              <span className="w-6 h-6 flex items-center justify-center">
                <DescriptionRounded sx={{ fontSize: 20 }} />
              </span>
              <p className="">{file.name}</p>
            </div>
          </div>
        }
      ></FileUploader>
    )
  }

  return (
    <FileUploader
      multiple={false}
      handleChange={handleChange}
      name="file"
      types={fileTypes}
      classes=""
      maxSize={10}
      children={
        <div className={cn('', wrapperClassName)}>
          {label && <p className="mb-2 font-semibold">{label}</p>}
          <div
            className={cn(
              'w-full h-full border-dashed border-2 border-gray-300 rounded text-gray-400 flex flex-col justify-center items-center',
              className,
              error ? 'border-red-500' : 'border-gray-border'
            )}
          >
            <span className="flex items-center">
              <span className="w-8 h-8 flex items-center justify-center">
                <AttachFileIcon sx={{ fontSize: 22 }} />
              </span>
              <p className="text-big">Select File</p>
            </span>
            <p>(.pdf file, max 10Mb)</p>
          </div>
        </div>
      }
    ></FileUploader>
  )
}
