import * as React from 'react'
import { cn } from '@/lib/utils'

const Input = React.forwardRef(({ className, type, isError, ...props }, ref) => {
  return (
    <input
      type={type}
      // px-3 py-3 rounded-[5px] border w-full block transition-all focus-within:ring-4 focus-within:ring-secondary-blue focus-within:outline-none focus:border-main-500
      className={cn(
        'flex h-10 w-full rounded-lg border bg-background px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-main-500/30 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-secondary-blue focus-visible:border-main-500 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm',
        className,
        isError ? 'border-red-500' : 'border-primary-gray'
      )}
      ref={ref}
      {...props}
    />
  )
})
Input.displayName = 'Input'

export { Input }
