import React from 'react'
import Subproduct from './Subproduct'
import Variants from './Variants'
import Product from './Product'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Link, Route, Routes, useLocation } from 'react-router-dom'

export default function ProductRouter() {
  const { pathname } = useLocation()
  return (
    <div className="py-4">
      <Tabs value={pathname} className="w-fit">
        <TabsList className="w-full justify-start">
          <Link to={`/data/products`}>
            <TabsTrigger value="/data/products">Products</TabsTrigger>
          </Link>
          <Link to={`/data/products/sub-products`}>
            <TabsTrigger value="/data/products/sub-products">Sub Products</TabsTrigger>
          </Link>
          <Link to={`/data/products/variants`}>
            <TabsTrigger value="/data/products/variants">Variants</TabsTrigger>
          </Link>
        </TabsList>
      </Tabs>
      <div className="py-4">
        <Routes>
          <Route path="/sub-products" element={<Subproduct />} />
          <Route path="/variants" element={<Variants />} />
          <Route path="/" exact element={<Product />} />
        </Routes>
      </div>
    </div>
  )
}
