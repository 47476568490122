import InvoiceAPI from '@/apis/InvoiceAPI'
import SalesDealAPI from '@/apis/SalesDealAPI'
import { queryClient } from '@/App'
import Loading from '@/components/Loading'
import Basic from '@/components/table/cell/Basic'
import StatusCell from '@/components/table/cell/StatusCell'
import Table from '@/components/table/Table'
import { Button } from '@/components/ui/button'
import usePermission from '@/hooks/usePermission'
import { hasPermission } from '@/lib/hasPermission'
import { formatThousands } from '@/services/helper'
import { useMutation, useQuery } from '@tanstack/react-query'
import { data } from 'autoprefixer'
import { RefreshCw, SquarePen } from 'lucide-react'
import moment from 'moment'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { Link, useParams } from 'react-router-dom'

export default function SalesDealInvoice({ salesDeal }) {
  const { id } = useParams()
  const { isAE, user } = usePermission()
  const [stateCellConfig, setCellConfig] = useState([
    {
      text: 'In Review',
      status: 'in_review',
      state: 'warning',
    },
    {
      text: 'Pending',
      status: 'pending',
      // state: 'success',
    },
    {
      text: 'Processed',
      status: 'processed',
      state: 'warning-urgent',
    },
    {
      text: 'Invoiced',
      status: 'invoiced',
      state: 'success',
    },
    {
      text: 'Rejected',
      status: 'rejected',
      state: 'danger',
    },
    {
      text: 'Returned',
      status: 'returned',
      state: 'danger',
    },
    {
      text: 'Void',
      status: 'void',
      state: 'neutral',
    },
  ])
  const [invoiceCols, setInvoiceCols] = useState([
    {
      id: 0,
      name: 'Terms',
      classes: '!w-16',
      selector: (row) => <Basic data={row.term} />,
    },
    {
      id: 2,
      name: 'Amount',
      classes: 'text-right min-w-[150px]',
      selector: (row) => <Basic data={`Rp${formatThousands(row.amount)}`} alignment={'right'} />,
    },
    {
      id: 3,
      name: 'Invoice Date',
      classes: 'min-w-[150px]',
      selector: (row) => <Basic data={row.invoice_date ? moment(row.invoice_date).format('D MMMM YYYY') : '-'} />,
    },
    {
      id: 4,
      name: 'Invoice Due Date',
      classes: 'min-w-[150px]',
      selector: (row) => <Basic data={row.due_date ? moment(row.due_date).format('D MMMM YYYY') : '-'} />,
    },
    {
      id: 5,
      name: 'Invoice Tag',
      classes: 'min-w-[250px]',
      selector: (row) => <Basic data={row.invoice_tag || '-'} />,
    },
    {
      id: 6,
      name: 'Invoice Number',
      classes: 'min-w-[150px]',
      selector: (row) => <Basic data={row.invoice_number ? row.invoice_number : '-'} />,
    },
    {
      id: 7,
      name: 'Invoice Status',
      classes: 'capitalize min-w-[150px]',
      selector: (row) => <StatusCell data={row.status} config={stateCellConfig} className={'font-medium'} />,
    },
    {
      id: 8,
      name: 'Finance Note',
      classes: 'min-w-[250px] max-w-[350px]',
      selector: (row) => <Basic data={row.finance_review_note || '-'} />,
    },
    {
      id: 9,
      name: 'Paid Amount',
      classes: 'min-w-[150px]',
      selector: (row) => (
        <Basic
          data={
            !row.paid_amount
              ? '-'
              : row.paid_amount < 0
              ? `- Rp${formatThousands(Math.abs(row.paid_amount))}`
              : `Rp${formatThousands(row.paid_amount || 0)}`
          }
        />
      ),
    },
    {
      id: 10,
      name: 'Invoice Returned Number',
      classes: 'min-w-[150px]',
      selector: (row) => <Basic data={row.invoice_returned_number || '-'} />,
    },
    // isAE && {
    //   id: 7,
    //   name: 'Action',
    //   classes: 'capitalize',
    //   selector: (row) => <Button icon={<Description sx={{ fontSize: 20 }} />}>Send Request</Button>,
    // },
  ])

  const invoices = useQuery({
    queryKey: ['sales-deal', id, 'invoice'],
    queryFn: async () => {
      const res = await SalesDealAPI.getSalesDealInvoice(id)
      // console.log(res)

      return res.data
    },
  })

  const invoiceMutation = useMutation({
    mutationFn: async (id) => {
      var res = await InvoiceAPI.sendRequestInvoice(id)
      return res
    },
    onSuccess: async (data) => {
      // console.log(data)
      if (!data.success) {
        toast.error(data.message)
        toast.error('Please verify that the invoice date was filled')
        return
      }
      toast.success(data.message)
      queryClient.invalidateQueries(['sales-deal', id, 'invoice'])
    },
    onError: async (err) => {
      if (err.response) {
        toast.error(err.response.data.message)
      } else {
        toast.error(err.statusText)
      }
    },
  })

  return (
    <>
      <div className="flex gap-4 mb-8">
        {hasPermission(user, 'salesDeal.invoice.change', {
          salesDealStatus: salesDeal.status,
          projectStatus: salesDeal.project.status,
        }) && (
          <Link to={`/sales-deal/${id}/request-invoice`}>
            <Button variant="outline">
              <SquarePen className="w-5 h-5" /> Change Invoice
            </Button>
          </Link>
        )}
        {hasPermission(user, 'salesDeal.invoice.sync') && (
          <Button variant="outline" onClick={() => toast.error('This feature is under development.')}>
            <RefreshCw className="w-5 h-5" /> Sync Invoice with CC
          </Button>
        )}
      </div>

      {invoices.isLoading ? (
        <Loading />
      ) : (
        <div className="md:col-span-2 lg:col-span-3">
          <Table
            tableType="inline"
            data={invoices.data}
            cols={invoiceCols}
            noIndex={true}
            customAction={(row) => (
              <>
                {row.status === 'pending' && isAE ? (
                  <Button onClick={() => invoiceMutation.mutate(row.id)}>Request Review</Button>
                ) : null}
              </>
            )}
          />
        </div>
      )}
    </>
  )
}
