import React, { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { dotsLimiter, formatThousands } from '../../helper'
import Status from '../Status'
import Loading from '../Loading'
import SalesPlanAPI from '../../apis/SalesPlanAPI'
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import usePermission from '../../hooks/usePermission'
import { cn } from '@/lib/utils'
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import { useQueries, useQuery } from '@tanstack/react-query'
import { Button } from '../ui/button'
import { Avatar, AvatarImage } from '../ui/avatar'
import {
  BriefcaseBusiness,
  Circle,
  CircleDollarSign,
  Edit,
  FolderOpen,
  Paperclip,
  Trash,
  Trash2,
  User,
} from 'lucide-react'
import { Badge } from '../ui/badge'

// const SalesPlanModal = ({ setIsOpen, salesPlanId, setRefreshDOM }) => {
//   const [salesPlan, setSalesPlan] = useState()
//   const [isLoading, setLoading] = useState(true)
//   const [isLoadingDelete, setLoadingDelete] = useState(false)
//   const navigate = useNavigate()
//   const { isAE, isAdmin } = usePermission()

//   useEffect(() => {
//     SalesPlanAPI.getSingleSalesPlan(salesPlanId).then((res) => {
//       setSalesPlan(res.data)
//       console.log(res.data)
//       setLoading(false)
//     })
//   }, [])

//   const handleDelete = () => {
//     setLoadingDelete(true)
//     SalesPlanAPI.deleteSalesPlan(salesPlanId).then((res) => {
//       if (res.success) {
//         setRefreshDOM(Math.random)
//         setLoadingDelete(false)
//       }
//     })
//   }

//   return (
//     <>
//       <div
//         className="bg-black bg-opacity-20 z-10 w-screen h-screen top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute"
//         onClick={() => setIsOpen(false)}
//       />
//       <div className="fixed z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full px-2">
//         <div className="w-full md:w-[600px] rounded bg-white shadow-lg z-20 mx-auto">
//           {isLoading ? (
//             <div className="h-[300px] py-8 flex justify-center">
//               <Loading />
//             </div>
//           ) : (
//             <>
//               <div className="p-4 overflow-hidden rounded-t border-b flex justify-between items-center mb-2.5">
//                 <h5 className="text-big">Sales Plan - {moment(salesPlan.meeting_date).utc().format('D MMMM YYYY')}</h5>
//                 <button
//                   className="w-6 h-6 flex items-center justify-center rounded text-gray-400 hover:bg-red-200 hover:text-red-500"
//                   onClick={() => setIsOpen(false)}
//                 >
//                   <Close sx={{ fontSize: 16 }} />
//                 </button>
//               </div>
//               <div className="p-4 grid grid-cols-2 gap-4 mb-2.5">
//                 <div>
//                   <p className="font-semibold mb-0.5">Meeting Date</p>
//                   <p className="font-normal">{moment(salesPlan.meeting_date).utc().format('dddd, D MMMM YYYY')}</p>
//                 </div>
//                 <div>
//                   <p className="font-semibold mb-0.5">Value</p>
//                   <p className="font-normal">Rp{formatThousands(salesPlan.project.value)}</p>
//                 </div>
//                 <div>
//                   <p className="font-semibold mb-0.5">Client</p>
//                   <p className="font-normal">{salesPlan.client.company_name}</p>
//                 </div>
//                 <div>
//                   <p className="font-semibold mb-0.5">Start Date</p>
//                   <p className="font-normal">{moment(salesPlan.sales_deal.created_at).utc().format('D MMMM YYYY')}</p>
//                 </div>
//                 <div>
//                   <p className="font-semibold mb-0.5">Project Type</p>
//                   <p className="font-normal capitalize">{salesPlan.project.type}</p>
//                 </div>
//                 <div>
//                   <p className="font-semibold mb-0.5">Expired Date</p>
//                   <p className="font-normal">
//                     {salesPlan.sales_deal.expired_date
//                       ? moment(salesPlan.sales_deal.expired_date).utc().format('D MMMM YYYY')
//                       : '-'}
//                   </p>
//                 </div>
//                 <div>
//                   <p className="font-semibold mb-0.5">Project Name</p>
//                   <p className="font-normal">{salesPlan.project.name}</p>
//                 </div>
//                 <div>
//                   <p className="font-semibold mb-0.5">Number Of Meetings</p>
//                   <p className="font-normal">{salesPlan.number_of_meetings}</p>
//                 </div>
//                 <div>
//                   <p className="font-semibold mb-1">Sales Deal Status</p>
//                   <div className="font-normal">{<Status size="small" type={salesPlan.sales_deal.status} />}</div>
//                 </div>
//                 <div>
//                   <p className="font-semibold mb-1">Account Owner</p>
//                   <div className="font-normal flex gap-1 items-center">
//                     <span className="w-6 h-6 rounded-full bg-slate-100">
//                       <img src={salesPlan.account_owner.avatar} alt="avatar" className="w-full" />
//                     </span>
//                     <p>{salesPlan.account_owner.name}</p>
//                   </div>
//                 </div>
//               </div>

//               <div className="flex justify-between gap-2.5 p-4 pt-0">
//                 <div>
//                   {(isAE || isAdmin) && (
//                     <>
//                       {salesPlan.mom_status === 'empty' ? (
//                         <>
//                           <Button href={`${salesPlan.id}/add-mom`} className={'mr-2'}>
//                             Add MoM
//                           </Button>
//                           <Button variant="outlined" onClick={handleDelete} isLoading={isLoadingDelete}>
//                             Delete
//                           </Button>
//                         </>
//                       ) : (
//                         <>
//                           <Button href={`${salesPlan.id}/edit-mom`} className={'mr-2'}>
//                             Edit MoM
//                           </Button>
//                         </>
//                       )}
//                     </>
//                   )}
//                 </div>
//                 <div>
//                   <Button href={`/sales-deal/${salesPlan.sales_deal.id}`} variant="outlined">
//                     Deal Details
//                   </Button>
//                 </div>
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//     </>
//   )
// }

export default function Item({ plan, index, setRefreshDOM, className }) {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoadingDelete, setLoadingDelete] = useState(false)
  const { isAE, isAdmin, isVP, isSM } = usePermission()

  const salesPlan = useQuery({
    queryFn: () => SalesPlanAPI.getSingleSalesPlan(plan.id),
    queryKey: ['sales-plan', plan.id],
    enabled: isOpen,
  })

  console.log(salesPlan.data)

  // useEffect(() => {
  //   if (!salesPlan.isLoading) console.log(salesPlan)
  //   console.log(plan)
  //   console.log('hi')
  // }, [salesPlan])

  const handleDelete = () => {
    setLoadingDelete(true)
    SalesPlanAPI.deleteSalesPlan(salesPlan.data.data.id).then((res) => {
      if (res.success) {
        setRefreshDOM(Math.random)
        setLoadingDelete(false)
      }
    })
  }

  return (
    <Draggable draggableId={`${plan.id}`} index={index}>
      {(provided) => (
        <div
          className={cn('rounded-xl p-4 shadow bg-white hover:bg-sky-50 transition-all', className)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Sheet open={isOpen} onOpenChange={setIsOpen}>
            <SheetTrigger asChild>
              <div>
                <p className="text-big mb-2 font-bold">{plan.client.name}</p>
                <div className="flex gap-2 items-center text-gray-text mb-2">
                  <FolderOpen className="w-4 h-4" />
                  <p className="">{dotsLimiter(plan.project.name, 30)}</p>
                </div>
                <div className="flex gap-2 items-center text-gray-text mb-2">
                  <CircleDollarSign className="w-4 h-4" />
                  <p>Rp{formatThousands(plan.project_value)}</p>
                </div>
                <div className="flex gap-2 items-center text-gray-text mb-2">
                  <User className="w-4 h-4" />
                  <p>Rp{formatThousands(plan.project_value)}</p>
                </div>
                <div className="flex items-center justify-between mt-4">
                  <Status size={'small'} type={plan.sales_deal_status} />
                  {(isAE || isAdmin) && (
                    <>
                      {plan.mom_status === 'filled' ? (
                        <Badge
                          asChild
                          size="xs"
                          className={
                            'bg-primary-green text-white ring-2 ring-primary-green border-0 hover:bg-primary-green hover:text-white'
                          }
                          // onClick={() => window.alert('click')}
                          disabled
                        >
                          Mom Added
                        </Badge>
                      ) : (
                        <Link to={`${plan.id}/add-mom`}>
                          <Button variant="outline" size="xs" className="px-3">
                            <Paperclip className="w-4 h-4" />
                            Add MoM
                          </Button>
                        </Link>
                      )}
                    </>
                  )}
                  {(isSM || isVP) && (
                    <>
                      {plan.mom_status === 'filled' ? (
                        <Badge
                          asChild
                          size="xs"
                          className={
                            'bg-primary-green text-white ring-2 ring-primary-green border-0 hover:bg-primary-green hover:text-white'
                          }
                          // onClick={() => window.alert('click')}
                          disabled
                        >
                          Mom Added
                        </Badge>
                      ) : (
                        <Badge
                          asChild
                          size="xs"
                          className={''}
                          variant={'destructive'}
                          // onClick={() => window.alert('click')}
                          disabled
                        >
                          No MoM Yet
                        </Badge>
                      )}
                    </>
                  )}
                </div>
              </div>
            </SheetTrigger>
            <SheetContent className="sm:max-w-[750px]">
              <SheetHeader>
                <SheetTitle className="text-xl">Sales Plan Details</SheetTitle>
                {/* <SheetDescription>
            This action cannot be undone. This will permanently delete your account and remove your data from our
            servers.
          </SheetDescription> */}
              </SheetHeader>
              {salesPlan.isLoading ? (
                <Loading />
              ) : (
                <>
                  <div className="grid grid-cols-2 py-8 gap-4 mb-2.5 text-sm">
                    <div>
                      <p className="font-semibold mb-1">Meeting Date</p>
                      <p className="font-normal text-main-500/90">
                        {moment(salesPlan.data.data.meeting_date).utc().format('dddd, D MMMM YYYY')}
                      </p>
                    </div>
                    <div>
                      <p className="font-semibold mb-1">Client</p>
                      <p className="font-normal text-main-500/90">{salesPlan.data.data.client.company_name}</p>
                    </div>
                    <div>
                      <p className="font-semibold mb-1">Project Type</p>
                      <p className="font-normal text-main-500/90 capitalize">
                        {salesPlan.data.data.project.type.replace('_', ' ')}
                      </p>
                    </div>
                    <div>
                      <p className="font-semibold mb-1">Account Owner</p>
                      <div className="font-normal text-main-500/90 flex gap-1 items-center">
                        <Avatar className="w-8 h-8">
                          <AvatarImage src={salesPlan.data.data.account_owner.avatar} alt="avatar"></AvatarImage>
                        </Avatar>
                        <p>{salesPlan.data.data.account_owner.name}</p>
                      </div>
                    </div>
                    <div>
                      <p className="font-semibold mb-1">Value</p>
                      <p className="font-normal text-main-500/90">
                        Rp{formatThousands(salesPlan.data.data.project.total_revenue)}
                      </p>
                    </div>
                    <div>
                      <p className="font-semibold mb-1">Start Date</p>
                      <p className="font-normal text-main-500/90">
                        {moment(salesPlan.data.data.sales_deal.created_at).utc().format('D MMMM YYYY')}
                      </p>
                    </div>
                    <div>
                      <p className="font-semibold mb-1">Expired Date</p>
                      <p className="font-normal text-main-500/90">
                        {salesPlan.data.data.sales_deal.expired_date
                          ? moment(salesPlan.data.data.sales_deal.expired_date).utc().format('D MMMM YYYY')
                          : '-'}
                      </p>
                    </div>
                    <div>
                      <p className="font-semibold mb-1">Project Name</p>
                      <p className="font-normal text-main-500/90">{salesPlan.data.data.project.name}</p>
                    </div>
                    <div>
                      <p className="font-semibold mb-1">Number Of Meetings</p>
                      <p className="font-normal text-main-500/90">{salesPlan.data.data.number_of_meetings}</p>
                    </div>
                    <div>
                      <p className="font-semibold mb-1">Sales Deal Status</p>
                      <div className="font-normal text-main-500/90">
                        {<Status size="small" type={salesPlan.data.data.sales_deal.status} />}
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4 w-full">
                    {(isAE || isAdmin) && (
                      <>
                        {salesPlan.data.data.mom_status === 'empty' ? (
                          <>
                            <Button
                              variant="outline"
                              onClick={handleDelete}
                              isLoading={isLoadingDelete}
                              className="flex-1"
                            >
                              <Trash2 className="w-5 h-5" />
                              Delete
                            </Button>
                            <Link to={`/sales-deal/${salesPlan.data.data.sales_deal.id}`} className="flex-1">
                              <Button variant="outline" className="w-full">
                                <BriefcaseBusiness className="w-5 h-5" />
                                See Details
                              </Button>
                            </Link>
                            <Link to={`${salesPlan.data.data.id}/add-mom`} className="flex-1">
                              <Button className="w-full">
                                <Paperclip className="w-5 h-5" />
                                Add MoM
                              </Button>
                            </Link>
                          </>
                        ) : (
                          <>
                            <Link to={`/sales-deal/${salesPlan.data.data.sales_deal.id}`} className="flex-1">
                              <Button variant="outline" isLoading={isLoadingDelete} className={'w-full'}>
                                <BriefcaseBusiness className="w-5 h-5" />
                                See Details
                              </Button>
                            </Link>
                            <Link to={`${salesPlan.data.data.id}/edit-mom`} className="flex-1">
                              <Button className={'w-full'}>
                                <Edit className="w-5 h-5" />
                                Edit MoM
                              </Button>
                            </Link>
                          </>
                        )}
                      </>
                    )}

                    {(isSM || isVP) && (
                      <Link to={`/sales-deal/${salesPlan.data.data.sales_deal.id}`} className="flex-1">
                        <Button variant="outline" className="w-full">
                          <BriefcaseBusiness className="w-5 h-5" />
                          See Details
                        </Button>
                      </Link>
                    )}
                  </div>
                </>
              )}
            </SheetContent>
          </Sheet>
        </div>
      )}
    </Draggable>
  )
}
