import React from 'react'
import { cn } from '@/lib/utils'
import DiscountTypeMenu from './DiscountTypeMenu'

export default function DiscountInput({
  label,
  onChange,
  className,
  value,
  name,
  error,
  onChangeType,
  discountType,
  ...props
}) {
  return (
    <div
      className={cn(
        `flex rounded-lg border w-full transition-all focus-within:ring-4 focus-within:ring-secondary-blue focus-within:outline-none focus:border-main-500 overflow-hidden`,
        error ? 'border-red-500' : 'border-primary-gray',
        className
      )}
    >
      <DiscountTypeMenu onChangeType={onChangeType} discountType={discountType || 'percentage'} />
      <input
        type={'number'}
        value={discountType === 'absolute' ? Number(value).toString() : value}
        name={name}
        className={`px-3 py-3 outline-none rounded-[5px] flex-1`}
        onChange={(e) => onChange(e)}
        {...props}
      />
    </div>
  )
}
