import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../components/BreadCrumbs'
import Input from '../../components/Input'
import DropdownInput from '../../components/DropdownInput'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../../components/Button'
import Alert from '../../components/Alert'
import AccountAPI from '../../apis/AccountAPI'
import SBUAPI from '../../apis/SBUAPI'
import { toast } from 'react-hot-toast'
import DismissableToast from '../../components/Toast'
import { sleep } from '../../helper'

export default function EditSBU() {
  const { id } = useParams()
  const [listVP, setListVP] = useState([])
  const [selectedVP, setSelectedVP] = useState()
  const [errorSaveSBU, setErrorSaveSBU] = useState()
  const [isLoadingSaveSBU, setLoadingSaveSBU] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        AccountAPI.getList('vp').then((res) => {
          if (res.success) {
            setListVP(res.data)
            resolve(res.data)
          }
        })
      }),
      new Promise((resolve, reject) => {
        SBUAPI.getAllSBU(id).then((res) => {
          resolve(res.data[0])
        })
      }),
    ]

    Promise.all(promises).then((res) => {
      console.log(res[0])
      let tempVp = res[0].find((account) => account.sbu_id === res[1].sbu_id)
      setSelectedVP(tempVp)
      setLoading(false)
    })
  }, [id])

  const handleSaveSbu = () => {
    setLoadingSaveSBU(true)
    if (selectedVP) {
      AccountAPI.updateVPAccount({ sbu_id: id, vp_id: selectedVP.id })
        .then((res) => {
          if (res.success) {
            toast.success('Update SBU success')
            sleep(3000).then((res) => {
              navigate(-1)
            })
          }
        })
        .catch((err) => {
          if (err.response) {
            setErrorSaveSBU(err.response.data.message)
            toast.error(err.response.data.message)
          } else {
            setErrorSaveSBU(err.statusText)
            toast.error(err.statusText)
          }
          setLoadingSaveSBU(false)
        })
    } else {
      setErrorSaveSBU('Lengkapi data terlebih dahulu!')
      setLoadingSaveSBU(false)
    }
  }

  return (
    <div className="py-4">
      <DismissableToast />

      <h1 className="font-semibold text-giant mb-4">Edit SBU</h1>
      <div className="p-4 border rounded-[5px] mb-4">
        <p className="font-semibold mb-1">SBU</p>
        <p className="mb-4"></p>
        <div className="w-[400px]">
          <p className="font-bold mb-1">Vice President</p>
          <DropdownInput
            data={listVP}
            value={selectedVP}
            handleSelectedOption={(selected) => setSelectedVP(selected)}
            placeholder="Pilih User"
            attributeShown="name"
          />
        </div>
      </div>
      <div className="flex gap-4">
        <Button onClick={handleSaveSbu} isLoading={isLoadingSaveSBU}>
          Save
        </Button>
        {errorSaveSBU && <Alert type={'error'} message={errorSaveSBU} />}
      </div>
    </div>
  )
}
