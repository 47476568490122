import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import BreadCrumbs from '../../components/BreadCrumbs'
import Loading from '../../components/Loading'
import Input from '../../components/Input'
import Textarea from '../../components/Textarea'
import GroupButtons from '../../components/GroupButtons'
import DropdownInput from '../../components/DropdownInput'
// import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import RadioButton from '../../components/RadioButton'
import Table from '../../components/table/Table'
import ProjectAPI from '../../apis/ProjectAPI'
import { ProductsAPI } from '../../apis/ProductsAPI'
import LogoCell from '../../components/table/cell/LogoCell'
import Basic from '../../components/table/cell/Basic'
import ActionCell from '../../components/table/cell/ActionCell'
import { formatThousands } from '../../helper'
import { COGSitemAPI } from '../../apis/COGSitemsAPI'
import { ArrowForward, ArrowRight, ArrowRightAlt, Delete, KeyboardArrowRight } from '@mui/icons-material'
import CustomTextDropdownInput from '../../components/CustomTextDropdownInput'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import Status from '../../components/Status'
import moment from 'moment'
import StatusCell from '../../components/table/cell/StatusCell'
import RevenueItemsTable from '../../components/RevenueItemsTable'
import COGSItemsTable from '../../components/COGSItemsTable'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { SquarePen } from 'lucide-react'
import { Button } from '@/components/ui/button'
import usePermission from '@/hooks/usePermission'

export default function ViewProject() {
  const { id } = useParams()
  const { isAE, isSM, isVP } = usePermission()
  const [project, setProject] = useState({})
  const [updatedProject, setUpdatedProject] = useState({})
  const [stateCellConfig, setCellConfig] = useState([
    {
      text: 'Approved',
      status: 'approved',
      state: 'success',
    },
    {
      text: 'Pending',
      status: 'pending',
      state: 'default',
    },
    {
      text: 'Rejected',
      status: 'rejected',
      state: 'danger',
    },
    {
      text: 'Need Revision',
      status: 'need_revision',
      state: 'warning-urgent',
    },
    {
      text: 'In Review - VP',
      state: 'warning',
      status: 'in_review_vp',
    },
    {
      text: 'In Review - SM',
      state: 'warning',
      status: 'in_review_sm',
    },
  ])
  const [isLoading, setLoading] = useState(true)
  const [review, setReview] = useState({ notes: '', results: null })
  const [revenueCols, setRevenueCols] = useState([
    {
      id: 0,
      name: 'Product',
      classes: 'min-w-[200px]',
    },
    {
      id: 1,
      name: 'Variant',
      classes: 'w-[320px]',
    },
    {
      id: 2,
      name: 'Price',
      classes: 'text-right min-w-[200px]',
    },
    {
      id: 3,
      name: 'Quantity',
      classes: 'text-right w-[100px]',
    },
    {
      id: 4,
      name: 'Discount',
      classes: 'text-right',
    },
    {
      id: 5,
      name: 'Total Price',
      classes: 'text-right min-w-[200px]',
    },
  ])
  const [cogsCols, setCOGSCols] = useState([
    {
      id: 0,
      name: 'Item',
      classes: 'min-w-[250px]',
      selector: (row) => <Basic data={row.cogs_item_name} />,
    },
    {
      id: 1,
      name: 'Cost',
      classes: 'text-right min-w-[200px]',
      selector: (row) => <Basic data={`Rp${formatThousands(row.cost)}`} alignment={'right'} />,
    },
    {
      id: 2,
      name: 'Quantity',
      classes: 'text-right',
      selector: (row) => <Basic alignment={'right'} data={row.quantity} />,
    },
    {
      id: 3,
      name: 'COGS',
      classes: 'text-right min-w-[200px]',
      selector: (row) => <Basic data={row.current_cost} alignment={'right'} />,
    },
    // {
    //   id: 4,
    //   name: 'COGS%',
    //   classes: 'text-right min-w-[100px]',
    //   selector: (row) => <Basic data={`??????`} alignment={'right'} />,
    // },
  ])
  const [marginCols, setMarginCols] = useState([
    { id: 0, name: 'Component', selector: (row) => <Basic data={row.row} />, classes: 'w-[180px] font-semibold' },
    {
      id: 1,
      name: 'Total Amount',
      selector: (row) => <Basic data={`Rp${formatThousands(row.totalAmount)}`} alignment={'right'} />,
      classes: 'w-[180px]  text-right',
    },
    {
      id: 2,
      name: 'Percentage',
      selector: (row) => <Basic data={`${(row.percentage * 100).toFixed(2)}%`} alignment={'right'} />,
      classes: 'w-[150px] text-right',
    },
    {
      id: 3,
      name: 'Total Amount Realization',
      selector: (row) => <Basic data={`Rp${row.totalAmountRealization}`} alignment={'right'} />,
      classes: 'w-[180px] text-right text-red-500',
    },
    {
      id: 4,
      name: 'Percentage',
      selector: (row) => (
        <Basic
          data={`${(row.percentage_realization * 100).toFixed(2)}%`}
          alignment={'right'}
          // withIcon={row.row === 'COGS'}
        />
      ),
      classes: 'w-[150px] text-right text-red-500',
    },
  ])
  const [listTopic, setListTopic] = useState([
    { id: 'green', topic: 'Green' },
    { id: 'non_green', topic: 'Non-green' },
  ])
  const navigate = useNavigate()

  // useEffect(() => {
  //   console.log(updatedProject)
  //   console.log('here')
  // }, [updatedProject])

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        ProjectAPI.getProjectById(id).then((res) => {
          let totalCOGSRealization = 0

          res.data.revenue_items.forEach((product) => {
            product.cogs_items.forEach((item) => {
              totalCOGSRealization += item.realization_amount
            })
          })

          console.log(((res.data.total_cogs / res.data.total_revenue) * 100).toFixed(2))

          setProject({ ...res.data, totalCogsRealization: totalCOGSRealization })
          setUpdatedProject({ ...res.data, totalCogsRealization: totalCOGSRealization })
          resolve()
        })
      }),
    ]
    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [id])

  const countMargin = () => {
    if (!updatedProject.total_revenue && !updatedProject.total_cogs) {
      return '0%'
    } else if (updatedProject.total_cogs > updatedProject.total_revenue) {
      return 'ERROR!!!'
    } else {
      return `${(100 - (updatedProject.total_cogs / updatedProject.total_revenue) * 100).toFixed(2)}%`
    }
  }

  return (
    <div className="py-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="mb-4">
            <BreadCrumbs>
              <BreadCrumbs.Item href={'/projects'}>Project</BreadCrumbs.Item>
              <BreadCrumbs.Item current>{project.name}</BreadCrumbs.Item>
            </BreadCrumbs>
          </div>
          <div className="mb-12">
            <div className="flex gap-4 items-center mb-4">
              <h1 className="font-semibold text-giant">Review Project</h1>
              {isAE && updatedProject.status === 'pending' && (
                <Link to={`/projects/edit/s/${id}`}>
                  <Button variant={'outline'} size={'icon'}>
                    <SquarePen />
                  </Button>
                </Link>
              )}
              {isSM && ['in_review_sm', 'need_revision', 'approved'].includes(updatedProject.status) && (
                <Link to={`/projects/review/${id}`}>
                  <Button variant={'outline'} size={'icon'}>
                    <SquarePen />
                  </Button>
                </Link>
              )}
              {isVP && ['in_review_vp', 'approved'].includes(updatedProject.status) && (
                <Link to={`/projects/review/${id}`}>
                  <Button variant={'outline'} size={'icon'}>
                    <SquarePen />
                  </Button>
                </Link>
              )}
            </div>
            <div className="flex w-full">
              <div className="p-4 flex-1 flex gap-16 border border-gray-border rounded-[5px] ">
                <div className="min-w-[350px] max-w-[500px] grid gap-4 h-fit">
                  <div>
                    <p className="font-semibold mb-1">Project Name</p>
                    <p className="">{updatedProject.name}</p>
                  </div>

                  <div>
                    <p className="font-semibold mb-1">Description</p>
                    <p className="">{updatedProject.description || '-'}</p>
                  </div>

                  <div className="">
                    <p className="font-semibold mb-1">Client</p>
                    <span className="flex items-center gap-2">
                      <Avatar className="rounded w-9 h-9">
                        <AvatarImage src={updatedProject.client.logo} />
                        <AvatarFallback>KD</AvatarFallback>
                      </Avatar>
                      <p className="capitalize">{updatedProject.client.company_name}</p>
                    </span>
                  </div>
                  <div className="">
                    <p className="font-semibold mb-1">Project Type</p>
                    <p className="capitalize">{updatedProject.type.replace('_', ' ')}</p>
                  </div>
                  <div>
                    <p className="font-semibold mb-2">Project Status</p>
                    <StatusCell config={stateCellConfig} data={updatedProject.status} className={'font-medium'} />
                  </div>
                </div>
                <div className="w-[200px] grid gap-4 h-fit">
                  <div className="">
                    <p className="font-semibold mb-1">Start Date</p>
                    <p className="">{moment(updatedProject.sales_deal.created_at).format('D MMMM YYYY')}</p>
                  </div>
                  <div className="">
                    <p className="font-semibold mb-1">Expired Date</p>
                    <p className="">
                      {updatedProject.sales_deal.expired_date
                        ? moment(updatedProject.sales_deal.expired_date).format('D MMMM YYYY')
                        : '-'}
                    </p>
                  </div>

                  <div className="">
                    <p className="font-semibold mb-1">Number of Meetings</p>
                    <p className="">{updatedProject.number_of_meeting || 0} meetings</p>
                  </div>
                  <div className="">
                    <p className="font-semibold mb-1">Account Owner</p>
                    <div className="font-normal flex gap-1 items-center">
                      <Avatar className="w-8 h-8">
                        <AvatarImage src={updatedProject.account.avatar} alt="avatar" className="w-full" />
                        <AvatarFallback>KD</AvatarFallback>
                      </Avatar>
                      <p>{updatedProject.account.name}</p>
                    </div>
                  </div>
                  <div className="">
                    <p className="font-semibold mb-2">Sales Deal Status</p>
                    <Status type={updatedProject.sales_deal.status} size={'small'} />
                  </div>

                  <div className="">
                    <p className="font-semibold mb-1">Sales Deal</p>
                    <Link
                      to={`/sales-deal/${updatedProject.sales_deal_id}`}
                      className="hover:underline hover:underline-offset-1"
                    >
                      See Detail{' '}
                      <span className="w-4 h-4">
                        <ArrowForward sx={{ fontSize: 16 }} />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <RevenueItemsTable project={updatedProject} setProject={setUpdatedProject} isEditable={false} />
          <COGSItemsTable project={updatedProject} setProject={setUpdatedProject} isEditable={false} />
          <div className="mb-12">
            <table></table>
            <h1 className="text-giant mb-4 font-semibold">Margin</h1>
            <Table
              tableType="inline"
              noIndex={true}
              cols={marginCols}
              data={[
                {
                  id: 0,
                  row: 'Revenue',
                  totalAmount: updatedProject.total_revenue,
                  percentage:
                    updatedProject.total_revenue > 0 ? 1 - updatedProject.total_cogs / updatedProject.total_revenue : 0,
                  totalAmountRealization: 0,
                  percentage_realization: 0,
                },
                {
                  id: 1,
                  row: 'COGS',
                  totalAmount: updatedProject.total_cogs,
                  percentage:
                    updatedProject.total_revenue > 0 ? updatedProject.total_cogs / updatedProject.total_revenue : 0,
                  totalAmountRealization: updatedProject.totalCogsRealization,
                  percentage_realization: updatedProject.totalCogsRealization / updatedProject.total_cogs,
                },
              ]}
            />
          </div>
        </>
      )}
    </div>
  )
}
