'use client'

import * as React from 'react'
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group'
import { Label } from '@/components/ui/label'
import { cn } from '@/lib/utils'

export default function GroupButton({ items, defaultValue, onValueChange, className, ...props }) {
  const [value, setValue] = React.useState(defaultValue || items[0].value)

  const handleValueChange = (newValue) => {
    if (newValue) {
      setValue(newValue)
      onValueChange(newValue)
    }
  }
  return (
    <ToggleGroup
      type="single"
      value={value}
      onValueChange={handleValueChange}
      className={cn('rounded-lg overflow-hidden border-main-500 border', className)}
    >
      {/* <Label>Text Align (Single Selection)</Label> */}
      {items.map((item) => (
        <ToggleGroupItem
          key={item.value}
          value={item.value}
          aria-label={item.ariaLabel}
          className="w-full"
          variant="outline"
          disabled={item.disabled}
          // size="sm"
        >
          {item.label}
        </ToggleGroupItem>
      ))}
    </ToggleGroup>
  )
}
