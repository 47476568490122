import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../components/BreadCrumbs'
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import ReimbursementAPI from '../../apis/ReimbursementAPI'
import Loading from '../../components/Loading'
import moment from 'moment'
import { formatThousands } from '../../helper'
import Basic from '../../components/table/cell/Basic'
import Table from '../../components/table/Table'
import RadioButton from '../../components/RadioButton'
import Textarea from '../../components/Textarea'
import Button from '../../components/Button'
import StatusCell from '../../components/table/cell/StatusCell'

export default function ReimbursementDetails() {
  const { id } = useParams()
  const [reimbursement, setReimbursement] = useState()
  const [isLoading, setLoading] = useState(true)
  const [review, setReview] = useState()
  const navigate = useNavigate()
  const configStatusCell = [
    { status: 'default', text: 'Unknown Status', state: 'default' },
    { status: 'manager_review', text: 'Manager Review', state: 'warning' },
    { status: 'finance_review', text: 'Finance Review', state: 'warning' },
    { status: 'reimbursed', text: 'Reimbursed', state: 'success' },
    { status: 'rejected_by_manager', text: 'Rejected by Manager', state: 'danger' },
    { status: 'rejected_by_finance', text: 'Rejected by Finance', state: 'danger' },
  ]
  const [tableCols, setTableCols] = useState([
    {
      id: 0,
      name: 'Item Name',
      classes: 'min-w-[200px]',
      selector: (row) => <Basic data={row.item_name} />,
    },
    {
      id: 1,
      name: 'Price',
      classes: 'text-right',
      selector: (row) => <Basic data={`Rp${formatThousands(row.price)}`} alignment="right" />,
    },
    {
      id: 2,
      name: 'Receipt or Proof',
      classes: 'min-w-[200px]',
      selector: (row) => (
        <Basic
          data={
            <a href={row.receipt} target="_blank" className="underline text-slate-700 underline-offset-1">
              See File
            </a>
          }
        />
      ),
    },
  ])

  useEffect(() => {
    ReimbursementAPI.getSingle(id).then((res) => {
      setReimbursement(res.data)
      setReview({ ...review, id: id })
      setLoading(false)
      console.log(res.data)
    })
  }, [id])

  function handleChange(e) {
    setReview({ ...review, [e.target.name]: e.target.value })
  }

  function handleSubmitReview() {
    ReimbursementAPI.saveReview(review)
      .then((res) => {
        navigate(-1)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className="py-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="mb-8">
            <h1 className="font-semibold text-giant mb-4">Review Reimbursement</h1>
            <div className="rounded-[5px] border border-gray-border p-4 ">
              <div className="grid grid-cols-4 gap-16 w-fit">
                <div className="min-w-[125px]">
                  <div className="mb-8">
                    <h3 className="font-semibold mb-1">Meeting Date</h3>
                    <p className="">{moment(reimbursement.meeting_date).format('D MMMM YYYY')}</p>
                  </div>
                  <div className="mb-8">
                    <h3 className="font-semibold mb-1">MoM Saved At</h3>
                    <p className="">{moment(reimbursement.mom_saved_at).format('D MMMM YYYY, H:mm')}</p>
                  </div>
                  <div className="mb-8">
                    <h3 className="font-semibold mb-2">Status</h3>
                    <p className="">
                      <StatusCell data={reimbursement.reimbursement_status} config={configStatusCell} />
                    </p>
                  </div>
                </div>
                <div className="min-w-[125px]">
                  <div className="mb-8">
                    <h3 className="font-semibold mb-1">MoM</h3>
                    <p className="">{'See Details'}</p>
                  </div>
                  <div className="mb-8">
                    <h3 className="font-semibold mb-1">Client</h3>
                    <p className="">{reimbursement.client.company_name}</p>
                  </div>
                  <div className="mb-8">
                    <h3 className="font-semibold mb-1">Account Executive</h3>
                    <p className="">{reimbursement.account.name}</p>
                  </div>
                </div>
                <div className="min-w-[125px]">
                  <div className="mb-8">
                    <h3 className="font-semibold mb-1">Total Meeting Expenses</h3>
                    <p className="">{'Rp' + formatThousands(reimbursement.total_meeting_expenses)}</p>
                  </div>
                </div>
              </div>
              <Table cols={tableCols} data={reimbursement.meeting_expenses_items} tableType="inline" />
            </div>
          </div>
          {reimbursement.reimbursement_status != 'manager_review' ? (
            <div>
              <h1 className="font-semibold text-giant mb-4">Manager Review</h1>
              <div className="border border-gray-border rounded-[5px] p-4 mb-8">
                <div className="mb-8">
                  <h3 className="font-semibold mb-1">Review Date</h3>
                  <p className="">{moment(reimbursement.manager_review_timestamp).format('D MMMM YYYY, H:mm')}</p>
                </div>

                <div className="mb-8">
                  <h3 className="font-semibold mb-1">Result</h3>
                  <p className="">{reimbursement.manager_review_result}</p>
                </div>
                <div className="mb-8">
                  <h3 className="font-semibold mb-1">Note</h3>
                  <p className="">{reimbursement.manager_review_note}</p>
                </div>
              </div>
            </div>
          ) : null}

          {(reimbursement.reimbursement_status === 'reimbursed') |
          (reimbursement.reimbursement_status === 'rejected_by_finance') ? (
            <div>
              <h1 className="font-semibold text-giant mb-4">Finance Review</h1>
              <div className="border border-gray-border rounded-[5px] p-4 mb-4">
                <div className="mb-8">
                  <h3 className="font-semibold mb-1">Review Date</h3>
                  <p className="">{moment(reimbursement.manager_review_timestamp).format('D MMMM YYYY, H:mm')}</p>
                </div>

                <div className="mb-8">
                  <h3 className="font-semibold mb-1">Result</h3>
                  <p className="">{reimbursement.manager_review_result}</p>
                </div>
                <div className="mb-8">
                  <h3 className="font-semibold mb-1">Note</h3>
                  <p className="">{reimbursement.manager_review_note}</p>
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}
