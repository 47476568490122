import React, { useEffect, useState } from 'react'
import Loading from '../../components/Loading'
import SBUAPI from '../../apis/SBUAPI'
import AccountAPI from '../../apis/AccountAPI'
import Table from '../../components/table/Table'
import Basic from '../../components/table/cell/Basic'
import StatusCell from '../../components/table/cell/StatusCell'
import ProjectAPI from '../../apis/ProjectAPI'
import { formatThousands } from '../../helper'
import LogoCell from '../../components/table/cell/LogoCell'
import moment from 'moment/moment'
import usePermission from '../../hooks/usePermission'
import { Link, createSearchParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Pagination from '../../components/Pagination'
import { useCustomSearchParams } from '../../hooks/useCustomSearchParams'
import { Component, ListTodo, Search, SquarePen, Users, Copy } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { TooltipContent, TooltipProvider, TooltipTrigger, Tooltip } from '@/components/ui/tooltip'
import { Input } from '@/components/ui/input'
import { Combobox } from '@/components/Combobox'
import { hasPermission } from '@/lib/hasPermission'
import { useCopyToClipboard } from '@uidotdev/usehooks'
import toast from 'react-hot-toast'

const sortByDateUpdated = (arr) => {
  var temp = []
  let sortedArray = arr.sort(
    (a, b) => moment(b.updated_at).format('YYYYMMDDHHmmss') - moment(a.updated_at).format('YYYYMMDDHHmmss')
  )
  return sortedArray
}

export default function ProjectFeasibility() {
  const { user, isAdmin, isVP, isSM, isAE } = usePermission()
  const userData = useSelector((state) => state.auth.userData)
  const [searchParams, setSearchParams] = useCustomSearchParams()
  const navigate = useNavigate()
  const [pagination, setPagination] = useState({ total_page: 1 })
  const [isLoading, setLoading] = useState(true)
  const [listSBU, setListSBU] = useState([])
  const [listSM, setListSM] = useState([])
  const [listAE, setListAe] = useState([])
  const [status, setStatus] = useState([
    { id: 1, text: 'Approved', value: 'approved' },
    { id: 2, text: 'Pending', value: 'pending' },
    { id: 3, text: 'Rejected', value: 'rejected' },
    { id: 4, text: 'In Review SM', value: 'in_review_sm' },
    { id: 4, text: 'In Review VP', value: 'in_review_vp' },
    // { id: 5, text: 'Revenue', value: 'revenue' },
  ])
  const [stateCellConfig, setCellConfig] = useState([
    {
      text: 'Approved',
      status: 'approved',
      state: 'success',
    },
    {
      text: 'Pending',
      status: 'pending',
      state: 'default',
    },
    {
      text: 'Rejected',
      status: 'rejected',
      state: 'danger',
    },
    {
      text: 'Need Revision',
      status: 'need_revision',
      state: 'warning-urgent',
    },
    {
      text: 'In Review - VP',
      state: 'warning',
      status: 'in_review_vp',
    },
    {
      text: 'In Review - SM',
      state: 'warning',
      status: 'in_review_sm',
    },
  ])
  const [projects, setProjects] = useState([])

  const [copiedText, copyToClipboard] = useCopyToClipboard()
  const [projectTableCols, setProjectTableCols] = useState([
    {
      id: 0,
      name: 'SBU',
      classes: 'min-w-[200px]',
      selector: (row) => <Basic data={row.sbu_name} />,
    },
    {
      id: 1,
      name: 'Client',
      classes: 'min-w-[200px]',
      selector: (row) => (
        <LogoCell
          img={row.client_logo ? row.client_logo : `https://ui-avatars.com/api/?name=Kata+Data`}
          imgShape={'circle'}
          text={row.client_name}
        />
      ),
    },
    {
      id: 3,
      name: 'Account Executive',
      classes: 'min-w-[200px]',
      selector: (row) => <LogoCell img={row.account_avatar} imgShape={'circle'} text={row.account_name} />,
    },
    {
      id: 2,
      name: 'Project Name',
      classes: 'min-w-[170px]',
      selector: (row) => <Basic data={row.project_name} />,
    },
    {
      id: 25,
      name: 'Project ID',
      classes: 'min-w-[250px]',
      // selector: (row) => <Basic data={row?.cc_project_id || '-'} />,
      selector: (row) => (
        <Basic
          data={
            row?.cc_project_id ? (
              <span className="w-full flex items-center gap-2">
                {row.cc_project_id}

                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        size="xs"
                        variant="outline"
                        onClick={(e) => {
                          e.stopPropagation()
                          copyToClipboard(row.cc_project_id)
                          toast.success('The project ID was copied to the clipboard!')
                        }}
                      >
                        <Copy className="w-4 h-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Copy Project Id</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </span>
            ) : (
              '-'
            )
          }
        />
      ),
    },
    {
      id: 4,
      name: 'Status',
      classes: 'min-w-[150px]',
      selector: (row) => <StatusCell data={row.project_status} config={stateCellConfig} className={'font-medium'} />,
    },
    {
      id: 5,
      name: 'Total Revenue',
      classes: 'text-right min-w-[150px]',
      selector: (row) => <Basic alignment="right" data={`Rp${formatThousands(row.total_revenue)}`} />,
    },
    {
      id: 6,
      name: 'Total Cogs',
      classes: 'text-right  min-w-[150px]',
      selector: (row) => <Basic alignment="right" data={`Rp${formatThousands(row.total_cogs)}`} />,
    },
    {
      id: 7,
      name: 'Margin',
      classes: 'text-right  min-w-[150px]',
      selector: (row) => (
        <Basic
          alignment="right"
          data={`${row.total_revenue ? ((1 - row.total_cogs / row.total_revenue) * 100).toFixed(1) : 0}%`}
        />
      ),
    },
    {
      id: 8,
      name: 'COGS',
      classes: 'text-right  min-w-[150px]',
      selector: (row) => (
        <Basic
          alignment="right"
          data={`${row.total_revenue ? ((row.total_cogs / row.total_revenue) * 100).toFixed(1) : 0}%`}
        />
      ),
    },
  ])
  const [isLoadingProjects, setLoadingProject] = useState(true)
  const [isFilterChanged, setFilterChanged] = useState(false)

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        SBUAPI.getAllSBU().then((res) => {
          setListSBU([...res.data])
          resolve()
        })
      }),
      new Promise((resolve, reject) => {
        AccountAPI.getList('sm').then((res) => {
          setListSM([...res.data])
          resolve()
        })
      }),
      new Promise((resolve, reject) => {
        AccountAPI.getList('ae').then((res) => {
          setListAe([...res.data])
          resolve()
        })
      }),
    ]

    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    ProjectAPI.getAllProjects(
      searchParams.sbu,
      searchParams.sm,
      searchParams.ae,
      searchParams.status,
      undefined,
      searchParams.page,
      searchParams.q
    )
      .then((res) => {
        setProjects(res.data)
        setLoadingProject(false)
        setPagination(res.meta)
      })
      .catch((err) => {
        setLoadingProject(false)
      })
  }, [searchParams.page, searchParams.sbu, searchParams.sm, searchParams.ae, searchParams.status, searchParams.q])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      ProjectAPI.getAllProjects(
        searchParams.sbu,
        searchParams.sm,
        searchParams.ae,
        searchParams.status,
        undefined,
        searchParams.page,
        searchParams.q
      )
        .then((res) => {
          setProjects(res.data)
          setLoadingProject(false)
          setPagination(res.meta)
        })
        .catch((err) => {
          setLoadingProject(false)
        })
    }, 250)
    return () => clearTimeout(timeOutId)
  }, [searchParams.q])

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-col gap-2 xl:flex-row lg:gap-4 mb-4">
            <div className="w-[250px] relative">
              <Input
                value={searchParams.q}
                onChange={(e) => setSearchParams({ ...searchParams, q: e.target.value, page: 1 })}
                placeholder="Search project"
                className="pr-10"
              />
              <Search className="w-5 h-5 text-primary-gray absolute top-1/2 right-0 -translate-x-1/2 -translate-y-1/2" />
            </div>

            {hasPermission(user, 'project.feasibility.filter.sbu') && (
              <Combobox
                label={'SBU'}
                icon={<Component />}
                placeholder={'All'}
                className={'w-[250px] shrink-0'}
                options={listSBU.map((sbu) => ({ value: sbu.sbu_id, label: sbu.sbu_name }))}
                defaultValue={listSBU.find((sbu) => sbu.sbu_id === parseInt(searchParams.sbu))?.sbu_id || ''}
                onSelect={(selected) => setSearchParams({ ...searchParams, sbu: selected })}
              />
            )}

            {hasPermission(user, 'project.feasibility.filter.sm') && (
              <Combobox
                label={'SM'}
                icon={<Users />}
                placeholder={'All'}
                className={'w-[250px] shrink-0'}
                options={listSM.map((sm) => ({ value: sm.id, label: sm.name }))}
                defaultValue={listSM.find((sm) => sm.id === parseInt(searchParams.sm))?.id || ''}
                onSelect={(selected) => setSearchParams({ ...searchParams, sm: selected })}
              />
            )}

            {hasPermission(user, 'project.feasibility.filter.ae') && (
              <Combobox
                label={'AE'}
                icon={<Users />}
                placeholder={'All'}
                className={'w-[250px] shrink-0'}
                options={listAE.map((ae) => ({ value: ae.id, label: ae.name }))}
                defaultValue={listAE.find((ae) => ae.id === parseInt(searchParams.ae))?.id || ''}
                onSelect={(selected) => setSearchParams({ ...searchParams, ae: selected })}
              />
            )}

            <Combobox
              label={'Status'}
              icon={<ListTodo />}
              placeholder={'All'}
              className={'w-[250px] shrink-0'}
              options={status.map((stat) => ({ value: stat.value, label: stat.text }))}
              defaultValue={status.find((stat) => stat.value === searchParams.status)?.value || ''}
              onSelect={(selected) => setSearchParams({ ...searchParams, status: selected })}
            />
          </div>
          <div>
            <Table
              data={projects}
              cols={projectTableCols}
              viewLink={(row) => `view/${row.id}`}
              customAction={(row) => (
                <>
                  {hasPermission(user, 'project.feasibility.edit', row.project_status) && (
                    <Link
                      to={`/projects/edit/s/${row.id}`}
                      className="inline-flex w-8 h-8 items-center justify-center rounded text-main-100 hover:bg-sky-200 hover:text-main-500 transition-all"
                    >
                      <Button variant="outline" size="icon" className="shrink-0">
                        <SquarePen className="w-5 h-5" />
                      </Button>
                    </Link>
                  )}

                  <>
                    {hasPermission(user, 'project.feasibility.review', {
                      salesManagerId: row.sales_manager_id,
                      projectStatus: row.project_status,
                    }) && (
                      <Link to={`/projects/review/${row.id}`}>
                        <Button variant="outline" size="icon" className="shrink-0">
                          <SquarePen className="w-5 h-5" />
                        </Button>
                      </Link>
                    )}
                  </>
                </>
              )}
              tableType="inline"
              pageIndex={searchParams.page}
            />

            {/* {isAE ? (
              <Table
                data={projects}
                cols={projectTableCols}
                viewLink={(row) => `view/${row.id}`}
                customAction={(row) => (
                  <>
                    {row.project_status === 'pending' && (
                      <Link
                        to={`/projects/edit/s/${row.id}`}
                        className="inline-flex w-8 h-8 items-center justify-center rounded text-main-100 hover:bg-sky-200 hover:text-main-500 transition-all"
                      >
                        <Button variant="outline" size="icon" className="shrink-0">
                          <SquarePen className="w-5 h-5" />
                        </Button>
                      </Link>
                    )}
                  </>
                )}
                tableType="inline"
                pageIndex={searchParams.page}
              />
            ) : isSM ? (
              <Table
                data={projects}
                cols={projectTableCols}
                viewLink={(row) => `view/${row.id}`}
                customAction={(row) => (
                  <>
                    {['need_revision', 'approved', 'in_review_sm'].includes(row.project_status) &&
                      row.sales_manager_id === userData.id && (
                        <Link to={`/projects/review/${row.id}`}>
                          <Button variant="outline" size="icon" className="shrink-0">
                            <SquarePen className="w-5 h-5" />
                          </Button>
                        </Link>
                      )}
                  </>
                )}
                tableType="inline"
                pageIndex={searchParams.page}
              />
            ) : isVP ? (
              <Table
                data={projects}
                cols={projectTableCols}
                viewLink={(row) => `view/${row.id}`}
                customAction={(row) => (
                  <>
                    {['approved', 'in_review_vp'].includes(row.project_status) && (
                      <Link to={`/projects/review/${row.id}`}>
                        <Button variant="outline" size="icon" className="shrink-0">
                          <SquarePen className="w-5 h-5" />
                        </Button>
                      </Link>
                    )}
                  </>
                )}
                tableType="inline"
                pageIndex={searchParams.page}
              />
            )} */}
            <Pagination
              totalPage={pagination.total_page || 1}
              currentPage={parseInt(searchParams.page) || 1}
              onChange={(page) =>
                navigate({
                  pathname: '/projects',
                  search: `?${createSearchParams({ ...searchParams, page: page })}`,
                })
              }
            />
          </div>
        </>
      )}
    </div>
  )
}
