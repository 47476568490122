import React, { useEffect, useState } from 'react'
import Loading from '../components/Loading'
import ClientAPI from '../apis/ClientAPI'
import SalesDealAPI from '../apis/SalesDealAPI'
import DnD from '../components/dnd_sales_deal/DnD'
import moment from 'moment'
import SBUAPI from '../apis/SBUAPI'
import StatisticsAPI from '../apis/Statistics'
import DismissableToast from '../components/Toast'
import { toast } from 'react-hot-toast'
import ProjectAPI from '../apis/ProjectAPI'
import { useCustomSearchParams } from '../hooks/useCustomSearchParams'
import AccountAPI from '../apis/AccountAPI'
import { industry } from '../services/industry'
import { Combobox } from '@/components/Combobox'
import {
  Building,
  CircleDollarSign,
  Component,
  Factory,
  Filter,
  Flag,
  FolderDown,
  LeafyGreen,
  Users,
} from 'lucide-react'
import { DateRangePicker } from '@/components/DateRangePicker'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'
import { Button } from '@/components/ui/button'
import { Link, useLocation } from 'react-router-dom'
import { hasPermission } from '@/lib/hasPermission'
import usePermission from '@/hooks/usePermission'

const createDNDData = (data, date) => {
  const status = ['Visit', 'Leads', 'Hot Prospect', 'On Hands', 'Revenue']
  var res = {}
  for (let i = 0; i < status.length; i++) {
    var temp = data[status[i].replace(' ', '_').toLowerCase()]
    res = {
      ...res,
      [status[i].replace(' ', '_').toLowerCase()]: {
        id: status[i],
        list: temp,
      },
    }
  }
  // console.log(res)
  return res
}

export default function SalesDeal() {
  const [searchParams, setSearchParams] = useCustomSearchParams()
  const [isLoading, setLoading] = useState(true)
  const [listSBU, setListSBU] = useState([])
  const [listClient, setListClient] = useState([])
  const [listFlagshipProject, setListFlagshipProjects] = useState([])
  const [listAE, setListAE] = useState([])
  const [listTopic, setListTopic] = useState([
    { id: '', text: 'All Topic' },
    { id: 'green', text: 'Green' },
    { id: 'non_green', text: 'Non-green' },
  ])
  const [valueRange, setValueRange] = useState([
    // { id: 12, text: 'All Value', value_range_max: '', value_range_min: '' },
    { id: 1, text: 'Kurang dari Rp50.000.000', value_range_max: 50000000, value_range_min: 0 },
    { id: 2, text: 'Rp50.000.000 - Rp100.000.000', value_range_max: 100000000, value_range_min: 50000000 },
    { id: 3, text: 'Rp100.000.000 - Rp200.000.000', value_range_max: 200000000, value_range_min: 100000000 },
    { id: 4, text: 'Rp200.000.000 - Rp300.000.000', value_range_max: 300000000, value_range_min: 200000000 },
    { id: 5, text: 'Lebih dari Rp300.000.000', value_range_max: 100000000000, value_range_min: 300000000 },
  ])
  const [listIndustry, setListIndustry] = useState([{ id: '', industry: 'All Industry' }, ...industry])
  const [statistics, setStatistics] = useState()
  const [salesDeal, setSalesDeal] = useState()
  const [isUpdatingStatus, setUpdatingStatus] = useState(false)
  const [isLoadingFilterData, setLoadingFilterData] = useState(true)
  const { pathname } = useLocation()
  const { user } = usePermission()
  console.log(hasPermission(user, 'salesDeal.filter.ae'))

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        ClientAPI.getClientSimple()
          .then((res) => {
            setListClient(res.data)
          })
          .then((res) => resolve())
      }),
      new Promise((resolve, reject) => {
        SBUAPI.getAllSBU()
          .then((res) => {
            setListSBU([...res.data])
          })
          .then((res) => {
            resolve()
          })
      }),
      new Promise((resolve, reject) => {
        ProjectAPI.getAllFlagshipProject().then((res) => {
          if (res.success) {
            setListFlagshipProjects([{ id: -1, name: 'Non Flagship' }, ...res.data])
            resolve()
          }
        })
      }),
      new Promise((resolve, reject) => {
        AccountAPI.getListSimple('ae').then((res) => {
          setListAE(res.data)
          resolve()
        })
      }),
    ]

    Promise.all(promises).then((res) => {
      setLoadingFilterData(false)
    })
  }, [])

  useEffect(() => {
    // console.log(statistics)
  }, [statistics])

  useEffect(() => {
    if (!isLoadingFilterData) {
      var promises = [
        new Promise((resolve, reject) => {
          SalesDealAPI.getAllSalesDeal(
            searchParams.client || undefined,
            searchParams.sbu || undefined,
            searchParams.value_max || undefined,
            searchParams.value_min || undefined,
            searchParams.flagship || undefined,
            searchParams.start || undefined,
            searchParams.end || undefined,
            searchParams.industry || undefined,
            searchParams.topic || undefined,
            searchParams.ae || undefined
          ).then((res) => {
            setSalesDeal(createDNDData(res.data))
            resolve(createDNDData(res.data))
          })
        }),
        new Promise((resolve, reject) => {
          StatisticsAPI.getSalesDealStatistics(
            searchParams.client || undefined,
            searchParams.sbu || undefined,
            searchParams.value_max || undefined,
            searchParams.value_min || undefined,
            searchParams.flagship || undefined,
            searchParams.start || undefined,
            searchParams.end || undefined,
            searchParams.industry || undefined,
            searchParams.topic || undefined,
            searchParams.ae || undefined
          )
            .then((res) => {
              const status = ['visit', 'leads', 'hot_prospect', 'on-hands', 'revenue']
              var temp = {
                visit: { total_deal: 0, total_revenue: 0 },
                leads: { total_deal: 0, total_revenue: 0 },
                hot_prospect: { total_deal: 0, total_revenue: 0 },
                on_hands: { total_deal: 0, total_revenue: 0 },
                revenue: { total_deal: 0, total_revenue: 0 },
              }
              res.data.map((stats) => {
                temp[stats.status] = {
                  total_deal: stats.total_deal,
                  total_revenue: stats.total_revenue,
                }
              })
              setStatistics(temp)
            })
            .then((res) => {
              resolve()
            })
        }),
      ]
      Promise.all(promises).then((res) => {
        setLoading(false)
      })
    }
  }, [
    searchParams.client,
    searchParams.sbu,
    searchParams.value_max,
    searchParams.value_min,
    searchParams.flagship,
    searchParams.start,
    searchParams.end,
    searchParams.industry,
    searchParams.topic,
    searchParams.ae,
    isLoadingFilterData,
  ])

  const loadMoreItem = async (status, page) => {
    SalesDealAPI.getSalesDealByStatus(
      status,
      searchParams.client || undefined,
      searchParams.sbu || undefined,
      searchParams.value_max || undefined,
      searchParams.value_min || undefined,
      page
    ).then((res) => {
      var tempSalesDeal = salesDeal
      var tempListOfTargetStatus = tempSalesDeal[status].list
      var newList = [...tempListOfTargetStatus, ...res.data]
      var newTargetStatusObject = { id: tempSalesDeal[status].id, list: newList }
      console.log(newTargetStatusObject)
      setSalesDeal({ ...tempSalesDeal, [status]: newTargetStatusObject })
    })
  }

  const toastUpdateSalesDeal = (type, message = undefined) => {
    if (type === 'success') toast.success('Update sales deal status success!')
    else {
      // console.log(message)
      if (message) {
        toast.error(message)
      } else {
        toast.error('Update sales deal failed. Please check all required documents')
      }
    }
  }

  return (
    <div className="h-[calc(100vh-72px-8px)] md:h-[calc(100vh-56px)] flex flex-col py-2 pb-0 lg:pt-4">
      <DismissableToast />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-col md:items-center md:flex-row gap-4 mb-4">
            <div className="flex-1 w-full flex flex-col gap-2 md:flex-row">
              <Tabs value={pathname.split('/').pop()}>
                <TabsList className="w-full justify-start">
                  <Link to={`/sales-deal`}>
                    <TabsTrigger value="sales-deal">Active Deals</TabsTrigger>
                  </Link>
                  <Link to={`/sales-deal/loss`}>
                    <TabsTrigger value="loss">Loss Deals</TabsTrigger>
                  </Link>
                </TabsList>
              </Tabs>
              <DateRangePicker
                label={'Date'}
                className={'min-w-72'}
                onSelect={(selected) =>
                  setSearchParams({
                    ...searchParams,
                    start: moment(selected.from).format('YYYY-MM-DD'),
                    end: moment(selected.to).format('YYYY-MM-DD'),
                  })
                }
                defaultDate={
                  searchParams.start && searchParams.end
                    ? { from: new Date(searchParams.start), to: new Date(searchParams.end) }
                    : {
                        from: new Date(moment().startOf('year').format()),
                        to: new Date(moment().endOf('year').format()),
                      }
                }
              />
              {hasPermission(user, 'salesDeal.filter.ae') && (
                <Combobox
                  className="w-64"
                  icon={<Users />}
                  label={'AE'}
                  placeholder={'All'}
                  defaultValue={parseInt(searchParams.ae) || ''}
                  options={listAE.map((ae) => ({ label: ae.name, value: ae.id }))}
                  onSelect={(selectedValue) => setSearchParams({ ...searchParams, ae: selectedValue })}
                />
              )}
              {hasPermission(user, 'salesDeal.filter.client') && (
                <Combobox
                  className="w-64"
                  icon={<Building />}
                  label={'Client'}
                  placeholder={'All'}
                  defaultValue={parseInt(searchParams.client) || ''}
                  options={listClient.map((client) => ({ label: client.company_name, value: client.id }))}
                  onSelect={(selectedValue) => setSearchParams({ ...searchParams, client: selectedValue })}
                />
              )}
            </div>
            <div className="flex gap-2">
              <Sheet>
                <SheetTrigger asChild>
                  <Button variant={'outline'}>
                    <Filter className="w-5 h-5" />
                    More Filters
                  </Button>
                </SheetTrigger>
                <SheetContent>
                  <SheetHeader className={'mb-4'}>
                    <SheetTitle>Filter</SheetTitle>
                    <SheetDescription>You can select one or more filters</SheetDescription>
                  </SheetHeader>
                  <div className="flex flex-col gap-2">
                    <DateRangePicker
                      label={'Date'}
                      labelWidth={100}
                      className={'w-full'}
                      onSelect={(selected) =>
                        setSearchParams({
                          ...searchParams,
                          start: moment(selected.from).format('YYYY-MM-DD'),
                          end: moment(selected.to).format('YYYY-MM-DD'),
                        })
                      }
                      defaultDate={
                        searchParams.start && searchParams.end
                          ? { from: new Date(searchParams.start), to: new Date(searchParams.end) }
                          : {
                              from: new Date(moment().startOf('year').format()),
                              to: new Date(moment().endOf('year').format()),
                            }
                      }
                    />
                    {hasPermission(user, 'salesDeal.filter.ae') && (
                      <Combobox
                        className="w-full"
                        icon={<Users />}
                        label={'AE'}
                        labelWidth={100}
                        placeholder={'All'}
                        options={listAE.map((ae) => ({ label: ae.name, value: ae.id }))}
                        defaultValue={parseInt(searchParams.ae) || ''}
                        onSelect={(selectedValue) => setSearchParams({ ...searchParams, ae: selectedValue })}
                      />
                    )}

                    {hasPermission(user, 'salesDeal.filter.client') && (
                      <Combobox
                        className="w-full"
                        icon={<Building />}
                        label={'Client'}
                        labelWidth={100}
                        placeholder={'All'}
                        options={listClient.map((client) => ({ label: client.company_name, value: client.id }))}
                        defaultValue={parseInt(searchParams.client) || ''}
                        onSelect={(selectedValue) => setSearchParams({ ...searchParams, client: selectedValue })}
                      />
                    )}

                    {hasPermission(user, 'salesDeal.filter.industry') && (
                      <Combobox
                        className="w-full"
                        icon={<Factory />}
                        label={'Industry'}
                        labelWidth={100}
                        placeholder={'All'}
                        options={listIndustry.map((industry) => ({ label: industry.industry, value: industry.id }))}
                        defaultValue={searchParams.industry || ''}
                        onSelect={(selectedValue) => setSearchParams({ ...searchParams, industry: selectedValue })}
                      />
                    )}
                    {hasPermission(user, 'salesDeal.filter.sbu') && (
                      <Combobox
                        className="w-full"
                        icon={<Component />}
                        label={'SBU'}
                        labelWidth={100}
                        placeholder={'All'}
                        options={listSBU.map((sbu) => ({ label: sbu.sbu_name, value: sbu.sbu_id }))}
                        defaultValue={parseInt(searchParams.sbu) || ''}
                        onSelect={(selectedValue) => setSearchParams({ ...searchParams, sbu: selectedValue })}
                      />
                    )}
                    {hasPermission(user, 'salesDeal.filter.flagship') && (
                      <Combobox
                        className="w-full"
                        icon={<Flag />}
                        label={'Flagship'}
                        labelWidth={100}
                        placeholder={'All'}
                        options={listFlagshipProject.map((project) => ({ label: project.name, value: project.id }))}
                        defaultValue={parseInt(searchParams.flagship) || ''}
                        onSelect={(selectedValue) => setSearchParams({ ...searchParams, flagship: selectedValue })}
                      />
                    )}
                    {hasPermission(user, 'salesDeal.filter.value') && (
                      <Combobox
                        className="w-full"
                        icon={<CircleDollarSign />}
                        label={'Value'}
                        labelWidth={100}
                        placeholder={'All'}
                        options={valueRange.map((value) => ({ label: value.text, value: value.id }))}
                        defaultValue={
                          valueRange.find(
                            (value) =>
                              parseInt(searchParams.value_min) === value.value_range_min &&
                              parseInt(searchParams.value_max) === value.value_range_max
                          )?.id || ''
                        }
                        onSelect={(selectedValue) => {
                          const selected = valueRange.find((value) => value.id === selectedValue)
                          setSearchParams({
                            ...searchParams,
                            value_min: selected?.value_range_min || '',
                            value_max: selected?.value_range_max || '',
                          })
                        }}
                      />
                    )}
                    {hasPermission(user, 'salesDeal.filter.topic') && (
                      <Combobox
                        className="w-full"
                        icon={<LeafyGreen />}
                        label={'Topic'}
                        labelWidth={100}
                        placeholder={'All'}
                        defaultValue={listTopic.find((topic) => topic.id === searchParams.topic)?.id || ''}
                        options={listTopic.map((topic) => ({ label: topic.text, value: topic.id }))}
                        onSelect={(selectedValue) =>
                          setSearchParams({
                            ...searchParams,
                            topic: selectedValue || '',
                          })
                        }
                      />
                    )}
                  </div>
                </SheetContent>
              </Sheet>
              <Button variant="outline" onClick={() => toast.error('This feature is under development.')}>
                <FolderDown className="w-5 h-5" />
                Export
              </Button>
            </div>
          </div>
          <div className="flex-1 overflow-y-hidden">
            {salesDeal && (
              <DnD
                data="test"
                initialColumns={salesDeal}
                statistics={statistics}
                loadMoreItem={loadMoreItem}
                toastUpdateSalesDeal={toastUpdateSalesDeal}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}
