import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BreadCrumbs from '../../components/BreadCrumbs'
import Loading from '../../components/Loading'
// import Input from '../../components/Input'
// import Textarea from '../../components/Textarea'
// import GroupButtons from '../../components/GroupButtons'
// import DropdownInput from '../../components/DropdownInput'
// import Button from '../../components/Button'
// import Checkbox from '../../components/Checkbox'
import Table from '../../components/table/Table'
import ProjectAPI from '../../apis/ProjectAPI'
import { ProductsAPI } from '../../apis/ProductsAPI'
import LogoCell from '../../components/table/cell/LogoCell'
import Basic from '../../components/table/cell/Basic'
import ActionCell from '../../components/table/cell/ActionCell'
import { formatThousands } from '../../helper'
import { COGSitemAPI } from '../../apis/COGSitemsAPI'
import { Delete } from '@mui/icons-material'
import CustomTextDropdownInput from '../../components/CustomTextDropdownInput'
import Alert from '../../components/Alert'
import usePermission from '../../hooks/usePermission'
import StatusCell from '../../components/table/cell/StatusCell'
import RevenueItemsTable from '../../components/RevenueItemsTable'
import COGSItemsTable from '../../components/COGSItemsTable'
import Modal from '../../components/Modal'
import { Button } from '@/components/ui/button'
import { NotebookPen, Save } from 'lucide-react'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import GroupButton from '@/components/ui/group-button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Label } from '@/components/ui/label'

export default function ProjectDetail() {
  const { id } = useParams()
  const [project, setProject] = useState()
  const [updatedProject, setUpdatedProject] = useState()
  const [projectTypeTemp, setProjectTypeTemp] = useState()
  const [listProducts, setListProduct] = useState([])
  const [selectedProduct, setSelectedProduct] = useState()
  const [selectedVariant, setSelectedVariant] = useState()
  const [listVariantProduct, setListVariantProduct] = useState([])
  const [listFlagshipProject, setListFlagshipProject] = useState([])
  const [selectedFlagship, setSelectedFlagship] = useState()
  const [isLoading, setLoading] = useState(true)
  const [isMissingProjectName, setMissingProjectName] = useState(false)
  const [isLoadingVariant, setLoadingVariant] = useState(false)
  const [isLoadingSaveProject, setLoadingSaveProject] = useState(false)
  const [isLoadingRequestReview, setLoadingRequestReview] = useState(false)
  const [isLoadingFlagship, setLoadingFlagship] = useState(false)
  const [saveProjectError, setSaveProjectError] = useState()

  const [stateCellConfig, setCellConfig] = useState([
    {
      text: 'Approved',
      status: 'approved',
      state: 'success',
    },
    {
      text: 'Pending',
      status: 'pending',
      state: 'warning',
    },
    {
      text: 'Need Revision',
      status: 'need_revision',
      state: 'warning-urgent',
    },
    {
      text: 'Rejected',
      status: 'rejected',
      state: 'danger',
    },
    {
      text: 'In Review - VP',
      state: 'default',
      status: 'in_review_vp',
    },

    {
      text: 'In Review - SM',
      state: 'default',
      status: 'in_review_sm',
    },
  ])
  const [revenueCols, setRevenueCols] = useState([
    {
      id: 0,
      name: 'Product',
      classes: 'min-w-[200px]',
    },
    {
      id: 1,
      name: 'Variant',
      classes: 'w-[320px]',
    },
    {
      id: 2,
      name: 'Price',
      classes: 'text-right min-w-[200px]',
    },
    {
      id: 3,
      name: 'Quantity',
      classes: 'text-right w-[100px]',
    },
    {
      id: 4,
      name: 'Discount',
      classes: 'text-right',
    },
    {
      id: 5,
      name: 'Total Price',
      classes: 'text-right min-w-[200px]',
    },
    {
      id: 6,
      name: 'Action',
      classes: 'text-center w-20',
    },
  ])
  const [marginCols, setMarginCols] = useState([
    { id: 0, name: 'Component', selector: (row) => <Basic data={row.row} />, classes: 'w-[180px] font-semibold' },
    {
      id: 1,
      name: 'Total Amount',
      selector: (row) => <Basic data={`Rp${formatThousands(row.totalAmount)}`} alignment={'right'} />,
      classes: 'w-[180px]  text-right',
    },
    {
      id: 2,
      name: 'Percentage',
      selector: (row) => <Basic data={`${(row.percentage * 100).toFixed(2)}%`} alignment={'right'} />,
      classes: 'w-[180px] text-right',
    },
  ])
  const [listTopic, setListTopic] = useState([
    { id: 'green', topic: 'Green' },
    { id: 'non_green', topic: 'Non-green' },
  ])
  const navigate = useNavigate()
  const { isAE, isSM, isVP, isAdmin } = usePermission()

  useEffect(() => {
    console.log(updatedProject)
  }, [updatedProject])

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        ProjectAPI.getProjectById(id).then((res) => {
          setProject(res.data)
          setUpdatedProject(res.data)
          setProjectTypeTemp(res.data.type)
          resolve()
        })
      }),
      new Promise((resolve, reject) => {
        ProjectAPI.getAllFlagshipProject().then((res) => {
          setListFlagshipProject(res.data)
          resolve()
        })
      }),
    ]
    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [id])

  useEffect(() => {
    if (updatedProject) {
      if (updatedProject.type === 'standard') {
        new Promise((resolve, reject) => {
          ProductsAPI.getProductOnly('standard').then((res) => {
            setListProduct(res.data)
            resolve()
          })
        })
      } else {
        new Promise((resolve, reject) => {
          ProductsAPI.getProductOnly().then((res) => {
            setListProduct(res.data)
            resolve()
          })
        })
      }
    } else {
      new Promise((resolve, reject) => {
        ProductsAPI.getProductOnly().then((res) => {
          setListProduct(res.data)
          resolve()
        })
      })
    }
  }, [projectTypeTemp])

  useEffect(() => {
    if (selectedFlagship) {
      setLoadingFlagship(true)
      ProjectAPI.getFlagshipProjectById(selectedFlagship.id).then((res) => {
        var flagship = res.data
        // console.log([...updatedProject.revenue_items, ...flagship.revenue_items])
        setUpdatedProject({
          ...updatedProject,
          flagship_project_id: flagship.id,
          total_revenue: flagship.total_revenue + updatedProject.total_revenue,
          total_cogs: updatedProject.total_cogs + flagship.total_cogs,
          revenue_items: [...updatedProject.revenue_items, ...flagship.revenue_items],
          cogs_item: [...updatedProject.cogs_items, ...flagship.cogs_items],
        })
        setLoadingFlagship(false)
      })
    }
  }, [selectedFlagship])

  const handleChange = (input) => {
    setUpdatedProject({ ...updatedProject, [input.name]: input.value })
  }

  const setProjectType = (type) => {
    setUpdatedProject({ ...updatedProject, type: type })
    setProjectTypeTemp(type)
  }

  const countMargin = () => {
    if (!updatedProject.total_revenue && !updatedProject.total_cogs) {
      return '0%'
    } else if (updatedProject.total_cogs > updatedProject.total_revenue) {
      return 'ERROR!!!'
    } else {
      return `${(100 - (updatedProject.total_cogs / updatedProject.total_revenue) * 100).toFixed(2)}%`
    }
  }

  const saveUpdatedProject = (status) => {
    setLoadingSaveProject(true)
    setSaveProjectError()
    setMissingProjectName(false)

    if (!updatedProject.name) {
      setSaveProjectError("Can't save project with no project name.")
      setMissingProjectName(true)
      setLoadingSaveProject(false)
      return
    }

    if (updatedProject.revenue_items.length === 0) {
      setLoadingSaveProject(false)
      setSaveProjectError("Can't save project with no revenue item.")
      return
    }

    var tempObject = {
      // id: updatedProject.id,
      name: updatedProject.name,
      description: updatedProject.description,
      type: updatedProject.type,
      flagship_project_id: updatedProject.flagship_project_id,
      status: status,
      topic: updatedProject.topic,
      revenue_items: updatedProject.revenue_items.map((item) => {
        return {
          product_id: item.product_id,
          sub_product_id: item.sub_product_id,
          product_variant_id: item.product_variant_id,
          price: item.price,
          quantity: item.quantity,
          discount_type: item.discount_type,
          discount_percentage: item.discount_percentage,
          discount_absolute: item.discount_absolute,
        }
      }),
    }
    ProjectAPI.updateProjectByAE(id, tempObject)
      .then((res) => {
        // console.log(res)
        if (res.success) {
          setLoadingSaveProject(false)
          navigate(-1)
        } else {
          setLoadingSaveProject(false)
        }
      })
      .catch((err) => {
        console.log(err)
        if (err.response) {
          setSaveProjectError(err.response.data.message)
        } else {
          setSaveProjectError(err.message)
        }
        setLoadingSaveProject(false)
      })
  }

  // const saveToBeReviewedBySM = () => {
  //   setLoadingRequestReview(true)
  //   setSaveProjectError()
  //   setMissingProjectName(false)
  //   if (updatedProject.name) {
  //     if (updatedProject.revenue_items.length > 0) {
  //       var tempObject = {
  //         id: updatedProject.id,
  //         name: updatedProject.name,
  //         description: updatedProject.description,
  //         type: updatedProject.type,
  //         flagship_project_id: updatedProject.flagship_project_id,
  //         status: 'in_review_sm',
  //         topic: updatedProject.topic,
  //         revenue_items: updatedProject.revenue_items.map((item) => {
  //           return {
  //             product_id: item.product_id,
  //             sub_product_id: item.sub_product_id,
  //             product_variant_id: item.product_variant_id,
  //             price: item.price,
  //             quantity: item.quantity,
  //             discount_type: item.discount_type,
  //             discount_percentage: item.discount_percentage,
  //             discount_absolute: item.discount_absolute,
  //           }
  //         }),
  //       }
  //       ProjectAPI.updateProject(tempObject.id, tempObject)
  //         .then((res) => {
  //           navigate('/projects')
  //         })
  //         .catch((err) => {
  //           if (err.response) {
  //             setSaveProjectError(err.response.message)
  //           } else {
  //             setSaveProjectError(err.message)
  //           }
  //           setLoadingRequestReview(false)
  //         })
  //     } else {
  //       setSaveProjectError("Can't request review project with no revenue item.")
  //       setLoadingRequestReview(false)
  //     }
  //   } else {
  //     setSaveProjectError("Can't request review project with no project name.")
  //     setLoadingRequestReview(false)
  //     setMissingProjectName(true)
  //   }
  // }

  return (
    <div className="py-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="mb-4">
            <BreadCrumbs>
              <BreadCrumbs.Item href={'/projects'}>Project</BreadCrumbs.Item>
              <BreadCrumbs.Item current>{project.name}</BreadCrumbs.Item>
            </BreadCrumbs>
          </div>
          <div className="mb-12">
            <h1 className="font-semibold text-giant mb-4">Project Information</h1>
            <div className="border border-gray-border rounded-[5px] p-4 max-w-[500px] w-full lg:w-[500px]">
              <div className="grid gap-4">
                <div>
                  <Label>Project Name</Label>
                  <Input
                    onChange={(e) => handleChange(e.target)}
                    name="name"
                    error={isMissingProjectName}
                    placeholder="Project Name"
                    value={updatedProject.name}
                    disabled={
                      !isAE && (updatedProject.status !== 'pending' || updatedProject.status !== 'need_revision')
                    }
                  />
                </div>
                <div>
                  <Label>Description</Label>
                  <Textarea
                    onChange={(e) => handleChange(e.target)}
                    name="description"
                    rows={5}
                    value={updatedProject.description}
                    disabled={
                      !isAE && (updatedProject.status !== 'pending' || updatedProject.status !== 'need_revision')
                    }
                  />
                </div>
                <div className="mb-4">
                  <Label>Project Type</Label>
                  {/* <GroupButton
                  className="mr-4"
                  defaultValue={updatedProject.type}
                  disabled={!isAE && (updatedProject.status !== 'pending' || updatedProject.status !== 'need_revision')}
                  data={[
                    { id: 'standard', text: 'Standard', onClick: () => setProjectType('standard') },
                    { id: 'custom', text: 'Custom', onClick: () => setProjectType('custom') },
                    { id: 'flagship', text: 'Flagship', onClick: () => setProjectType('flagship') },
                  ]}
                /> */}

                  <GroupButton
                    defaultValue={updatedProject.type}
                    items={[
                      { value: 'single_client', label: 'Single Client', ariaLabel: 'Single Client' },
                      { value: 'multiple_clients', label: 'Multi Clients', ariaLabel: 'Multi Clients' },
                    ]}
                    onValueChange={(value) => setUpdatedProject({ ...updatedProject, type: value })}
                  />
                </div>
                {updatedProject.type === 'multiple_clients' && (
                  <div className="">
                    <Label>Flagship Project</Label>
                    {/* <DropdownInput
                      placeholder="Choose flagship project"
                      data={listFlagshipProject}
                      attributeShown="name"
                      value={listFlagshipProject.find((flagship) => flagship.id == updatedProject.flagship_project_id)}
                      disabled={
                        !isAE && (updatedProject.status !== 'pending' || updatedProject.status !== 'need_revision')
                      }
                      handleSelectedOption={(selected) => {
                        setSelectedFlagship(selected)
                      }}
                    />
                    {isLoadingFlagship && <Loading />} */}

                    <Select
                      key={updatedProject.type}
                      defaultValue={updatedProject.flagship_project_id}
                      onValueChange={(value) => {
                        setUpdatedProject({ ...updatedProject, flagship_project_id: value })
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select Flagship Project" />
                      </SelectTrigger>
                      <SelectContent>
                        {/* <SelectItem value="active">Active</SelectItem>
                  <SelectItem value="inactive">Inactive</SelectItem> */}
                        {listFlagshipProject.map((flagshipProject) => (
                          <SelectItem key={flagshipProject.id} value={flagshipProject.id}>
                            {flagshipProject.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                )}
                <div>
                  <Label>Topic</Label>
                  <Select
                    onValueChange={(value) => {
                      setUpdatedProject({ ...updatedProject, topic: value })
                    }}
                    defaultValue={updatedProject.topic}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Topic" />
                    </SelectTrigger>
                    <SelectContent>
                      {/* <SelectItem value="active">Active</SelectItem>
                  <SelectItem value="inactive">Inactive</SelectItem> */}
                      {listTopic.map((topic) => (
                        <SelectItem key={topic.id} value={topic.id}>
                          {topic.topic}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <RevenueItemsTable
            isEditable={isAE}
            project={updatedProject}
            setProject={setUpdatedProject}
            projectTypeTemp={projectTypeTemp}
          />
          {/* <COGSItemsTable
            project={updatedProject}
            setProject={setUpdatedProject}
            projectTypeTemp={projectTypeTemp}
            isEditable={false}
          /> */}
          {/* <div className="mb-12">
            <h1 className="text-giant mb-4 font-semibold">Margin</h1>
            <Table
              tableType="inline"
              noIndex={true}
              cols={marginCols}
              data={[
                {
                  id: 0,
                  row: 'Revenue',
                  totalAmount: updatedProject.total_revenue,
                  percentage:
                    updatedProject.total_revenue !== 0
                      ? 1 - updatedProject.total_cogs / updatedProject.total_revenue
                      : 0,
                },
                {
                  id: 1,
                  row: 'COGS',
                  totalAmount: updatedProject.total_cogs,
                  percentage:
                    updatedProject.total_revenue !== 0 ? updatedProject.total_cogs / updatedProject.total_revenue : 1,
                },
              ]}
            />
          </div> */}
          <div className="flex justify-end gap-4 mb-12">
            {saveProjectError && <Alert type={'error'} message={saveProjectError} className={'flex-1'} />}
            {isAE && updatedProject.sales_deal.status === 'hot_prospect' && (
              <Button
                variant={'outline'}
                onClick={() => saveUpdatedProject('in_review_sm')}
                disabled={isLoadingRequestReview}
              >
                {isLoadingRequestReview ? (
                  <Loading className="" />
                ) : (
                  <>
                    <NotebookPen className="w-5 h-5" />
                    Send Review Request to SM
                  </>
                )}
              </Button>
            )}
            <Button onClick={() => saveUpdatedProject('pending')} isLoading={isLoadingSaveProject}>
              <Save className="w-5 h-5" />
              Save Project
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
