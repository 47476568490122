import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../components/BreadCrumbs'
import { Link, useNavigate, useParams } from 'react-router-dom'
import InvoiceAPI from '../../apis/InvoiceAPI'
import DismissableToast from '../../components/Toast'
import toast from 'react-hot-toast'
import LoadingSpinner from '../../components/LoadingSpinner'
import Loading from '../../components/Loading'
import { ArrowForward } from '@mui/icons-material'
import { formatThousands } from '../../helper'
import RevenueItemsTable from '../../components/RevenueItemsTable'
import Basic from '../../components/table/cell/Basic'
import Table from '../../components/table/Table'
import moment from 'moment'
import RadioButton from '../../components/RadioButton'
import Textarea from '../../components/Textarea'
import Alert from '../../components/Alert'
import { el } from 'date-fns/locale'
import StatusCell from '../../components/table/cell/StatusCell'
import usePermission from '../../hooks/usePermission'
import ErrorPage from '../../ErrorPage'
import { Button } from '@/components/ui/button'
import { Copy } from 'lucide-react'
import { useCopyToClipboard } from '@uidotdev/usehooks'

const reCountTotal = (revenue_items) => {
  var total = 0
  revenue_items.map((item) => {
    if (item.discount_type === 'absolute') {
      total += parseInt(item.price) * parseInt(item.quantity) - item.discount_absolute
    } else
      total += parseInt(item.price) * parseInt(item.quantity) * (1 - parseFloat(item.discount_percentage).toFixed(3))
  })
  console.log(total)
  return total
}

export default function InvoiceDetail() {
  const { id, mode } = useParams()
  const navigate = useNavigate()
  const { isFinance, isAdmin } = usePermission()
  const [isLoading, setLoading] = useState(true)
  const [requestInvoice, setRequestInvoice] = useState()
  const [revenueCols, setRevenueCols] = useState([
    {
      id: 0,
      name: 'Product',
      classes: 'min-w-[200px]',
    },
    {
      id: 1,
      name: 'Variant',
      classes: 'w-[320px]',
    },
    {
      id: 2,
      name: 'Price',
      classes: 'text-right min-w-[200px]',
    },
    {
      id: 3,
      name: 'Quantity',
      classes: 'text-right w-[100px]',
    },
    {
      id: 4,
      name: 'Discount',
      classes: 'text-right w-[250px]',
    },
    {
      id: 5,
      name: 'Total Price',
      classes: 'text-right min-w-[200px]',
    },
  ])
  const [statusCellConfig, setCellConfig] = useState([
    {
      text: 'In Review',
      status: 'in_review',
      state: 'warning',
    },
    {
      text: 'Pending',
      status: 'pending',
      // state: 'success',
    },
    {
      text: 'Processed',
      status: 'processed',
      state: 'warning-urgent',
    },
    {
      text: 'Invoiced',
      status: 'invoiced',
      state: 'success',
    },
    {
      text: 'Rejected',
      status: 'rejected',
      state: 'danger',
    },
    {
      text: 'Returned',
      status: 'returned',
      state: 'danger',
    },
    {
      text: 'Void',
      status: 'void',
      state: 'neutral',
    },
  ])
  const [invoiceCols, setInvoiceCols] = useState([
    {
      id: 5,
      name: 'Invoice ID',
      classes: 'min-w-[120px]',
      selector: (row) => <Basic data={row.id} />,
    },
    {
      id: 0,
      name: 'Terms',
      classes: 'min-w-[100px]',
      selector: (row) => <Basic data={row.term} />,
    },
    // {
    //   id: 1,
    //   name: 'Percentage',
    //   classes: 'w-[100px]',
    //   selector: (row) => <Basic data={`${(row.percentage * 100).toFixed(2)}%`} />,
    // },
    {
      id: 2,
      name: 'Amount',
      classes: 'text-right min-w-[180px]',
      selector: (row) => <Basic data={`Rp${formatThousands(row.amount)}`} className={'text-right'} />,
    },
    {
      id: 3,
      name: 'Invoice Date',
      classes: 'w-[200px]',
      selector: (row) => <Basic data={moment(row.invoice_date).format('D MMMM YYYY')} />,
    },
    {
      id: 4,
      name: 'Invoice Due Date',
      classes: 'w-[200px]',
      selector: (row) => <Basic data={moment(row.due_date).format('D MMMM YYYY')} />,
    },
  ])
  const [totalRev, setTotalRev] = useState()
  const [review, setReview] = useState({
    result: '',
    note: '',
  })
  const [isLoadingReview, setLoadingReview] = useState(false)
  const [errorReview, setErrorReview] = useState()

  const [copiedText, copyToClipboard] = useCopyToClipboard()

  // const []
  useEffect(() => {
    if (mode === 'view') {
      setInvoiceCols([
        ...invoiceCols,
        {
          id: 4,
          name: 'Status',
          classes: 'w-[200px]',
          selector: (row) => <StatusCell data={row.status} config={statusCellConfig} className={'font-medium'} />,
        },
      ])
    }
  }, [mode])

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        InvoiceAPI.getSingleInvoice(id)
          .then((res) => {
            setRequestInvoice(res.data)
            setTotalRev(reCountTotal(res.data.revenue_items))
            // toast.success(res.message)
            resolve()
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message)
            } else {
              toast.error(err.statusText)
            }
            reject()
          })
      }),
    ]

    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [])

  const saveReview = () => {
    setLoadingReview(true)
    var isAllowSave = true

    Object.keys(review).map((key) => {
      if (!review[key]) isAllowSave = false
    })

    if (isAllowSave) {
      InvoiceAPI.saveReviewInvoice(id, review.result, review.note)
        .then((res) => {
          if (res.success) {
            navigate('/data/invoice-requests')
            setLoadingReview(false)
          }
        })
        .catch((err) => {
          if (err.response) {
            setErrorReview(err.response.data.message)
            toast.error(err.response.data.message)
          } else {
            setErrorReview(err.statusText)
            toast.error(err.statusText)
          }
          setLoadingReview(false)
        })
    } else {
      setErrorReview('Please fill all review fields!')
      setLoadingReview(false)
    }
  }

  if (mode === 'review' && !isFinance) {
    return <ErrorPage type={403} />
  }

  return (
    <div className="py-4">
      <DismissableToast />

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <h1 className="font-semibold text-giant mb-4">Sales Deal</h1>
          <div className="p-4 border border-gray-border rounded-[5px] mb-12">
            <div className="flex gap-8">
              <div className="w-[300px] grid gap-4">
                <div className="">
                  <p className="font-semibold mb-1">Client</p>
                  <p className="">{requestInvoice.sales_deal.client_name}</p>
                </div>
                <div className="">
                  <p className="font-semibold mb-1">Project</p>
                  <p className="">{requestInvoice.sales_deal.project_name}</p>
                </div>
                <div className="">
                  <p className="font-semibold mb-1">Sales Deal</p>
                  <Link
                    to={`/sales-deal/${requestInvoice.sales_deal.sales_deal_id}`}
                    className='className="hover:underline hover:underline-offset-1'
                  >
                    See detail
                    <span className="w-4 h-4">
                      <ArrowForward sx={{ fontSize: 16 }} />
                    </span>{' '}
                  </Link>
                </div>
              </div>
              <div className="w-[300px] grid gap-4">
                <div className="">
                  <p className="font-semibold mb-1">Account Executive</p>
                  <div className="font-normal flex gap-1 items-center">
                    <span className="w-6 h-6 rounded-full bg-slate-100 overflow-hidden">
                      <img src={requestInvoice.sales_deal.ae_avatar} alt="avatar" className="w-full" />
                    </span>
                    <p>{requestInvoice.sales_deal.ae_name}</p>
                  </div>
                </div>
                <div className="">
                  <p className="font-semibold mb-1">Total Revenue</p>
                  <p className="">{`Rp${formatThousands(requestInvoice.sales_deal.total_revenue)}`}</p>
                </div>
                <div className="">
                  <p className="font-semibold mb-1">Total COGS</p>
                  <p className="">{`Rp${formatThousands(requestInvoice.sales_deal.total_cogs)}`}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-12">
            <h1 className="font-semibold text-giant mb-4">Revenue Details</h1>
            <div className="border border-gray-border rounded-[5px] p-4">
              <div className="pb-2 relative overflow-x-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-main-500 scrollbar-track-sky-50">
                <table className={'table-auto w-full'}>
                  <thead className="rounded-[5px] py-4 text-left font-bold text-white bg-main-500 overflow-hidden">
                    <tr>
                      <th className="px-4 text-center grow-0 shrink-0 rounded-l-[5px]">#</th>
                      {revenueCols.map((col, index) => {
                        // console.log(col)
                        if (index === revenueCols.length - 1) {
                          return (
                            <th
                              key={index}
                              className={`px-4 py-4 font-semibold rounded-r-[5px] ${col.classes && col.classes}`}
                            >
                              {col.name}
                            </th>
                          )
                        }
                        return (
                          <th key={index} className={`px-4 py-4 font-semibold ${col.classes && col.classes}`}>
                            {col.name}
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {requestInvoice.revenue_items.length === 0 ? (
                      <tr className="text-center italic">
                        <td colSpan={revenueCols.length} className="text-gray-text font-semibold py-4">
                          No data found
                        </td>
                      </tr>
                    ) : (
                      requestInvoice.revenue_items.map((row, index) => {
                        return (
                          <tr key={index} className="border-b border-gray-border">
                            <td className="px-4 py-4 font-semibold w-8">{index + 1}.</td>
                            <td className={`px-4 py-4 font-semibold`}>{row.product_name}</td>
                            <td
                              className={`px-4 py-4 font-semibold`}
                            >{`${row.sub_product_name} - ${row.product_variant_name}`}</td>
                            <td className={`px-4 py-4 font-semibold text-right`}>
                              {`Rp${formatThousands(row.price)}`}
                            </td>
                            <td className={`px-4 py-4 font-semibold text-right`}>
                              <Basic data={row.quantity} alignment={'right'} />
                            </td>
                            <td className={`px-4 py-4 font-semibold text-right`}>
                              {row.discount_type === 'absolute' ? (
                                <Basic
                                  data={row.discount_absolute > 0 ? `Rp${formatThousands(row.discount_absolute)}` : `0`}
                                  alignment={'right'}
                                />
                              ) : (
                                <Basic
                                  data={
                                    row.discount_percentage > 0
                                      ? `${parseFloat((parseFloat(row.discount_percentage) * 100).toFixed(3))}%`
                                      : `0`
                                  }
                                  alignment={'right'}
                                />
                              )}
                            </td>
                            <td className={`px-4 py-4 font-semibold text-right`}>
                              Rp
                              {row.discount_type === 'absolute'
                                ? formatThousands(row.price * row.quantity - row.discount_absolute)
                                : formatThousands(
                                    row.price * row.quantity - row.price * row.quantity * row.discount_percentage
                                  )}
                            </td>
                          </tr>
                        )
                      })
                    )}
                    <tr className="border-b border-gray-border">
                      <td className="px-4 py-4 font-semibold w-8"></td>
                      <td className={`px-4 py-4 font-semibold`}></td>
                      <td className={`px-4 py-4 font-semibold`}></td>
                      <td className={`px-4 py-4 font-semibold text-right`}></td>
                      <td className={`px-4 py-4 font-semibold text-right`}></td>
                      <td className={`px-4 py-4 font-semibold text-right`}>Total Revenue</td>
                      <td className={`px-4 py-4 font-semibold text-right`}>Rp{formatThousands(totalRev)}</td>
                      <td className={`px-4 py-4 font-semibold`}></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="mb-12">
            <div className="mb-4">
              <h1 className="font-semibold text-giant mb-2">Invoice Request</h1>
              <div className="flex items-center gap-4">
                <h2 className="font-semibold text-lg">
                  <span className="text-main-500">Tag:</span> {requestInvoice?.invoice_request.invoice_tag || '-'}
                </h2>
                {requestInvoice?.invoice_request?.invoice_tag && (
                  <Button
                    size={'xs'}
                    variant={'outline'}
                    onClick={(e) => {
                      copyToClipboard(requestInvoice.invoice_request.invoice_tag)
                      toast.success('The invoice tag was copied to the clipboard!')
                    }}
                  >
                    <Copy className="w-4 h-4" />
                  </Button>
                )}
              </div>
              <h2 className="font-semibold text-lg">
                <span className="text-main-500">Invoice Number:</span>
                {requestInvoice?.invoice_request.invoice_number || '-'}
              </h2>
            </div>
            <div className="border border-gray-border rounded-[5px] p-4">
              <Table cols={invoiceCols} data={[requestInvoice.invoice_request]} tableType="inline" noIndex />
            </div>
          </div>
          {mode === 'review' && (
            <>
              <div className="mb-4">
                <h1 className="font-semibold text-big mb-4">Review</h1>
                <div className="border border-gray-border rounded-[5px] p-4">
                  <div className="flex gap-8 mb-4">
                    <div className="1">
                      <p className="font-semibold mb-2">Review Result</p>
                      <RadioButton
                        label={'Approve'}
                        className="mb-2"
                        checked={review.result === 'approved'}
                        onChange={() => setReview({ ...review, result: 'approved' })}
                      />
                      <RadioButton
                        label={'Reject'}
                        className="mb-2"
                        checked={review.result === 'rejected'}
                        onChange={() => setReview({ ...review, result: 'rejected' })}
                      />
                    </div>
                    <div className="w-[500px]">
                      <Textarea
                        rows={5}
                        label={'Notes'}
                        placeholder="Write your notes here..."
                        onChange={(e) => setReview({ ...review, note: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end gap-4 mb-12">
                {/* <Button variant={'outlined'} onClick={saveProject} isLoading={isLoadingReview}>
              Save Project
            </Button> */}
                {errorReview && <Alert type={'error'} message={errorReview} className={'w-fit'} />}
                <Button onClick={saveReview} isLoading={isLoadingReview}>
                  Submit Review
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
