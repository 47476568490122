import React, { useEffect, useState } from 'react'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import BreadCrumbs from '../../components/BreadCrumbs'
import Loading from '../../components/Loading'
import Textarea from '../../components/Textarea'
import RadioButton from '../../components/RadioButton'
import Table from '../../components/table/Table'
import ProjectAPI from '../../apis/ProjectAPI'
import Basic from '../../components/table/cell/Basic'
import { formatThousands } from '../../helper'
import { COGSitemAPI } from '../../apis/COGSitemsAPI'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import Alert from '../../components/Alert'
import StatusCell from '../../components/table/cell/StatusCell'
import usePermission from '../../hooks/usePermission'
import RevenueItemsTable from '../../components/RevenueItemsTable'
import { useSelector } from 'react-redux'
import { Label } from '@/components/ui/label'
import { cn } from '@/lib/utils'
import { Avatar } from '@/components/ui/avatar'
import { AvatarFallback, AvatarImage } from '@radix-ui/react-avatar'
import GroupButton from '@/components/ui/group-button'
import toast from 'react-hot-toast'
import EditableCOGSItemsTable from '@/components/EditableCOGSItemsTable'
import { Button } from '@/components/ui/button'
import { NotebookPen } from 'lucide-react'

export default function ReviewProject() {
  const { id } = useParams()
  const [user, setUser] = useLocalStorage('user', {})
  const userData = useSelector((state) => state.auth.userData)
  const [project, setProject] = useState()
  const [updatedProject, setUpdatedProject] = useState()
  const [isLoading, setLoading] = useState(true)
  const [review, setReview] = useState({ note: '', result: null })
  const [errorReview, setErrorReview] = useState()
  const [selectedCOGS, setSelectedCOGS] = useState()
  const [isCustomCOGS, setIsCustomCOGS] = useState(false)
  const [customCOGS, setCustomCOGS] = useState({ name: '', cost: '' })
  const [isMissingCogsData, setMissingCustomCOGSData] = useState(false)
  const [isLoadingUpdate, setLoadingUpdate] = useState(false)
  const [isLoadingSaveReview, setLoadingSaveReview] = useState(false)
  const [listCOGS, setListCOGS] = useState([])
  const [stateCellConfig, setCellConfig] = useState([
    {
      text: 'Approved',
      status: 'approved',
      state: 'success',
    },
    {
      text: 'Pending',
      status: 'pending',
      state: 'default',
    },
    {
      text: 'Rejected',
      status: 'rejected',
      state: 'danger',
    },
    {
      text: 'Need Revision',
      status: 'need_revision',
      state: 'warning-urgent',
    },
    {
      text: 'In Review - VP',
      state: 'warning',
      status: 'in_review_vp',
    },
    {
      text: 'In Review - SM',
      state: 'warning',
      status: 'in_review_sm',
    },
  ])
  const [marginCols, setMarginCols] = useState([
    { id: 0, name: 'Component', selector: (row) => <Basic data={row.row} />, classes: 'w-[180px] font-semibold' },
    {
      id: 1,
      name: 'Total Amount',
      selector: (row) => <Basic data={`Rp${formatThousands(row.totalAmount)}`} alignment={'right'} />,
      classes: 'w-[180px]  text-right',
    },
    {
      id: 2,
      name: 'Percentage',
      selector: (row) => <Basic data={`${(row.percentage * 100).toFixed(2)}%`} alignment={'right'} />,
      classes: 'w-[180px] text-right',
    },
  ])
  const [listTopic, setListTopic] = useState([
    { id: 'green', topic: 'Green' },
    { id: 'non_green', topic: 'Non-green' },
  ])
  const navigate = useNavigate()
  const { isSM, isVP, isAdmin } = usePermission()

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        ProjectAPI.getProjectById(id).then((res) => {
          // if(userData.id !== res.data) for later use
          setProject({ ...res.data, set_as_commitment_project: res.data.commitment_id !== undefined ? true : false })
          setUpdatedProject({
            ...res.data,
            set_as_commitment_project: res.data.commitment_id ? true : false,
          })

          console.log(res.data)
          resolve()
        })
      }),
      new Promise((resolve, reject) => {
        COGSitemAPI.getCOGSItemsSimple().then((res) => {
          setListCOGS(res.data)
          resolve()
        })
      }),
    ]
    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [id])

  const saveReviewSM = (status) => {
    setLoadingSaveReview(true)
    if (!status && !review.result) {
      setErrorReview('Please select review result')
      return
    }

    var obj = {
      status: status || review.result,
      review_note: review.note ? review.note : '',
      set_as_commitment_project: updatedProject.set_as_commitment_project,
      commitment_id: updatedProject.set_as_commitment_project ? updatedProject.commitment.id : null,
      revenue_items: updatedProject.revenue_items.map((product) => ({
        id: product.id,
        product_id: product.product_id,
        sub_product_id: product.sub_product_id,
        product_variant_id: product.product_variant_id,
        price: product.price,
        quantity: product.quantity,
        discount_type: product.discount_type,
        discount_percentage: product.discount_percentage,
        discount_absolute: product.discount_absolute,
        cogs_items: product.cogs_items.map((item) => {
          console.log(item)
          return {
            id: item.id,
            cogs_id: item.cogs_id === null ? null : item.cogs_id.toString().includes('custom') ? null : item.cogs_id,
            cost: item.cost,
            quantity: item.quantity,
            custom_name:
              !item.cogs_id || item.cogs_id.toString().includes('custom')
                ? item.custom_name || item.cogs_item_name
                : '',
            project_revenue_item_id: item.project_revenue_item_id,
          }
        }),
      })),
    }

    ProjectAPI.updateProjectBySM(id, obj)
      .then((res) => {
        if (res.success) {
          toast.success('Submit review success!')
          navigate(-1)
        } else {
          setErrorReview(res.message)
        }

        setLoadingSaveReview(false)
      })
      .catch((err) => {
        if (err.response) {
          setErrorReview(err.response.data.message)
          toast.error(err.response.data.message)
        } else {
          setErrorReview(err.message)
          toast.error(err.message)
        }
        setLoadingSaveReview(false)
      })
  }

  const saveReviewedFromVP = (status) => {
    setLoadingSaveReview(true)
    console.log(review)
    console.log(status)

    if (!status && !review.result) {
      setErrorReview('Please select review result')
      return
    }

    var obj = {
      status: status || review.result,
      review_note: review.note ? review.note : '',
      set_as_commitment_project: updatedProject.set_as_commitment_project || false,
      commitment_id: updatedProject.set_as_commitment_project ? updatedProject.commitment.id : null,
      revenue_items: updatedProject.revenue_items.map((product) => ({
        id: product.id.toString().includes('new') ? null : product.id,
        product_id: product.product_id,
        sub_product_id: product.sub_product_id,
        product_variant_id: product.product_variant_id,
        price: product.price,
        quantity: product.quantity,
        discount_type: product.discount_type,
        discount_percentage: product.discount_percentage,
        discount_absolute: product.discount_absolute,
        cogs_items: product.cogs_items.map((item) => ({
          id: item.id.toString().includes('new') ? null : item.id,
          // item.cogs_id === null, or item.cogs_id contains string 'custom' means it's a custom cogs item.
          // If the item is custom then we need to input the cogs_id === null
          cogs_id: !item.cogs_id ? null : item.cogs_id.toString().includes('custom') ? null : item.cogs_id,
          cost: item.cost,
          quantity: item.quantity,
          custom_name:
            !item.cogs_id || item.cogs_id.toString().includes('custom') ? item.custom_name || item.cogs_item_name : '',
          project_revenue_item_id: item.project_revenue_item_id,
        })),
      })),
    }

    ProjectAPI.updateProjectByVP(id, obj)
      .then((res) => {
        if (res.success) {
          toast.success('Submit review success!')
          setLoadingSaveReview(false)
          navigate(-1)
        }
      })
      .catch((err) => {
        if (err.response) {
          setErrorReview(err.response.data.message)
          toast.error(err.response.data.message)
        } else {
          setErrorReview(err.message)
          toast.error(err.message)
        }
        setLoadingSaveReview(false)
      })
  }

  return (
    <div className="py-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="mb-4">
            <BreadCrumbs>
              <BreadCrumbs.Item href={'/projects'}>Project</BreadCrumbs.Item>
              <BreadCrumbs.Item current>{project.name}</BreadCrumbs.Item>
            </BreadCrumbs>
          </div>

          <div className="mb-12">
            <h1 className="font-semibold text-giant mb-4">Project Information</h1>
            <div
              className={cn(
                'grid gap-4',
                ['need_revision', 'in_review_vp'].includes(updatedProject.status) ? 'grid-cols-3' : 'grid-cols-2'
              )}
            >
              <div className="border border-gray-border rounded-[5px] p-4">
                <div className="grid gap-4">
                  <div>
                    <Label className="mb-2">Project Name</Label>
                    <p className="text-main-500/70">
                      <StatusCell config={stateCellConfig} data={updatedProject.status} className={'font-medium'} />
                    </p>
                  </div>
                  <div>
                    <Label>Project Name</Label>
                    <p className="text-main-500/70">{updatedProject.name}</p>
                  </div>
                  <div>
                    <Label>Description</Label>
                    <p className="text-main-500/70">{updatedProject.description || '-'}</p>
                  </div>
                  <div className="mb-4">
                    <Label>Project Type</Label>
                    <p className="text-main-500/70 capitalize">{updatedProject.type.replace('_', ' ')}</p>
                  </div>
                  <div className="mb-4">
                    <Label>Topic</Label>
                    <p className="text-main-500/70 capitalize">{updatedProject.topic?.replace('_', ' ') || '-'}</p>
                  </div>
                </div>
              </div>
              <div className="border border-gray-border rounded-[5px] p-4">
                <div className="grid gap-4">
                  <div>
                    <Label className="mb-2">Client</Label>
                    <div className="flex gap-2 items-center">
                      <Avatar className="w-8 h-8 rounded">
                        <AvatarImage src={updatedProject.client.logo} alt={updatedProject.client.company_name} />
                        <AvatarFallback>KD</AvatarFallback>
                      </Avatar>
                      <p className="font-medium">{updatedProject.client.company_name}</p>
                    </div>
                  </div>
                  <div>
                    <Label>Commitment Year</Label>
                    <p className="text-main-500/70">{updatedProject.commitment.year}</p>
                  </div>
                  <div>
                    <Label>Available Commitment</Label>
                    <p className="text-main-500/70">
                      Rp{formatThousands(updatedProject.commitment.available_commitment || 0)}
                    </p>
                  </div>
                  <div className="mb-4">
                    <Label>Project Value</Label>
                    <p className="text-main-500/70 capitalize">Rp{formatThousands(updatedProject.total_revenue)}</p>
                  </div>
                  <div className="mb-4">
                    <Label>Set as Commitment Project</Label>

                    <GroupButton
                      className="w-48"
                      defaultValue={updatedProject.set_as_commitment_project ? 'yes' : 'no'}
                      items={[
                        {
                          value: 'yes',
                          label: 'Yes',
                          ariaLabel: 'Yes',
                          disabled: updatedProject.commitment.available_commitment < updatedProject.total_revenue,
                        },
                        { value: 'no', label: 'No', ariaLabel: 'No' },
                      ]}
                      onValueChange={(value) => {
                        if (
                          value === 'yes' &&
                          updatedProject.commitment.available_commitment < updatedProject.total_revenue
                        ) {
                          toast.error("The client's available commitment is insufficient.")
                          return
                        }
                        setUpdatedProject({
                          ...updatedProject,
                          set_as_commitment_project: value === 'yes' ? true : false,
                          commitment_id: updatedProject.commitment.id,
                        })
                      }}
                    />
                    {updatedProject.commitment.available_commitment < updatedProject.total_revenue && (
                      <p className="text-xs mt-1 text-black/30">The client's available commitment is insufficient.</p>
                    )}
                  </div>
                </div>
              </div>
              {updatedProject.status === 'need_revision' && (
                <div className="border border-gray-border rounded-[5px] p-4">
                  <div className="grid gap-4">
                    <div>
                      <Label className="mb-2">Note From VP</Label>
                      <p className="text-main-500/80">{updatedProject.vp_review_note}</p>
                    </div>
                  </div>
                </div>
              )}
              {isVP && updatedProject.status === 'in_review_vp' && (
                <div className="border border-gray-border rounded-[5px] p-4">
                  <div className="grid gap-4">
                    <div>
                      <Label className="mb-2">Note From sm</Label>
                      <p className="text-main-500/80">{updatedProject.sm_review_note}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <RevenueItemsTable
            project={updatedProject}
            setProject={setUpdatedProject}
            isEditable={project.sales_deal.status !== 'revenue' ? true : false}
          />
          <EditableCOGSItemsTable project={updatedProject} setProject={setUpdatedProject} />
          <div className="mb-12">
            <h1 className="text-giant mb-4 font-semibold">Margin</h1>
            <Table
              tableType="inline"
              noIndex={true}
              cols={marginCols}
              data={[
                {
                  id: 0,
                  row: 'Revenue',
                  totalAmount: updatedProject.total_revenue,
                  percentage:
                    updatedProject.total_revenue !== 0
                      ? 1 - updatedProject.total_cogs / updatedProject.total_revenue
                      : 0,
                },
                {
                  id: 1,
                  row: 'COGS',
                  totalAmount: updatedProject.total_cogs,
                  percentage:
                    updatedProject.total_revenue !== 0 ? updatedProject.total_cogs / updatedProject.total_revenue : 1,
                },
              ]}
            />
          </div>
          {isSM && updatedProject.status === 'in_review_sm' && (
            <>
              <div className="mb-8">
                <h1 className="text-giant mb-4 font-semibold">Review Result</h1>
                <div className="flex gap-8 mb-4">
                  <div className="1">
                    <p className="font-semibold mb-2">Review Result</p>
                    <RadioButton
                      label={'Approved Directly'}
                      className="mb-2"
                      checked={review.result === 'approved'}
                      onChange={() => setReview({ ...review, result: 'approved' })}
                    />
                    <RadioButton
                      label={'Review by VP'}
                      className="mb-2"
                      checked={review.result === 'in_review_vp'}
                      onChange={() => setReview({ ...review, result: 'in_review_vp' })}
                    />
                  </div>
                  <div className="w-[500px]">
                    <Textarea
                      rows={5}
                      label={'Review Notes'}
                      placeholder="Write your notes here..."
                      onChange={(e) => setReview({ ...review, note: e.target.value })}
                    />
                  </div>
                </div>
                {errorReview && <Alert type={'error'} message={errorReview} className={'w-[632px]'} />}
              </div>
              <div className="flex justify-end gap-4 mb-12">
                <Button onClick={() => saveReviewSM()} isLoading={isLoadingSaveReview}>
                  <NotebookPen className="w-5 h-5" />
                  Submit Review
                </Button>
              </div>
            </>
          )}
          {isSM && ['need_revision', 'approved'].includes(updatedProject.status) && (
            <>
              <div className="mb-8">
                <h1 className="text-giant mb-4 font-semibold">Review Result</h1>
                <div className="flex gap-8 mb-4">
                  <div className="w-[500px]">
                    <Textarea
                      rows={5}
                      label={'Review Notes'}
                      placeholder="Write your notes here..."
                      onChange={(e) => setReview({ ...review, note: e.target.value })}
                    />
                  </div>
                </div>
                {errorReview && <Alert type={'error'} message={errorReview} className={'w-[632px]'} />}
              </div>
              <div className="flex justify-end gap-4 mb-12">
                <Button onClick={() => saveReviewSM('in_review_vp')} isLoading={isLoadingSaveReview}>
                  <NotebookPen className="w-5 h-5" />
                  Submit Review
                </Button>
              </div>
            </>
          )}
          {isVP && updatedProject.status === 'in_review_vp' && (
            <>
              <div className="mb-8">
                <h1 className="text-giant mb-4 font-semibold">Review Result</h1>
                <div className="flex gap-8 mb-4">
                  <div className="1">
                    <p className="font-semibold mb-2">Review Result</p>
                    <RadioButton
                      label={'Approved'}
                      className="mb-2"
                      checked={review.result === 'approved'}
                      onChange={() => setReview({ ...review, result: 'approved' })}
                    />
                    <RadioButton
                      label={'Need revision from SM'}
                      className="mb-2"
                      checked={review.result === 'need_revision'}
                      onChange={() => setReview({ ...review, result: 'need_revision' })}
                    />
                  </div>
                  <div className="w-[500px]">
                    <Textarea
                      rows={5}
                      label={'Notes'}
                      placeholder="Write your notes here..."
                      onChange={(e) => setReview({ ...review, note: e.target.value })}
                    />
                  </div>
                </div>
                {errorReview && <Alert type={'error'} message={errorReview} className={'w-[632px]'} />}
              </div>
              <div className="flex justify-end gap-4 mb-12">
                <Button onClick={() => saveReviewedFromVP()} isLoading={isLoadingSaveReview}>
                  <NotebookPen className="w-5 h-5" />
                  Submit Review
                </Button>
              </div>
            </>
          )}
          {isVP && updatedProject.status === 'approved' && (
            <>
              <div className="mb-8">
                <h1 className="text-giant mb-4 font-semibold">Review Result</h1>
                <div className="flex gap-8 mb-4">
                  <div className="w-[500px]">
                    <Textarea
                      rows={5}
                      label={'Review Notes'}
                      placeholder="Write your notes here..."
                      onChange={(e) => setReview({ ...review, note: e.target.value })}
                    />
                  </div>
                </div>
                {errorReview && <Alert type={'error'} message={errorReview} className={'w-[632px]'} />}
              </div>
              <div className="flex justify-end gap-4 mb-12">
                <Button onClick={() => saveReviewedFromVP('approved')} isLoading={isLoadingSaveReview}>
                  <NotebookPen className="w-5 h-5" />
                  Submit Review
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
