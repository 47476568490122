import React, { useEffect, useState } from 'react'
import Input from '../../components/Input'
import DragAndDropFile from '../../components/DragAndDropFile'
import DropdownDatePicker from '../../components/DropdownDatePicker'
import moment from 'moment'
import Button from '../../components/Button'
import AccountAPI from '../../apis/AccountAPI'
import { useSelector } from 'react-redux'
import Alert from '../../components/Alert'
import { Edit } from '@mui/icons-material'
import { Dialog } from '@mui/material'
import AvatarUpload from '../../components/AvatarUpload'
import Loading from '../../components/Loading'
import { useDispatch } from 'react-redux'
import { saveDetailUserData } from '../../redux/authSlice'
import DismissableToast from '../../components/Toast'
import { toast } from 'react-hot-toast'

export default function UpdateProfile() {
  const user = useSelector((state) => state.auth.firebaseUser)
  const [refresh, setRefresh] = useState(Math.random() * 1000000)
  const [isLoading, setLoading] = useState(true)
  const [profile, setProfile] = useState({
    name: '',
    dob: '',
    phone_number: '',
    bio: '',
    avatar: '',
  })
  const [isLoadingSave, setLoadingSave] = useState(false)
  const [responseUpdate, setResponseUpdate] = useState({
    success: '',
    error: '',
  })
  const [dpChanged, setDPChanged] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    // get profile
    AccountAPI.getSingleAccountByUID(user.uid).then((res) => {
      setProfile({
        name: res.data.name,
        bio: res.data.bio,
        dob: res.data.dob,
        phone_number: res.data.phone_number,
        avatar: res.data.avatar,
      })
      setLoading(false)
    })
  }, [refresh])

  const handleChange = (input) => {
    setProfile({ ...profile, [input.name]: input.value })
  }

  const handleSave = (e) => {
    e.preventDefault()
    console.log(profile)
    setLoadingSave(true)
    let allowSave = true

    for (let key in profile) {
      if (profile[key] === '') {
        allowSave = false
        break
      }
    }

    console.log(allowSave)

    if (allowSave) {
      AccountAPI.updateProfile(user.uid, profile, dpChanged)
        .then((res) => {
          if (res.success) {
            AccountAPI.getSingleAccountByUID(user.uid).then((res) => {
              dispatch(saveDetailUserData(res.data))
            })
            setRefresh(Math.random() * 1000000)
            setResponseUpdate({ success: res.data.message, error: '' })
            setLoadingSave(false)
            toast.success('Profile updated!')
          }
        })
        .catch((err) => {
          if (err.response) {
            setResponseUpdate({ success: '', error: err.response.data.message })
          } else {
            setResponseUpdate({ success: '', error: err.statusText })
          }
          toast.error('Something went wrong!')
          setLoadingSave(false)
        })
    } else {
      setResponseUpdate({ success: '', error: 'Please fill all fields' })
      setLoadingSave(false)
    }
  }

  return (
    <div className="w-[400px]">
      <h2 className="text-giant mb-8 font-semibold">Profile</h2>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <DismissableToast />
          <AvatarUpload
            src={profile.avatar}
            className="mb-8"
            onChange={(blob) => {
              setProfile({ ...profile, avatar: blob })
              setDPChanged(true)
            }}
          />
          <form className="grid gap-4" onSubmit={handleSave}>
            <Input
              label={'Name'}
              name="name"
              onChange={(e) => handleChange(e.target)}
              placeholder="Your Name"
              value={profile.name}
            />
            <Input
              label={'Phone Number'}
              name="phone_number"
              type={'number'}
              value={profile.phone_number}
              onChange={(e) => handleChange(e.target)}
              placeholder="0812xxxxxxx"
            />
            <Input
              label={'Bio'}
              name="bio"
              onChange={(e) => handleChange(e.target)}
              placeholder="Set Bio"
              value={profile.bio}
            />
            <DropdownDatePicker
              handleDatePicked={(date) => setProfile({ ...profile, dob: date })}
              defaultValue={moment(profile.dob).format('YYYY-MM-DD')}
            >
              <Input
                label={'Date of Birth'}
                name="dob"
                value={moment(profile.dob).format('DD-MM-YYYY')}
                onChange={(e) => handleChange(e.target)}
                placeholder="Birth day"
                // disabled
              />
            </DropdownDatePicker>
            {/* <div className="h-[175px]">
          <p className="font-semibold mb-1">Avatar</p>
          <DragAndDropFile onChange={(file) => setProfile({ ...profile, avatar: file })} className={'py-8'} />
        </div> */}
            <div className="flex gap-4">
              <Button type="submit" className={'w-fit'} isLoading={isLoadingSave} onClick={handleSave}>
                Update Profile
              </Button>
              {responseUpdate.success && <Alert type={'success'} message={responseUpdate.success} />}
              {responseUpdate.error && <Alert type={'error'} message={responseUpdate.error} />}
            </div>
          </form>{' '}
        </>
      )}
    </div>
  )
}
