import React from 'react'
import { Link, Route, Routes, useLocation, useParams } from 'react-router-dom'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import EditClient from './EditClient'

export default function ClientRouter() {
  const { pathname } = useLocation()
  const { id } = useParams()

  console.log(pathname)
  return (
    <div className="py-4">
      <Tabs value={pathname} className="w-fit">
        <TabsList className="w-full justify-start">
          <Link to={`/data/clients/${id}/detail`}>
            <TabsTrigger value={`/data/clients/${id}/detail`}>Details</TabsTrigger>
          </Link>
          <Link to={`/data/clients/${id}/commitments`}>
            <TabsTrigger value={`/data/clients/${id}/commitments`}>Commitment</TabsTrigger>
          </Link>
          <Link to={`/data/clients/${id}/commitment-logs`}>
            <TabsTrigger value={`/data/clients/${id}/commitment-logs`}>Commitment Logs</TabsTrigger>
          </Link>
        </TabsList>
      </Tabs>
      <div className="py-4">
        <Routes>
          <Route path="/detail" element={<EditClient />} />
          <Route path="/commitment" element={null} />
          <Route path="/commitment-logs" exact element={null} />
        </Routes>
      </div>
    </div>
  )
}
