import {
  Bell,
  Building,
  Calendar,
  CircleUser,
  Component,
  Goal,
  HandCoins,
  Home,
  Inbox,
  ReceiptText,
  Search,
  Settings,
  ShoppingBasket,
  ShoppingCart,
  Speech,
  Wallet,
} from 'lucide-react'

import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '@/components/ui/sidebar'
import { Link, useLocation } from 'react-router-dom'
import { cn } from '@/lib/utils'
HandCoins
// Menu items.
const items = [
  {
    title: 'Products',
    url: '/data/products',
    icon: ShoppingBasket,
  },
  {
    title: 'Clients',
    url: '/data/clients',
    icon: Building,
  },
  {
    title: 'Commitment',
    url: '/data/commitment',
    icon: Wallet,
  },
  {
    title: 'COGS Items',
    url: '/data/cogs',
    icon: ShoppingCart,
  },
  {
    title: 'Accounts',
    url: '/data/account-role',
    icon: CircleUser,
  },
  {
    title: 'Target',
    url: '/data/target',
    icon: Goal,
  },
  {
    title: 'Reimbursement',
    url: '/data/reimbursement',
    icon: ReceiptText,
  },
  {
    title: 'Invoice Requests',
    url: '/data/invoice-requests',
    icon: HandCoins,
  },
  {
    title: 'Agency',
    url: '/data/agency',
    icon: Speech,
  },
  {
    title: 'SBU',
    url: '/data/sbu',
    icon: Component,
  },
  {
    title: 'Notification',
    url: '/data/notification',
    icon: Bell,
  },
]

export function DataSidebar() {
  const { pathname } = useLocation()
  console.log(pathname)
  return (
    <Sidebar className="min-h-full">
      <SidebarContent className="bg-white">
        <SidebarGroup className="p-2.5">
          {/* <SidebarGroupLabel>Application</SidebarGroupLabel> */}
          <SidebarGroupContent>
            <SidebarMenu>
              {items.map((item) => (
                <SidebarMenuItem
                  key={item.title}
                  className={cn(
                    'rounded-lg overflow-hidden font-medium',
                    pathname.startsWith(item.url) ? 'bg-primary-blue/20 text-main-500' : ''
                  )}
                >
                  <SidebarMenuButton asChild className="p-4 h-fit hover:bg-main-50/50">
                    <Link to={item.url}>
                      <item.icon className="w-5 h-5" />
                      <span>{item.title}</span>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  )
}
