import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../components/BreadCrumbs'
import DropdownInput from '../../components/DropdownInput'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../../components/Button'
import Alert from '../../components/Alert'
import AccountAPI from '../../apis/AccountAPI'
import LoadingSpinner from '../../components/LoadingSpinner'
import { setSelectedSM } from '../../redux/performanceSlice'
import DismissableToast from '../../components/Toast'
import { toast } from 'react-hot-toast'
import { sleep } from '../../helper'

export default function EditAE() {
  const { id } = useParams()
  const [ae, setAE] = useState()
  const [updatedAE, setUpdatedAE] = useState({
    ae_id: parseInt(id),
    sm_id: undefined,
    seniority_level: undefined,
    status: undefined,
    sbu_id: undefined,
  })
  const [listSM, setlistSM] = useState([])
  const [listStatus, setListStatus] = useState([
    {
      id: 0,
      text: 'Active',
      value: 'active',
    },
    {
      id: 1,
      text: 'Inactive',
      value: 'inactive',
    },
  ])
  const [listLevel, setListLevel] = useState([
    {
      id: 0,
      text: 'Senior',
      value: 'senior',
    },
    {
      id: 2,
      text: 'Mid',
      value: 'mid',
    },
    {
      id: 1,
      text: 'Junior',
      value: 'junior',
    },
  ])
  const [selectedLevel, setSelectedLevel] = useState()
  const [selectedSM, setSelectedSM] = useState()
  const [selectedStatus, setSelectedStatus] = useState()
  const [errorSaveAE, setErrorSaveAE] = useState()
  const [isLoadingSaveAE, setLoadingSaveAE] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    console.log(id)
    var promises = [
      new Promise((resolve, reject) => {
        AccountAPI.getSingleAccount(id)
          .then((res) => {
            setAE(res.data[0])
            setUpdatedAE({ ...updatedAE, sm_id: res.data[0].sm_id, status: res.data[0].status })
            resolve(res.data[0])
          })
          .catch((err) => {
            console.log(err)
            reject()
          })
      }),
      new Promise((resolve, reject) => {
        AccountAPI.getList('sm')
          .then((res) => {
            setlistSM(res.data)
            setLoading(false)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
            reject()
          })
      }),
    ]
    Promise.all(promises).then((res) => {
      console.log('hi')
      var tempSM = res[1].find((item) => item.id === res[0].sales_manager_id)
      let tempLevel = listLevel.find((level) => level.value === res[0].ae_seniority_level)
      let tempStatus = listStatus.find((status) => status.value === res[0].status)
      // console.log(tempStatus)
      setSelectedSM(tempSM)
      setSelectedLevel(tempLevel)
      setSelectedStatus(tempStatus)
      setUpdatedAE({
        ...updatedAE,
        sm_id: res[0].sales_manager_id,
        sbu_id: res[0].sbu_id,
        seniority_level: tempLevel.value,
        status: res[0].status,
      })
      setLoading(false)
    })
  }, [id])

  useEffect(() => {
    console.log(updatedAE)
  }, [updatedAE])

  const handleSaveAE = () => {
    setLoadingSaveAE(true)
    if (updatedAE.seniority_level && updatedAE.sm_id && updatedAE.status) {
      AccountAPI.updateAEAccount(updatedAE)
        .then((res) => {
          if (res.success) {
            toast.success('Account updated!')
            sleep(3000).then((res) => navigate(-1))
          }
        })
        .catch((err) => {
          if (err.response) {
            setErrorSaveAE(err.response.data.message)
          } else setErrorSaveAE(err.statusText)
          setLoadingSaveAE(false)
        })
    } else {
      setErrorSaveAE('Please complete all fields!')
      setLoadingSaveAE(false)
    }
  }

  return (
    <div className="py-4">
      <h1 className="font-semibold text-giant mb-4">Edit AE</h1>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="p-4 border rounded-[5px] mb-4">
            <p className="font-semibold mb-1">Account Executive</p>
            <p className="mb-4">{ae.name}</p>
            <div className="w-[400px] mb-4">
              <p className="font-semibold mb-1">Sales Manager</p>
              <DropdownInput
                data={listSM}
                value={selectedSM}
                attributeShown="name"
                handleSelectedOption={(selected) => {
                  setUpdatedAE({ ...updatedAE, sm_id: selected.id, sbu_id: selected.sbu_id })
                  setSelectedSM(selectedSM)
                }}
                placeholder="Pilih SM"
              />
            </div>
            <div className="w-[400px] mb-4">
              <p className="font-semibold mb-1">Level</p>
              <DropdownInput
                data={listLevel}
                attributeShown="text"
                value={selectedLevel}
                handleSelectedOption={(selected) => {
                  setUpdatedAE({ ...updatedAE, seniority_level: selected.value })
                  setSelectedLevel(selected)
                }}
                placeholder="Pilih Level"
              />
            </div>
            <div className="w-[400px] mb-4">
              <p className="font-semibold mb-1">Status</p>
              <DropdownInput
                data={listStatus}
                attributeShown="text"
                value={selectedStatus}
                handleSelectedOption={(selected) => {
                  setUpdatedAE({ ...updatedAE, status: selected.value })
                  setSelectedStatus(selected)
                }}
                placeholder="Pilih Status"
              />
            </div>
          </div>
          <div className="flex gap-4">
            <Button onClick={handleSaveAE} isLoading={isLoadingSaveAE}>
              Save
            </Button>
            {errorSaveAE && <Alert type={'error'} message={errorSaveAE} />}
          </div>
        </>
      )}
    </div>
  )
}
