import React, { useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Component, Info, ListTodo, PenSquare, ShoppingBasket, Trash2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { z } from 'zod'
import { hasPermission } from '@/lib/hasPermission'
import useAuth from '@/hooks/useAuth'
import { useCustomSearchParams } from '@/hooks/useCustomSearchParams'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Sheet, SheetClose, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'
import { Switch } from '@/components/ui/switch'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { Combobox } from '@/components/Combobox'
import Loading from '@/components/Loading'
import Pagination from '@/components/Pagination'
import Basic from '@/components/table/cell/Basic'
import StatusCell from '@/components/table/cell/StatusCell'
import Table from '@/components/table/Table'
import { ProductsAPI } from '@/apis/ProductsAPI'
import SBUAPI from '@/apis/SBUAPI'
import { queryClient } from '@/App'

export default function Subproduct() {
  const { user } = useAuth()
  const [searchParams, setSearchParams] = useCustomSearchParams()
  const [defaultSelected, setDefaultSelected] = useState({})
  const [colsSubproduct, setColsSubproduct] = useState([
    {
      id: 0,
      name: 'SBU',
      selector: (row) => <Basic data={row.sbu_name} />,
      classes: 'min-w-[150px]',
    },
    {
      id: 1,
      name: 'Product',
      selector: (row) => <Basic data={row.product_name} />,
      classes: 'min-w-[150px]',
    },
    {
      id: 2,
      name: 'Sub Product',
      selector: (row) => <Basic data={row.sub_product_name} />,
      classes: 'min-w-[150px]',
    },
    {
      id: 3,
      name: 'Status',
      selector: (row) => (
        <StatusCell
          data={row.sub_product_status}
          config={[
            { state: 'danger', text: 'Inactive', status: 'inactive' },
            { state: 'success', text: 'Active', status: 'active' },
            { state: 'neutral', text: 'Deleted', status: 'deleted' },
          ]}
        />
      ),
      classes: 'min-w-[150px]',
    },
  ])
  const [selectedSubproduct, setSelectedSubproduct] = useState({})
  const [saveError, setSaveError] = useState('')
  const [meta, setMeta] = useState({ total_page: 1 })
  const form = useForm({
    resolver: zodResolver(
      z.object({
        name: z.string().min(1, { message: 'This field is required!' }),
        product_id: z.string({ required_error: 'This field is required!' }),
      })
    ),
    defaultValues: {
      name: '',
      product_id: '',
    },
  })
  const { setValue, getValues, handleSubmit } = form

  const products = useQuery({
    queryKey: ['products'],
    queryFn: async () => {
      var res = await ProductsAPI.getProductOnly(undefined, undefined, undefined, true)
      console.log(res.data)
      return res.data
    },
  })
  const subproduct = useQuery({
    queryKey: [
      'products',
      'subproducts',
      { sbu: searchParams.sbu, product: searchParams.product, status: searchParams.status, page: searchParams.page },
    ],
    queryFn: async () => {
      var res = await ProductsAPI.getSubproduct(
        searchParams.sbu || undefined,
        searchParams.product || undefined,
        searchParams.status || undefined,
        searchParams.page
      )

      setMeta(res.meta)

      // if (searchParams.edit || searchParams.delete || searchParams.changeStatus) {
      //   const temp =
      //     res.data.find(
      //       (item) =>
      //         item.sub_product_id === parseInt(searchParams.edit || searchParams.delete || searchParams.changeStatus)
      //     ) || {}
      //   setDefaultSelected(temp)
      // }
      return res.data
    },
  })

  const sbu = useQuery({
    queryKey: ['sbu'],
    queryFn: async () => {
      var res = await SBUAPI.getAllSBU()
      console.log(res.data)
      return res.data
    },
  })

  const mutateSubroduct = useMutation({
    mutationFn: async (data) => {
      var res = undefined
      if (searchParams.edit) {
        res = await ProductsAPI.updateSubproduct(searchParams.edit, data)
      } else {
        res = await ProductsAPI.createSubproduct(data)
      }
      return res
    },
    onSuccess: (res) => {
      if (!res.success) {
        throw res
      }
      toast.success(res.message)
      queryClient.invalidateQueries(['products', 'subproducts'])
      setSearchParams(delete searchParams.edit)
      setSearchParams(delete searchParams.create)
      setSaveError('')
      form.reset()
    },
    onError: (err) => {
      setSaveError(
        err.data?.message || err?.response.data.message || err.statusText || 'Something went wrong while updating data'
      )
    },
  })

  const changeStatusSubproduct = useMutation({
    mutationFn: async ({ subproductId, status }) => {
      const res = await ProductsAPI.updateStatusSubproduct(subproductId, status)
      return res
    },
    onSuccess: (res) => {
      console.log(res)
      if (!res.success) throw res

      queryClient.invalidateQueries(['products', 'subproducts'])
      toast.success(res.message)
      setSearchParams(delete searchParams.changeTo)
      setSearchParams(delete searchParams.changeStatus)
    },
    onError: (err) => {
      console.log(err)
      toast.error(
        err.data?.message || err?.response?.data.message || err.statusText || 'Something went wrong while updating data'
      )
    },
  })

  const deleteSubproduct = useMutation({
    mutationFn: async (subproductId) => {
      const res = await ProductsAPI.deleteSubproduct(subproductId)
      return res
    },
    onSuccess: (res) => {
      if (!res.success) {
        throw res
      }
      queryClient.invalidateQueries(['products', 'subproducts'])
      toast.success('Delete subproduct success!')
      form.reset()
      setSearchParams(delete searchParams.delete)
    },
    onError: (err) => {
      console.log(err)
      toast.error(err?.response.data.message || err.statusText || 'Something went wrong while deleting product!')
    },
  })

  useEffect(() => {
    setSaveError('')
    if (subproduct.isLoading) {
      return
    }

    if (searchParams.create) {
      form.reset()
      return
    }

    if (searchParams.edit) {
      const temp = subproduct?.data?.find((item) => item.sub_product_id === parseInt(searchParams.edit))
      setSelectedSubproduct(temp)
      form.setValue('product_id', temp.product_id.toString())
      form.setValue('name', temp.sub_product_name)
      return
    }
    if (searchParams.delete) {
      const temp = subproduct?.data?.find((item) => item.sub_product_id === parseInt(searchParams.delete))
      setSelectedSubproduct(temp)
      return
    }
    if (searchParams.changeStatus) {
      const temp = subproduct?.data?.find((item) => item.sub_product_id === parseInt(searchParams.changeStatus))
      setSelectedSubproduct(temp)
      return
    }
  }, [searchParams.edit, searchParams.create, searchParams.delete, searchParams.changeStatus, subproduct.isLoading])

  return (
    <div>
      <div className="flex gap-4 items-center mb-4">
        <div className="flex-1 flex gap-2">
          <Combobox
            className={'w-72'}
            options={sbu.isLoading ? [] : sbu.data.map((item) => ({ label: item.sbu_name, value: item.sbu_id }))}
            label={'SBU'}
            placeholder={'All'}
            icon={<Component />}
            isLoading={sbu.isLoading}
            defaultValue={parseInt(searchParams.sbu) || ''}
            onSelect={(value) => setSearchParams({ ...searchParams, sbu: value })}
          />
          <Combobox
            className={'w-72'}
            options={products.isLoading ? [] : products.data.map((item) => ({ label: item.name, value: item.id }))}
            label={'Product'}
            placeholder={'All'}
            icon={<ShoppingBasket />}
            isLoading={products.isLoading}
            defaultValue={parseInt(searchParams.product) || ''}
            onSelect={(value) => setSearchParams({ ...searchParams, product: value })}
          />
          <Combobox
            className={'w-64'}
            options={[
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' },
            ]}
            label={'Status'}
            placeholder={'All'}
            icon={<ListTodo />}
            defaultValue={searchParams.status || 'active'}
            onSelect={(value) => setSearchParams({ ...searchParams, status: value })}
          />
        </div>
        {hasPermission(user, 'data.subproduct.create') && (
          <Button onClick={() => setSearchParams({ ...searchParams, create: true })}>Create New Sub Product</Button>
        )}
      </div>
      {subproduct.isLoading ? (
        <Loading />
      ) : (
        <div className="overflow-x-auto">
          <Table
            data={subproduct.data || []}
            cols={colsSubproduct}
            pageIndex={parseInt(searchParams.page) || 1}
            customAction={
              !hasPermission(user, 'data.subproduct.edit')
                ? undefined
                : (row) => (
                    <>
                      {row.status === 'deleted' ? null : (
                        <>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <div>
                                  <Switch
                                    id="status"
                                    checked={row.sub_product_status === 'active'}
                                    onCheckedChange={(value) => {
                                      console.log(value)
                                      setSearchParams({
                                        ...searchParams,
                                        changeTo: value ? 'active' : 'inactive',
                                        changeStatus: row.sub_product_id,
                                      })
                                    }}
                                  />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>Change Status</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                          {/* <Label htmlFor="airplane-mode">Status</Label> */}
                          <Button
                            variant={'outline'}
                            size={'icon'}
                            onClick={() => {
                              setSearchParams({ ...searchParams, edit: row.sub_product_id })
                            }}
                          >
                            <PenSquare className="w-5 h-5" />
                          </Button>

                          <Button
                            variant={'outline'}
                            size={'icon'}
                            onClick={() => {
                              setSearchParams({ ...searchParams, delete: row.sub_product_id })
                            }}
                          >
                            <Trash2 className="w-5 h-5" />
                          </Button>
                        </>
                      )}
                    </>
                  )
            }
          />
          <Pagination
            currentPage={parseInt(searchParams.page) || 1}
            totalPage={meta.total_page || 1}
            onChange={(page) => setSearchParams({ ...searchParams, page: page })}
          />
        </div>
      )}
      <Dialog open={!!searchParams.delete} onOpenChange={() => setSearchParams(delete searchParams.delete)}>
        <DialogContent className="w-full max-w-[500px]">
          <DialogHeader className={'mb-2.5'}>
            <DialogTitle className={'text-xl font-semibold'}>Are you sure to delete the subproduct?</DialogTitle>
          </DialogHeader>
          <div>
            <p className="font-semibold">Subproduct</p>
            <p className="text-main-500/70">{selectedSubproduct?.sub_product_name}</p>
          </div>
          <Alert variant="warning" size="sm">
            <AlertDescription className="flex items-start gap-2">
              <Info className="w-6 h-6" />
              <p>
                This action will result in the deletion of all variants of the subproduct, making it inaccessible to all
                users.
              </p>
            </AlertDescription>
          </Alert>
          <div className="flex gap-2">
            <Button variant="outline" className="flex-1">
              Cancel
            </Button>
            <Button
              variant="destructive"
              className="flex-1"
              onClick={() => deleteSubproduct.mutate(selectedSubproduct?.sub_product_id)}
              isLoading={deleteSubproduct.isLoading}
              disabled={deleteSubproduct.isLoading}
            >
              Yes, proceed
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={!!searchParams.changeStatus} onOpenChange={() => setSearchParams(delete searchParams.changeStatus)}>
        <DialogContent className="w-full max-w-[500px]">
          <DialogHeader className={'mb-2.5'}>
            <DialogTitle className={'text-xl font-semibold'}>Change status to {searchParams.changeTo}?</DialogTitle>
          </DialogHeader>
          <div>
            <p className="font-semibold">Subroduct</p>
            <p className="text-main-500/70">{selectedSubproduct?.sub_product_name}</p>
          </div>
          <Alert variant="warning" size="sm">
            <AlertDescription className="flex items-start gap-2">
              <Info className="w-6 h-6" />
              <p>
                This action will result in the{' '}
                {searchParams.changeTo === 'inactive'
                  ? 'deactivation'
                  : searchParams.changeTo === 'active'
                    ? 'activation'
                    : ''}
                of all variants of the sub-products, making it inaccessible to all users.
              </p>
            </AlertDescription>
          </Alert>
          <div className="flex gap-2">
            <Button variant="outline" className="flex-1">
              Cancel
            </Button>
            <Button
              variant={searchParams.changeTo === 'inactive' ? 'destructive' : ''}
              className="flex-1"
              onClick={() =>
                changeStatusSubproduct.mutate({
                  subproductId: selectedSubproduct?.sub_product_id,
                  status: searchParams.changeTo,
                })
              }
              isLoading={changeStatusSubproduct.isLoading}
              disabled={changeStatusSubproduct.isLoading}
            >
              Yes, proceed
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Sheet
        open={!!searchParams.edit || !!searchParams.create}
        onOpenChange={() => setSearchParams(delete searchParams.edit)}
      >
        <SheetContent>
          <SheetHeader className={'mb-4'}>
            <SheetTitle className="text-xl">Edit Product</SheetTitle>
          </SheetHeader>
          {subproduct.isLoading ? (
            <Loading />
          ) : (
            <Form {...form}>
              <form onSubmit={handleSubmit(mutateSubroduct.mutate)} className="grid gap-2.5">
                <FormField
                  control={form.control}
                  name="product_id"
                  render={({ field }) => {
                    console.log(field)
                    return (
                      <FormItem>
                        <FormLabel>Product</FormLabel>
                        <Select
                          onValueChange={(value) => field.onChange(value)}
                          defaultValue={field.value.toString()}
                          value={field.value.toString()}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select Product" />
                            </SelectTrigger>
                          </FormControl>
                          {!products.isLoading && (
                            <SelectContent>
                              {products.data.map((product) => (
                                <SelectItem key={product.id} value={product.id.toString()}>
                                  {product.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          )}
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => {
                    console.log(field)
                    return (
                      <FormItem>
                        <FormLabel>Product Name</FormLabel>
                        <FormControl>
                          <Input placeholder="" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />
                {saveError && (
                  <Alert variant="destructive">
                    <AlertDescription>{saveError}</AlertDescription>
                  </Alert>
                )}
                <div className="flex gap-2.5">
                  <SheetClose asChild>
                    <Button className="w-full" variant="outline">
                      Close
                    </Button>
                  </SheetClose>
                  <Button className="w-full" isLoading={mutateSubroduct.isLoading} disabled={mutateSubroduct.isLoading}>
                    Save
                  </Button>
                </div>
              </form>
            </Form>
          )}
        </SheetContent>
      </Sheet>
    </div>
  )
}
