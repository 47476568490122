import React, { useEffect, useState } from 'react'
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import ProjectFeasibility from './Projects/ProjectFeasibility'
import ProjectFlagship from './Projects/ProjectFlagship'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'

export default function Project() {
  const { pathname } = useLocation()
  return (
    <div className="py-4">
      {/* <Tabs
        data={[
          { id: 1, name: 'Project Feasibility', link: '' },
          { id: 2, name: 'Flagship Projects', link: 'flagship' },
        ]}
      /> */}
      <div className="w-fit">
        <Tabs value={pathname.split('/').pop()}>
          <TabsList className="w-full justify-start mb-4">
            <Link to={`/projects`}>
              <TabsTrigger value="projects">Projects Feasibility</TabsTrigger>
            </Link>
            <Link to={`/projects/flagship`}>
              <TabsTrigger value="flagship">Flagship Project</TabsTrigger>
            </Link>
          </TabsList>
        </Tabs>
      </div>
      <Routes>
        <Route path="/" element={<ProjectFeasibility />} />
        <Route path="/flagship" element={<ProjectFlagship />} />
      </Routes>
    </div>
  )
}
