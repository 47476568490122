import React, { useEffect, useState } from 'react'
import { ChevronLeft, ChevronRight, Close } from '@mui/icons-material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Info, Search, SquarePen, Trash, Trash2 } from 'lucide-react'
import toast from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'
import { hasPermission } from '@/lib/hasPermission'
import useAuth from '@/hooks/useAuth'
import { useCustomSearchParams } from '@/hooks/useCustomSearchParams'
import usePermission from '@/hooks/usePermission'
import { Alert, AlertDescription } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import DropdownInput from '@/components/DropdownInput'
import Loading from '@/components/Loading'
import Pagination from '@/components/Pagination'
import LogoCell from '@/components/table/cell/LogoCell'
import Table from '@/components/table/Table'
import ClientAPI from '@/apis/ClientAPI'
import { queryClient } from '@/App'
import { industry } from '../../../services/industry'

export default function Clients() {
  const { user } = useAuth()
  const [searchParams, setSearchParams] = useCustomSearchParams()
  const [cols, setCols] = useState([
    {
      id: 0,
      name: 'Company',
      classes: 'w-[400px]',
      selector: (row) => (
        <LogoCell
          img={row.logo != null ? row.logo : 'https://ui-avatars.com/api/?name=Kata+Data'}
          imgShape={'rounded'}
          text={row.company_name}
        />
      ),
    },
    {
      id: 1,
      name: 'Company Code',
      classes: 'w-[100px]',
      selector: (row) => row.company_code,
    },
    {
      id: 2,
      name: 'PIC Name',
      // classes: 'min-w-[200px]',
      selector: (row) => row.pic_name || '-',
    },
    {
      id: 3,
      name: 'PIC Title',
      // classes: 'min-w-[250px]',
      selector: (row) => row.pic_title || '-',
    },
    {
      id: 4,
      name: 'Email',
      // classes: 'min-w-[250px]',
      selector: (row) => row.pic_email || '-',
    },
    {
      id: 5,
      name: 'Phone Number',
      // classes: 'min-w-[200px]',
      // selector: (row) => row.phone,
      selector: (row) => row.pic_phone_number,
    },
  ])
  const [pagination, setPagination] = useState({ total_page: 1 })
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selectedClient, setSelectedClient] = useState({})
  const { isAdmin } = usePermission()

  const clients = useQuery({
    queryKey: ['clients', { page: searchParams.page || 1, q: searchParams.q || '' }],
    queryFn: async () => {
      const res = await ClientAPI.getAllClients(searchParams.q, undefined, undefined, searchParams.page)
      setPagination(res.meta)
      return res.data
    },
  })

  useEffect(() => {
    if (clients.isLoading) {
      return
    }
    if (!!searchParams.delete) {
      setSelectedClient(clients.data.find((client) => client.id === parseInt(searchParams.delete)))
      console.log(clients.data.find((client) => client.id === parseInt(searchParams.delete)))
    }
  }, [clients.isLoading, searchParams.delete])

  const deleteClient = useMutation({
    mutationFn: async (id) => {
      const res = await ClientAPI.delete(id)
      if (!res.success) throw res
      return res
    },
    onSuccess: async (res) => {
      setOpenModal(false)
      queryClient.invalidateQueries(['clients', { page: searchParams.page || 1, q: searchParams.q || '' }])
      setDeleteModalOpen(false)
    },
    onError: (err) => {
      console.log(err)
      toast.error(err.response?.data?.message || err.statusText || 'Something went wrong! Please try again later.')
    },
  })

  function handleDelete(id) {
    setDeleteId(id)
    setOpenModal(true)
  }

  return (
    <div className="py-4">
      <div className="flex flex-col md:flex-row md:justify-between md:items-end gap-2.5 mb-2.5 md:mb-4">
        <div className="flex w-full items-center gap-2.5">
          <div className="flex-1">
            <div className="w-[300px] relative">
              <Input
                value={searchParams.q}
                onChange={(e) => setSearchParams({ ...searchParams, q: e.target.value, page: 1 })}
                placeholder="Search Client"
                className="pr-10"
              />
              <Search className="w-5 h-5 text-primary-gray absolute top-1/2 right-0 -translate-x-1/2 -translate-y-1/2" />
            </div>
          </div>
          {hasPermission(user, 'data.client.create') && (
            <Link to="add">
              <Button className={'w-full md:w-fit'}>Add New Client</Button>
            </Link>
          )}
        </div>
      </div>
      {clients.isLoading ? (
        <Loading />
      ) : (
        <Table
          cols={cols}
          data={clients.data || []}
          pageIndex={searchParams.page}
          customAction={
            !hasPermission(user, 'data.client.edit')
              ? undefined
              : (row) => {
                  return (
                    <div className="flex gap-1">
                      <Link to={`${row.id}/detail`}>
                        <Button size="icon" variant="outline">
                          <SquarePen className="w-5 h-5" />
                        </Button>
                      </Link>
                      <Button
                        size="icon"
                        variant="outline"
                        onClick={() => setSearchParams({ ...searchParams, delete: row.id })}
                      >
                        <Trash2 className="w-5 h-5" />
                      </Button>
                    </div>
                  )
                }
          }
        />
      )}
      <Pagination
        totalPage={pagination.total_page || 1}
        currentPage={parseInt(searchParams.page) || 1}
        onChange={(page) => setSearchParams({ ...searchParams, page: page })}
      />
      <Dialog open={!!searchParams.delete} onOpenChange={() => setSearchParams(delete searchParams.delete)}>
        <DialogContent className="w-full max-w-[500px]">
          <DialogHeader className={'mb-2.5'}>
            <DialogTitle className={'text-xl font-semibold'}>Are you sure to delete this client?</DialogTitle>
            {/* <DialogDescription>
            This action will result in the deletion of all sub-products and variants of the product, resulting
            it inaccessible to all users.
          </DialogDescription> */}
          </DialogHeader>
          <div>
            <p className="font-semibold">Client</p>
            <p className="text-main-500/70">{selectedClient?.company_name}</p>
          </div>
          <Alert variant="warning" size="sm">
            <AlertDescription className="flex items-start gap-2">
              <Info className="w-6 h-6" />
              <p>This action will result in the deletion the client, making it inaccessible to all users.</p>
            </AlertDescription>
          </Alert>
          <div className="flex gap-2">
            <Button variant="outline" className="flex-1">
              Cancel
            </Button>
            <Button
              variant="destructive"
              className="flex-1"
              onClick={() => deleteClient.mutate(selectedClient.id)}
              isLoading={deleteClient.isLoading}
              disabled={deleteClient.isLoading}
            >
              Yes, proceed
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

const CreateModal = ({ setOpenModal, id }) => {
  const navigate = useNavigate()

  function handleDelete() {
    console.log(id)

    ClientAPI.delete(id)
      .then((data) => {
        setOpenModal(false)
        navigate(0)
      })
      .catch((err) => {
        setOpenModal(false)
        console.log(err)
      })
  }

  return (
    <>
      <div
        className="bg-black bg-opacity-20 z-10 w-screen h-screen top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 fixed"
        onClick={() => setOpenModal(false)}
      />
      <div className="fixed z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="w-[600px] rounded bg-white shadow-lg z-20">
          <div className="p-4 overflow-hidden rounded-t border-b flex justify-between items-center mb-2.5">
            <h5 className="text-big"> Are you sure to delete this client?</h5>
            <button
              className="w-6 h-6 flex items-center justify-center rounded text-gray-400 hover:bg-red-200 hover:text-red-500"
              onClick={() => setOpenModal(false)}
            >
              <Close sx={{ fontSize: 16 }} />
            </button>
          </div>
          <div className="flex justify-end gap-2.5 p-4 pt-0">
            <Button variant="outlined" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
            <Button onClick={handleDelete}>Delete</Button>
          </div>
        </div>
      </div>
    </>
  )
}
