import * as React from 'react'
import { format } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import moment from 'moment'

export function DatePicker({ label, labelWidth, icon, className, defaultValue, onSelect }) {
  const [date, setDate] = React.useState(defaultValue || moment().format('MM-DD-YYYY'))
  const [skipFirst, setSkipFirst] = React.useState(true)

  React.useEffect(() => {
    if (skipFirst) setSkipFirst(false)
    if (!skipFirst) onSelect(date)
  }, [date])

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button id="date" variant={'outline'} className={cn('h-10 px-3 items-center justify-between', className)}>
          <div className="flex-1 flex gap-3 items-center">
            <span
              className={cn(
                'flex gap-2 items-center text-gray-900  [&_svg]:w-5 [&_svg]:h-5',
                labelWidth && `w-[${labelWidth}px]`
              )}
            >
              <CalendarIcon />
              <span className="font-medium">{label}</span>
            </span>
            <div className="border-r min-h-4 !min-w-[1px] border-gray-500"></div>
            {date ? (
              <p>
                {moment(date).startOf('week').format('D MMM YYYY')} - {moment(date).endOf('week').format('D MMM YYYY')}
              </p>
            ) : (
              <span>Pick a date</span>
            )}
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar mode="single" selected={date} onSelect={setDate} initialFocus />
      </PopoverContent>
    </Popover>
  )
}
